import { makeAutoObservable } from 'mobx';
import RootStore from './index';
import { ICheckoutInfo, IPaymentMethodWithRequisites, IPaymentInfo } from '../shared/interfaces/checkout.interface';
import checkoutService from '../shared/services/checkout/checkoutService';
import signDocumentsService from '../shared/services/sign-documents/signDocumentsService';
import { ContractData } from '../shared/services/sign-documents/types';

class CheckoutStore {
  rootStore: RootStore;

  isLoading = true;

  successCreating = false;

  successPayment = false;

  isExist = false;

  contractData: ContractData | null = null;

  message = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  checkoutInfo: ICheckoutInfo = {
    id: '',
    name: '',
    tickerSymbol: '',
    currency: '',
    unitPrice: '',
    previewImage: {
      url: '',
    },
    initialSale: null,
    isSoftCup: false,
    paymentTokenRaised: '',
    maxAmount: '',
  };

  paymentMethods: IPaymentMethodWithRequisites[] = [];

  setCheckoutInfo(payload: ICheckoutInfo) {
    this.checkoutInfo = payload;
  }

  setPaymentMethods(payload: IPaymentMethodWithRequisites[]) {
    this.paymentMethods = payload;
  }

  resetContractData() {
    this.contractData = null;
    this.isExist = false;
  }

  *getCheckoutInfoById(id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      this.message = '';
      const checkoutInfo: ICheckoutInfo = yield checkoutService.getCheckoutInfo(id);
      this.setCheckoutInfo(checkoutInfo);
    } catch (e) {
      this.message = 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *createContractToSign(assetId: string, amount: number) {
    this.isLoading = true;
    this.successCreating = false;
    this.rootStore.loaderStore.toggleLoader();
    try {
      yield signDocumentsService.createContract(assetId, amount);
      this.successCreating = true;
      this.message = '';
    } catch (e: any) {
      this.isLoading = false;
      if (e?.response?.data?.message) {
        this.message = e.response.data.message;
      }
    }
  }

  *getPortalContractStatus(assetId: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const data: ContractData = yield signDocumentsService.getContractDataByAsset(assetId);
      if (data.embeddedUrl) {
        this.isLoading = false;
        this.rootStore.loaderStore.toggleLoader();
        this.contractData = data;
        this.message = '';
      }
    } catch (e: any) {
      this.isLoading = false;
      if (e?.response?.data?.message) {
        this.message = e.response.data.message;
      }
    }
  }

  *isContractExist() {
    try {
      this.message = '';
      const data: { result: boolean } = yield signDocumentsService.isExist();
      this.isExist = data.result;
    } catch (e: any) {
      if (e?.response?.data?.message) {
        this.message = e.response.data.message;
      }
    }
  }

  *closeContract(contractId: string, purchaseProvider: string) {
    try {
      yield signDocumentsService.closeContract(contractId, purchaseProvider);
    } catch (e: any) {
      if (e?.response?.data?.message) {
        this.message = e.response.data.message;
      }
    }
  }

  noContract() {
    this.isLoading = false;
    this.rootStore.loaderStore.toggleLoader();
    this.message = 'Can`t get purchase contract to sign';
  }

  *getPaymentMethods(id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const paymentMethods: IPaymentMethodWithRequisites[] = yield checkoutService.getPaymentMethods(id);
      this.setPaymentMethods(paymentMethods);
      this.rootStore.loaderStore.toggleLoader();
    } catch {
      this.message = 'Server Error';
    } finally {
      this.isLoading = false;
    }
  }

  *stripePurchase(payload: IPaymentInfo) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const linkToPurchase: string = yield checkoutService.stripePurchase(payload);
      document.location.assign(linkToPurchase);
      this.rootStore.loaderStore.toggleLoader();
    } catch {
      this.message = 'Stripe Purchase Error';
    } finally {
      this.isLoading = false;
    }
  }

  *fortressPurchase(payload: IPaymentInfo) {
    this.isLoading = true;
    try {
      this.message = '';
      yield checkoutService.fortressPurchase(payload);
      this.successPayment = true;
    } catch (e: any) {
      this.message = e.response.data.message;
    } finally {
      this.isLoading = false;
    }
  }
}

export default CheckoutStore;
