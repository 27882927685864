import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import numberWithCommasDots from '@token-suite/common-ui/src/sharedFunctions/numberWIthDots';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import BuyArrow from '../../../shared/assets/icons/buy.svg';
import { useStores } from '../../../store';
import CheckoutStages from '../../../components/checkout-payments/checkout-stages/checkoutStages';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';
import c from './style.module.scss';
import {
  EAccountAccreditationStatuses,
  EAccountStatuses,
  EAccountStatusesNorthCapital,
} from '../../../shared/types/account.types';

function CheckoutOverview() {
  const navigate = useNavigate();
  const { checkoutStore, portalStore, vereficationStore, headerStore } = useStores();
  const { id, tokens = 0 } = useParams();
  const [contractCreation, setContractCreation] = useState(false);

  const verifyStatus = vereficationStore.userInfo.verificationStatus;

  const checkPrepared = useCallback(() => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return (
        verifyStatus.kycStatus !== EAccountStatuses.Verified ||
        (verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.Verified &&
          verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded) ||
        (verifyStatus.isBusiness &&
          verifyStatus.fortressKybVerificationStatus?.kybStatus !== EAccountStatuses.Verified) ||
        !headerStore.user.wallet
      );
    }
    return verifyStatus.status && verifyStatus.status !== EAccountStatusesNorthCapital.Verified;
  }, [
    headerStore.user.wallet,
    portalStore.portalUI.features?.kycKyb,
    verifyStatus.accreditationStatus,
    verifyStatus.kycStatus,
    verifyStatus.isBusiness,
    verifyStatus.fortressKybVerificationStatus?.kybStatus,
    verifyStatus.status,
  ]);

  useEffect(() => {
    flowResult(vereficationStore.getUserVerifyStatus()).then(() => {
      if (checkPrepared()) {
        navigate(`/checkout/onboarding/${id}/${tokens}`);
      }
    });
  }, [vereficationStore, id, tokens, checkPrepared, navigate]);

  useEffect(() => {
    if (!tokens || !id) {
      navigate('/assets');
      return;
    }
    const isExist = async () => {
      await flowResult(checkoutStore.isContractExist());
      if (checkoutStore.checkoutInfo.id !== id) {
        flowResult(checkoutStore.getCheckoutInfoById(id as string));
      }
    };
    isExist();
  }, [checkoutStore, id, tokens, navigate]);

  const postPurchase = async () => {
    if (id && tokens) {
      if (checkoutStore.isExist) {
        setContractCreation(true);
        await flowResult(checkoutStore.createContractToSign(id, +tokens));
        if (checkoutStore.successCreating) {
          let seconds = 0;
          const interval = setInterval(() => {
            seconds++;
            flowResult(checkoutStore.getPortalContractStatus(id));
            if (checkoutStore.contractData?.embeddedUrl) {
              setContractCreation(false);
              clearInterval(interval);
              return;
            }
            if (seconds >= 20) {
              setContractCreation(false);
              checkoutStore.noContract();
              clearInterval(interval);
            }
          }, 3000);
        }
      } else {
        navigate(`/checkout/payment/${id}/${tokens}`);
      }
    }
  };

  useEffect(() => {
    const checkDocumentSign = (event: any) => {
      const type = event.data && event.data.type;
      if (type === 'session_view.document.completed') {
        navigate(`/checkout/payment/${id}/${tokens}`);
      }
    };
    window.addEventListener('message', checkDocumentSign);
    return () => {
      window.removeEventListener('message', checkDocumentSign);
    };
  }, [id, navigate, tokens]);

  return (
    <div className={c.checkout}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Checkout Order | ${portalStore.portalUI.portalUi.title}`
            : 'Checkout Order | TokenSuite'}
        </title>
      </Helmet>
      <CheckoutStages />
      {checkoutStore.isLoading ? (
        <div className={c.contractLoading}>
          {contractCreation && <p>Subscription agreement is being generated, it may take up to 1 minute...</p>}
          <Spinner single />
        </div>
      ) : (
        <div className={c.container}>
          {checkoutStore.contractData?.embeddedUrl ? (
            <iframe src={checkoutStore.contractData?.embeddedUrl} title="contract to sign" className={c.contract} />
          ) : (
            <>
              <div className={c.cart}>
                <div className={c.head}>
                  <div className={c.asset}>Asset</div>
                  <div className={c.quantity}>Quantity</div>
                  <div className={c.price}>Unit Price</div>
                  <div className={c.subtotal}>Subtotal</div>
                </div>
                <div className={c.card}>
                  <div className={c.asset}>
                    <div className={c.img}>
                      <img src={checkoutStore.checkoutInfo.previewImage.url} alt="" />
                    </div>
                    <div className={c.info}>
                      <div className={c.title}>{checkoutStore.checkoutInfo.name}</div>
                      <div className={c.tokens}>
                        {checkoutStore.checkoutInfo.tickerSymbol
                          ? `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()} tokens`
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className={c.quantity}>
                    <span className={c.label}>Quantity</span>
                    <span className={c.value}>{tokens}</span>
                  </div>
                  <div className={c.price}>
                    <span className={c.label}>Unit Price</span>
                    <span className={c.value}>
                      {CURRENCY_SYMBOLS[checkoutStore.checkoutInfo.currency]}
                      {numberWithCommasDots(+checkoutStore.checkoutInfo.unitPrice)}
                    </span>
                  </div>
                  <div className={c.subtotal}>
                    <span className={c.label}>Subtotal</span>
                    <span className={c.value}>
                      {CURRENCY_SYMBOLS[checkoutStore.checkoutInfo.currency]}
                      {addComma(String(+checkoutStore.checkoutInfo.unitPrice * +tokens))}
                    </span>
                  </div>
                </div>
              </div>
              <div className={c.rightOverview}>
                <div className={c.wrapper}>
                  <div className={c.total}>
                    <div className={c.result}>
                      <div className={c.title}>Total:</div>
                      <div className={c.amount}>
                        {CURRENCY_SYMBOLS[checkoutStore.checkoutInfo.currency]}
                        {addComma(String(+checkoutStore.checkoutInfo.unitPrice * +tokens))}{' '}
                        <span className={c.currency}>{checkoutStore.checkoutInfo.currency}</span>
                      </div>
                    </div>
                    <button className={c.next} onClick={postPurchase}>
                      {checkoutStore.isExist ? 'Sign Contract' : 'Next'}
                      <img src={BuyArrow} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default observer(CheckoutOverview);
