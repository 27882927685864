import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import axios from 'axios';
import userPool from '../../config/cognitoPool';

const baseURL = process.env.REACT_APP_API_KEY;
const tokenInstance = axios.create({ baseURL });

// Request interceptor for API calls
tokenInstance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('accessToken');
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: 0,
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
tokenInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.is_retry) {
      originalRequest.is_retry = true;
      const cognitoUser = userPool.getCurrentUser();
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        const promise = new Promise((resolve, reject) => {
          cognitoUser?.refreshSession(new CognitoRefreshToken({ RefreshToken: refreshToken }), (err, session) => {
            if (err) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              cognitoUser?.signOut();

              reject();
            } else {
              localStorage.setItem('accessToken', `${session.getAccessToken().getJwtToken()}`);
              localStorage.setItem('refreshToken', `${session.getRefreshToken().getToken()}`);
              resolve('setTokens');
            }
          });
        });
        try {
          await promise;
          return await tokenInstance(originalRequest);
        } catch {
          document.location.reload();
        }
      }
    }
    return Promise.reject(error);
  },
);

export default tokenInstance;
