import DashboardPage from '../../../pages/issuer/dashboard/dashboard';
import IssuerAssetsPage from '../../../pages/issuer/issuer-assets/issuer-assets';
import Asset from '../../../pages/issuer/asset/Asset';
import Investors from '../../../pages/issuer/investors/investors';
import CheckoutOverview from '../../../pages/investor/checkout/checkoutOverview';
import CheckoutPayment from '../../../pages/investor/checkout/checkoutPayment';
import CheckoutConfirmation from '../../../pages/investor/checkout/checkoutConfirmation';
import EditAssetPage from '../../../pages/admin/edit-asset/editAsset';
import WalletPage from '../../../pages/common/wallet/wallet';
import DividendDistributionPage from '../../../pages/issuer/dividend-destribution';
import DividendDistributionAssetsPage from '../../../pages/issuer/dividend-destribution-assets';
import InvestorDetailsPage from '../../../pages/issuer/investor-details';
import DividendCalculationPage from '../../../pages/issuer/dividend-calculations/dividendCalculations';
import { IRoute } from '../model/private-route';
import CheckoutVerification from '../../../pages/investor/checkout/checkoutVerification';
import BrokersTable from '../../../pages/issuer/brokers/brokers';

const issuerRoutes: IRoute[] = [
  {
    path: '/portal-settings',
    element: DashboardPage,
  },
  {
    path: '/issuer-assets',
    element: IssuerAssetsPage,
  },
  {
    path: '/issuer-assets/:id',
    element: Asset,
  },
  {
    path: '/investors',
    element: Investors,
  },
  {
    path: '/checkout/onboarding/:id/:tokens',
    element: CheckoutVerification,
  },
  {
    path: '/checkout/order/:id/:tokens',
    element: CheckoutOverview,
  },
  {
    path: '/checkout/payment/:id/:tokens',
    element: CheckoutPayment,
  },
  {
    path: '/checkout/confirmation/:id/:tokens',
    element: CheckoutConfirmation,
  },
  {
    path: '/edit-asset/:id',
    element: EditAssetPage,
  },
  {
    path: '/wallet',
    element: WalletPage,
  },
  {
    path: '/brokers',
    element: BrokersTable,
  },
  {
    path: '/dividend-destribution/assets/:id',
    element: DividendDistributionPage,
  },
  {
    path: '/dividend-destribution/assets',
    element: DividendDistributionAssetsPage,
  },
  {
    path: '/dividends/calculate/:id',
    element: DividendCalculationPage,
  },
  {
    path: '/investors/investor-details/:id',
    element: InvestorDetailsPage,
  },
];

export { issuerRoutes };
