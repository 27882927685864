import { useEffect, useState } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import withoutTokenInstance from '../../../shared/services/API/withoutTokenAPI';

import c from './style.module.scss';
import IvestorAssetCard, { AssetType } from './InvestorAssetCard/IvestorAssetCard';
import { useStores } from '../../../store';

type ResponseType = {
  tickerSymbol: string;
  currency: string;
  id: string;
  name: string;
  minAmount: number;
  maxAmount: number;
  targetAmount: number;
  unitPrice: number;
  location: string;
  previewImage: {
    url: string;
  };
  investmentDetail: {
    revenueType: string;
    capitalStack: string;
    propertyType: string;
  };
  isSoftCup: boolean;
  paymentTokenRaised: string;
  endDate: string;
};

const translateResponce = (r: ResponseType): AssetType => ({
  id: r.id,
  name: r.name,
  img: r.previewImage.url,
  location: r.location,
  alreadyInvested: r.paymentTokenRaised ? +r.paymentTokenRaised : 0,
  currency: r.currency,
  investmentCap: r.maxAmount,
  minIvesment: r.minAmount,
  targetAmount: r.targetAmount,
  pricePerToken: r.unitPrice,
  tags: [r.investmentDetail.propertyType, r.investmentDetail.capitalStack],
  tickerSymbol: r.tickerSymbol,
  endDate: r.endDate,
});

export default function InvestorAssets() {
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState<AssetType[]>([]);
  const { portalStore } = useStores();

  useEffect(() => {
    withoutTokenInstance.get<ResponseType[]>('assets/marketplace').then(asset => {
      if (asset.data.length > 0) {
        setAssets(asset.data.map(a => translateResponce(a)));
      }
      setLoading(false);
    });
  }, []);

  if (loading) return <Spinner />;
  if (assets.length === 0)
    return (
      <div className={c.noAssets}>
        <Helmet>
          <title>
            {portalStore.portalUI?.portalUi?.title
              ? `Assets | ${portalStore.portalUI.portalUi.title}`
              : 'Assets | TokenSuite'}
          </title>
        </Helmet>
        <FormattedMessage id="assetsPage.noAssets" />
      </div>
    );

  return (
    <div className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Assets | ${portalStore.portalUI.portalUi.title}`
            : 'Assets | TokenSuite'}
        </title>
      </Helmet>
      <div className={c.list}>
        {assets.map(a => (
          <IvestorAssetCard key={a.id} asset={a} />
        ))}
      </div>
    </div>
  );
}
