import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import c from './styles.module.scss';
import { RequestParams, NonMintedAsset } from '../../../../shared/types/investorTableType';
import { useStores } from '../../../../store';
import assetService from '../../../../shared/services/asset/assetService';
import { usePagination } from '../../../../shared/hooks/usePagination';
import filterDESC from '../../../../shared/assets/icons/filterArrowsDESC.svg';
import filterASC from '../../../../shared/assets/icons/filterArrowsASC.svg';
import filterDisabled from '../../../../shared/assets/icons/filterArrowsDisabled.svg';
import { columns } from './NonMintedAssets.meta';
import NonMintedAssetsList from './NonMintedAssetsList';

interface NonMintedAsstesProps {
  minted?: boolean;
}

export default function NonMintedAsstes({ minted = false }: NonMintedAsstesProps) {
  const { portalStore } = useStores();
  const [items, setItems] = useState<NonMintedAsset[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const loadAssets = async (params: RequestParams) => {
    setIsLoading(true);
    try {
      const data = await (minted ? assetService.getMintedAssets(params) : assetService.getNonMintedAssets(params));
      setItems(data.assets);
      setTotalItems(data.total);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const { loadItems, controlledParams, onCurrentPageChange, onPageSizeChange, onOrderChange } = usePagination({
    callback: loadAssets,
  });

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  return (
    <div className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Assets | ${portalStore.portalUI.portalUi.title}`
            : 'Assets | TokenSuite'}
        </title>
      </Helmet>
      <h2 className={c.title}>
        {minted ? (
          <FormattedMessage id="issuersAssetPage.minted.header" />
        ) : (
          <FormattedMessage id="issuersAssetPage.nonMinted.header" />
        )}
      </h2>
      <TableContainer className={c.table_container}>
        <Table className={c.table}>
          <TableHead className={c.head}>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                  <div className={c.flexHead}>
                    {column.label}
                    {column.sortable && (
                      <IconButton disabled={isLoading} onClick={() => onOrderChange(column.id as 'name')}>
                        {renderOrderIcon(column.id as 'name')}
                      </IconButton>
                    )}
                  </div>
                </TableCell>
              ))}
              {minted && (
                <>
                  <TableCell>
                    <FormattedMessage id="issuersAssetPage.nonMinted.table.head.investors" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="issuersAssetPage.nonMinted.table.head.fundedPercentage" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="issuersAssetPage.nonMinted.table.head.contractAddress" />
                  </TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
            {isLoading && items.length === 0 && (
              <TableRow>
                <TableCell colSpan={19}>
                  <div className={c.firstLoading}>
                    <Spinner single />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!isLoading && items.length === 0 && (
              <TableRow>
                <TableCell colSpan={19}>
                  <div className={c.emptyBlock}>No assets yet</div>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            <NonMintedAssetsList assets={items} />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={+controlledParams.pageSize}
        page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            isLoading ||
            Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
            totalItems === 0,
        }}
      />
    </div>
  );
}
