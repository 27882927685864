import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Button, Divider, IconButton, Modal, Paper, Stack, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import CloseIcon from '@mui/icons-material/Close';
import AppCognitoUser from '../../../shared/lib/cognito-wrapper';

interface MfaQrCodeProps {
  qrcode: string;
  setQrCode: Dispatch<SetStateAction<string>>;
  cUser: AppCognitoUser | null;
  setIsMfaEnabled: Dispatch<SetStateAction<boolean>>;
}

const MfaQrCode: FC<MfaQrCodeProps> = ({ qrcode, setQrCode, cUser, setIsMfaEnabled }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    setError('');
  };

  const onSendCodeHandler = async () => {
    setIsLoading(true);
    try {
      await cUser?.verifyToken(code);
      await cUser?.enableMFA();
      await cUser?.updateAttributes([
        new CognitoUserAttribute({
          Name: 'custom:isMfaEnabled',
          Value: 'true',
        }),
      ]);
      setIsMfaEnabled(true);
      setQrCode('');
    } catch (e: any) {
      if (typeof e.message !== 'string') return;
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onModalCloseHandler = () => {
    setQrCode('');
  };

  return (
    <Modal open={Boolean(qrcode)} onClose={onModalCloseHandler}>
      <Paper
        sx={{
          maxWidth: '820px',
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 20,
        }}
      >
        <IconButton
          onClick={onModalCloseHandler}
          sx={{
            position: 'absolute',
            right: 0,
            top: -5,
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Box>
          <Box my={16}>
            <Divider />
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            <FormattedMessage id="accountSetting.security.appsTitle" />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            ml={10}
            mt={8}
            sx={{
              fontWeight: 400,
            }}
          >
            <FormattedMessage id="accountSetting.security.apps" />
          </Stack>
          <Stack mt={5} justifyContent="center" alignItems="center" maxWidth={220} mx="auto">
            <img src={qrcode} alt="qrcode" />
          </Stack>
          <Box
            my={24}
            sx={{
              fontWeight: 400,
            }}
          >
            <FormattedMessage id="accountSetting.security.actionDescription" />
          </Box>
          <Stack direction="row" gap={8}>
            <TextField
              value={code}
              onChange={onCodeChangeHandler}
              sx={[
                {
                  flex: '0 1 80%',
                },
                {
                  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                  'input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              ]}
              error={!!error}
              helperText={error}
              type="number"
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                flex: '0 1 20%',
                alignSelf: 'flex-start',
                height: 56,
              }}
              disabled={!code.trim() || isLoading || code.length !== 6}
              onClick={onSendCodeHandler}
            >
              <FormattedMessage id="accountSetting.security.btnTitle" />
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Modal>
  );
};

export default MfaQrCode;
