import { FormattedMessage } from 'react-intl';
import c from './style.module.scss';

function Powered() {
  return (
    <div className={c.powered}>
      <FormattedMessage
        id="powered"
        values={{
          TokenSuite: 'TokenSuite™',
          SolidBlock: (
            <a href="https://dibscapital.com/" target="_blank" rel="noreferrer">
              DIBS<span>Capital</span>
            </a>
          ),
        }}
      />
      <div className={c.terms}>
        <a
          href="https://sb-footer.s3.amazonaws.com/Terms+of+Use.docx.pdf"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          Terms of Use
        </a>
        {' | '}
        <a
          href="https://sb-footer.s3.amazonaws.com/Privacy+Policy.docx.pdf"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default Powered;
