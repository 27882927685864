import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

type Column = {
  id: any;
  label: ReactElement | null;
  minWidth?: number;
  sortable?: true;
  left?: true;
  format?: (asset: any) => string;
  render?: (asset: any) => JSX.Element;
};

const columns: readonly Column[] = [
  {
    id: 'name',
    left: true,
    label: <FormattedMessage id="investorsPage.allowList.table.columns.name" />,
    sortable: true,
  },
  {
    id: 'asset',
    left: true,
    label: <FormattedMessage id="investorsPage.allowList.table.columns.assetName" />,
    sortable: true,
  },
  {
    id: 'token',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.token" />,
    sortable: true,
  },
  {
    id: 'tokenAllocation',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.tokenAllocation" />,
    sortable: true,
  },
  {
    id: 'value',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.value" />,
    sortable: true,
  },
  {
    id: 'currency',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.currency" />,
  },
  {
    id: 'price',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.tokenPrice" />,
    sortable: true,
  },
];

export { columns };
