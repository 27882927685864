import React, { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import copyMail from '../../../shared/assets/icons/copyMail.svg';
import c from './style.module.scss';
import { copyDataInBuffer } from '../../../shared/lib';
import { IInvestorDividend } from '../../../shared/interfaces/userInfo.interface';

function DividendRow({ investorData }: { investorData: IInvestorDividend }) {
  const { firstName, lastName, email, tokensAmount, tokenAllocation, dividends } = investorData;
  const emailRef = useRef<null | HTMLDivElement>(null);
  const [isEmailTooltipOpen, setIsEmailTooltipOpen] = useState(false);

  const mouseOnEmailHandler = () => {
    if (!emailRef.current) return;
    if (emailRef.current.offsetWidth >= emailRef.current.scrollWidth) return;
    setIsEmailTooltipOpen(true);
  };

  return (
    <div className={`${c.profileRow}`}>
      <div className={c.columnLeft}>
        <div className={c.name}>
          {firstName} {lastName}
        </div>
        <div className={c.email}>
          <Tooltip
            open={isEmailTooltipOpen}
            onClose={() => setIsEmailTooltipOpen(false)}
            title={email}
            onOpen={mouseOnEmailHandler}
            arrow
          >
            <div className={c.emailText} ref={emailRef}>
              {email}
            </div>
          </Tooltip>
          <button
            onClick={() => {
              copyDataInBuffer(email);
            }}
          >
            <img src={copyMail} alt="copyMail" />
          </button>
        </div>
      </div>
      <div className={c.columnRight}>
        <div className={c.itemColType}>
          <span className={c.mobileDescription}>Token Allocation - </span>
          {tokensAmount ? addComma(tokensAmount.toString()) : '0'}
        </div>
        <div className={c.itemColType}>
          <span className={c.mobileDescription}>Allocation % - </span>
          {tokenAllocation || '0'}
        </div>
        <div className={c.itemColType}>
          <span className={c.mobileDescription}>USD Value - </span>
          {dividends ? `$${addComma(dividends)}` : '$0'}
        </div>
      </div>
    </div>
  );
}
export default DividendRow;
