import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { IconButton } from '@mui/material';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { Helmet } from 'react-helmet-async';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import c from './styles.module.scss';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { IPaymentMethods, IRecuisite } from '../../../shared/interfaces/checkout.interface';
import { useStores } from '../../../store';

function PaymentMethodsPage() {
  const [wireId, setWireId] = useState('');
  const { checkoutStore } = useStores();
  const { id } = useParams();
  const [requisites, setRequisites] = useState<IRecuisite[]>([]);
  const [loading, setLoading] = useState(true);

  const addRequsite = () => {
    setRequisites([...requisites, { name: '', value: '', position: 0 }]);
  };

  const perkTitleHandler = (e: string, index: number) => {
    const tempArr = requisites;
    tempArr[index].name = e;
    setRequisites([...tempArr]);
  };
  const perkDescriptionHandler = (e: string, index: number) => {
    const tempArr = requisites;
    tempArr[index].value = e;
    setRequisites([...tempArr]);
  };

  const removeFile = (index: number) => {
    const newrequisites = requisites.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setRequisites(newrequisites);
  };

  useEffect(() => {
    tokenInstance
      .get('payments/methods')
      .then(({ data }: IPaymentMethods) => {
        const wireMethodId = data.find(method => method.name.toLowerCase() === 'wire');
        if (wireMethodId) {
          setWireId(wireMethodId.id);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    flowResult(checkoutStore.getPaymentMethods(id as string)).then(() => {
      const wireRequisites = checkoutStore.paymentMethods.find(item => item.name.toLocaleLowerCase() === 'wire');
      if (wireRequisites?.requisites) {
        setRequisites(wireRequisites?.requisites);
      }
    });
  }, [id, checkoutStore]);

  const [errors, setError] = useState<string[]>([]);
  const [sucess, setSucess] = useState(false);

  const submit = () => {
    tokenInstance
      .put('payments/requisites', {
        assetId: id,
        paymentMethodId: wireId,
        requisites,
      })
      .then(() => {
        setError([]);
        setSucess(true);
        setTimeout(() => {
          setSucess(false);
        }, 5000);
      })
      .catch(e => {
        if (Array.isArray(e.response.data.errors)) {
          setError(e.response.data.errors);
        } else {
          setError([e.response.data.errors, e.response.data.message]);
        }
      });
  };

  const addWirePayment = () => {
    tokenInstance
      .post('payments/methods', {
        name: 'wire',
      })
      .then(() => {
        tokenInstance.get('payments/methods').then(({ data }: IPaymentMethods) => {
          const wireMethodId = data.find(method => method.name.toLowerCase() === 'wire');
          if (wireMethodId) {
            setWireId(wireMethodId.id);
          }
        });
      })
      .catch(e => {
        if (Array.isArray(e.response.data.errors)) {
          setError(e.response.data.errors);
        } else {
          setError([e.response.data?.errors, e.response.data.message]);
        }
      });
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Helmet>
        <title>Payments Methods Settings | TokenSuite</title>
      </Helmet>
      <Link to="/admin-assets" className={c.back}>
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="createAssetPage.text.back" />
      </Link>
      <div className={c.title}>Asset Payment Methods</div>
      <div className={c.block}>
        <div className={c.formPart}>
          {wireId ? (
            <>
              <div className={c.title}>Wire requisites</div>
              {sucess && <p className={c.sucessText}>Payment Method Updated</p>}
              <div className={c.listHighlights}>
                <div className={c.errors}>
                  {errors.map((error, index) => {
                    return (
                      <div className={c.errorText} key={index}>
                        {error}
                      </div>
                    );
                  })}
                </div>
                <div className={c.wrapperBlock}>
                  {requisites.map((perk, index: number) => (
                    <div className={c.perk} key={index}>
                      <div className={c.left}>
                        <TextIndexInput
                          value={perk.name}
                          changeValue={perkTitleHandler}
                          label={`Name ${index + 1}`}
                          placeholder="Enter name"
                          type="text"
                          fullWidth
                          index={index}
                        />
                        <TextIndexInput
                          value={perk.value}
                          changeValue={perkDescriptionHandler}
                          label={`Value ${index + 1}`}
                          placeholder="Enter value"
                          type="text"
                          fullWidth
                          multiline
                          index={index}
                        />
                        <div className={c.right}>
                          <IconButton className={c.delete} onClick={() => removeFile(index)}>
                            <img src={closeIcon} alt="X" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button className={c.addButton} onClick={addRequsite}>
                + Add another
              </button>
            </>
          ) : (
            <button className={c.addButton} onClick={addWirePayment}>
              + Add wire payment
            </button>
          )}
        </div>
      </div>
      <div className={c.btns}>
        <button className={c.btn} onClick={submit}>
          Submit
        </button>
      </div>
    </>
  );
}

export default observer(PaymentMethodsPage);
