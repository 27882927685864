import React from 'react';
import IconButton from '@mui/material/IconButton';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { FormattedMessage, useIntl } from 'react-intl';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from './style.module.scss';
import MediaBlockUploader from '../mediaBlockUploader/mediaBlockUploader';
import FormatEditor from '../../UI/formatEditor/formatEditor';

function MediaAndText({
  media,
  setMediaBlocks,
}: {
  media: Array<{ title: string; description: string; mediaFiles: Array<{ linksToVideo?: string; image?: any }> }>;
  setMediaBlocks: any;
}) {
  const intl = useIntl();
  const addReview = () => {
    setMediaBlocks([...media, { title: '', description: '', mediaFiles: [] }]);
  };

  const blockTitleHandler = (e: string, index: number) => {
    const tempArr = media;
    tempArr[index].title = e;
    setMediaBlocks([...tempArr]);
  };
  const blockDescriptionHandler = (e: string, index: number) => {
    const tempArr = media;
    tempArr[index].description = e;
    setMediaBlocks([...tempArr]);
  };

  const setIndexDocument = (file: any, index: number) => {
    const tempArr = media;
    tempArr[index].mediaFiles = file;
    setMediaBlocks([...tempArr]);
  };

  const removeFile = (index: number) => {
    const newHighlights = media.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setMediaBlocks(newHighlights);
  };

  return (
    <div className={c.wrapper}>
      <div className={c.listHighlights}>
        <div className={c.wrapperBlock}>
          {media.map((item, index) => {
            return (
              <div className={c.media} key={index}>
                <div className={c.left}>
                  <MediaBlockUploader setDocuments={setIndexDocument} documents={item.mediaFiles} index={index} />
                  <TextIndexInput
                    value={item.title}
                    changeValue={blockTitleHandler}
                    label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.title.label' })}
                    placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.title.placeholder' })}
                    type="text"
                    fullWidth
                    index={index}
                  />
                  <FormatEditor
                    value={item.description}
                    changeValue={blockDescriptionHandler}
                    label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.label' })}
                    placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.placeholder' })}
                    fullWidth
                    index={index}
                  />
                  <div className={c.right}>
                    <IconButton className={c.delete} onClick={() => removeFile(index)}>
                      <img src={closeIcon} alt="X" />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <button className={c.addButton} onClick={addReview}>
        {media.length > 0 ? (
          <FormattedMessage id="uploader.button.addAnotherImgOrVideo" />
        ) : (
          <FormattedMessage id="uploader.button.addMediaBlock" />
        )}
      </button>
    </div>
  );
}
export default MediaAndText;
