import React, { FC, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, TableCell, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import c from './style.module.scss';
import copyIcon from '../../../../shared/assets/icons/copyMail.svg';
import { TokenHolderInvestor } from '../../../../shared/interfaces/issuer/issuer.interfaces';
import { copyDataInBuffer } from '../../../../shared/lib';

interface HolderRowProps {
  holder: TokenHolderInvestor;
}

const HolderRow: FC<HolderRowProps> = ({ holder }) => {
  const { email, assets, fullName } = holder;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const onCloseMenuHandler = () => {
    setAnchorEl(null);
  };

  const onOpenMenuHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onRedirectToViewDetailsHandler = () => {
    navigate(`/investors/investor-details/${holder.id}`);
  };

  return (
    <>
      <TableRow className={c.tableRow}>
        <TableCell className={c.nameCell}>
          <Stack>
            <Stack direction="row" alignItems="center">
              {fullName}
            </Stack>
            <Stack direction="row" alignItems="center" gap={8}>
              <div className={c.email}>{email}</div>
              <IconButton onClick={() => copyDataInBuffer(email)} sx={{ p: 0 }}>
                <img src={copyIcon} alt="" />
              </IconButton>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell className={c.cell}>
          <Stack gap={32}>
            {assets.length > 0 && assets.map(({ assetName }) => <Box key={assetName}>{assetName}</Box>)}
          </Stack>
        </TableCell>
        <TableCell className={c.cell}>
          <Stack gap={32}>
            {assets.length > 0 &&
              assets.map(({ assetName, tickerSymbol }) => (
                <Box key={assetName}>{tickerSymbol ? `${tickerSymbol.toUpperCase()}` : '-'}</Box>
              ))}
          </Stack>
        </TableCell>
        <TableCell className={c.cell}>
          <Stack gap={32}>
            {assets.length > 0 &&
              assets.map(({ assetName, numberOfTokens }) => (
                <Box key={assetName}>{Intl.NumberFormat('en-US').format(numberOfTokens)}</Box>
              ))}
          </Stack>
        </TableCell>
        <TableCell className={c.cell}>
          <Stack gap={32}>
            {assets.length > 0 &&
              assets.map(({ assetName, amountOfMoney }) => (
                <Box key={assetName}>{Intl.NumberFormat('en-US').format(amountOfMoney)}</Box>
              ))}
          </Stack>
        </TableCell>
        <TableCell className={c.cell}>
          <Stack gap={32}>{assets.length > 0 && assets.map(({ assetName }) => <Box key={assetName}>USD</Box>)}</Stack>
        </TableCell>
        <TableCell className={c.cell}>
          <Stack gap={32}>
            {assets.length > 0 &&
              assets.map(({ assetName, unitPrice }) => (
                <Box key={assetName}>
                  {Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(unitPrice)}
                </Box>
              ))}
          </Stack>
        </TableCell>
        <TableCell align="center">
          <IconButton
            sx={{
              mt: -8,
            }}
            onClick={onOpenMenuHandler}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenuHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onRedirectToViewDetailsHandler}>
          <FormattedMessage id="investorsPage.menu.viewDetails" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HolderRow;
