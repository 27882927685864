import {
  SelectInput,
  TextInput,
  DateInput,
  AutocompleteInput,
  StatesAutocompleteInput,
  ICountryType,
} from '@token-suite/common-ui/src/components/Inputs/inputs';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { MuiTelInput } from 'mui-tel-input';
import scrollToTop from '@token-suite/common-ui/src/sharedFunctions/scrollToTop';
import c from './styles.module.scss';
import { states, countriesWithPhonecode } from '../../../shared/config/constants';
import IdentityFilesUploader from '../../../components/identityFilesUploader/identityFilesUploader';
import tokenInstance from '../../../shared/services/API/tokenApi';
import Back from '../../../shared/assets/icons/arrow-back.svg';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { PersonalIdentityDocumentType } from '../../../shared/interfaces/userInfo.interface';
import FortressDocumentsModal from '../../../components/UI/dialogs/fortressDocumentsModal/fortressDocumentsModal';
import { UserAgreements } from '../../../shared/services/sign-documents/types';

export default function KycPage() {
  const query = useQueryParams();
  const id = query.get('id');
  const isBenificiary = query.get('beneficiary');
  const resumbit = query.get('resubmit');
  const issuer = query.get('issuer');
  const navigate = useNavigate();
  const intl = useIntl();

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');

  const [birthDate, setBirthDate] = useState(moment(new Date()).subtract(18, 'year'));
  const [ssn, setSsn] = useState('');
  const [streetAddress, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [percentageOfDivision, setPercentage] = useState('25');
  const [city, setCity] = useState('');
  const [state, setState] = useState(null);
  const [country, setCountry] = useState<ICountryType | null>(null);
  const [idType, setIdType] = useState<'Passport' | 'Identity Card' | 'Drivers License'>('Passport');

  const [isBusiness, setBusiness] = useState(!!issuer || false);
  const [jurisdiction, setJurisdiction] = useState(false);
  const [documentFront, setFront] = useState([]);
  const [documentBack, setBack] = useState([]);

  const [dateError, setDateError] = useState(false);
  const [errorMessage, setError] = useState('');
  const [errorMessages, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [loading, setLoading] = useState(false);

  const { portalStore } = useStores();

  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    dateError: false,
    birthDate: false,
    occupation: false,
    address: false,
    zipCode: false,
    percentageOfDivision: false,
    state: false,
    city: false,
    streetAddress: false,
    ssn: false,
    email: false,
    country: false,
    phone: false,
    documentFront: false,
    jurisdiction: false,
  });

  const handlePhoneChange = (newPhone: string) => {
    setPhone(newPhone.replaceAll(' ', ''));
  };

  const getCommonFields = useCallback(() => {
    const commonFields = [
      { field: 'firstName', value: firstName },
      { field: 'lastName', value: lastName },
      { field: 'birthDate', value: birthDate },
      { field: 'country', value: country },
      { field: 'phone', value: phone },
      { field: 'zipCode', value: zipCode },
      { field: 'city', value: city },
      { field: 'streetAddress', value: streetAddress },
      { field: 'dateError', value: !dateError },
      { field: 'documentFront', value: documentFront.length > 0 },
    ];

    if (country?.label === 'United States of America') {
      commonFields.splice(commonFields.length - 1, 0, { field: 'ssn', value: ssn }, { field: 'state', value: state });
    } else {
      commonFields.splice(commonFields.length - 1, 0, { field: 'jurisdiction', value: jurisdiction });
    }
    if (isBenificiary) {
      commonFields.splice(
        commonFields.length - 1,
        0,
        { field: 'email', value: email },
        { field: 'percentageOfDivision', value: percentageOfDivision },
      );
    }
    return commonFields;
  }, [
    city,
    country,
    phone,
    dateError,
    documentFront.length,
    firstName,
    lastName,
    birthDate,
    ssn,
    email,
    isBenificiary,
    state,
    streetAddress,
    jurisdiction,
    zipCode,
    percentageOfDivision,
  ]);

  const isValidDataHandler = () => {
    const commonFields = getCommonFields();
    const newErrorFields = {
      firstName: false,
      lastName: false,
      dateError: false,
      birthDate: false,
      occupation: false,
      address: false,
      zipCode: false,
      state: false,
      city: false,
      streetAddress: false,
      ssn: false,
      email: false,
      percentageOfDivision: false,
      country: false,
      phone: false,
      jurisdiction: false,
      documentFront: false,
    };
    commonFields.forEach(({ field, value }) => {
      newErrorFields[field as 'firstName'] = typeof value === 'string' ? !value?.trim() : !value;
      if (field === 'documentFront') {
        newErrorFields[field] = !value as boolean;
      }
    });
    setErrorFields(newErrorFields);
    return (
      !commonFields.some(({ value }) => (typeof value === 'string' ? !value?.trim() : !value)) &&
      documentFront.length > 0 &&
      (!isBenificiary || (+percentageOfDivision >= 25 && +percentageOfDivision <= 100))
    );
  };

  const handleClose = () => {
    navigate('/portfolio', { replace: true });
  };

  useEffect(() => {
    setJurisdiction(false);
    if (country?.label !== 'United States of America') {
      setSsn('');
      setState(null);
      setIdType('Passport');
    }
  }, [country]);

  useEffect(() => {
    setFront([]);
    setBack([]);
  }, [idType]);

  useEffect(() => {
    if (!id) {
      // not admin pass kyc for user
      setLoading(true);
      tokenInstance
        .get('agreements')
        .then(({ data }: { data: UserAgreements }) => {
          if (!data?.eSign) {
            setOpenDocuments(true);
          }
          setLoading(false);
        })
        .catch(e => {
          setError(e.response.data.message);
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const fieldsForChange: string[] = [];
    const commonFields = getCommonFields();
    commonFields.forEach(({ field, value }) => {
      if (errorFields[field as 'firstName'] && value) {
        fieldsForChange.push(field);
      }
    });
    if (!fieldsForChange.length) return;
    const newErrorFields = { ...errorFields };
    fieldsForChange.forEach(field => {
      newErrorFields[field as 'firstName'] = false;
    });
    setErrorFields(newErrorFields);
  }, [
    city,
    country,
    phone,
    dateError,
    birthDate,
    errorFields,
    firstName,
    getCommonFields,
    lastName,
    email,
    percentageOfDivision,
    ssn,
    state,
    streetAddress,
    zipCode,
  ]);

  const submit = () => {
    setErrors([]);
    setError('');
    if (!isValidDataHandler()) {
      scrollToTop();
      return;
    }
    setLoading(true);
    const kycFormData = new FormData();
    kycFormData.append('firstName', firstName.trim());
    kycFormData.append('lastName', lastName.trim());
    kycFormData.append('phone', `${phone}`);
    kycFormData.append('dateOfBirth', moment(birthDate).format('YYYY-MM-DD'));
    kycFormData.append('street1', streetAddress.trim());
    kycFormData.append('postalCode', zipCode);
    kycFormData.append('city', city.trim());
    kycFormData.append('country', `${country?.code}`);
    if (country?.label === 'United States of America') {
      kycFormData.append('ssn', ssn);
      kycFormData.append('state', `${state}`);
    }
    if (isBenificiary) {
      kycFormData.append('email', email);
      kycFormData.append('percentageOfDivision', percentageOfDivision);
      kycFormData.append('isBusiness', 'false');
    } else {
      kycFormData.append('isBusiness', `${isBusiness}`);
    }
    kycFormData.append('documentType', PersonalIdentityDocumentType[idType]);
    kycFormData.append('DocumentFront', documentFront[0]);
    if (documentBack.length > 0) {
      kycFormData.append('DocumentBack', documentBack[0]);
    }
    if (id && isBenificiary && resumbit) {
      kycFormData.append('userId', id);
      tokenInstance
        .put('users/kyc/v2/user/beneficiary', kycFormData)
        .then(() => {
          navigate(`/beneficiars?resubmit=true&id=${id}`, { replace: true });
          setLoading(false);
        })
        .catch(e => {
          scrollToTop();
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setError(e.response.data.message);
          }
          setLoading(false);
        });
      return;
    }
    if (id && isBenificiary) {
      kycFormData.append('userId', id);
      tokenInstance
        .post('users/kyc/v2/user/beneficiary', kycFormData)
        .then(() => {
          navigate(`/beneficiars?id=${id}`, { replace: true });
          setLoading(false);
        })
        .catch(e => {
          scrollToTop();
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setError(e.response.data.message);
          }
          setLoading(false);
        });
      return;
    }
    if (id && !isBenificiary) {
      kycFormData.append('userId', id);
      tokenInstance
        .post('users/kyc/v2/user', kycFormData)
        .then(() => {
          navigate(`/user/${id}`, { replace: true });
          setLoading(false);
        })
        .catch(e => {
          scrollToTop();
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setError(e.response.data.message);
          }
          setLoading(false);
        });
      return;
    }
    if (!id && isBenificiary) {
      tokenInstance
        .post('users/kyc/v2/beneficiary', kycFormData)
        .then(() => {
          navigate('/beneficiars');
          setLoading(false);
        })
        .catch(e => {
          scrollToTop();
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setError(e.response.data.message);
          }
          setLoading(false);
        });
      return;
    }
    tokenInstance
      .post('users/kyc/v2', kycFormData)
      .then(() => {
        setOpen(true);
        setLoading(false);
      })
      .catch(e => {
        scrollToTop();
        if (e.response.data.errors) {
          setErrors(e.response.data.errors);
        } else {
          setError(e.response.data.message);
        }
        setLoading(false);
      });
  };

  function formatSSN(value: string) {
    if (!value) return value;
    const ssnFormat = value.replace(/[^\d]/g, '');
    const ssnLength = ssnFormat.length;
    if (ssnLength < 4) return ssnFormat;
    if (ssnLength < 6) {
      return `${ssnFormat.slice(0, 3)}-${ssnFormat.slice(3)}`;
    }
    return `${ssnFormat.slice(0, 3)}-${ssnFormat.slice(3, 5)}-${ssnFormat.slice(5, 9)}`;
  }

  const handleSsnInput = (e: any) => {
    const formattedSSN = formatSSN(e.target.value);
    setSsn(formattedSSN);
  };

  return (
    <>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title ? `KYC | ${portalStore.portalUI.portalUi.title}` : 'KYC | TokenSuite'}
        </title>
      </Helmet>
      {isBenificiary && (
        <button className={c.back} onClick={() => navigate(-1)}>
          <img src={Back} alt="" />
          Back
        </button>
      )}
      <div className={c.title}>
        <FormattedMessage id="kycPage.title" />
      </div>
      <div className={c.text}>
        {isBenificiary && ' For Any shareholder in the business who owns 25% or more of the primary business'}
      </div>
      <div className={c.block}>
        <div className={c.errors}>
          <div className={c.errorText}>{errorMessage}</div>
          {errorMessages.map((error, index) => {
            return (
              <div className={c.errorText} key={index}>
                {error}
              </div>
            );
          })}
        </div>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            {isBenificiary && (
              <>
                <TextInput
                  value={email}
                  changeValue={setEmail}
                  label={intl.formatMessage({ id: 'kycPage.fields.email.label' })}
                  placeholder={intl.formatMessage({ id: 'kycPage.fields.email.placeholder' })}
                  type="text"
                  error={errorFields.email}
                />
                <TextInput
                  value={percentageOfDivision}
                  changeValue={setPercentage}
                  label={intl.formatMessage({ id: 'kycPage.fields.percentage.label' })}
                  placeholder={intl.formatMessage({ id: 'kycPage.fields.percentage.placeholder' })}
                  type="number"
                  error={!(+percentageOfDivision >= 25 && +percentageOfDivision <= 100)}
                  errorText="Incorrect value"
                />
              </>
            )}
            <TextInput
              value={firstName}
              changeValue={setFirstName}
              label={intl.formatMessage({ id: 'kycPage.fields.firstName.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.firstName.placeholder' })}
              type="text"
              error={errorFields.firstName}
            />
            <TextInput
              value={lastName}
              changeValue={setLastName}
              label={intl.formatMessage({ id: 'kycPage.fields.lastName.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.lastName.placeholder' })}
              type="text"
              error={errorFields.lastName}
            />
            <DateInput
              value={birthDate}
              changeValue={setBirthDate}
              label={intl.formatMessage({ id: 'kycPage.fields.dateOfBirth.label' })}
              setError={setDateError}
              minDate={moment(new Date()).subtract(100, 'year')}
              maxDate={moment(new Date()).subtract(18, 'year')}
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            <AutocompleteInput
              value={country}
              changeValue={setCountry}
              label={intl.formatMessage({ id: 'kycPage.fields.country.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.country.placeholder' })}
              options={countriesWithPhonecode}
              error={errorFields.country}
            />
            <div className={c.inputBlock}>
              <div className={c.inputLabel}>Phone number</div>
              <MuiTelInput
                disableDropdown
                value={phone}
                onChange={handlePhoneChange}
                className={c.input}
                error={errorFields.phone}
                helperText={errorFields.phone ? `field is required` : null}
              />
            </div>
            <TextInput
              value={streetAddress}
              changeValue={setStreet}
              label={intl.formatMessage({ id: 'kycPage.fields.street.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.street.placeholder' })}
              type="text"
              error={errorFields.streetAddress}
            />
            <TextInput
              value={city}
              changeValue={setCity}
              label={intl.formatMessage({ id: 'kycPage.fields.city.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.city.placeholder' })}
              type="text"
              error={errorFields.city}
            />
            {country?.label === 'United States of America' && (
              <StatesAutocompleteInput
                value={state}
                changeValue={setState}
                label={intl.formatMessage({ id: 'kycPage.fields.state.label' })}
                placeholder={intl.formatMessage({ id: 'kycPage.fields.state.placeholder' })}
                options={states}
                error={errorFields.state}
              />
            )}
            <TextInput
              value={zipCode}
              changeValue={setZipCode}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.zipCode.placeholder' })}
              label={intl.formatMessage({ id: 'kycPage.fields.zipCode.label' })}
              type="number"
              error={errorFields.zipCode}
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.checkboxes}>
            {!isBenificiary && (
              <FormControlLabel
                className={c.checkbox}
                label={intl.formatMessage({ id: 'kycPage.fields.isEntity' })}
                control={
                  <Checkbox
                    checked={isBusiness}
                    onChange={(e: any) => setBusiness(e.target.checked)}
                    disabled={!!issuer || false}
                  />
                }
              />
            )}
            {country?.label !== 'United States of America' && (
              <div>
                <FormControlLabel
                  className={c.checkbox}
                  label={intl.formatMessage({ id: 'kycPage.fields.jurisdiction' })}
                  control={
                    <Checkbox
                      required
                      checked={jurisdiction}
                      onChange={(e: any) => setJurisdiction(e.target.checked)}
                      sx={{
                        color: `${errorFields.jurisdiction ? '#F05555' : '#8D8D8D'}`,
                      }}
                    />
                  }
                />
                {errorFields.jurisdiction && <p className={c.validationText}>Your confirmation is required</p>}
              </div>
            )}
          </div>
          <div className={c.inputsBlock}>
            <SelectInput
              value={idType}
              changeValue={setIdType}
              label={intl.formatMessage({ id: 'kycPage.fields.idType.label' })}
              defaultValue={intl.formatMessage({ id: 'kycPage.fields.idType.label' })}
              disabled={country?.label !== 'United States of America'}
            >
              <MenuItem value="Passport">Passport</MenuItem>
              <MenuItem value="Drivers License">Drivers License</MenuItem>
              <MenuItem value="Identity Card">Identity Card</MenuItem>
            </SelectInput>
            {country?.label === 'United States of America' ? (
              <div className={c.inputBlock}>
                <div className={c.inputLabel}>
                  <FormattedMessage id="kycPage.fields.ssn.label" />
                </div>
                <TextField
                  error={errorFields.ssn}
                  autoComplete="off"
                  className={c.input}
                  value={ssn}
                  onChange={handleSsnInput}
                  helperText={errorFields.ssn ? 'field is required' : null}
                  placeholder={intl.formatMessage({ id: 'kycPage.fields.ssn.placeholder' })}
                />
              </div>
            ) : null}
          </div>
          <div className={c.inputsBlock}>
            <div className={idType === 'Passport' ? `${c.inputBlock} ${c.fullWidth}` : `${c.inputBlock}`}>
              <div className={c.inputLabel}>
                Document Front View {idType === 'Passport' && '(page containing the user`s name and photograph)'}
              </div>
              <IdentityFilesUploader setDocuments={setFront} documents={documentFront} cap={1} isKycPage />
              {errorFields.documentFront && (
                <Stack justifyContent="center" alignItems="center">
                  <Typography fontSize="12px" lineHeight="14px" sx={{ color: '#F05555FF', fontFamily: 'Montserrat' }}>
                    Upload document
                  </Typography>
                </Stack>
              )}
            </div>
            {idType !== 'Passport' && (
              <div className={c.inputBlock}>
                <div className={c.inputLabel}>Document Back View (optional)</div>
                <IdentityFilesUploader setDocuments={setBack} documents={documentBack} cap={1} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={c.btns}>
        <button className={c.btn} onClick={submit} disabled={loading}>
          {loading ? <>loading...</> : <FormattedMessage id="kycPage.submit" />}
        </button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div className={c.dialog}>
          <div className={c.title}>
            <FormattedMessage id="kycPage.dialog.title" />
          </div>
          <p className={c.text}>
            <FormattedMessage id="kycPage.dialog.text" />
          </p>
          <div className={c.actions}>
            <Link to="/portfolio" className={c.submitButton}>
              <FormattedMessage id="kycPage.dialog.continue" />
            </Link>
          </div>
        </div>
      </Dialog>
      <FortressDocumentsModal
        openModal={openDocuments}
        onClose={() => {
          setOpenDocuments(false);
        }}
      />
    </>
  );
}
