import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { flowResult } from 'mobx';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { ITransactionItem } from '../../../shared/interfaces/transactions.interface';
import { useStores } from '../../../store';
import {
  EAccountAccreditationStatuses,
  EAccountStatuses,
  EAccountStatusesNorthCapital,
} from '../../../shared/types/account.types';
import c from './styles.module.scss';

function PortfolioPage() {
  const [transactions, setTransactions] = useState<ITransactionItem[]>([]);
  const { vereficationStore, portalStore } = useStores();

  useEffect(() => {
    tokenInstance.get('payments/transactions').then(({ data }) => {
      setTransactions(data);
    });
    flowResult(vereficationStore.getUserVerifyStatus());
  }, [vereficationStore]);

  const verifyStatus = vereficationStore.userInfo.verificationStatus;

  if (vereficationStore.isLoading) return <Spinner />;

  const renderVerificationButtons = () => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return (
        <>
          {verifyStatus.kycStatus === EAccountStatuses.VerificationDenied && (
            <p className={c.text}>
              Your Identity Verification Failed, contact the administrator if you want to provide other data
            </p>
          )}
          {verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationDenied && (
            <p className={c.text}>
              Your Entity Verification Failed, contact the administrator if you want to provide other data
            </p>
          )}
          {verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Denied && (
            <p className={c.text}>Your Accreditation Verification Failed</p>
          )}
          {(verifyStatus.kycStatus === EAccountStatuses.VerificationPending ||
            verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationPending ||
            verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Pending) && (
            <p className={c.text}>Verification check is ongoing</p>
          )}
          {verifyStatus.kycStatus === EAccountStatuses.VerificationNotStartedYet ||
          verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationNotStartedYet ? (
            <p className={c.text}>
              <FormattedMessage id="vereficationReminderPage.text.description" />
            </p>
          ) : null}
          {verifyStatus.kycStatus === EAccountStatuses.VerificationNotStartedYet && !portalStore.portalUI.isDemo && (
            <Link to="/kyc" className={c.btn}>
              Complete Identity Verification
            </Link>
          )}
          {verifyStatus.kycStatus === EAccountStatuses.Verified &&
            verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationNotStartedYet && (
              <Link to="/beneficiars" className={c.btn}>
                Complete KYB
              </Link>
            )}
          {verifyStatus.kycStatus === EAccountStatuses.Verified &&
            verifyStatus.accreditationStatus === EAccountAccreditationStatuses.NotStartedYet &&
            (!verifyStatus.isBusiness ||
              verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.Verified) &&
            !portalStore.portalUI.isDemo && (
              <Link to="/accreditation" className={c.btn}>
                Complete Accreditation
              </Link>
            )}
          {verifyStatus.kycStatus === EAccountStatuses.Verified &&
            (!verifyStatus.isBusiness ||
              verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.Verified) &&
            (verifyStatus.accreditationStatus === EAccountAccreditationStatuses.NotNeeded ||
              verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Verified) && (
              <Link to="/assets" className={c.btn}>
                Start Investing Now
              </Link>
            )}
        </>
      );
    }
    return (
      <>
        {(verifyStatus.status === EAccountStatusesNorthCapital.VerificationDeniedKYCAML ||
          verifyStatus.status === EAccountStatusesNorthCapital.VerificationDeniedAccreditation) && (
          <p className={c.text}>Your Identity Verification Failed</p>
        )}
        {(verifyStatus.status === EAccountStatusesNorthCapital.Verification ||
          verifyStatus.status === EAccountStatusesNorthCapital.VerificationInProgress) && (
          <p className={c.text}>Verification check is ongoing</p>
        )}
        {(verifyStatus.status === EAccountStatusesNorthCapital.VerificationNotStartedYet ||
          verifyStatus.status === EAccountStatusesNorthCapital.VerificationPending) && (
          <p className={c.text}>
            <FormattedMessage id="vereficationReminderPage.text.description" />
          </p>
        )}
        {verifyStatus.status === EAccountStatusesNorthCapital.VerificationNotStartedYet &&
          !portalStore.portalUI.isDemo && (
            <Link to="/kyc-nc" className={c.btn}>
              Complete Identity Verification
            </Link>
          )}
        {verifyStatus.status === EAccountStatusesNorthCapital.VerificationPending && !portalStore.portalUI.isDemo && (
          <Link to="/accreditation" className={c.btn}>
            Complete Accreditation
          </Link>
        )}
        {verifyStatus.status === EAccountStatusesNorthCapital.Verified && (
          <Link to="/assets" className={c.btn}>
            Start Investing Now
          </Link>
        )}
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Portfolio | ${portalStore.portalUI.portalUi.title}`
            : 'Portfolio | TokenSuite'}
        </title>
      </Helmet>
      <div className={c.emptyBlock}>
        <div className={c.title}>Welcome</div>
        {renderVerificationButtons()}
      </div>
      <div className={c.emptyBlock}>
        <div className={c.title}>Your investments</div>
        <div className={c.assets}>
          {transactions.map((item: ITransactionItem) => (
            <div className={c.assetRow} key={item.id}>
              <div className={c.assetName}>{item.asset.name}</div>
              <div className={c.number}>{item.numberOfTokens} tokens</div>
              <div className={c.number}>${item.amountOfMoney}</div>
            </div>
          ))}
        </div>
        {transactions.length === 0 && <p>You dont have any tokens yet</p>}
      </div>
    </div>
  );
}

export default observer(PortfolioPage);
