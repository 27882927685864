import React from 'react';
import { Helmet } from 'react-helmet-async';
import AccountSetting from '../../../components/account-setting/AccountSetting';

const SettingPage = () => {
  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <AccountSetting />
    </>
  );
};

export default SettingPage;
