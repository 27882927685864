import { makeAutoObservable } from 'mobx';
import RootStore from './index';
import {
  InviteIssuerDto,
  InviteDemoIssuerDto,
  ActivateDemoIssuerDto,
} from '../shared/services/admin/adminService.types';
import adminService from '../shared/services/admin/adminService';

class AdminStore {
  rootStore: RootStore;

  isLoading = false;

  errorMessage = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  *inviteIssuer(dto: InviteIssuerDto) {
    this.isLoading = true;
    yield adminService.inviteIssuer(dto);
    this.isLoading = false;
  }

  *inviteDemoIssuer(dto: InviteDemoIssuerDto) {
    this.isLoading = true;
    yield adminService.inviteDemoIssuer(dto);
    this.isLoading = false;
  }

  *activateDemoIssuer(dto: ActivateDemoIssuerDto, id: string) {
    this.isLoading = true;
    yield adminService.activateDemoIssuer(dto, id);
    this.rootStore.portalStore.getPortalUiById(id);
    this.isLoading = false;
  }
}

export default AdminStore;
