import React from 'react';
import IconButton from '@mui/material/IconButton';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { FormattedMessage, useIntl } from 'react-intl';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from './style.module.scss';

function Perks({ perks, setPerks }: { perks: Array<{ title: string; description: string }>; setPerks: any }) {
  const intl = useIntl();
  const addPerk = () => {
    setPerks([...perks, { title: '', description: '' }]);
  };

  const perkTitleHandler = (e: string, index: number) => {
    const tempArr = perks;
    tempArr[index].title = e;
    setPerks([...tempArr]);
  };
  const perkDescriptionHandler = (e: string, index: number) => {
    const tempArr = perks;
    tempArr[index].description = e;
    setPerks([...tempArr]);
  };

  const removeFile = (index: number) => {
    if (perks.length === 1) {
      return;
    }
    const newPerks = perks.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setPerks(newPerks);
  };

  return (
    <div className={c.wrapper}>
      <div className={c.listHighlights}>
        <div className={c.wrapperBlock}>
          {perks.map((perk, index: number) => (
            <div className={c.perk} key={index}>
              <div className={c.left}>
                <TextIndexInput
                  value={perk.title}
                  changeValue={perkTitleHandler}
                  label={intl.formatMessage(
                    { id: 'createAssetPage.investorPerks.field.title.label' },
                    { count: `${index + 1}` },
                  )}
                  placeholder={intl.formatMessage({ id: 'createAssetPage.investorPerks.field.title.placeholder' })}
                  type="text"
                  fullWidth
                  index={index}
                />
                <TextIndexInput
                  value={perk.description}
                  changeValue={perkDescriptionHandler}
                  label={intl.formatMessage(
                    { id: 'createAssetPage.investorPerks.field.description.label' },
                    { count: `${index + 1}` },
                  )}
                  placeholder={intl.formatMessage({
                    id: 'createAssetPage.investorPerks.field.description.placeholder',
                  })}
                  type="text"
                  fullWidth
                  multiline
                  index={index}
                />
                <div className={c.right}>
                  <IconButton className={c.delete} onClick={() => removeFile(index)}>
                    <img src={closeIcon} alt="X" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className={c.addButton} onClick={addPerk}>
        <FormattedMessage id="createAssetPage.investorPerks.button.addPerk" />
      </button>
    </div>
  );
}
export default Perks;
