import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, InputAdornment, TablePagination, TextField } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import { ProfileData } from '../../../shared/interfaces/headerInterface';
import tokenInstance from '../../../shared/services/API/tokenApi';
import filterDisabled from '../../../shared/assets/icons/filterArrowsDisabled.svg';
import filterASC from '../../../shared/assets/icons/filterArrowsASC.svg';
import filterDESC from '../../../shared/assets/icons/filterArrowsDESC.svg';
import { RequestParams } from '../../../shared/types/investorTableType';
import { usePagination } from '../../../shared/hooks/usePagination';
import ProfileRow from '../../../components/profileRow/profileRow';

// TODO Create shared components for such tables

export default function AdminInvestorsPage() {
  const intl = useIntl();
  const [items, setItems] = useState<ProfileData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [searchText, setSearch] = useState('');

  const loadItems = async (requestParams: RequestParams) => {
    setIsLoading(true);
    const { data } = await tokenInstance.get<{ users: ProfileData[]; total: number }>('users/investors/all', {
      params: requestParams,
    });
    setItems(data.users);
    setTotalItems(data.total);
    setIsLoading(false);
  };

  const {
    controlledParams,
    onOrderChange,
    onPageSizeChange,
    onCurrentPageChange,
    onFilterChange,
    loadItems: load,
  } = usePagination({
    callback: loadItems,
  });

  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  const searchClick = () => {
    onFilterChange(searchText);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      searchClick();
    }
  };

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <Helmet>
        <title>Investors | TokenSuite</title>
      </Helmet>
      <div className={c.title}>
        <FormattedMessage id="investorsPage.text.header" />
      </div>
      <div className={c.wrapper}>
        <div className={c.topPanel}>
          <TextField
            onChange={e => setSearch(e.target.value)}
            onKeyDown={e => handleEnterPress(e)}
            value={searchText}
            className={`${c.input} ${c.search}`}
            margin="normal"
            placeholder={intl.formatMessage({ id: 'investorsPage.input.search.placeholder' })}
            InputProps={{
              inputProps: {
                maxLength: 35,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <button className={c.searchBtn} onClick={searchClick}>
                    {' '}
                    <SearchIcon />{' '}
                  </button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={c.table}>
          <div className={c.titleRow}>
            <div className={c.columnLeftTitle}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.name" />
              <IconButton className={c.orderButton} disabled={isLoading} onClick={() => onOrderChange('name')}>
                {renderOrderIcon('name')}
              </IconButton>
            </div>
            <div className={c.columnRightTitleStatus}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.status" />
              <IconButton disabled={isLoading} onClick={() => onOrderChange('status')} className={c.orderButton}>
                {renderOrderIcon('status')}
              </IconButton>
            </div>
            <div className={`${c.columnRightTitleStatus} ${c.noSort}`}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.type" />
            </div>
            <div className={`${c.columnRightTitleStatus} ${c.noSort}`}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.isActive" />
            </div>
            <div className={`${c.columnRightTitleStatus} ${c.noSort}`}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.portalName" />
            </div>
          </div>
          <div className={c.profilesRows}>
            <div className={c.loadingCell}>
              {isLoading && (
                <div className={c.loading}>
                  <Spinner single />
                </div>
              )}
            </div>
            {items.length > 0 ? (
              items.map((profile: ProfileData) => (
                <ProfileRow profileData={profile} onboardingInvestors key={profile.email} setItems={setItems} />
              ))
            ) : (
              <div className={c.emptyBlock}>
                <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
              </div>
            )}
          </div>
          <TablePagination
            className={c.pagination}
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={+controlledParams.pageSize}
            page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
            onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
            onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
            SelectProps={{
              disabled: isLoading,
            }}
            backIconButtonProps={{
              disabled: isLoading || +controlledParams.currentPage === 0,
            }}
            nextIconButtonProps={{
              disabled:
                isLoading ||
                Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
                totalItems === 0,
            }}
          />
        </div>
      </div>
    </>
  );
}
