import React, { useEffect } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import c from './styles.module.scss';
import AssetRow from './asset-row/asset-row';
import '../../../app/styles/reusable/tables.scss';
import { useStores } from '../../../store';
import StartDividendDate from '../../../components/distribution-page/startDividendDate/startDividendDate';
import CalculateDividendModal from '../../../components/distribution-page/calculateDividendModal/calculateDividendModal';
import { IMarketPlaceAsset } from '../../../shared/services/asset/asset.types';

function DividendDistributionAssetsPage() {
  const { dividendStore, portalStore } = useStores();

  useEffect(() => {
    flowResult(dividendStore.getAllIssuerAssets());
    return () => {
      dividendStore.resetAssets();
    };
  }, [dividendStore]);

  return (
    <>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Dividend Distribution | ${portalStore.portalUI.portalUi.title}`
            : 'Dividend Distribution | TokenSuite'}
        </title>
      </Helmet>
      <div className={c.title}>Assets Ready for Dividend Distribution</div>
      <div className={c.wrapper}>
        <TableContainer className="wrapperDT">
          <Table>
            <TableHead>
              <TableRow className={c.headRow}>
                <TableCell className={c.imageRow} />
                <TableCell className={c.headCellDT}>Asset Name</TableCell>
                <TableCell className={c.headCellDT}>Token Amount</TableCell>
                <TableCell className={c.headCellDT} width="20%">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dividendStore.isLoading && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Spinner single />
                  </TableCell>
                </TableRow>
              )}
              {dividendStore.allDividendIssuerAssets.length > 0 &&
                dividendStore.allDividendIssuerAssets.map((assetItem: IMarketPlaceAsset) => (
                  <AssetRow asset={assetItem} key={assetItem.id} />
                ))}
              {!dividendStore.allDividendIssuerAssets.length && !dividendStore.isLoading && (
                <TableRow>
                  <TableCell className="emptyBlockDT" colSpan={4}>
                    <FormattedMessage id="assetsDividendPage.noAssets" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <StartDividendDate />
      <CalculateDividendModal />
    </>
  );
}

export default observer(DividendDistributionAssetsPage);
