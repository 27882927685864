import { observer } from 'mobx-react-lite';

import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AdvanceInvestmentDetails() {
  const {
    assetStore: { currentAsset },
  } = useStores();
  return (
    <div className={c.investmentDetails}>
      <div className={c.title}>Investment Details</div>
      <div className={c.fieldItem}>
        <div className={c.field}>Revenue type</div>
        <div className={c.value}>{currentAsset.investmentDetail?.revenueType}</div>
      </div>
      <div className={c.fieldItem}>
        <div className={c.field}>Capital Stack</div>
        <div className={c.value}>{currentAsset.investmentDetail?.capitalStack}</div>
      </div>
      <div className={c.fieldItem}>
        <div className={c.field}>Property Type</div>
        <div className={c.value}>{currentAsset.investmentDetail?.propertyType}</div>
      </div>
    </div>
  );
}

export default observer(AdvanceInvestmentDetails);
