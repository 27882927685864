import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
} from '@mui/material';
import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import c from './style.module.scss';

export interface AcceptActionDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAgreeHandler: () => void | Promise<void>;
  title: string;
  content: string;
  isActionLoading?: boolean;
  children?: ReactNode;
  actionButtonText?: string;
}

const AcceptActionDialog: FC<AcceptActionDialogProps> = ({
  open,
  setOpen,
  title,
  content,
  onAgreeHandler,
  isActionLoading = false,
  children = null,
  actionButtonText = '',
}) => {
  const onCloseHandler = () => {
    setOpen(false);
  };

  const onAgreeClickHandler = async () => {
    await onAgreeHandler();
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={c.wrapper}
    >
      <IconButton
        aria-label="close"
        onClick={onCloseHandler}
        sx={{
          position: 'absolute',
          right: 0,
          top: -4,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: 24,
        }}
        className={c.title}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Divider />
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            pt: 24,
            pb: 4,
          }}
          className={c.content}
        >
          {content}
        </DialogContentText>
        {children && <Stack>{children}</Stack>}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          px: 24,
          pb: 24,
          pt: 0,
          gap: 16,
        }}
      >
        <Button onClick={onAgreeClickHandler} className={c.agreeActionBtn} fullWidth disabled={isActionLoading}>
          {actionButtonText || 'Delete'}
        </Button>
        <Button onClick={onCloseHandler} className={c.cancelActionBtn} fullWidth>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptActionDialog;
