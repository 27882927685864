import React from 'react';
import c from './styles.module.scss';
import { IGetAllBalance } from '../../shared/interfaces/HeaderWallet.interface';

export const WalletBalance = ({ balance }: { balance: IGetAllBalance | null }) => {
  if (!balance || balance.balances.length === 0) {
    return (
      <div className={c.balances}>
        <div className={`${c.whiteBlock}`}>
          <div className={c.noTokens}>You don`t have any tokens yet</div>
        </div>
      </div>
    );
  }
  return (
    <div className={c.balances}>
      {balance.balances.map((item, index) => {
        return (
          <div className={`${c.balanceBlock}`} key={index}>
            <div className={c.img}>{item.symbol ? item.symbol.slice(0, 1) : 'C'}</div>
            <div className={c.column}>
              <div className={c.blockTitle}>{item.symbol ? item.symbol : 'Custom'}</div>
              <div className={c.block}>
                <div className={c.balance}>{item.balance}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
