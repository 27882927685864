import { makeAutoObservable } from 'mobx';
import RootStore from './index';

class LoaderStore {
  rootStore: RootStore;

  loader = false;

  btnLoader = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  toggleLoader() {
    this.loader = !this.loader;
  }

  toggleBtnLoader() {
    this.btnLoader = !this.btnLoader;
  }
}

export default LoaderStore;
