import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { ICryptoTransactions, IFortressTransactions, ITransactions } from './transactions.types';
import { RequestParams } from '../../types/investorTableType';

class TransactionsService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async getInvestorTransactions(params: RequestParams): Promise<ITransactions> {
    const { data } = await this.authInstance.get<ITransactions>('transactions/investor', {
      params,
    });
    return data;
  }

  async getTransactionsFortress(params: RequestParams): Promise<IFortressTransactions> {
    const { data } = await this.authInstance.get<IFortressTransactions>('transactions/fortress', {
      params,
    });
    return data;
  }

  async getTransactionsCrypto(params: RequestParams): Promise<ICryptoTransactions> {
    const { data } = await this.authInstance.get<ICryptoTransactions>('users/wallet/transactions', {
      params,
    });
    return data;
  }

  async getTransactionsFortressForUser(params: RequestParams, userId: string): Promise<IFortressTransactions> {
    const { data } = await this.authInstance.get<IFortressTransactions>(`transactions/fortress/user/${userId}`, {
      params,
    });
    return data;
  }

  async getIssuerTransactions(params: RequestParams): Promise<ITransactions> {
    const { data } = await this.authInstance.get<ITransactions>('transactions/issuer', {
      params,
    });
    return data;
  }

  async getCryptoTransactionsByWallet(wallet: string): Promise<ICryptoTransactions> {
    const { data } = await this.authInstance.get<ICryptoTransactions>(`transactions/wallet/${wallet}`);
    return data;
  }
}

export default new TransactionsService(tokenInstance);
