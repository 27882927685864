import React from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from './style.module.scss';
import { TokenToSign } from '../../../../shared/services/token/types';
import DefaultButton from '../../buttons/default-button';

interface IProps {
  tokens: TokenToSign[];
  handleSignClick: (tokenAddress: string) => void;
  isLoading: boolean;
  wallet: string;
}

export const SignContractsTokens = ({ tokens, handleSignClick, isLoading, wallet }: IProps) => {
  if (tokens.length === 0 && wallet) {
    return (
      <div className={c.contracts}>
        <div className={c.contract}>
          <div className={c.contractData}>To get started, please connect your saved wallet</div>
          <DefaultButton variant="contained" disabled className={c.submitButton}>
            Connect Wallet
            <Spinner single size={20} style={{ marginLeft: 10, minWidth: 20 }} />
          </DefaultButton>
        </div>
      </div>
    );
  }
  return (
    <div className={c.contracts}>
      {tokens.map((item: TokenToSign) => {
        return (
          <div className={c.contract} key={item.id}>
            <div className={c.contractData}>
              <b>${item.tokenSymbol.toUpperCase()}</b> tokens
            </div>
            <DefaultButton
              variant="contained"
              onClick={() => {
                handleSignClick(item.tokenAddress);
              }}
              disabled={isLoading}
              className={c.submitButton}
            >
              Sign
            </DefaultButton>
          </div>
        );
      })}
    </div>
  );
};
