import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { TokenHoldersResponse } from './issuer.types';
import { RequestParams } from '../../types/investorTableType';

class IssuerService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  public async getTokenHolders(params: RequestParams): Promise<TokenHoldersResponse> {
    const { data } = await this.authInstance.get<TokenHoldersResponse>('users/investors/token-holders', {
      params,
    });
    return data;
  }

  public async getCSV({ value, sortOrder }: { value?: string; sortOrder?: string }): Promise<any> {
    const { data } = await this.authInstance.get('users/investors/token-holders/csv', {
      params: {
        value,
        sortOrder,
      },
    });
    return data;
  }
}

export default new IssuerService(tokenInstance);
