import { observer } from 'mobx-react-lite';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AssetMap() {
  const {
    assetStore: { currentAsset },
  } = useStores();

  const position = {
    lat: currentAsset.lat,
    lng: currentAsset.lng,
  };

  if (!currentAsset.lat || !currentAsset.lng) return null;

  return (
    <div className={c.mapArea}>
      <span className={c.title}>Location</span>
      <img
        className={c.mapImg}
        src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/url-https%3A%2F%2Fdocs.mapbox.com%2Fapi%2Fimg%2Fcustom-marker.png(${`${position.lng},${position.lat}`})/${`${position.lng},${position.lat}`},6/720x300?access_token=pk.eyJ1Ijoia2lyaWxsLWlqaW5nIiwiYSI6ImNrcWpkd3Q0bjJ4MGEyc3F0ejFpdTQyYXIifQ.okjXNaHyPEWxchgYsD2PYQ`}
        alt=""
      />
    </div>
  );
}

export default observer(AssetMap);
