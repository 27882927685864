import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import SignInHeader from '../../../components/SignInHeader/SignInHeader';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import EyeClosedImg from '../../../shared/assets/icons/eye-off.svg';
import EyeOpenedImg from '../../../shared/assets/icons/eye.svg';
import PasswordStatus from '../register/PasswordStatus/PasswordStatus';
import userPool from '../../../shared/config/cognitoPool';
import Powered from '../../../components/powered/powered';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

export default function SetNewPasswordPage() {
  const intl = useIntl();
  const { portalStore } = useStores();
  const navigate = useNavigate();
  const query = useQueryParams();
  const code = `${query.get('code')}`;
  const email = decodeURIComponent(`${query.get('email')}`);

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const [isPasswordCOnfirmed, setIsPasswordConfirmed] = useState(false);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordErrorShown, setIsPasswordErrorShown] = useState(false);
  const passwordOnBlur = () => setIsPasswordErrorShown(true);
  useEffect(() => {
    if (isPasswordValid) {
      setPasswordError('');
      return;
    }
    setPasswordError('Weak password');
  }, [isPasswordValid]);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isConfirmPasswordErrorShown, setIsConfirmPasswordErrorShown] = useState(false);
  const confirmPasswordOnBlur = () => {
    setIsConfirmPasswordErrorShown(true);
  };
  useEffect(() => {
    if (password === confirmPassword) {
      setConfirmPasswordError('');
      return;
    }
    setConfirmPasswordError('Passwords do not match');
  }, [password, confirmPassword]);

  const [serverError, setServerError] = useState('');

  const submit = () => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(
      code,
      password,
      {
        onSuccess() {
          setIsPasswordConfirmed(true);
        },
        onFailure(e) {
          if (e.message.startsWith('Invalid')) {
            setServerError('Expired link to restore password');
            return;
          }
          setServerError(e.message);
        },
      },
      {
        host: window.location.hostname,
      },
    );
  };

  if (isPasswordCOnfirmed) {
    return (
      <main className={c.wrapper}>
        <Helmet>
          <title>
            {portalStore.portalUI?.portalUi?.title
              ? `Update Password | ${portalStore.portalUI.portalUi.title}`
              : 'Update Password | TokenSuite'}
          </title>
        </Helmet>
        <SignInHeader />
        <div className={c.rightBlock}>
          <div className={c.data}>
            <header>
              <FormattedMessage id="resetPasswordPage.text.header.success" />
            </header>
            <p className={c.text}>
              <FormattedMessage id="resetPasswordPage.text.success" />
            </p>
            <Link to="/login" className={c.back}>
              <img src={ArrowBackImg} alt="" />
              <FormattedMessage id="forgotPasswordPage.button.backToLogin" />
            </Link>

            <div className={c.inputsBlock}>
              <Button className={c.submitButton} onClick={() => navigate('/login')}>
                <FormattedMessage id="resetPasswordPage.button.continue" />
              </Button>
            </div>
            <Powered />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className={c.wrapper}>
      <SignInHeader />
      <div className={c.rightBlock}>
        <div className={c.data}>
          <Link to="/login" className={c.back}>
            <img src={ArrowBackImg} alt="" />
            <FormattedMessage id="forgotPasswordPage.button.backToLogin" />
          </Link>
          <header>
            <FormattedMessage id="resetPasswordPage.text.header" />
          </header>
          <p className={c.text}>
            <FormattedMessage id="resetPasswordPage.text.main" />
          </p>
          <form className={c.inputsBlock}>
            <input type="email" value={email} autoComplete="email" readOnly hidden />
            <p className={c.inputLabel}>
              <FormattedMessage id="login.fields.password.name" />
            </p>
            <TextField
              onChange={e => setPassword(e.target.value.trim())}
              value={password}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="new-password"
              id="new-password"
              className={c.input}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'login.fields.password.placeHolder' })}
              InputProps={{
                inputProps: {
                  maxLength: 127,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                      edge="end"
                    >
                      <img src={isPasswordShown ? EyeClosedImg : EyeOpenedImg} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={isPasswordErrorShown && passwordError}
              onBlur={passwordOnBlur}
              error={!!(isPasswordErrorShown && passwordError)}
            />
            <p className={c.inputLabel}>
              <FormattedMessage id="signUp.fields.confirmPassword.name" />
            </p>
            <TextField
              onChange={e => setConfirmPassword(e.target.value.trim())}
              value={confirmPassword}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="new-password"
              id="new-password-confirm"
              className={c.input}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'signUp.fields.confirmPassword.placeholder' })}
              InputProps={{
                inputProps: {
                  maxLength: 127,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                      edge="end"
                    >
                      <img src={isPasswordShown ? EyeClosedImg : EyeOpenedImg} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onBlur={confirmPasswordOnBlur}
              helperText={isConfirmPasswordErrorShown && confirmPasswordError}
              error={!!confirmPasswordError && isConfirmPasswordErrorShown}
            />
            <PasswordStatus password={password} setIsValid={setIsPasswordValid} />
            <Button
              className={c.submitButton}
              onClick={submit}
              disabled={!(isPasswordValid && confirmPassword === password)}
            >
              <FormattedMessage id="resetPasswordPage.button.submit" />
            </Button>
            {serverError && <p className={c.errorText}>{serverError}</p>}
          </form>
          <Powered />
        </div>
      </div>
    </main>
  );
}
