import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import InvestorActivityLog from '../../../components/activity-log/issuer/investors-activity-log';
import UserInfo from '../../../components/userDetails/userInfo/userInfo';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import c from './styles.module.scss';
import { useStores } from '../../../store';
import { EAccountStatusesNorthCapital } from '../../../shared/types/account.types';
import AddPaymentModal from '../../../components/userDetails/addTransaction/addTransaction';

const InvestorDetailsPage = () => {
  const navigate = useNavigate();
  const { vereficationStore } = useStores();
  const verifyStatusNC = vereficationStore.userInfoById.accountStatus.status;

  return (
    <>
      <Helmet>
        <title>Investor Details</title>
      </Helmet>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className={c.back}
      >
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="usersPage.back" />
      </button>
      <p className={c.title}>
        <FormattedMessage id="activityLog.investorDetails" />
      </p>
      <UserInfo isIssuer />
      {verifyStatusNC === EAccountStatusesNorthCapital.Verified && <AddPaymentModal isIssuer />}
      <InvestorActivityLog />
    </>
  );
};

export default observer(InvestorDetailsPage);
