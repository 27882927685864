import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import withoutTokenAPI from '../API/withoutTokenAPI';
import {
  ColorStyles,
  CurrentPortalUI,
  EditPortalDto,
  IPortal,
  PortalUI,
} from '../../interfaces/portal/portal.interface';
import { SignIssuer } from '../sign-documents/types';
import { GetPortalAssets, InitialSaleAsset } from './types';

class PortalService {
  authInstance: AxiosInstance;

  withoutAuthInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance, withoutAuthInstance: AxiosInstance) {
    this.authInstance = authInstance;
    this.withoutAuthInstance = withoutAuthInstance;
  }

  async getAllPortals(): Promise<IPortal[]> {
    const { data } = await this.authInstance.get<IPortal[]>('portal');
    return data;
  }

  async getAllBrokerPortals(): Promise<IPortal[]> {
    const { data } = await this.authInstance.get<IPortal[]>('portal/brokerage');
    return data;
  }

  async editPortal(dto: EditPortalDto): Promise<void> {
    const payload = new FormData();
    Object.entries(dto).forEach(([key, value]) => {
      payload.append(key, value);
    });
    await this.authInstance.put('portal/ui', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async editColorScheme(colors: ColorStyles, id: string): Promise<void> {
    await this.authInstance.put(`portal/ui/color/${id}`, colors);
  }

  async editPortalFont(font: string, id: string): Promise<void> {
    await this.authInstance.put(`portal/ui/font/${id}`, { font });
  }

  async getPortalUiById(id: string): Promise<PortalUI> {
    const { data } = await this.authInstance.get<PortalUI>(`portal/ui/data/${id}`);
    return data;
  }

  async changePortalFeature(id: string, kycKyb: string): Promise<void> {
    await this.authInstance.put<PortalUI>(`portal/${id}/features`, {
      kycKyb: `${kycKyb}`,
    });
  }

  async getPortalUi(host: string): Promise<CurrentPortalUI> {
    const { data } = await this.withoutAuthInstance.get<CurrentPortalUI>(`portal/ui/${host}`);
    return data;
  }

  async deleteImage(id: string): Promise<void> {
    await this.authInstance.delete(`files/image/${id}`);
  }

  /**
   *
   * @param portalId Id of portal
   * @param domain Domain for adding
   */
  async addDomain(portalId: string, domain: string): Promise<void> {
    await this.authInstance.post(`portal/${portalId}/domain`, { domain });
  }

  async getIssuersByPortal(portalId: string): Promise<SignIssuer[]> {
    const { data } = await this.authInstance.get<SignIssuer[]>(`portal/${portalId}/issuers`);
    return data;
  }

  async getLinkedAssetsByPortal(portalId: string): Promise<GetPortalAssets> {
    const { data } = await this.authInstance.get<GetPortalAssets>(`assets/dealer/opened?dealerId=${portalId}`);
    return data;
  }

  async getNotLinkedAssetsByPortal(portalId: string): Promise<GetPortalAssets> {
    const { data } = await this.authInstance.get<GetPortalAssets>(`assets/dealer/not-opened?dealerId=${portalId}`);
    return data;
  }

  async addNewAssetToDealer(assetId: string, portalId: string): Promise<void> {
    await this.authInstance.put<void>('assets/dealer', {
      dealerId: portalId,
      assetId,
    });
  }

  async getPortalInitialSaleAssets(portalId: string): Promise<InitialSaleAsset[]> {
    const { data } = await this.authInstance.get<InitialSaleAsset[]>(`portal/${portalId}/initial-sale-assets`);
    return data;
  }
}

export default new PortalService(tokenInstance, withoutTokenAPI);
