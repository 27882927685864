import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { ContractData, IssuerBrokerContract, IssuerContract, SignContractToIssuerDto, SignIssuer } from './types';

class SignDocumentsService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async signContractToIssuer(id: string, dto: SignContractToIssuerDto): Promise<void> {
    await this.authInstance.post(`sign-documents/per-purchase/portal/${id}`, dto, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async getIssuerWithContract(id: string): Promise<SignIssuer> {
    const { data } = await this.authInstance.get<SignIssuer>(`sign-documents/per-purchase/portal/${id}/issuer`);
    return data;
  }

  async createContract(id: string, amount: number): Promise<void> {
    await this.authInstance.post(`sign-documents/per-purchase/asset/${id}`, { amount });
  }

  async getInvestorContractDataById(contractId: string): Promise<ContractData> {
    const { data } = await this.authInstance.get<ContractData>(`sign-documents/per-purchase/${contractId}`);
    return data;
  }

  async getBrokerContractDataById(contractId: string): Promise<ContractData> {
    const { data } = await this.authInstance.get<ContractData>(`sign-documents/broker/${contractId}`);
    return data;
  }

  async getContractDataByAsset(id: string): Promise<ContractData> {
    const { data } = await this.authInstance.get<ContractData>(`sign-documents/per-purchase/asset/${id}`);
    return data;
  }

  async closeContract(id: string, purchaseProvider: string): Promise<void> {
    await this.authInstance.post(`sign-documents/per-purchase/${id}`, { purchaseProvider });
  }

  async isExist(): Promise<{ result: boolean }> {
    const { data } = await this.authInstance.get<{ result: boolean }>(`sign-documents/per-purchase/template/exist`);
    return data;
  }

  async getContractsForIssuer(): Promise<IssuerContract[]> {
    const { data } = await this.authInstance.get<IssuerContract[]>('sign-documents/per-purchase');
    return data;
  }

  async getBrokerContractsForIssuer(): Promise<IssuerBrokerContract[]> {
    const { data } = await this.authInstance.get<IssuerBrokerContract[]>('sign-documents/broker/issuer');
    return data;
  }
}

export default new SignDocumentsService(tokenInstance);
