import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage, useIntl } from 'react-intl';
import documentIcon from '../../../shared/assets/createAsset/documentIcon.svg';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import upload from '../../../shared/assets/createAsset/upload.svg';
import checkmarkImg from '../../../shared/assets/createAsset/bigCheckmark.svg';
import c from './style.module.scss';

const boldFragment = (chunks: any) => <b>{`${chunks}`}</b>;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 26214400; // 25MB

export interface IFiles {
  [fileName: string]: File;
}

function formatBytes(bytes: number, decimals = 2) {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function UploaderForDocuments({ document, setDocument, index }: { document: any; setDocument: any; index: number }) {
  const intl = useIntl();
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [showBlock, setShowBlock] = useState(true);

  const [errorMessage, setErrorMessage] = useState({
    head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
    desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap: 1 }),
  });

  const showError = (message: { head: string; desc: string }) => {
    setErrorMessage(message);
    setIsErrorShown(true);
  };

  const fileInputField = useRef(null);

  const addNewFiles = async (newFiles: any) => {
    const allowedTypes = ['pdf'];
    for (const file of newFiles) {
      if (allowedTypes.indexOf(file.name.split('.').reverse()[0]) === -1) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.notSupported' }),
        });
        return;
      }
      if (newFiles.length > 1) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
          desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap: 1 }),
        });
        return;
      }
      if (file.size >= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.maxSize' }),
        });
        return;
      }
      setShowBlock(false);
      setDocument(file, index);
    }
  };

  const removeFile = () => {
    setDocument({}, index);
    setShowBlock(true);
  };

  const handleNewFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: any | null = event.target.files;
    if (newFiles.length) {
      addNewFiles(newFiles);
    }
  };

  const [inDropZone, setDropZone] = useState(false);
  const handleDragEnter = () => {
    setDropZone(true);
  };
  const handleDragLeave = () => {
    setDropZone(false);
  };
  const handleDrop = () => {
    setDropZone(false);
  };
  return (
    <div className={c.uploader}>
      {document?.size && (
        <div className={c.listFiles}>
          <div className={c.wrapperBlock}>
            <div className={c.file}>
              <div className={c.left}>
                <img src={documentIcon} alt="documentIcon" />
                <div className={c.name}>{document.name}</div>
                <span>{document.size && formatBytes(+document.size)}</span>
              </div>
              <div className={c.right}>
                <img src={checkmarkImg} alt="checkmarkImg" />
                <IconButton className={c.delete} onClick={removeFile}>
                  <img src={closeIcon} alt="X" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {showBlock && (
        <div className={`${c.upload} ${inDropZone ? c.drag : ''}`}>
          <div className={`${c.error} ${isErrorShown ? '' : c.hidden}`}>
            <div className={c.container}>
              <header>{errorMessage.head}</header>
              <p>{errorMessage.desc}</p>
              <IconButton className={c.close} onClick={() => setIsErrorShown(false)}>
                x
              </IconButton>
            </div>
          </div>
          <input
            id="file-input"
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            title=""
            value=""
            multiple
            accept="application/pdf"
          />
          <img src={upload} alt="" />
          <p className={c.text}>
            <FormattedMessage
              id="uploader.text.header"
              values={{
                b: boldFragment,
              }}
            />
          </p>
          <label htmlFor="file-input" className={c.browse}>
            <FormattedMessage id="uploader.button.upload.document" />
          </label>
          <p className={c.help}>
            <FormattedMessage id="uploader.text.formats" values={{ formats: 'pdf' }} />
          </p>
        </div>
      )}
    </div>
  );
}
export default UploaderForDocuments;
