import { makeAutoObservable } from 'mobx';
import RootStore from './index';
import {
  ColorStyles,
  CurrentPortalUI,
  EditPortalDto,
  IPortal,
  PortalUI,
} from '../shared/interfaces/portal/portal.interface';
import portalService from '../shared/services/portal/portalService';
import { SignIssuer } from '../shared/services/sign-documents/types';
import { GetPortalAssets, PortalAsset } from '../shared/services/portal/types';

class PortalStore {
  rootStore: RootStore;

  portals: IPortal[] = [];

  brokerPortals: IPortal[] = [];

  currentPortal: PortalUI | null = {} as PortalUI;

  isLoading = false;

  errorMessage = '';

  portalUI: CurrentPortalUI = {} as CurrentPortalUI;

  isCustomPortal: boolean;

  isCustomPortalLoading = false;

  issuers: SignIssuer[] = [];

  linkedAssets: PortalAsset[] = [];

  notLinkedAssets: PortalAsset[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.isCustomPortal = !window.location.href.includes('tokensuite');
    if (this.isCustomPortal) {
      this.getCurrentPortalUI();
    }
  }

  //  TODO: make request for delete picture(now temporary method)
  resetPortalPicture(pictureType: 'authBack' | 'authMobileBack' | 'logo' | 'favicon' | 'metaImage' | 'emailBack') {
    if (!this.currentPortal?.portalUi) return;
    this.currentPortal.portalUi[pictureType] = null;
  }

  *deleteImage(
    id: string,
    pictureType: 'authBack' | 'authMobileBack' | 'logo' | 'favicon' | 'metaImage' | 'emailBack',
  ) {
    this.isLoading = true;
    try {
      // TODO swap method to next line after correct request on back end
      this.resetPortalPicture(pictureType);
      yield portalService.deleteImage(id);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  resetPortals() {
    this.portals = [];
    this.brokerPortals = [];
  }

  resetCurrentPortal() {
    this.currentPortal = {} as PortalUI;
  }

  *getCurrentPortalUI() {
    this.isCustomPortalLoading = true;
    try {
      const host = new URL(window.location.href).host;
      // const host = 'pavel.solidblock.net'; // for localhost
      this.portalUI = yield portalService.getPortalUi(host);
      this.isCustomPortalLoading = false;
    } catch (e: any) {
      this.isCustomPortalLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *editPortalUi(dto: EditPortalDto) {
    this.isLoading = true;
    try {
      yield portalService.editPortal(dto);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *editColorScheme(colors: ColorStyles, id: string) {
    this.isLoading = true;
    try {
      yield portalService.editColorScheme(colors, id);
      this.getCurrentPortalUI();
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *editPortalFont(font: string, id: string) {
    this.isLoading = true;
    try {
      yield portalService.editPortalFont(font, id);
      this.getCurrentPortalUI();
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *addDomainToPortal(portalId: string, domain: string, setError?: (value: string) => void) {
    this.isLoading = true;
    try {
      yield portalService.addDomain(portalId, domain);
      this.isLoading = false;
      return true;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
      if (setError) {
        setError(e?.response?.data?.message || '');
      }
      return false;
    }
  }

  *getPortalUiById(id: string) {
    this.isLoading = true;
    try {
      this.currentPortal = yield portalService.getPortalUiById(id);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *getAllPortals() {
    this.isLoading = true;
    try {
      this.portals = yield portalService.getAllPortals();
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *getAllBrokerPortals() {
    this.isLoading = true;
    try {
      this.brokerPortals = yield portalService.getAllBrokerPortals();
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  resetIssuers() {
    this.issuers = [];
  }

  *getIssuersOnPortal(portalId: string) {
    this.isLoading = true;
    try {
      this.issuers = yield portalService.getIssuersByPortal(portalId) ?? [];
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }

  *getAssetsOnPortal(portalId: string) {
    this.isLoading = true;
    try {
      const linkedAssets: GetPortalAssets = yield portalService.getLinkedAssetsByPortal(portalId);
      const notLinkedAssets: GetPortalAssets = yield portalService.getNotLinkedAssetsByPortal(portalId);
      this.linkedAssets = linkedAssets.assets;
      this.notLinkedAssets = notLinkedAssets.assets;
    } catch (e: any) {
      this.errorMessage = e?.response?.data?.message || '';
    } finally {
      this.isLoading = false;
    }
  }

  *addNewAssetToDealer(assetId: string, portalId: string) {
    this.isLoading = true;
    try {
      yield portalService.addNewAssetToDealer(assetId, portalId);
      yield this.getAssetsOnPortal(portalId);
    } catch (e: any) {
      this.errorMessage = e?.response?.data?.message || '';
    } finally {
      this.isLoading = false;
    }
  }

  *changePortalFeature(id: string, kycKyb: string) {
    this.isLoading = true;
    try {
      yield portalService.changePortalFeature(id, kycKyb);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessage = e?.response?.data?.message || '';
    }
  }
}

export { PortalStore };
