import React from 'react';
import IconButton from '@mui/material/IconButton';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { FormattedMessage, useIntl } from 'react-intl';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from './style.module.scss';

function Reviews({ reviews, setReviews }: { reviews: Array<{ text: string; reviewer: string }>; setReviews: any }) {
  const intl = useIntl();
  const addReview = () => {
    setReviews([...reviews, { text: '', reviewer: '' }]);
  };

  const perkAuthorHandler = (e: string, index: number) => {
    const tempArr = reviews;
    tempArr[index].reviewer = e;
    setReviews([...tempArr]);
  };
  const perkTextHandler = (e: string, index: number) => {
    const tempArr = reviews;
    tempArr[index].text = e;
    setReviews([...tempArr]);
  };

  const removeFile = (index: number) => {
    const newPerks = reviews.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setReviews(newPerks);
  };

  return (
    <div className={c.wrapper}>
      <div className={c.listHighlights}>
        <div className={c.wrapperBlock}>
          {reviews.map((review, index: number) => (
            <div className={c.review} key={index}>
              <div className={c.left}>
                <TextIndexInput
                  value={review.reviewer}
                  changeValue={perkAuthorHandler}
                  label={intl.formatMessage(
                    { id: 'createAssetPage.reviews.field.reviewer.label' },
                    { count: `${index + 1}` },
                  )}
                  placeholder={intl.formatMessage({ id: 'createAssetPage.reviews.field.reviewer.placeholder' })}
                  type="text"
                  fullWidth
                  index={index}
                />
                <TextIndexInput
                  value={review.text}
                  changeValue={perkTextHandler}
                  label={intl.formatMessage(
                    { id: 'createAssetPage.reviews.field.review.label' },
                    { count: `${index + 1}` },
                  )}
                  placeholder={intl.formatMessage({ id: 'createAssetPage.reviews.field.review.placeholder' })}
                  type="text"
                  fullWidth
                  multiline
                  index={index}
                />
                <div className={c.right}>
                  <IconButton className={c.delete} onClick={() => removeFile(index)}>
                    <img src={closeIcon} alt="X" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className={c.addButton} onClick={addReview}>
        {reviews.length > 0 ? (
          <FormattedMessage id="createAssetPage.reviews.button.addAnother" />
        ) : (
          <FormattedMessage id="createAssetPage.reviews.button.addFirst" />
        )}
      </button>
    </div>
  );
}
export default Reviews;
