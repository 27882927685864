import { MenuItem } from '@mui/material';
import { SelectInput, TextInput, DateInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import scrollToTop from '@token-suite/common-ui/src/sharedFunctions/scrollToTop';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import Highlights from '../../../components/createAsset/highlights/highlights';
import MediaAndText from '../../../components/createAsset/mediaAndText/mediaAndText';
import OneDocumentUploader from '../../../components/createAsset/oneDocumentUploader/OneDocumentUploader';
import Perks from '../../../components/createAsset/perks/perks';
import Reviews from '../../../components/createAsset/reviews/reviews';
import Uploader from '../../../components/createAsset/uploader/Uploader';
import Documents from '../../../components/createAsset/documents/documents';
import { CreateAsset } from '../../../shared/interfaces/AssetsInterfaces';
import c from './styles.module.scss';
import HowItWork from '../../../components/createAsset/howItWork/howItWork';
import tokenInstance from '../../../shared/services/API/tokenApi';
import CheckboxInput from '../../../components/checkout-payments/checkbox-input/checkboxInput';

export default function CreateAssetPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [issuerEmail, setIssuerEmail] = useState('');

  const [name, setName] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [targetAmount, setTargetAmount] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tokenQuantity, setTokenQuantity] = useState('');
  const [currency, setCurrency] = useState('');
  const [location, setLocation] = useState('');
  const [lat, setlat] = useState(''); // -90\90
  const [lng, setLng] = useState(''); // -180\180

  const [previewImage, setPreview] = useState<{ image?: any }[]>([]);

  const [mainMedia, setMedia] = useState<{ linksToVideo?: string; image?: File }[]>([]);
  const [description, setDescription] = useState('');
  const [showMap, setShowMap] = useState(true);
  const [hideAsset, setHide] = useState(false);

  const [highlights, setHighlights] = useState<
    {
      text: string;
      position?: number;
    }[]
  >([]);

  const [revenueType, setRevenueType] = useState('');
  const [capitalStack, setCapitalStack] = useState('');
  const [propertyType, setPropertyType] = useState('');

  const [presentationDocument, setPresentation] = useState(null);

  const [media, setMediaBlocks] = useState<
    {
      title: string;
      description: string;
      mediaFiles: [
        {
          linksToVideo?: string;
          image?: File;
        },
      ];
    }[]
  >([]);

  const [worksHighlight, setWorkHighlight] = useState('');
  const [works, setWorks] = useState<
    {
      title: string;
      description: string;
    }[]
  >([]);

  const [perksHighlight, setPerksHighlight] = useState('');
  const [perks, setPerks] = useState<
    {
      title: string;
      description: string;
    }[]
  >([{ title: '', description: '' }]);

  const [reviews, setReviews] = useState<
    {
      reviewer: string;
      text: string;
    }[]
  >([]);

  const [marketerAnalysis, setMarketer] = useState<
    {
      description: string;
      document: File;
    }[]
  >([]);
  const [documents, setDocuments] = useState<
    {
      description: string;
      document: File;
    }[]
  >([]);

  // get issuers list
  const [issuers, setIssuers] = useState<{ id: string; firstName: string; lastName: string; email: string }[]>([]);
  useEffect(() => {
    tokenInstance.get('users/issuers').then(({ data }) => {
      setIssuers(data);
    });
  }, []);

  // validation
  const [dateError, setDateError] = useState(false);
  const [errors, setError] = useState<string[]>([]);
  const mainErrorElement = document.getElementById('mainInfo');
  const [previewError, setPreviewError] = useState('');
  const previewErrorElement = document.getElementById('preview');
  const [mainImageError, setMainError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const imageErrorElement = document.getElementById('mainImage');
  const [detailsError, setDetailsError] = useState('');
  const detailsErrorElement = document.getElementById('details');
  const [perksError, setPerkError] = useState('');
  const perksErrorElement = document.getElementById('perks');
  const [documentsError, setDocumentError] = useState('');
  const documentErrorElement = document.getElementById('documents');

  // Loader
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    if (revenueType && propertyType.trim() && capitalStack) {
      setDetailsError('');
    }
  }, [revenueType, propertyType, capitalStack]);

  useEffect(() => {
    if (description.trim()) {
      setDescriptionError('');
    }
  }, [description]);

  useEffect(() => {
    if (perks[0].title.trim() && perks[0].description.trim() && perksHighlight) {
      setPerkError('');
    }
  }, [perks, perksHighlight]);

  useEffect(() => {
    if (!documents.some(doc => !doc.document?.name || !doc.description.trim())) {
      setDocumentError('');
    }
  }, [documents]);

  // createAssetPage.errors.required.documents
  const validateAsset = () => {
    let noErrors = true;
    let firstErrorElement = null;

    if (!documents.length || documents.some(doc => !doc.description.trim() || !doc.document?.name)) {
      setDocumentError(intl.formatMessage({ id: 'createAssetPage.errors.required.documents' }));
      firstErrorElement = documentErrorElement;
      noErrors = false;
    }

    if (!perks[0].title.trim() || !perks[0].description.trim() || !perksHighlight) {
      setPerkError(intl.formatMessage({ id: 'createAssetPage.errors.required.perks' }));
      firstErrorElement = perksErrorElement;
      noErrors = false;
    }
    if (!revenueType || !propertyType.trim() || !capitalStack) {
      setDetailsError(intl.formatMessage({ id: 'createAssetPage.errors.required.details' }));
      firstErrorElement = detailsErrorElement;
      noErrors = false;
    }

    if (!mainMedia[0]) {
      setMainError(intl.formatMessage({ id: 'createAssetPage.errors.required.mainMedia' }));
      firstErrorElement = imageErrorElement;
      noErrors = false;
    }
    if (!description.trim()) {
      setDescriptionError(intl.formatMessage({ id: 'createAssetPage.errors.required.description' }));
      firstErrorElement = imageErrorElement;
      noErrors = false;
    }
    if (!previewImage[0]?.image) {
      setPreviewError(intl.formatMessage({ id: 'createAssetPage.errors.required.previewImage' }));
      firstErrorElement = previewErrorElement;
      noErrors = false;
    }

    if (
      !issuerEmail ||
      !name.trim() ||
      !minAmount ||
      !maxAmount ||
      !targetAmount ||
      !tokenQuantity ||
      !currency ||
      !location.trim() ||
      !lat.trim() ||
      !lng.trim() ||
      dateError
    ) {
      setError([intl.formatMessage({ id: 'createAssetPage.errors.required.all' })]);
      firstErrorElement = mainErrorElement;
      noErrors = false;
    }
    firstErrorElement?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    return noErrors;
  };

  // create formData from Final object to send
  const submit = () => {
    setError([]);
    setPreviewError('');
    setMainError('');
    setDetailsError('');
    setPerkError('');
    if (!validateAsset()) {
      return;
    }
    const selectedIssuer = issuers.find(issuer => issuer.email === issuerEmail);
    const investmentDetail = {
      revenueType,
      capitalStack,
      propertyType,
    };
    let howItWorks = {};
    if (worksHighlight) {
      howItWorks = {
        highlights: worksHighlight.trim(),
        fields: works,
      };
    }
    const investorPerks = {
      highlights: perksHighlight.trim(),
      fields: perks,
    };
    const mainInfo: CreateAsset = {
      issuerId: selectedIssuer?.id,
      name,
      minAmount,
      maxAmount,
      targetAmount,
      unitPrice: '1',
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      tokenQuantity,
      currency,
      location,
      lat: +lat,
      lng: +lng,
      previewImage: previewImage[0]?.image,
      mainMedia: mainMedia[0] || null,
      showMap,
      description,
      highlights,
      investmentDetail,
      presentationDocument,
      media,
      howItWorks,
      investorPerks,
      reviews,
      marketerAnalysis,
      documents,
      private: hideAsset,
    };
    const options = {
      indices: true,
    };
    const newAsset = serialize(mainInfo, options);
    setIsSubmiting(true);
    tokenInstance
      .post('assets', newAsset)
      .then(() => {
        navigate('/admin-assets');
      })
      .catch(e => {
        scrollToTop();
        if (Array.isArray(e.response.data.errors)) {
          setError(e.response.data.errors);
        } else {
          setError([e.response.data.errors, e.response.data.message]);
        }
      })
      .finally(() => setIsSubmiting(false));
  };

  return (
    <>
      <Helmet>
        <title>Create New Asset | TokenSuite</title>
      </Helmet>
      <Link to="/admin-assets" className={c.back}>
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="createAssetPage.text.back" />
      </Link>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.header" />
      </div>
      <div className={c.block}>
        <div className={c.formPart}>
          <div className={c.title} id="mainInfo">
            <FormattedMessage id="createAssetPage.text.mainBlock" />
          </div>
          <div className={c.errors}>
            {errors.map((error, index) => {
              return (
                <div className={c.errorText} key={index}>
                  {error}
                </div>
              );
            })}
          </div>
          <SelectInput
            value={issuerEmail}
            changeValue={setIssuerEmail}
            label={intl.formatMessage({ id: 'createAssetPage.fields.issuerEmail.label' })}
            defaultValue={intl.formatMessage({ id: 'createAssetPage.fields.issuerEmail.defaultValue' })}
            fullWidth
          >
            {issuers.map(issuer => (
              <MenuItem value={issuer.email} key={issuer.id}>
                {issuer.firstName} - {issuer.email}
              </MenuItem>
            ))}
          </SelectInput>
          <div className={c.inputsBlock}>
            <TextInput
              value={name}
              changeValue={setName}
              label={intl.formatMessage({ id: 'createAssetPage.fields.name.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.name.placeholder' })}
              type="text"
            />
            <TextInput
              value={minAmount}
              changeValue={setMinAmount}
              label={intl.formatMessage({ id: 'createAssetPage.fields.minAmount.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.minAmount.placeholder' })}
              type="number"
            />
            <TextInput
              value={maxAmount}
              changeValue={setMaxAmount}
              label={intl.formatMessage({ id: 'createAssetPage.fields.maxAmount.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.maxAmount.placeholder' })}
              type="number"
            />
            <TextInput
              value={targetAmount}
              changeValue={setTargetAmount}
              label={intl.formatMessage({ id: 'createAssetPage.fields.targetAmount.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.targetAmount.placeholder' })}
              type="number"
            />
            <DateInput
              value={startDate}
              changeValue={setStartDate}
              label={intl.formatMessage({ id: 'createAssetPage.fields.startDate.label' })}
              setError={setDateError}
            />
            <DateInput
              value={endDate}
              changeValue={setEndDate}
              label={intl.formatMessage({ id: 'createAssetPage.fields.endDate.label' })}
              setError={setDateError}
            />
            <TextInput
              value={tokenQuantity}
              changeValue={setTokenQuantity}
              label={intl.formatMessage({ id: 'createAssetPage.fields.tokenQuantity.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.tokenQuantity.placeholder' })}
              type="number"
            />
            <SelectInput
              value={currency}
              changeValue={setCurrency}
              label={intl.formatMessage({ id: 'createAssetPage.fields.currency.label' })}
              defaultValue={intl.formatMessage({ id: 'createAssetPage.fields.currency.placeholder' })}
            >
              <MenuItem value="USD">USD</MenuItem>
              {/* <MenuItem value="EUR">
                EUR
              </MenuItem>
              <MenuItem value="GBP">
                GBP
              </MenuItem> */}
            </SelectInput>
            <TextInput
              value={location}
              changeValue={setLocation}
              label={intl.formatMessage({ id: 'createAssetPage.fields.location.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.location.placeholder' })}
              type="text"
              fullWidth
            />
            <TextInput
              value={lat}
              changeValue={setlat}
              label={intl.formatMessage({ id: 'createAssetPage.fields.lat.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.lat.placeholder' })}
              type="number"
            />
            <TextInput
              value={lng}
              changeValue={setLng}
              label={intl.formatMessage({ id: 'createAssetPage.fields.lng.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.lng.placeholder' })}
              type="number"
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.title} id="preview">
            <FormattedMessage id="createAssetPage.text.previewImageBlock" />
          </div>
          <div className={c.errorText}>{previewError}</div>
          <Uploader
            setDocuments={setPreview}
            documents={previewImage}
            cap={1}
            withVideo={false}
            setError={setPreviewError}
          />
        </div>
        <div className={c.formPart}>
          <div className={c.title} id="mainImage">
            <FormattedMessage id="createAssetPage.text.mainImageBlock" />
          </div>
          <div className={c.errorText}>{mainImageError}</div>
          <Uploader setDocuments={setMedia} documents={mainMedia} cap={1} setError={setMainError} />
          <TextInput
            value={description}
            changeValue={setDescription}
            label={intl.formatMessage({ id: 'createAssetPage.fields.description.label' })}
            placeholder={intl.formatMessage({ id: 'createAssetPage.fields.description.placeholder' })}
            type="text"
            fullWidth
            multiline
            error={Boolean(descriptionError)}
            errorText={descriptionError}
          />
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.highlihtsBlock" />
          </div>
          <Highlights setHighlights={setHighlights} highlights={highlights} />
        </div>
        <div className={c.formPart}>
          <div className={c.title} id="details">
            <FormattedMessage id="createAssetPage.text.investmentDetailsBlock" />
          </div>
          <div className={c.errorText}>{detailsError}</div>
          <div className={c.inputsBlock}>
            <SelectInput
              value={revenueType}
              changeValue={setRevenueType}
              label={intl.formatMessage({ id: 'createAssetPage.fields.revenueType.label' })}
              defaultValue={intl.formatMessage({ id: 'createAssetPage.fields.revenueType.placeholder' })}
            >
              <MenuItem value="NNN Lease">
                <FormattedMessage id="createAssetPage.fields.revenueType.values.NNNLease" />
              </MenuItem>
              <MenuItem value="Yielding">
                <FormattedMessage id="createAssetPage.fields.revenueType.values.yielding" />
              </MenuItem>
              <MenuItem value="Mixed">
                <FormattedMessage id="createAssetPage.fields.revenueType.values.mixed" />
              </MenuItem>
            </SelectInput>
            <SelectInput
              value={capitalStack}
              changeValue={setCapitalStack}
              label={intl.formatMessage({ id: 'createAssetPage.fields.capitalStack.label' })}
              defaultValue={intl.formatMessage({ id: 'createAssetPage.fields.capitalStack.placeholder' })}
            >
              <MenuItem value="Debt">
                <FormattedMessage id="createAssetPage.fields.capitalStack.values.Debt" />
              </MenuItem>
              <MenuItem value="Equity">
                <FormattedMessage id="createAssetPage.fields.capitalStack.values.Equity" />
              </MenuItem>
              <MenuItem value="Preferred Equity">
                <FormattedMessage id="createAssetPage.fields.capitalStack.values.PreferredEquity" />
              </MenuItem>
            </SelectInput>
            <TextInput
              value={propertyType}
              changeValue={setPropertyType}
              label={intl.formatMessage({ id: 'createAssetPage.fields.propertyType.label' })}
              placeholder={intl.formatMessage({ id: 'createAssetPage.fields.propertyType.placeholder' })}
              type="text"
              fullWidth
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.ivestorPresentationBlock" />
          </div>
          <OneDocumentUploader setDocument={setPresentation} document={presentationDocument} />
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.mediaBlock" />
          </div>
          <MediaAndText media={media} setMediaBlocks={setMediaBlocks} />
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.howItWorksBlock" />
          </div>
          <HowItWork
            works={works}
            setWorks={setWorks}
            workHighlight={worksHighlight}
            setWorkHighlight={setWorkHighlight}
          />
        </div>
        <div className={c.formPart}>
          <div className={c.title} id="perks">
            <FormattedMessage id="createAssetPage.text.investorPerksBlock" />
          </div>
          <div className={c.errorText}>{perksError}</div>
          <TextInput
            value={perksHighlight}
            changeValue={setPerksHighlight}
            label={intl.formatMessage({ id: 'createAssetPage.investorPerks.field.highlights.label' })}
            placeholder={intl.formatMessage({ id: 'createAssetPage.investorPerks.field.highlights.placeholder' })}
            type="text"
            fullWidth
          />
          <Perks perks={perks} setPerks={setPerks} />
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.reviewsBlock" />
          </div>
          <Reviews reviews={reviews} setReviews={setReviews} />
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.marketAnalysisBlock" />
          </div>
          <Documents setDocuments={setMarketer} documents={marketerAnalysis} />
        </div>
        <div className={c.formPart}>
          <div className={c.title} id="documents">
            <FormattedMessage id="createAssetPage.text.documentsBlock" />
          </div>
          <div className={c.errorText}>{documentsError}</div>
          <Documents setDocuments={setDocuments} documents={documents} />
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.mapBlock" />
          </div>
          <label className={c.checkbox} htmlFor="showMap">
            <CheckboxInput
              disabled={false}
              id="showMap"
              checked={showMap}
              label=""
              checkBoxHandler={() => {
                setShowMap(!showMap);
              }}
            />
            <span className={c.label}>Show Map</span>
          </label>
        </div>
        <div className={c.formPart}>
          <div className={c.title}>
            <FormattedMessage id="createAssetPage.text.hideAsset" />
          </div>
          <label className={c.checkbox} htmlFor="hideAsset">
            <CheckboxInput
              disabled={false}
              id="hideAsset"
              checked={hideAsset}
              label=""
              checkBoxHandler={() => {
                setHide(!hideAsset);
              }}
            />
            <span className={c.label}>Hide Asset from marketplace</span>
          </label>
        </div>
      </div>
      <div className={c.btns}>
        {/* <button className={c.btnEmpty} onClick={submit}>
          Save and continue later
        </button> */}
        <button className={c.btn} onClick={submit} disabled={isSubmiting}>
          <FormattedMessage
            id={isSubmiting ? 'createAssetPage.button.submitLoading' : 'createAssetPage.button.submit'}
          />
        </button>
      </div>
    </>
  );
}
