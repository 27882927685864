import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import verticalMenuDots from '../../../../../shared/assets/icons/verticalMenuDots.svg';

const NonMintedRowMenu = ({ id }: { id: string; isLoading?: boolean }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const navigateToAssetHandler = () => {
    navigate(`/assets/${id}`);
  };
  const editAsset = () => {
    navigate(`/edit-asset/${id}`);
  };
  const openMenuHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  return (
    <>
      <IconButton onClick={openMenuHandler}>
        <img src={verticalMenuDots} alt="verticalMenuDots" />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={navigateToAssetHandler}>View asset</MenuItem>
        <MenuItem onClick={editAsset}>Edit asset</MenuItem>
      </Menu>
    </>
  );
};

export default NonMintedRowMenu;
