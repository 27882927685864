import { Button, Dialog, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import c from './style.module.scss';
import { useStores } from '../../../../store';
import closeIcon from '../../../../shared/assets/icons/closeIcon.svg';
import CopyIcon from '../../../../shared/assets/icons/copyMail.svg';
import { ITransactionHistory } from '../../../../shared/services/transactions/transactions.types';
import DefaultButton from '../../buttons/default-button';
import { getFormmatedDateAndTime } from '../../../../shared/lib/formmated-date';
import copyDataInBuffer from '../../../../shared/lib/copy-data-in-buffer';

interface ViewDetailsModalProps {
  open: boolean;
  transaction: ITransactionHistory;
}

const ViewDetailsModal = ({ open, transaction }: ViewDetailsModalProps) => {
  const { transactionsStore } = useStores();
  const { dateFormatted, timeFormatted } = getFormmatedDateAndTime(transaction.createdAt);
  const transactionData = [
    ['Date', `${dateFormatted} ${timeFormatted}`],
    ['Amount', transaction.send ? `${transaction.send} ${transaction.sendCurrency}` : null],
    ['Amount', transaction.receive ? `${transaction.receive} ${transaction.receiveCurrency}` : null],
    [
      'From User',
      `${transaction.userFrom?.firstName} ${transaction.userFrom?.lastName} - ${transaction.userFrom?.email}`,
    ],
    ['To User', `${transaction.userTo?.firstName} ${transaction.userTo?.lastName} - ${transaction.userTo?.email}`],
    ['Transaction Type', transaction.transactionType],
    ['Asset', transaction.asset.name],
  ];

  return (
    <Dialog open={open} onClose={() => transactionsStore.closeViewModal()}>
      <div className={c.dialog}>
        <Button onClick={() => transactionsStore.closeViewModal()} className={c.closeIcon}>
          <img src={closeIcon} alt="" />
        </Button>
        <div className={c.title}>Transaction Details</div>
        {transactionData.map(
          ([key, value]) =>
            value && (
              <div key={key}>
                <p className={c.smallTitle}>{key}:</p>
                <p className={c.text}>{value}</p>
              </div>
            ),
        )}
        {transaction.transactionHash && (
          <>
            <p className={c.smallTitle}>Transaction Hash:</p>
            <p>
              <a
                href={`https://mumbai.polygonscan.com/tx/${transaction.transactionHash}`}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                {transaction.transactionHash?.slice(0, 4)}...{transaction.transactionHash?.slice(-4)}
              </a>
              <IconButton onClick={() => copyDataInBuffer(transaction.transactionHash!)}>
                <img src={CopyIcon} alt="" />
              </IconButton>
            </p>
          </>
        )}

        <div className={c.actions}>
          <DefaultButton
            variant="contained"
            onClick={() => transactionsStore.closeViewModal()}
            className={c.cancelButton}
          >
            Close
          </DefaultButton>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(ViewDetailsModal);
