import { AxiosInstance } from 'axios';
import TokenInstance from '../API/tokenApi';
import { Asset } from '../../interfaces/assets/asset.interface';
import { IAssetItem } from '../../interfaces/userInfo.interface';
import { NonMintedAsset, RequestParams } from '../../types/investorTableType';
import { IInvestorBoughtAssets, IMarketPlaceAsset } from './asset.types';
import withoutTokenInstance from '../API/withoutTokenAPI';

class AssetService {
  authInstance: AxiosInstance;

  withoutAuth: AxiosInstance;

  constructor(authInstance: AxiosInstance, wAuth: AxiosInstance) {
    this.authInstance = authInstance;
    this.withoutAuth = wAuth;
  }

  async getAssetById(id: string): Promise<Asset> {
    const { data } = await this.authInstance.get<Asset>(`assets/asset/${id}`);
    return data;
  }

  async getAllAssets(dto: RequestParams): Promise<{ assets: IAssetItem[]; total: number }> {
    const { data } = await this.authInstance.get<{ assets: IAssetItem[]; total: number }>(`assets`, {
      params: dto,
    });
    return data;
  }

  async saveMainInfo(id: string, startDate: string, endDate: string): Promise<void> {
    await this.authInstance.put<{ startDate: string; endDate: string }>(`assets/${id}/dates`, {
      startDate,
      endDate,
    });
  }

  async saveLocation(id: string, location: string, lat: number, lng: number, showMap: boolean): Promise<void> {
    await this.authInstance.put<{ location: string; lat: string; lng: string; showMap: boolean }>(
      `assets/${id}/location`,
      {
        location,
        lat,
        lng,
        showMap,
      },
    );
  }

  async saveHighlights(id: string, highlights: Array<{ text: string; position?: number }>): Promise<void> {
    await this.authInstance.put<{ highlights: Array<{ text: string; position?: number }> }>(`assets/${id}/highlights`, {
      highlights,
    });
  }

  async savePreview(id: string, file: any): Promise<void> {
    await this.authInstance.put<{ file: any }>(`assets/${id}/preview-image`, file);
  }

  async saveMainMedia(id: string, formData: any): Promise<void> {
    await this.authInstance.put(`assets/${id}/main-media`, formData);
  }

  async saveDescription(id: string, description: string): Promise<void> {
    await this.authInstance.put(`assets/${id}/description`, { description });
  }

  async saveDetails(id: string, revenueType: string, capitalStack: string, propertyType: string): Promise<void> {
    await this.authInstance.put(`assets/${id}/investment-details`, { revenueType, capitalStack, propertyType });
  }

  async savePresentation(id: string, file: any): Promise<void> {
    await this.authInstance.put<{ file: any }>(`assets/${id}/investor-presentation`, file);
  }

  async deletePresentation(id: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/investor-presentation`);
  }

  async addNewMediaBlock(id: string, title: string, description: string): Promise<void> {
    await this.authInstance.put<{ id: string; title: string; description: string }>(`assets/${id}/main-block`, {
      title,
      description,
      position: 0,
    });
  }

  async changeMediaBlock(id: string, blockId: string, title: string, description: string): Promise<void> {
    await this.authInstance.put<{ id: string; title: string; description: string }>(`assets/${id}/main-block`, {
      mediaBlockId: blockId,
      title,
      description,
      position: 0,
    });
  }

  async deleteMediaBlock(id: string, blockId: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/main-block/${blockId}`);
  }

  async addMediaImage(id: string, blockId: string, formData: any): Promise<void> {
    await this.authInstance.put(`assets/${id}/main-block/${blockId}/media`, formData);
  }

  async updatePrivate(id: string, status: boolean): Promise<void> {
    await this.authInstance.put(`assets/${id}/status`, { status });
  }

  async deleteMediaImage(id: string, blockId: string, mediaId: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/main-block/${blockId}/media/${mediaId}`);
  }

  async addHowItWorks(
    id: string,
    worksHighlight: string,
    works: {
      title: string;
      description: string;
    }[],
  ): Promise<void> {
    await this.authInstance.put(`assets/${id}/how-it-works`, {
      records: works,
      highlights: worksHighlight,
    });
  }

  async deleteHowItWorks(id: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/how-it-works`);
  }

  async updateReviews(
    id: string,
    reviews: {
      reviewer: string;
      text: string;
    }[],
  ): Promise<void> {
    await this.authInstance.put(`assets/${id}/review`, {
      records: reviews,
    });
  }

  async deleteReview(id: string, reviewId: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/review/${reviewId}`);
  }

  async updatePerks(
    id: string,
    highlight: string,
    perks: {
      title: string;
      description: string;
    }[],
  ): Promise<void> {
    await this.authInstance.put(`assets/${id}/investor-perks`, {
      highlights: highlight,
      fields: perks,
    });
  }

  async deletePerk(id: string, perkId: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/investor-perks/field/${perkId}`);
  }

  async addDocument(id: string, formData: any): Promise<void> {
    await this.authInstance.post(`assets/${id}/asset-document`, formData);
  }

  async deleteDocument(id: string, documentId: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/asset-document/${documentId}`);
  }

  async addMarket(id: string, formData: any): Promise<void> {
    await this.authInstance.post(`assets/${id}/market-analysis`, formData);
  }

  async deleteMarket(id: string, documentId: string): Promise<void> {
    await this.authInstance.delete(`assets/${id}/market-analysis/${documentId}`);
  }

  async getNonMintedAssets(params: RequestParams): Promise<{ total: number; assets: NonMintedAsset[] }> {
    const { data } = await this.authInstance.get<{ total: number; assets: NonMintedAsset[] }>('assets/non-minted', {
      params,
    });
    return data;
  }

  async getMintedAssets(params: RequestParams): Promise<{ total: number; assets: NonMintedAsset[] }> {
    const { data } = await this.authInstance.get<{ total: number; assets: NonMintedAsset[] }>('assets/minted', {
      params,
    });
    return data;
  }

  async getInvestorBoughtAssets(): Promise<IInvestorBoughtAssets[]> {
    const { data } = await this.authInstance.get<IInvestorBoughtAssets[]>('assets/user');
    return data;
  }

  async getAssetsForPortal(): Promise<IMarketPlaceAsset[]> {
    const { data } = await this.withoutAuth.get<IMarketPlaceAsset[]>('assets/marketplace');
    return data;
  }

  async createCustodialForAsset(id: string): Promise<void> {
    await this.authInstance.post(`assets/custodial`, { assetId: id });
  }
}

export default new AssetService(TokenInstance, withoutTokenInstance);
