import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useNavigate, Link } from 'react-router-dom';
import { FormEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import jwtDecode from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import SignInHeader from '../../../components/SignInHeader/SignInHeader';
import userPool from '../../../shared/config/cognitoPool';
import EyeClosedImg from '../../../shared/assets/icons/eye-off.svg';
import EyeOpenedImg from '../../../shared/assets/icons/eye.svg';
import Powered from '../../../components/powered/powered';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import ErrorSnackbar from '../../../components/UI/snackbar/error-snackbar';
import tokenInstance from '../../../shared/services/API/tokenApi';

function LoginPage() {
  const intl = useIntl();
  const navigate = useNavigate();

  const query = useQueryParams();
  const emailFromAsset = query.get('email');
  const decodeEmail = decodeURIComponent(`${emailFromAsset}`);
  const redirectTo = query.get('redirectTo');
  const expired = query.get('expired');
  const { portalStore } = useStores();
  const [email, setEmail] = useState('');
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isLogoutAlertOpen, setIsLogoutAlertOpen] = useState(!!expired || false);
  const [password, setPassword] = useState('');

  const [loginError, setLoginError] = useState('');
  // MFA
  const [secretCode, setSecretCode] = useState('');
  const [isMFARequired, setIsMFARequired] = useState(false);
  const [isSecretCodeVisible, setIsSecretCodeVisible] = useState(false);
  const [mfaError, setMfaError] = useState('');
  const [session, setSession] = useState<null | CognitoUser>(null);

  useEffect(() => {
    if (decodeEmail !== 'null') {
      setEmail(decodeEmail);
    }
  }, [decodeEmail]);

  const submit: FormEventHandler = async e => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) return;
    if (isMFARequired && !secretCode.trim()) return;
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    const onSuccess = (result: CognitoUserSession) => {
      const sessionIdInfo: any = jwtDecode(result.getAccessToken().getJwtToken());
      const tokenRole = sessionIdInfo['cognito:groups'].find((elem: string) => elem.includes('role')).slice(5);
      if (tokenRole === 'issuer' || tokenRole === 'demoIssuer') {
        const portals = sessionIdInfo['cognito:groups']
          .filter((elem: string) => elem.includes('portal'))
          .map((item: string) => item.slice(7));
        if (
          portals.length > 0 &&
          portals.indexOf(window.location.hostname) === -1 &&
          window.location.hostname !== 'localhost'
        ) {
          setLoginError('You don`t have access to this portal');
          return;
        }
      }
      if (tokenRole === 'broker') {
        setLoginError('You don`t have access to this portal');
        return;
      }
      localStorage.setItem('accessToken', `${result.getAccessToken().getJwtToken()}`);
      localStorage.setItem('refreshToken', `${result.getRefreshToken().getToken()}`);

      const referralId = localStorage.getItem('referralId');
      if (referralId) {
        tokenInstance
          .post('referrals/link', {
            referralId,
          })
          .then(() => {
            localStorage.removeItem('referralId');
          })
          .catch(error => {
            setLoginError(error.response.data.message);
          });
      }

      if (redirectTo) {
        navigate(`${redirectTo}`, { replace: true });
        return;
      }
      navigate('/redirect', { replace: true });
    };

    const onFailure = (error: any) => {
      if (typeof error.message !== 'string') return;
      if (isMFARequired) {
        setMfaError(error.message);
        return;
      }
      setLoginError(error.message);
    };

    if (isMFARequired) {
      session?.sendMFACode(
        secretCode,
        {
          onSuccess,
          onFailure,
        },
        'SOFTWARE_TOKEN_MFA',
      );
      return;
    }
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess,
      onFailure,
      totpRequired: () => {
        setIsMFARequired(true);
        setSession(cognitoUser);
      },
    });
  };

  return (
    <main className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Login | ${portalStore.portalUI.portalUi.title}`
            : 'Login | TokenSuite'}
        </title>
      </Helmet>
      <SignInHeader />
      <div className={c.rightBlock}>
        <div className={c.data}>
          <header>
            <FormattedMessage id="login.login" />
          </header>
          <form className={c.inputsBlock} onSubmit={submit}>
            <p className={c.inputLabel}>
              <FormattedMessage id="login.fields.email.name" />
            </p>
            <TextField
              type="email"
              onChange={e => {
                setEmail(e.target.value.trim());
                setLoginError('');
              }}
              value={email}
              autoComplete="email"
              id="auth-email"
              className={c.input}
              disabled={portalStore.isCustomPortalLoading}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'login.fields.email.placeHolder' })}
              inputProps={{
                maxLength: 127,
              }}
              error={!!loginError}
            />
            <p className={c.inputLabel}>
              <FormattedMessage id="login.fields.password.name" />
            </p>
            <TextField
              onChange={e => {
                setPassword(e.target.value.trim());
                setLoginError('');
              }}
              value={password}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="current-password"
              id="password"
              className={c.input}
              disabled={portalStore.isCustomPortalLoading}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'login.fields.password.placeHolder' })}
              InputProps={{
                inputProps: {
                  maxLength: 127,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                      edge="end"
                    >
                      <img src={isPasswordShown ? EyeClosedImg : EyeOpenedImg} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={loginError}
              error={!!loginError}
            />
            {isMFARequired && (
              <>
                <p className={c.inputLabel}>
                  <FormattedMessage id="login.fields.password.secret" />
                </p>
                <TextField
                  type={isSecretCodeVisible ? 'text' : 'password'}
                  onChange={e => {
                    setSecretCode(e.target.value.trim());
                    setMfaError('');
                  }}
                  value={secretCode}
                  className={c.input}
                  disabled={portalStore.isCustomPortalLoading}
                  margin="normal"
                  helperText={mfaError}
                  error={!!mfaError}
                  placeholder={intl.formatMessage({ id: 'login.fields.secret.placeHolder' })}
                  InputProps={{
                    inputProps: {
                      maxLength: 15,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle code visibility"
                          onClick={() => setIsSecretCodeVisible(!isSecretCodeVisible)}
                          edge="end"
                        >
                          <img src={isSecretCodeVisible ? EyeClosedImg : EyeOpenedImg} alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
            <Link className={c.forgot} to="/forgot-password">
              <FormattedMessage id="login.forgotPasswordButton" />
            </Link>
            <Button className={c.submitButton} type="submit">
              <FormattedMessage id="login.signInButton" />
            </Button>
            {!portalStore.portalUI.isDemo && (
              <Link className={c.signUp} to="/sign-up">
                <FormattedMessage id="login.signUpButton" />
              </Link>
            )}
            <div className={c.reminder}>
              <FormattedMessage id="login.reminder" />
            </div>
          </form>
          <Powered />
        </div>
      </div>
      {isLogoutAlertOpen && (
        <ErrorSnackbar
          message="You Session has expired. Sign in again to continue work"
          open={isLogoutAlertOpen}
          autoHideDuration={5000}
          setOpen={setIsLogoutAlertOpen}
        />
      )}
    </main>
  );
}

export default observer(LoginPage);
