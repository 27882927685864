import { FormattedMessage, useIntl } from 'react-intl';
import { TextIndexInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from '../styles.module.scss';
import { useStores } from '../../../store';
import { IMedia } from '../../../shared/interfaces/assets/asset.interface';
import videoIcon from '../../../shared/assets/createAsset/videoIcon.svg';
import EditUploader from '../editUploader/editUploader';
import FormatEditor from '../../UI/formatEditor/formatEditor';

function EditMediaBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [addNewError, setError] = useState('');

  const [media, setMediaBlocks] = useState<IMedia[]>([]);

  const addNewBlock = (newTitleProps: string, newDescriptionProps: string) => {
    if (newTitleProps.trim() && newDescriptionProps.trim()) {
      setError('');
      flowResult(assetStore.addNewMediaBlock(id, newTitleProps, newDescriptionProps));
      setNewTitle('');
      setNewDescription('');
    } else {
      setError('All filed are required');
    }
  };

  const blockTitleHandler = (e: string, index: number) => {
    const tempArr = media;
    tempArr[index].title = e;
    setMediaBlocks([...tempArr]);
  };
  const blockDescriptionHandler = (e: string, index: number) => {
    const tempArr = media;
    tempArr[index].description = e;
    setMediaBlocks([...tempArr]);
  };

  const saveBlockChanges = (blockId: string | undefined, title: string, description: string) => {
    if (id && blockId) {
      flowResult(assetStore.saveBlockChanges(id, blockId, title, description));
    }
  };

  const removeBlock = (index: number, blockId: string | undefined) => {
    const newHighlights = media.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    if (id && blockId) {
      flowResult(assetStore.deleteMediaBlock(id, blockId));
      setMediaBlocks(newHighlights);
    }
  };

  const removeImage = (index: number, mediaIndex: number, blockId: string | undefined, mediaId: string | undefined) => {
    const newMediaBlocks = [...media];
    newMediaBlocks[index].mediaFiles.splice(mediaIndex, 1);
    if (id && blockId && mediaId) {
      flowResult(assetStore.deleteMediaImage(id, blockId, mediaId));
      setMediaBlocks(newMediaBlocks);
    }
  };

  useEffect(() => {
    setMediaBlocks(assetStore.currentAsset.media);
  }, [assetStore.currentAsset.media]);

  if (assetStore.isMediaBlocksLoading)
    return (
      <div className={c.formPart}>
        <Spinner single />
      </div>
    );

  return (
    <div className={c.formPart}>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.mediaBlock" />
      </div>
      <div className={c.wrapper}>
        <div className={c.listHighlights}>
          <div className={c.wrapperBlock}>
            {media.map((item, index) => {
              return (
                <div className={c.media} key={index}>
                  <div className={c.left}>
                    <div className={c.listFiles}>
                      <div className={c.wrapperBlock}>
                        {item.mediaFiles.map((mediaItem, mediaIndex) => {
                          return (
                            <div className={c.imgWrapper} key={mediaIndex}>
                              {mediaItem.image ? (
                                <img src={mediaItem.image.url} alt="" />
                              ) : (
                                <>
                                  <img src={videoIcon} alt="" />
                                  <div className={c.name}>{mediaItem.linksToVideo}</div>
                                </>
                              )}
                              <Button
                                variant="outlined"
                                onClick={() => removeImage(index, mediaIndex, item.id, mediaItem.id)}
                              >
                                Delete Image
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <EditUploader id={id} blockId={item.id} />
                    <TextIndexInput
                      value={item.title}
                      changeValue={blockTitleHandler}
                      label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.title.label' })}
                      placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.title.placeholder' })}
                      type="text"
                      fullWidth
                      index={index}
                    />
                    <FormatEditor
                      value={item.description}
                      changeValue={blockDescriptionHandler}
                      label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.label' })}
                      placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.placeholder' })}
                      fullWidth
                      index={index}
                    />
                    <button
                      className={c.addButton}
                      onClick={() => {
                        saveBlockChanges(item.id, item.title, item.description);
                      }}
                    >
                      Save Changes
                    </button>
                    <div className={c.right}>
                      {item.id && (
                        <IconButton className={c.delete} onClick={() => removeBlock(index, item.id)}>
                          <img src={closeIcon} alt="X" />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={c.media}>
              <div className={c.left}>
                <TextInput
                  value={newTitle}
                  changeValue={setNewTitle}
                  label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.title.label' })}
                  placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.title.placeholder' })}
                  type="text"
                  fullWidth
                />
                <FormatEditor
                  value={newDescription}
                  changeValue={setNewDescription}
                  label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.label' })}
                  placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.placeholder' })}
                  fullWidth
                />
                <p className={c.errorText}>{addNewError}</p>
                <button
                  className={c.addButton}
                  onClick={() => {
                    addNewBlock(newTitle, newDescription);
                  }}
                >
                  + Add This
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(EditMediaBlock);
