import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import { DateInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { serialize } from 'object-to-formdata';
import closeIcon from '../../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { IAssetItem } from '../../../shared/interfaces/userInfo.interface';
import IdentityFilesUploader from '../../identityFilesUploader/identityFilesUploader';

function AddPaymentModal({ isIssuer = false }: { isIssuer?: boolean }) {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [paymentDate, setDate] = useState<Date>(new Date());
  const [amountOfMoney, setMoney] = useState(0);
  const [numberOfTokens, setTokens] = useState(0);
  const [asset, setAsset] = useState('');
  const [documents, setDocuments] = useState([]);

  const [assets, setAssets] = useState<IAssetItem[]>([]);
  const [assetId, setAssetId] = useState('');

  const [validationErrors, setErrors] = useState<string[]>([]);
  const [dateError, setDateError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setErrors([]);
    setOpen(false);
  };

  const handleSendClick = () => {
    setLoading(true);
    const mainInfo = {
      paymentDate: paymentDate.toISOString(),
      assetId,
      investorId: id,
      files: documents,
      amountOfMoney,
      numberOfTokens,
    };
    const options = {
      indices: true,
    };
    const transaction = serialize(mainInfo, options);
    if (isIssuer) {
      tokenInstance
        .post('payments/transactions/issuer', transaction)
        .then(() => {
          setErrors([]);
          setDate(new Date());
          setDocuments([]);
          setTokens(0);
          setMoney(0);
          setAsset('');
          setOpen(false);
        })
        .catch(e => {
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setErrors([e.response.data.message]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      tokenInstance
        .post('payments/transactions', transaction)
        .then(() => {
          setErrors([]);
          setDate(new Date());
          setDocuments([]);
          setTokens(0);
          setMoney(0);
          setAsset('');
          setOpen(false);
        })
        .catch(e => {
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setErrors([e.response.data.message]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isIssuer) {
      tokenInstance.get('assets/issuer').then(({ data }) => {
        setAssets(data);
      });
    } else {
      tokenInstance.get('assets/list').then(({ data }) => {
        setAssets(data);
      });
    }
  }, [isIssuer]);

  const changeAsset = (assetSearch: string) => {
    setAsset(assetSearch);
    const selectedAsset = assets.find((assetItem: IAssetItem) => assetItem.name === assetSearch);
    if (selectedAsset) {
      setAssetId(selectedAsset.id);
    }
  };

  return (
    <div className={c.wrapper}>
      <button onClick={handleClickOpen} className={c.submitButton}>
        Add Wire Transaction
      </button>
      <Dialog open={open} onClose={handleClose}>
        <div className={c.dialog}>
          <div className={c.title}>Add transaction to user</div>
          <Button onClick={handleClose} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <p className={c.text}>Payment date</p>
          <DateInput fullWidth value={paymentDate} changeValue={setDate} setError={setDateError} />
          <TextInput
            value={amountOfMoney || ''}
            changeValue={setMoney}
            label="Amount of money"
            placeholder="Enter amount of Money"
            type="number"
            fullWidth
          />
          <TextInput
            value={numberOfTokens || ''}
            changeValue={setTokens}
            label="Number of Tokens"
            placeholder="Enter number of Tokens"
            type="number"
            fullWidth
          />
          <p className={c.text}>Choose Asset</p>
          <Select
            displayEmpty
            className={c.select}
            value={asset}
            onChange={e => {
              changeAsset(e.target.value);
            }}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em style={{ color: '#8D8D8D' }}>Select</em>;
              }
              return selected;
            }}
          >
            {assets.length > 0 ? (
              assets.map((assetItem: any) => (
                <MenuItem value={assetItem.name} key={assetItem.id}>
                  {assetItem.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="There are no assets on this portal" disabled>
                No assets created
              </MenuItem>
            )}
          </Select>
          <p className={c.text}>Payment proofs</p>
          <div style={{ marginTop: '8px' }}>
            <IdentityFilesUploader setDocuments={setDocuments} documents={documents} cap={5} />
          </div>
          {validationErrors &&
            validationErrors.map((error, index) => {
              return (
                <div className={c.errorText} key={index}>
                  {error}
                </div>
              );
            })}
          <div className={c.actions}>
            <button
              onClick={handleSendClick}
              className={c.submitButton}
              disabled={!amountOfMoney || !numberOfTokens || !asset || dateError || documents.length === 0 || isLoading}
            >
              {isLoading ? 'Loading...' : 'Add'}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AddPaymentModal;
