import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import SignInHeader from '../../../components/SignInHeader/SignInHeader';
import userPool from '../../../shared/config/cognitoPool';
import c from './styles.module.scss';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

function ConfirmEmailPage() {
  const query = useQueryParams();
  const email = query.get('email');
  const decodeEmail = decodeURIComponent(`${email}`);
  const { portalStore } = useStores();

  const [isSentShown, setIsSetShown] = useState(false);

  const pingSent = () => {
    setIsSetShown(true);
    setTimeout(() => setIsSetShown(false), 10000);
  };

  const resend = () => {
    const userData = {
      Username: `${decodeEmail}`,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    const clientMetaData: {
      [key: string]: string;
    } = {
      host: window.location.hostname,
      accountRole: 'investor',
      primaryColor: portalStore.portalUI.portalUi?.colorStyles
        ? portalStore.portalUI.portalUi?.colorStyles.primary
        : '#00BC68',
      portalName: portalStore.portalUI?.portalName ? portalStore.portalUI?.portalName : 'TokenSuite',
      portalBackground: portalStore.portalUI?.portalUi?.emailBack?.url!
        ? portalStore.portalUI.portalUi?.emailBack?.url!
        : portalStore.portalUI.portalUi?.authBack?.url!,
    };
    if (!portalStore.portalUI?.portalUi?.authBack?.url! && !portalStore.portalUI?.portalUi?.emailBack?.url!) {
      delete clientMetaData.portalBackground;
    }
    cognitoUser.resendConfirmationCode(err => {
      if (err) {
        return;
      }
      pingSent();
    }, clientMetaData);
  };

  return (
    <main className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Confirm | ${portalStore.portalUI.portalUi.title}`
            : 'Confirm | TokenSuite'}
        </title>
      </Helmet>
      <SignInHeader />
      <div className={c.rightBlock}>
        <div className={c.data}>
          <header>
            <FormattedMessage id="checkEmailPage.text.header" />
          </header>
          <p className={c.text}>
            <FormattedMessage
              id="checkEmailPage.text.email"
              values={{ email: <span className={c.email}>{decodeEmail}</span> }}
            />
          </p>
          <p className={c.text}>
            <FormattedMessage id="checkEmailPage.text.checkEmail" />
          </p>
          <div className={c.buttonBlock}>
            <Button className={c.resendBtn} disabled={isSentShown} onClick={resend}>
              <FormattedMessage id="checkEmailPage.button.resend" />
            </Button>
            <div className={`${c.sent} ${!isSentShown ? c.hidden : ''}`}>
              <FormattedMessage id="checkEmailPage.button.resendDisabled" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default observer(ConfirmEmailPage);
