import React from 'react';
import { useWallet } from '../../context/walletContext/Wallet.context';
import ErrorSnackbar from '../../components/UI/snackbar/error-snackbar';

const WalletErrorWrapper = () => {
  const { isAuthWalletError, authWalletError, setIsAuthWalletError } = useWallet();
  return isAuthWalletError ? (
    <ErrorSnackbar
      autoHideDuration={6000}
      message={authWalletError}
      open={isAuthWalletError}
      setOpen={setIsAuthWalletError!}
    />
  ) : null;
};

export default WalletErrorWrapper;
