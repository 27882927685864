import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { IUserNotifications, IUserVerifyInfoById, IUserVerifyStatus } from './verefication.types';
import { BenefeciarData } from '../../interfaces/headerInterface';

class UserInfoService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async getUserVerifyStatus(): Promise<IUserVerifyStatus> {
    const { data } = await this.authInstance.get<IUserVerifyStatus>('users/status');
    return data;
  }

  async getUserNotifications(): Promise<IUserNotifications> {
    const { data } = await this.authInstance.get<IUserNotifications>('users/notifications');
    return data;
  }

  async getUserVerifyStatusById(id: string): Promise<IUserVerifyInfoById> {
    const { data } = await this.authInstance.get<IUserVerifyInfoById>(`users/status/${id}`);
    return data;
  }

  async getBeneficiars(): Promise<BenefeciarData[]> {
    const { data } = await this.authInstance.get<BenefeciarData[]>('users/beneficiary');
    return data;
  }

  async getBeneficiarsById(id: string): Promise<BenefeciarData[]> {
    const { data } = await this.authInstance.get<BenefeciarData[]>(`users/beneficiary/user/${id}`);
    return data;
  }

  async createWallet(id: string): Promise<void> {
    await this.authInstance.post(`users/wallet/user/${id}`);
  }
}
export default new UserInfoService(tokenInstance);
