export enum ElementTypes {
  TRANSFER = 'transfer',
  KYC = 'kyc',
  KYB = 'kyb',
  ADD_BANK_ACC = 'addBankAccount',
  CARD_LINKING = 'cardLinking',
  TRADE = 'trade',
}

export interface CryptoBalance {
  network: string;
  assetType: string;
  address: string;
  totalBalance: string;
}

export interface FiatBalance {
  assetType: string;
  assetFiatType: string;
  total: number;
  pending: number;
  locked: number;
}

export interface FiatBalances {
  data: FiatBalance[];
}

export interface CustodialAccount {
  id: string;
  name: string;
  tickerSymbol: string;
  balances: FiatBalance[];
}

export interface CryptoBalances {
  data: CryptoBalance[];
  network: string;
  address: string;
}
