import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import CheckoutStages from '../../../components/checkout-payments/checkout-stages/checkoutStages';
import CheckoutWire from '../../../components/checkout-payments/checkout-wire/checkoutWire';
import { useStores } from '../../../store';
import c from './style.module.scss';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';
import CheckoutStripe from '../../../components/checkout-payments/checkout-stripe/checkoutStripe';
import CheckoutCrypto from '../../../components/checkout-payments/checkout-crypto/checkoutCrypto';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

function CheckoutConfirmation() {
  const navigate = useNavigate();
  const query = useQueryParams();

  const selectedMethod = query.get('method');

  const { checkoutStore, portalStore } = useStores();
  const { id, tokens = 0 } = useParams();

  useEffect(() => {
    if (!tokens || !id || !selectedMethod) {
      navigate('/assets');
      return;
    }
    if (checkoutStore.checkoutInfo.id !== id) {
      flowResult(checkoutStore.getCheckoutInfoById(id as string));
    }
    flowResult(checkoutStore.getPaymentMethods(id as string));
  }, [checkoutStore, id, tokens, selectedMethod, navigate]);

  const renderSelectedMethod = () => {
    if (selectedMethod === 'stripe' || selectedMethod === 'fiat') {
      return <CheckoutStripe />;
    }
    if (selectedMethod === 'blockchain') {
      return <CheckoutCrypto />;
    }
    const selectedRequisites = checkoutStore.paymentMethods.find(
      item => item.name.toLocaleLowerCase() === selectedMethod?.toLocaleLowerCase(),
    );
    if (selectedRequisites?.requisites) {
      return <CheckoutWire requisites={selectedRequisites?.requisites} />;
    }
    return 'Requisites not provided';
  };

  return (
    <div className={c.checkout}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Checkout Confirmation | ${portalStore.portalUI.portalUi.title}`
            : 'Checkout Confirmation | TokenSuite'}
        </title>
      </Helmet>
      <CheckoutStages />
      {checkoutStore.isLoading ? (
        <Spinner />
      ) : (
        <div className={c.container}>
          <div className={c.left}>{renderSelectedMethod()}</div>
          <div className={c.right}>
            <div className={c.wrapper}>
              <div className={c.title}>Order Summary</div>
              <div className={c.image}>
                <img src={checkoutStore.checkoutInfo.previewImage.url} alt="" />
              </div>
              <div className={c.asset}>
                <span className={c.name}>{`${checkoutStore.checkoutInfo.name.slice(0, 22).trim()}...`}</span>
                <span className={c.cost}>
                  {addComma(String(+tokens * +checkoutStore.checkoutInfo.unitPrice))}{' '}
                  {checkoutStore.checkoutInfo.currency}
                </span>
              </div>
              <div className={c.tokens}>
                {tokens}{' '}
                {checkoutStore.checkoutInfo.tickerSymbol
                  ? `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`
                  : ''}{' '}
                tokens
              </div>
              <div className={c.total}>
                <span className={c.title}>Total:</span>
                <div className={c.amount}>
                  {CURRENCY_SYMBOLS[checkoutStore.checkoutInfo.currency]}
                  {addComma(String(+checkoutStore.checkoutInfo.unitPrice * +tokens))}{' '}
                  <span className={c.currency}>{checkoutStore.checkoutInfo.currency}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(CheckoutConfirmation);
