import React from 'react';
import { Helmet } from 'react-helmet-async';
import Portals from '../../../components/admin-portals/Portals';

const AdminPortalsPage = () => {
  return (
    <>
      <Helmet>
        <title>Portals | TokenSuite</title>
      </Helmet>
      <h2>Portals</h2>
      <Portals />
    </>
  );
};

export default AdminPortalsPage;
