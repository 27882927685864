import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import React, { useState, useEffect, ReactElement } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import c from './styles.module.scss';
import tokenInstance from '../../../shared/services/API/tokenApi';
import filterDisabled from '../../../shared/assets/icons/filterArrowsDisabled.svg';
import filterASC from '../../../shared/assets/icons/filterArrowsASC.svg';
import filterDESC from '../../../shared/assets/icons/filterArrowsDESC.svg';
import { usePagination } from '../../../shared/hooks/usePagination';
import { RequestParams } from '../../../shared/types/investorTableType';
import DividendHistoryRow from './dividendHistoryRow';

type Column = {
  id: any;
  label: ReactElement | string;
  minWidth?: number;
  sortable?: true;
  left?: true;
  format?: (asset: any) => string;
  render?: (asset: any) => JSX.Element;
};

export interface RecordData {
  id: string;
  amountOfMoney: number;
  reportingDate: string;
}

const columns: readonly Column[] = [
  {
    id: 'Payment Date',
    left: true,
    label: 'Payment Date',
  },
  {
    id: 'Amount',
    label: 'Amount',
  },
];

export function DividendHistory() {
  const { id } = useParams<{ id: string }>();
  const [items, setItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const loadItems = async (requestParams: RequestParams) => {
    setIsLoading(true);
    const { data } = await tokenInstance.get<{ records: RecordData[]; total: number }>(`dividends/asset/${id}`, {
      params: requestParams,
    });
    setItems(data.records);
    setTotalItems(data.total);
    setIsLoading(false);
  };

  const {
    loadItems: load,
    onPageSizeChange,
    onCurrentPageChange,
    controlledParams,
    onOrderChange,
  } = usePagination({
    callback: loadItems,
  });

  const renderOrderIcon = (orderId: 'name' | 'status') => {
    if (controlledParams.value === orderId && controlledParams.sortOrder === 'DESC')
      return <img src={filterDESC} alt="" />;
    if (controlledParams.value === orderId && controlledParams.sortOrder === 'ASC')
      return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <h2 className={c.title}>Dividend History</h2>
      <div className={c.wrapper}>
        <TableContainer className={c.table_container}>
          <Table className={c.table} stickyHeader aria-label="sticky table">
            <TableHead className={c.head}>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                    <div className={`${column.left ? `${c.flexHead} ${c.left}` : `${c.flexHead}`}`}>
                      {column.label}
                      {column.sortable && (
                        <IconButton disabled={isLoading} onClick={() => onOrderChange(column.id)}>
                          {renderOrderIcon(column.id)}
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={20} align="center" className={c.loadingCell}>
                  {isLoading && (
                    <div className={c.loading}>
                      <Spinner single />
                    </div>
                  )}
                </TableCell>
              </TableRow>

              {items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={20}>
                    <div className={c.emptyBlock}>
                      <FormattedMessage id="dividendPage.dividendHistory.noHistory" />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                items.map((row: RecordData) => {
                  return <DividendHistoryRow key={row.id} row={row} isLoading={isLoading} />;
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={c.pagination}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={+controlledParams.pageSize}
          page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
          onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
          onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
          SelectProps={{
            disabled: isLoading,
          }}
          backIconButtonProps={{
            disabled: isLoading || +controlledParams.currentPage === 0,
          }}
          nextIconButtonProps={{
            disabled:
              isLoading ||
              Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
              totalItems === 0,
          }}
        />
      </div>
    </>
  );
}
