import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

const headCells = ['Token Name', 'Token address', 'Asset name', 'Issuer', 'Before softcap', 'Total', 'Minted quantity'];

const TableTokenHead = () => {
  return (
    <TableHead className="tableHeadDT">
      <TableRow>
        {headCells.map(h => (
          <TableCell key={h} className="headCellDT">
            {h}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default TableTokenHead;
