import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import c from './style.module.scss';
import checkmarkImg from '../../../../shared/assets/icons/checkmark.svg';

export default function PasswordStatus({
  password,
  setIsValid,
}: {
  password: string;
  setIsValid: (b: boolean) => void;
}) {
  const [points, setPoints] = useState({
    lenght: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });

  useEffect(() => {
    setPoints({
      lenght: password.length >= 12,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[$&+,:;=?@#|`'"<>.^*()%!~-]/.test(password),
    });
  }, [password]);

  useEffect(() => {
    setIsValid(!Object.values(points).includes(false));
  }, [points, setIsValid]);

  return (
    <div className={c.wrapper}>
      {Object.values(points).filter(a => a).length === 1 && (
        <div className={`${c.status} ${c.weak}`}>
          <FormattedMessage id="newPasswordStrenght.strength.veryWeak" />
        </div>
      )}

      {Object.values(points).filter(a => a).length === 2 && (
        <div className={`${c.status} ${c.weak}`}>
          <FormattedMessage id="newPasswordStrenght.strength.weak" />
        </div>
      )}

      {Object.values(points).filter(a => a).length === 3 && (
        <div className={`${c.status} ${c.medium}`}>
          <FormattedMessage id="newPasswordStrenght.strength.medium" />
        </div>
      )}

      {Object.values(points).filter(a => a).length === 4 && (
        <div className={c.status}>
          <FormattedMessage id="newPasswordStrenght.strength.strong" />
        </div>
      )}

      {Object.values(points).filter(a => a).length === 5 && (
        <div className={c.status}>
          <FormattedMessage id="newPasswordStrenght.strength.veryStrong" />
        </div>
      )}

      <p className={c.requirements}>
        <FormattedMessage id="newPasswordStrenght.requirements" />
      </p>
      <div className={c.points}>
        <div className={c.item}>
          <img src={checkmarkImg} className={(points.lenght && c.active) || ''} alt="" />
          <FormattedMessage id="newPasswordStrenght.points.lenght" />
        </div>
        <div className={c.item}>
          <img src={checkmarkImg} className={(points.lowercase && c.active) || ''} alt="" />
          <FormattedMessage id="newPasswordStrenght.points.lowerCase" />
        </div>
        <div className={c.item}>
          <img src={checkmarkImg} className={(points.uppercase && c.active) || ''} alt="" />
          <FormattedMessage id="newPasswordStrenght.points.upperCase" />
        </div>
        <div className={c.item}>
          <img src={checkmarkImg} className={(points.number && c.active) || ''} alt="" />
          <FormattedMessage id="newPasswordStrenght.points.number" />
        </div>
        <div className={c.item}>
          <img src={checkmarkImg} className={(points.special && c.active) || ''} alt="" />
          <FormattedMessage id="newPasswordStrenght.points.special" />
        </div>
      </div>
    </div>
  );
}
