import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { SelectInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';
import c from './style.module.scss';
import { useStores } from '../../../store';
import { KycProvider, KycProviderResponse } from '../../../shared/interfaces/userInfo.interface';

function PortalFeatures({ id }: { id: string }) {
  const [kycKyb, setKycKyb] = useState<'Fortress' | 'North Capital'>('Fortress');

  const { portalStore } = useStores();

  useEffect(() => {
    if (portalStore.currentPortal?.features?.kycKyb) {
      setKycKyb(KycProviderResponse[portalStore.currentPortal?.features?.kycKyb as KycProvider]);
    } else {
      setKycKyb('North Capital');
    }
  }, [portalStore.currentPortal?.features?.kycKyb]);

  const submitFont = () => {
    flowResult(portalStore.changePortalFeature(id, KycProvider[kycKyb]));
  };

  return (
    <Stack gap="20px" mb="25px">
      <SelectInput
        value={kycKyb}
        changeValue={setKycKyb}
        label="Change KycKyb Provider"
        defaultValue={kycKyb}
        fullWidth
      >
        <MenuItem value="North Capital">North Capital</MenuItem>
        <MenuItem value="Fortress">Fortress</MenuItem>
      </SelectInput>
      <div className={c.btns}>
        <button className={c.button} onClick={submitFont}>
          Change
        </button>
      </div>
    </Stack>
  );
}

export default observer(PortalFeatures);
