import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Stack, TableCell, TableRow } from '@mui/material';
import c from './style.module.scss';
import CopyIcon from '../../../shared/assets/icons/copyMail.svg';
import DefaultButton from '../../UI/buttons/default-button';
import { InitialSalesToken, TokenOnPortal } from '../../../shared/services/token/types';
import { InitialSaleAsset } from '../../../shared/services/portal/types';
import MintTokensAdminModal from './mint-tokens-admin-modal';
import tokenService from '../../../shared/services/token/tokenService';

interface TokenRowsProps {
  token: TokenOnPortal;
  setTokenId: Dispatch<SetStateAction<string>>;
  setIsDeploying: Dispatch<SetStateAction<boolean>>;
  getIssuerData: (value: string) => string;
  initialAssets: InitialSaleAsset[];
}

const onCopyValueHandler = (value: string) => {
  if (!navigator) return;
  navigator.clipboard.writeText(value);
};

const getTokenAddressView = (address: string) => `${address.slice(0, 4)}...${address.slice(-5)}`;

const getTokenDimensions = (sale: InitialSalesToken) => {
  if (!sale)
    return {
      beforeSoftCup: '-',
      totalQuantity: '-',
      mintedQuantity: '-',
    };

  let beforeSoftCup = 0;
  beforeSoftCup = sale.asset.targetAmount - sale.paymentTokenRaised!;
  beforeSoftCup *= +!(beforeSoftCup < 0);
  return {
    beforeSoftCup,
    totalQuantity: sale.asset.tokenQuantity,
    mintedQuantity: sale.paymentTokenRaised,
  };
};

const TokenRows: FC<TokenRowsProps> = ({ token, setTokenId, setIsDeploying, getIssuerData, initialAssets }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDistributeHandler = async (contractAddress: string) => {
    setLoading(true);
    try {
      await tokenService.distributeTokenByAdmin(contractAddress);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const tokenDimensions = getTokenDimensions(token.initialSales[0]);

  return (
    <TableRow key={token.id} className="tableRowDT" sx={{ verticalAlign: 'middle' }}>
      <TableCell className="cellDT">
        {token.tokenName} <span className={c.tokenSymbol}>(${token.tokenSymbol.toUpperCase()})</span>
      </TableCell>
      <TableCell className="cellDT">
        <Stack
          direction="row"
          alignItems="center"
          gap={8}
          sx={{ cursor: 'pointer' }}
          onClick={() => onCopyValueHandler(token.tokenAddress)}
        >
          {getTokenAddressView(token.tokenAddress)}
          <img src={CopyIcon} alt="" />
        </Stack>
      </TableCell>
      <TableCell className="cellDT">{token.initialSales.length > 0 ? token.initialSales[0].asset.name : '-'}</TableCell>
      <TableCell className="cellDT">{getIssuerData(token.issuerAccount)}</TableCell>
      <TableCell className="cellDT">{tokenDimensions.beforeSoftCup}</TableCell>
      <TableCell className="cellDT">{tokenDimensions.totalQuantity}</TableCell>
      <TableCell className="cellDT">{tokenDimensions.mintedQuantity}</TableCell>
      <TableCell align="right">
        <Stack direction="row" alignItems="center" gap={8}>
          <DefaultButton
            variant="contained"
            onClick={() => {
              setTokenId(token.id);
              setIsDeploying(true);
            }}
            disabled={!initialAssets.length || token.initialSales.length > 0}
          >
            {!initialAssets ? 'No initial assets' : 'Deploy smart contract for asset sale'}
          </DefaultButton>
          <DefaultButton variant="contained" onClick={() => setOpen(true)} disabled={!token.initialSales?.length}>
            Mint tokens
          </DefaultButton>
          <DefaultButton
            variant="contained"
            disabled={!token.initialSales?.length || !token.initialSales[0]?.isSoftCup}
            onClick={() => {
              onDistributeHandler(token.initialSales[0].contractAddress);
            }}
          >
            {loading ? 'Distributing...' : 'Distribute tokens'}
          </DefaultButton>
        </Stack>
      </TableCell>
      {open && <MintTokensAdminModal open={open} setOpen={setOpen} tokenId={token.id} />}
    </TableRow>
  );
};

export default TokenRows;
