import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import c from '../styles.module.scss';
import { useStores } from '../../../store';
import CheckboxInput from '../../checkout-payments/checkbox-input/checkboxInput';

function AssetHideBlock(props: { id: string }) {
  const { id } = props;
  const { assetStore } = useStores();

  const [hide, setHide] = useState(assetStore.currentAsset.private);

  const submit = () => {
    flowResult(assetStore.updatePrivate(id, hide));
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="mainInfo">
        Asset Visability
      </div>
      <div className={c.inputsBlock}>
        <label className={c.checkbox} htmlFor="hide">
          <CheckboxInput
            disabled={false}
            id="hide"
            checked={hide}
            label=""
            checkBoxHandler={() => {
              setHide(!hide);
            }}
          />
          <span className={c.label}>Hide asset from marketplace</span>
        </label>
      </div>
      <button className={c.submitButton} onClick={submit}>
        Save
      </button>
    </div>
  );
}

export default observer(AssetHideBlock);
