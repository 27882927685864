import React, { FC } from 'react';
import c from '../style.module.scss';

interface SettingBlockProps {
  title: string;
  children: React.ReactNode;
}

const SettingBlock: FC<SettingBlockProps> = ({ title, children }) => {
  return (
    <div className={c.blockWrapper}>
      <h3 className="titleForBlock">{title}</h3>
      <div className={c.nested}>{children}</div>
    </div>
  );
};

export default SettingBlock;
