import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import c from './styles.module.scss';
import IdentityFilesUploader from '../../../components/identityFilesUploader/identityFilesUploader';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

// @ts-ignore
const investReadyiFrame = window.IR;

export default function AccreditationPage() {
  const query = useQueryParams();
  const id = query.get('id');
  const navigate = useNavigate();
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [accreditationType, setType] = useState('');
  const [documents, setDocuments] = useState([]);
  const [errorMessages, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const { portalStore } = useStores();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    navigate('/portfolio');
  };

  const submit = () => {
    setLoading(true);
    const aiFormData = new FormData();
    aiFormData.append('documentType', accreditationType);
    aiFormData.append('file', documents[0]);
    if (id) {
      aiFormData.append('userId', id);
      tokenInstance
        .post('users/ai/user', aiFormData)
        .then(() => {
          navigate(`/user/${id}`);
        })
        .catch(e => {
          setErrors(e.response.data.message);
          setLoading(false);
        });
    } else {
      tokenInstance
        .post('users/ai', aiFormData)
        .then(() => {
          setOpen(true);
          setLoading(false);
        })
        .catch(e => {
          setErrors(e.response.data.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      setLoading(true);
      tokenInstance
        .get('users/ai/investready')
        .then((res: any) => {
          investReadyiFrame.init(res.data.publicToken);
          investReadyiFrame.iFrame.src = `${process.env.REACT_APP_INVESTREADY_IFRAME_URL}/login/${res.data.loginToken}`;
          setLoading(false);
        })
        .catch(e => {
          setErrors(e.response?.data?.message);
          setLoading(false);
        });
    }
  }, [portalStore.portalUI.features?.kycKyb]);

  const handleDismiss = () => {
    if (id) {
      tokenInstance.get(`users/ai/not-accredited/${id}`).then(() => {
        navigate(`/user/${id}`);
      });
    } else {
      tokenInstance.get('users/ai/not-accredited').then(() => {
        navigate('/portfolio', { replace: true });
      });
    }
  };

  const renderHeader = () => {
    if (accreditationType === 'Tax Return') {
      return (
        <>
          <div className={c.title}>
            <FormattedMessage id="accreditationPage.fields.taxReturn.title" />
          </div>
          <div className={c.text}>
            <FormattedMessage id="accreditationPage.fields.taxReturn.description" />
          </div>
        </>
      );
    }
    if (accreditationType === 'Account Statement') {
      return (
        <>
          <div className={c.title}>
            <FormattedMessage id="accreditationPage.fields.accountStatement.title" />
          </div>
          <div className={c.text}>
            <FormattedMessage id="accreditationPage.fields.accountStatement.description" />
          </div>
        </>
      );
    }
    return (
      <>
        <div className={c.title}>
          <FormattedMessage id="accreditationPage.fields.yourAccreditation.title" />
        </div>
        <div className={c.text}>
          <FormattedMessage id="accreditationPage.fields.yourAccreditation.description" />
        </div>
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Accreditation | ${portalStore.portalUI.portalUi.title}`
            : 'Accreditation | TokenSuite'}
        </title>
      </Helmet>
      {portalStore.portalUI.features?.kycKyb === 'fortress' && (
        <>
          <iframe
            id="InvestReadyiFrame"
            name="InvestReady"
            title="investReady"
            allowFullScreen
            scrolling="no"
            className={loading ? `${c.investReady} ${c.hide}` : `${c.investReady}`}
          />
          {loading && <Spinner />}
        </>
      )}
      {portalStore.portalUI.features?.kycKyb !== 'fortress' &&
        (!isSecondStep ? (
          <>
            <p className={c.title}>
              <FormattedMessage id="accreditationPage.title" />
            </p>
            <div className={c.block}>
              <p className={c.text}>
                <FormattedMessage id="accreditationPage.fields.title" />
              </p>
              <div className={c.fields}>
                <div className={c.select}>
                  <button
                    className={`${c.item} ${accreditationType === 'Tax Return' ? c.active : ''}`}
                    onClick={() => {
                      setType('Tax Return');
                    }}
                  >
                    <Checkbox
                      checked={accreditationType === 'Tax Return'}
                      onChange={() => setType('Tax Return')}
                      className={c.checkbox}
                    />
                    <header>
                      <FormattedMessage id="accreditationPage.fields.taxReturn.title" />
                    </header>
                    <p>
                      <FormattedMessage id="accreditationPage.fields.taxReturn.text" />
                    </p>
                  </button>
                  <button
                    className={`${c.item} ${accreditationType === 'Account Statement' ? c.active : ''}`}
                    onClick={() => {
                      setType('Account Statement');
                    }}
                  >
                    <Checkbox
                      checked={accreditationType === 'Account Statement'}
                      onChange={() => setType('Account Statement')}
                      className={c.checkbox}
                    />
                    <header>
                      <FormattedMessage id="accreditationPage.fields.accountStatement.title" />
                    </header>
                    <p>
                      <FormattedMessage id="accreditationPage.fields.accountStatement.text" />
                    </p>
                  </button>
                  <button
                    className={`${c.item} ${accreditationType === 'Your Accreditation' ? c.active : ''}`}
                    onClick={() => {
                      setType('Your Accreditation');
                    }}
                  >
                    <Checkbox
                      checked={accreditationType === 'Your Accreditation'}
                      onChange={() => setType('Your Accreditation')}
                    />
                    <header>
                      <FormattedMessage id="accreditationPage.fields.yourAccreditation.title" />
                    </header>
                    <p>
                      <FormattedMessage id="accreditationPage.fields.yourAccreditation.text" />
                    </p>
                  </button>
                </div>
              </div>

              <button onClick={handleDismiss} className={c.skip}>
                <FormattedMessage id="accreditationPage.fields.dismiss" />
              </button>
            </div>
            <div className={c.btns}>
              <Button onClick={() => setIsSecondStep(true)} disabled={!accreditationType} className={c.btn}>
                <FormattedMessage id="accreditationPage.next" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <button
              onClick={() => {
                setIsSecondStep(false);
                setDocuments([]);
              }}
              className={c.back}
            >
              <img src={ArrowBackImg} alt="" />
              <FormattedMessage id="kycPage.back" />
            </button>
            <p className={c.title}>
              <FormattedMessage id="accreditationPage.title" />
            </p>
            <div className={c.block}>
              {renderHeader()}
              <div>
                <IdentityFilesUploader setDocuments={setDocuments} documents={documents} cap={1} />
              </div>
              <div className={c.errors}>
                <div className={c.errorText}>{errorMessages}</div>
              </div>
            </div>
            <div className={c.btns}>
              <Button
                className={c.btn}
                onClick={submit}
                disabled={documents.length !== 1 || !accreditationType || loading}
              >
                {loading ? <>loading...</> : <FormattedMessage id="accreditationPage.submit" />}
              </Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
              <div className={c.dialog}>
                <p className={c.title}>
                  <FormattedMessage id="accreditationPage.dialog.title" />
                </p>
                <p className={c.text}>
                  <FormattedMessage id="accreditationPage.dialog.text1" />
                </p>
                <p className={c.text}>
                  <FormattedMessage id="accreditationPage.dialog.text2" />
                </p>
                <div className={c.actions}>
                  <Link to="/portfolio" className={c.submitButton}>
                    <FormattedMessage id="accreditationPage.dialog.continue" />
                  </Link>
                </div>
              </div>
            </Dialog>
          </>
        ))}
    </div>
  );
}
