import React, { useEffect, useRef, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { observer, Observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import { useStores } from '../../../store';
import FormikContainer from '../../formik/formik-container/formik-container';
import { InitialValues } from './portal-details.types';
import { fields } from './portal-details.meta';
import FormikControl from '../../formik/formik-control/formik-control';
import Uploader from '../../createAsset/uploader/Uploader';
import { editPortalDetailsSchema } from '../../../shared/lib/validation-schemas/edit-portal-details-schema';
import { EditPortalDto } from '../../../shared/interfaces/portal/portal.interface';
import AddPortalDomain from './add-portal-domain';
import ActivateIssuerModal from '../../activate-issuer-modal/activateIssuerModal';
import PortalTheme from '../portal-theme/portal-theme';
import SuccessSnackbar from '../../UI/snackbar/success-snackbar';
import PortalFeatures from './portal-features';

// TODO map all pictures instead of it solution

const PortalDetails = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [portalId, setId] = useState(id);
  const [open, setOpen] = useState(false);
  const { portalStore, headerStore } = useStores();
  const userData = headerStore.user;
  const userRole = userData.role;
  const [favicon, setFavicon] = useState<{ image?: any }[]>([]);
  const [faviconError, setFaviconError] = useState('');
  const [authBack, setAuthBack] = useState<{ image?: any }[]>([]);
  const [authBackError, setAuthBackError] = useState('');
  const [logo, setLogo] = useState<{ image?: any }[]>([]);
  const [logoError, setLogoError] = useState('');
  const [authMobileBack, setAuthMobileBack] = useState<{ image?: any }[]>([]);
  const [authMobileBackError, setAuthMobileBackError] = useState('');
  const [emailBack, setEmailBack] = useState<{ image?: any }[]>([]);
  const [emailBackError, setEmailBackError] = useState('');
  const [metaImage, setMetaImage] = useState<{ image?: any }[]>([]);
  const [metaError, setMetaError] = useState('');

  const [faviconSizeError, setFaviconSizeError] = useState('');

  const favWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (id === undefined) {
      setId(userData.portals[0]);
    } else {
      setId(id);
    }
    if (portalId) {
      flowResult(portalStore.getPortalUiById(portalId));
    }
  }, [portalId, id, portalStore, userData.portals]);

  useEffect(() => {
    return () => {
      portalStore.resetCurrentPortal();
    };
  }, [portalStore]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'auto',
    });
  }, []);

  const initialValues: InitialValues = {
    title: portalStore.currentPortal?.portalUi?.title || '',
    authDescription: portalStore.currentPortal?.portalUi?.authDescription || '',
    metaDescription: portalStore.currentPortal?.portalUi?.metaDescription || '',
    googleTag: portalStore.currentPortal?.portalUi?.googleTag || '',
  };

  const validateFaviconSize = async () => {
    if (!favicon.length) return true;
    const image = new Image();
    image.src = URL.createObjectURL(favicon[0].image);
    const result: boolean = await new Promise<boolean>(resolve => {
      image.onload = () => {
        resolve(
          (image.naturalWidth === 32 && image.naturalHeight === 32) ||
            (image.naturalWidth === 16 && image.naturalHeight === 16),
        );
      };
      image.onerror = () => {
        resolve(false);
      };
    });
    return result;
  };

  useEffect(() => {
    if (!favWrapper.current) return;
    if (!favicon.length) {
      setFaviconSizeError('');
      return;
    }
    favWrapper.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }, [favicon.length, faviconError]);

  const onSubmit = async (title: string, authDescription: string, metaDescription: string, googleTag: string) => {
    if (faviconError || authBackError || logoError || authMobileBackError || metaError || emailBackError) return;
    const isFaviconSizeValid = await validateFaviconSize();
    if (!isFaviconSizeValid) {
      setFaviconSizeError('Upload favicon with right sizes (16x16 or 32x32 px)');
      return;
    }
    const payload: EditPortalDto = {
      authDescription,
      title,
      metaDescription,
      portalId,
    } as EditPortalDto;
    if (googleTag.trim()) {
      payload.googleTag = googleTag;
    }
    if (authBack.length) {
      payload.authBack = authBack[0].image;
    }
    if (favicon.length) {
      payload.favicon = favicon[0].image;
    }
    if (logo.length) {
      payload.logo = logo[0].image;
    }
    if (authMobileBack.length) {
      payload.authMobileBack = authMobileBack[0].image;
    }
    if (emailBack.length) {
      payload.emailBack = emailBack[0].image;
    }
    if (metaImage.length) {
      payload.metaImage = metaImage[0].image;
    }
    await flowResult(portalStore.editPortalUi(payload));
    setOpen(true);
    await flowResult(portalStore.getPortalUiById(portalId as string));
    setFavicon([]);
    setLogo([]);
    setAuthBack([]);
    setAuthMobileBack([]);
    setMetaImage([]);
    setEmailBack([]);
  };

  return (
    <Stack mt="40px" className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Portal Settings | ${portalStore.portalUI.portalUi.title}`
            : 'Portal Settings | TokenSuite'}
        </title>
      </Helmet>
      <PortalTheme id={portalId as string} />
      {userRole === 'admin' && portalStore.currentPortal?.isDemo && (
        <ActivateIssuerModal portalId={portalId as string} />
      )}
      {userRole === 'admin' && <AddPortalDomain portalId={portalId as string} />}
      {userRole === 'admin' && <PortalFeatures id={portalId as string} />}
      <FormikContainer<InitialValues>
        initialValues={initialValues}
        validationSchema={editPortalDetailsSchema}
        onSubmitHandler={async values => {
          await onSubmit(values.title, values.authDescription, values.metaDescription, values.googleTag);
        }}
      >
        {values => (
          <Observer>
            {() => (
              <Stack gap="20px">
                <div ref={favWrapper} className={faviconSizeError ? c.favError : ''}>
                  <div>Favicon (16x16 or 32x32)</div>
                  {!portalStore.currentPortal?.portalUi?.favicon?.url ? (
                    <div>
                      <Uploader
                        documents={favicon}
                        withIcoFormat
                        setDocuments={setFavicon}
                        setError={setFaviconError}
                        cap={1}
                        withVideo={false}
                      />
                    </div>
                  ) : (
                    <div className={c.imgWrapper}>
                      <div className={c.img}>
                        <img src={portalStore.currentPortal.portalUi.favicon.url} alt="" />
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          portalStore.deleteImage(
                            portalStore.currentPortal?.portalUi?.favicon?.id as string,
                            'favicon',
                          );
                        }}
                      >
                        Delete image
                      </Button>
                    </div>
                  )}
                </div>

                <div>
                  <div>Logo</div>
                  {!portalStore.currentPortal?.portalUi?.logo?.url ? (
                    <Uploader
                      withSvgFormat
                      documents={logo}
                      setDocuments={setLogo}
                      setError={setLogoError}
                      cap={1}
                      withVideo={false}
                    />
                  ) : (
                    <div className={c.imgWrapper}>
                      <div className={c.img}>
                        <img src={portalStore.currentPortal.portalUi.logo.url} alt="" />
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          portalStore.deleteImage(portalStore.currentPortal?.portalUi?.logo?.id as string, 'logo');
                        }}
                      >
                        Delete image
                      </Button>
                    </div>
                  )}
                </div>
                <div>
                  <div>Auth background</div>
                  {!portalStore.currentPortal?.portalUi?.authBack?.url ? (
                    <Uploader
                      documents={authBack}
                      setDocuments={setAuthBack}
                      setError={setAuthBackError}
                      cap={1}
                      withVideo={false}
                    />
                  ) : (
                    <div className={c.imgWrapper}>
                      <div className={c.img}>
                        <img src={portalStore.currentPortal.portalUi.authBack.url} alt="" />
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          portalStore.deleteImage(
                            portalStore.currentPortal?.portalUi?.authBack?.id as string,
                            'authBack',
                          );
                        }}
                      >
                        Delete image
                      </Button>
                    </div>
                  )}
                </div>
                <div>
                  <div>Auth mobile background</div>
                  {!portalStore.currentPortal?.portalUi?.authMobileBack?.url ? (
                    <Uploader
                      documents={authMobileBack}
                      setDocuments={setAuthMobileBack}
                      setError={setAuthMobileBackError}
                      cap={1}
                      withVideo={false}
                    />
                  ) : (
                    <div className={c.imgWrapper}>
                      <div className={c.img}>
                        <img src={portalStore.currentPortal.portalUi.authMobileBack.url} alt="" />
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          portalStore.deleteImage(
                            portalStore.currentPortal?.portalUi?.authMobileBack?.id as string,
                            'authMobileBack',
                          );
                        }}
                      >
                        Delete image
                      </Button>
                    </div>
                  )}
                </div>
                <div>
                  <div>Email picture</div>
                  {!portalStore.currentPortal?.portalUi?.emailBack?.url ? (
                    <Uploader
                      documents={emailBack}
                      setDocuments={setEmailBack}
                      setError={setEmailBackError}
                      cap={1}
                      withVideo={false}
                    />
                  ) : (
                    <div className={c.imgWrapper}>
                      <div className={c.img}>
                        <img src={portalStore.currentPortal.portalUi.emailBack.url} alt="" />
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          portalStore.deleteImage(
                            portalStore.currentPortal?.portalUi?.emailBack?.id as string,
                            'emailBack',
                          );
                        }}
                      >
                        Delete image
                      </Button>
                    </div>
                  )}
                </div>
                <div>
                  <div>Meta image</div>
                  {!portalStore.currentPortal?.portalUi?.metaImage?.url ? (
                    <Uploader
                      documents={metaImage}
                      setDocuments={setMetaImage}
                      setError={setMetaError}
                      cap={1}
                      withVideo={false}
                    />
                  ) : (
                    <div className={c.imgWrapper}>
                      <div className={c.img}>
                        <img src={portalStore.currentPortal.portalUi.metaImage.url} alt="" />
                      </div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          portalStore.deleteImage(
                            portalStore.currentPortal?.portalUi?.metaImage?.id as string,
                            'metaImage',
                          );
                        }}
                      >
                        Delete image
                      </Button>
                    </div>
                  )}
                </div>
                {fields.map(({ id: key, ...field }) => (
                  <FormikControl key={key} {...field} />
                ))}
                <Button disabled={values.isSubmitting} className={c.button} type="submit" variant="contained">
                  {portalStore.isLoading ? 'Loading...' : 'Edit details'}
                </Button>
              </Stack>
            )}
          </Observer>
        )}
      </FormikContainer>
      <SuccessSnackbar open={open} setOpen={setOpen} autoHideDuration={3000} message="Settings were changed" />
    </Stack>
  );
});

export default PortalDetails;
