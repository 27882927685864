import React, { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import copyMail from '../../../../shared/assets/icons/copyMail.svg';
import c from './style.module.scss';
import { BenefeciarData } from '../../../../shared/interfaces/headerInterface';
import copyDataInBuffer from '../../../../shared/lib/copy-data-in-buffer';
import { EAccountStatuses } from '../../../../shared/types/account.types';

function RenderStatus({ status }: { status: string }) {
  if (!status) {
    return (
      <div className={`${c.status} ${c.notStarted}`}>
        <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.notStarted" />
      </div>
    );
  }
  switch (status) {
    case 'Verification Not Started Yet':
      return (
        <div className={`${c.status} ${c.notStarted}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.notStarted" />
        </div>
      );
    case 'Verified':
      return (
        <div className={`${c.status} ${c.verified}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verified" />
        </div>
      );
    case 'Verification Pending':
      return (
        <div className={`${c.status} ${c.pending}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedPending" />
        </div>
      );
    case 'Verification Denied':
      return (
        <div className={`${c.status} ${c.denied}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedDenied" />
        </div>
      );
    case 'Blocked':
      return <div className={`${c.status} ${c.denied}`}>Blocked</div>;
    default:
      return <div className={`${c.status} ${c.notStarted}`}>{status}</div>;
  }
}

function BenefeciarRow({ profileData, resubmit = false }: { profileData: BenefeciarData; resubmit: boolean }) {
  const { firstName, lastName, kycStatus, email, id } = profileData;
  const emailRef = useRef<null | HTMLDivElement>(null);
  const [isEmailTooltipOpen, setIsEmailTooltipOpen] = useState(false);

  const mouseOnEmailHandler = () => {
    if (!emailRef.current) return;
    if (emailRef.current.offsetWidth >= emailRef.current.scrollWidth) return;
    setIsEmailTooltipOpen(true);
  };

  return (
    <div className={`${c.profileRow} ${c.profileRowGrid}`}>
      <div className={c.columnLeft}>
        <div className={c.name}>
          {firstName} {lastName}
        </div>
        <div className={c.email}>
          <Tooltip
            open={isEmailTooltipOpen}
            onClose={() => setIsEmailTooltipOpen(false)}
            title={email}
            onOpen={mouseOnEmailHandler}
            arrow
          >
            <div className={c.emailText} ref={emailRef}>
              {email}
            </div>
          </Tooltip>
          <button
            onClick={() => {
              copyDataInBuffer(email);
            }}
          >
            <img src={copyMail} alt="copyMail" />
          </button>
        </div>
      </div>
      <div className={c.columnRight}>
        <div className={c.itemColStatus}>
          <RenderStatus status={kycStatus} />
        </div>
      </div>
      {resubmit && kycStatus === EAccountStatuses.VerificationDenied && (
        <div className={c.menu}>
          <Link to={`/kyc?beneficiary=true&resubmit=true&id=${id}`} className="defaultButton">
            Resubmit KYC
          </Link>
        </div>
      )}
    </div>
  );
}
export default BenefeciarRow;
