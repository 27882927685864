import { TextIndexInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import first from '../../../shared/assets/createAsset/first.png';
import second from '../../../shared/assets/createAsset/second.png';
import third from '../../../shared/assets/createAsset/third.png';

import c from './style.module.scss';

function HowItWork({
  works,
  setWorks,
  workHighlight,
  setWorkHighlight,
}: {
  works: {
    title: string;
    description: string;
  }[];
  setWorks: any;
  workHighlight: string;
  setWorkHighlight: any;
}) {
  const intl = useIntl();
  const [showBlock, setShowBlock] = useState(false);
  const [showBtn, setShow] = useState(true);

  const showClick = () => {
    setWorks([
      { title: '', description: '' },
      { title: '', description: '' },
      { title: '', description: '' },
    ]);
    setShowBlock(true);
    setShow(false);
  };

  const workTitleHandler = (e: string, index: number) => {
    const tempArr = works;
    tempArr[index].title = e;
    setWorks([...tempArr]);
  };
  const workDescriptionHandler = (e: string, index: number) => {
    const tempArr = works;
    tempArr[index].description = e;
    setWorks([...tempArr]);
  };
  return (
    <div className={c.wrapper}>
      {showBlock && (
        <>
          <TextInput
            value={workHighlight}
            changeValue={setWorkHighlight}
            label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.highlights.label' })}
            placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.highlights.placeholder' })}
            type="text"
            fullWidth
          />
          <div className={c.block}>
            <img src={first} alt="first" />
            <div className={c.right}>
              <TextIndexInput
                value={works[0].title}
                changeValue={workTitleHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.placeholder' })}
                type="text"
                fullWidth
                index={0}
              />
              <TextIndexInput
                value={works[0].description}
                changeValue={workDescriptionHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.placeholder' })}
                type="text"
                fullWidth
                multiline
                index={0}
              />
            </div>
          </div>
          <div className={c.block}>
            <img src={second} alt="second" />
            <div className={c.right}>
              <TextIndexInput
                value={works[1].title}
                changeValue={workTitleHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.placeholder' })}
                type="text"
                fullWidth
                index={1}
              />
              <TextIndexInput
                value={works[1].description}
                changeValue={workDescriptionHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.placeholder' })}
                type="text"
                fullWidth
                multiline
                index={1}
              />
            </div>
          </div>
          <div className={c.block}>
            <img src={third} alt="third" />
            <div className={c.right}>
              <TextIndexInput
                value={works[2].title}
                changeValue={workTitleHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.placeholder' })}
                type="text"
                fullWidth
                index={2}
              />
              <TextIndexInput
                value={works[2].description}
                changeValue={workDescriptionHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.placeholder' })}
                type="text"
                fullWidth
                multiline
                index={2}
              />
            </div>
          </div>
        </>
      )}
      {showBtn && (
        <button className={c.addButton} onClick={showClick}>
          <FormattedMessage id="uploader.button.addBlock" />
        </button>
      )}
    </div>
  );
}
export default HowItWork;
