import React from 'react';
import { FormikControlProps } from './formik-control.types';
import FormikInput from '../formik-input/formik-input';
import FormikAutocomplete from '../formik-autocomplete/formik-autocomplete';
import FormikCheckbox from '../formik-checkbox/formik-checkbox';
import FormikDate from '../formik-date/formik-date';

const FormikControl = (props: FormikControlProps) => {
  const { control, inputProps, autocompleteProps, checkboxProps, dateProps } = props;
  const getControl = () => {
    switch (control) {
      case 'autocomplete':
        return <FormikAutocomplete {...autocompleteProps} />;
      case 'checkbox':
        return <FormikCheckbox {...checkboxProps} />;
      case 'date':
        return <FormikDate {...dateProps} />;
      case 'select':
      case 'textarea':
      case 'input':
        return <FormikInput {...inputProps} />;
      default:
        return null;
    }
  };

  return <>{getControl()}</>;
};

export default FormikControl;
