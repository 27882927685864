import { FormattedMessage } from 'react-intl';

const DatesRange = {
  All: 0,
  'Past 30d': 30,
  'Past 60d': 60,
  'Past 90d': 90,
  'Past 180d': 180,
  'Past 360d': 360,
};

const headCells = [
  {
    id: 'date',
    text: <FormattedMessage id="transactionsHistory.table.date" />,
    sortable: true,
  },
  {
    id: 'sent',
    text: <FormattedMessage id="transactionsHistory.table.sent" />,
    sortable: true,
  },
  {
    id: 'receive',
    text: <FormattedMessage id="transactionsHistory.table.received" />,
    sortable: true,
  },
  {
    id: 'transactionHash',
    text: <FormattedMessage id="transactionsHistory.table.transactionHash" />,
    sortable: false,
  },
  {
    id: 'type',
    text: <FormattedMessage id="transactionsHistory.table.type" />,
    sortable: false,
  },
];

export { DatesRange, headCells };
