import { ethers } from 'ethers';

const availableNetworks = process.env.REACT_APP_METAMASK_AVAILABLE_NETWORKS?.split(' ');

export const getChainID = async (provider: ethers.providers.Web3Provider) => {
  return provider.send('eth_chainId', []);
};

export const getNetworkCode = (id: string) => {
  let networkName = '';
  switch (Number(id)) {
    case 80001:
      if (availableNetworks?.includes('mumbai')) {
        networkName = 'mumbai';
      }
      break;
    case 137:
      if (availableNetworks?.includes('polygon')) {
        networkName = 'polygon';
      }
      break;
    default:
      networkName = 'unknown';
      break;
  }
  return networkName;
};
const switchNetworkPolygon = async (provider: ethers.providers.Web3Provider) => {
  try {
    const MaticNetworkId = '0x89';
    let chainId = await getChainID(provider);

    if (chainId !== MaticNetworkId) {
      try {
        await provider.send('wallet_switchEthereumChain', [{ chainId: MaticNetworkId }]);
        chainId = await getChainID(provider);
      } catch (error: any) {
        if (error.code !== 4902) {
          return false;
        }

        await provider.send('wallet_addEthereumChain', [
          {
            chainId: MaticNetworkId,
            chainName: 'Polygon Mainnet',
            rpcUrls: ['https://polygon-rpc.com'],
            blockExplorerUrls: ['https://polygonscan.com'],
            nativeCurrency: {
              symbol: 'MATIC',
              decimals: 18,
            },
          },
        ]);
        chainId = await getChainID(provider);
      }
    }
    return true;
  } catch (error: any) {
    return false;
  }
};

const switchNetworkMumbai = async (provider: ethers.providers.Web3Provider) => {
  try {
    const MumbaiNetworkId = '0x13881';
    let chainId = await getChainID(provider);

    if (chainId !== MumbaiNetworkId) {
      try {
        await provider.send('wallet_switchEthereumChain', [{ chainId: MumbaiNetworkId }]);
        chainId = await getChainID(provider);
      } catch (error: any) {
        if (error.code !== 4902) {
          return false;
        }

        await provider.send('wallet_addEthereumChain', [
          {
            chainId: MumbaiNetworkId,
            chainName: 'Mumbai',
            rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
            blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
            nativeCurrency: {
              symbol: 'MATIC',
              decimals: 18,
            },
          },
        ]);
        chainId = await getChainID(provider);
      }
    }
    return true;
  } catch (error: any) {
    return false;
  }
};

export const switchToAvailableNetwork = async (provider: ethers.providers.Web3Provider) => {
  if (availableNetworks && availableNetworks[0] === 'mumbai') {
    return switchNetworkMumbai(provider);
  }
  if (availableNetworks && availableNetworks[0] === 'polygon') {
    return switchNetworkPolygon(provider);
  }
  return false;
};
