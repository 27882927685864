import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import { useParams } from 'react-router-dom';
import { IRecuisite } from '../../../shared/interfaces/checkout.interface';
import { useStores } from '../../../store';
import ClipboardCopy from '../clipboard-copy/clipboardCopy';
import c from './styles.module.scss';

function CheckoutWire(props: { requisites: IRecuisite[] }) {
  const { requisites } = props;
  const { checkoutStore } = useStores();
  const { tokens = 0 } = useParams();

  return (
    <div className={c.checkoutWire}>
      <div className={c.title}>Your tokens are reserved!</div>
      <div className={c.descr}>
        In order to finailze this purchase, you must transfer funds to the following account:
      </div>
      <div className={c.fields}>
        {requisites.map((recuisite: IRecuisite) => (
          <div className={c.fieldItem}>
            <div className={c.title}>{recuisite.name}:</div>
            <div className={c.info}>
              {recuisite.value}
              <ClipboardCopy text={recuisite.value} />
            </div>
          </div>
        ))}
      </div>
      <div className={c.title}>What to expect next:</div>
      <div className={c.descr}>
        <ul>
          <li>You will receive a payment confirmation email.</li>
          <li>
            Your {addComma(String(tokens))}{' '}
            {checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`}{' '}
            token allocation will appear in your account.
          </li>
          <li>This allocation will remain in reserved status until the minimum market cap is reached.</li>
          <li>
            Once the minimum market cap is reached, your {addComma(String(tokens))}{' '}
            {checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`}{' '}
            tokens will be distributed to your account.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CheckoutWire;
