import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import c from './style.module.scss';
import { useStores } from '../../../store';

function BrokerDetails() {
  const navigate = useNavigate();
  const {
    userInfoStore,
    userInfoStore: { brokerInfo },
  } = useStores();
  const { id } = useParams();

  const validationData = [['CRD:', brokerInfo.crd]];

  useEffect(() => {
    if (!id) return;
    flowResult(userInfoStore.getBrokerInfoById(id as string));
  }, [userInfoStore, id]);

  return (
    <>
      <Helmet>
        <title>Broker Rep Details | TokenSuite</title>
      </Helmet>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className={c.back}
      >
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="usersPage.back" />
      </button>
      <div className="title">Broker Rep details</div>
      <div className={c.topPanel}>
        <div className={c.text}>
          <b>
            {brokerInfo.firstName} {brokerInfo.lastName} ({brokerInfo.role.slice(5)})
          </b>
        </div>
        <div className={c.text}>{brokerInfo.email}</div>
      </div>
      {brokerInfo.crd && (
        <div className={c.panel}>
          <div className="title">Validation Info</div>
          {validationData.map(([key, value]) => (
            <Grid item key={key} xs={12} display="flex" alignItems="center" gap="5px">
              <Typography fontWeight={600}>{key}</Typography>
              <Typography>{value || '-'}</Typography>
            </Grid>
          ))}
        </div>
      )}
    </>
  );
}

export default observer(BrokerDetails);
