import { AxiosInstance } from 'axios';
import moment from 'moment';
import tokenInstance from '../API/tokenApi';
import { InvestorProfileData } from '../../interfaces/headerInterface';
import { BrokerInfo, IActivity, ILockupTransaction, INewLockup, UserInfo } from '../../interfaces/userInfo.interface';
import { CreateKybNCDto, IUserInfo } from './userInfo.types';

class UserInfoService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async getUserActivity(id: string): Promise<IActivity[]> {
    const { data } = await this.authInstance.get<IActivity[]>(`users/activity/user/${id}`);
    return data;
  }

  async getUserLockupTransactions(id: string): Promise<ILockupTransaction[]> {
    const { data } = await this.authInstance.get<ILockupTransaction[]>(`lockup/transaction/${id}`);
    return data;
  }

  async createKyb(dto: CreateKybNCDto): Promise<void> {
    const serializeDto = new FormData();
    Object.entries(dto).forEach(([key, value]) => {
      if (key === 'files' && Array.isArray(value)) {
        value.forEach(file => {
          serializeDto.append(key, file);
        });
      } else {
        serializeDto.append(key, value);
      }
    });
    await this.authInstance.post('users/kyb', serializeDto, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async createKybAdmin(dto: CreateKybNCDto & { userId: string }): Promise<void> {
    const serializeDto = new FormData();
    Object.entries(dto).forEach(([key, value]) => {
      if (key === 'files' && Array.isArray(value)) {
        value.forEach(file => {
          serializeDto.append(key, file);
        });
      } else {
        serializeDto.append(key, value);
      }
    });
    await this.authInstance.post('users/kyb/user', serializeDto, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async getUserInfo(id: string): Promise<InvestorProfileData> {
    const { data } = await this.authInstance.get<InvestorProfileData>(`users/info/${id}`);
    return data;
  }

  async getUserById(id: string): Promise<UserInfo> {
    const { data } = await this.authInstance.get<UserInfo>(`users/info/${id}`);
    return data;
  }

  async getBrokerById(id: string): Promise<BrokerInfo> {
    const { data } = await this.authInstance.get<BrokerInfo>(`users/brokers/broker/${id}`);
    return data;
  }

  async getUserFileById(id: string): Promise<Blob> {
    const { data, headers } = await this.authInstance.get(`users/info/file/${id}`, {
      responseType: 'arraybuffer',
    });
    const newData = new Uint8Array(data);
    const blob = new Blob([newData], {
      type: headers['content-type'],
    });
    return blob;
  }

  async deleteUserById(id: string): Promise<void> {
    await this.authInstance.delete(`users/${id}`);
  }

  async deleteInvestorById(id: string): Promise<void> {
    await this.authInstance.delete(`users/investors/${id}`);
  }

  async getUserInfoById(id: string): Promise<IUserInfo> {
    const { data } = await this.authInstance.get<IUserInfo>(`users/user/${id}`);
    return data;
  }

  async sendNewLockup(dto: INewLockup, wallet: string, newLockupEndTime: Date): Promise<void> {
    const newLockupEndTimeFormat = moment(newLockupEndTime).toDate().getTime() / 1000;
    await this.authInstance.get(
      `lockup/transaction/adjust?token=${dto.tokenAddress}&account=${wallet}&position=${dto.position}&newLockupEndTime=${newLockupEndTimeFormat}`,
    );
  }

  async unlockTransaction(tokenAddress: string, wallet: string, position: number): Promise<void> {
    await this.authInstance.get(`token/unlock?token=${tokenAddress}&account=${wallet}&position=${position}`);
  }
}
export default new UserInfoService(tokenInstance);
