import { FormattedMessage, useIntl } from 'react-intl';
import { SelectInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import MenuItem from '@mui/material/MenuItem';
import c from '../styles.module.scss';
import { useStores } from '../../../store';

function InvestmentDetailsBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [revenueType, setRevenueType] = useState(assetStore.currentAsset.investmentDetail.revenueType);
  const [capitalStack, setCapitalStack] = useState(assetStore.currentAsset.investmentDetail.capitalStack);
  const [propertyType, setPropertyType] = useState(assetStore.currentAsset.investmentDetail.propertyType);
  const [editError, setError] = useState('');

  const submit = () => {
    if (propertyType) {
      flowResult(assetStore.saveDetails(id, revenueType, capitalStack, propertyType));
    } else {
      setError('All fields are require');
    }
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="details">
        <FormattedMessage id="createAssetPage.text.investmentDetailsBlock" />
      </div>
      <div className={c.inputsBlock}>
        <SelectInput
          value={revenueType}
          changeValue={setRevenueType}
          label={intl.formatMessage({ id: 'createAssetPage.fields.revenueType.label' })}
          defaultValue={intl.formatMessage({ id: 'createAssetPage.fields.revenueType.placeholder' })}
        >
          <MenuItem value="NNN Lease">
            <FormattedMessage id="createAssetPage.fields.revenueType.values.NNNLease" />
          </MenuItem>
          <MenuItem value="Yielding">
            <FormattedMessage id="createAssetPage.fields.revenueType.values.yielding" />
          </MenuItem>
          <MenuItem value="Mixed">
            <FormattedMessage id="createAssetPage.fields.revenueType.values.mixed" />
          </MenuItem>
        </SelectInput>
        <SelectInput
          value={capitalStack}
          changeValue={setCapitalStack}
          label={intl.formatMessage({ id: 'createAssetPage.fields.capitalStack.label' })}
          defaultValue={intl.formatMessage({ id: 'createAssetPage.fields.capitalStack.placeholder' })}
        >
          <MenuItem value="Debt">
            <FormattedMessage id="createAssetPage.fields.capitalStack.values.Debt" />
          </MenuItem>
          <MenuItem value="Equity">
            <FormattedMessage id="createAssetPage.fields.capitalStack.values.Equity" />
          </MenuItem>
          <MenuItem value="Preferred Equity">
            <FormattedMessage id="createAssetPage.fields.capitalStack.values.PreferredEquity" />
          </MenuItem>
        </SelectInput>
        <TextInput
          value={propertyType}
          changeValue={setPropertyType}
          label={intl.formatMessage({ id: 'createAssetPage.fields.propertyType.label' })}
          placeholder={intl.formatMessage({ id: 'createAssetPage.fields.propertyType.placeholder' })}
          type="text"
          fullWidth
        />
      </div>
      <p className={c.errorText}>{editError}</p>
      <button className={c.submitButton} onClick={submit}>
        Save
      </button>
    </div>
  );
}

export default observer(InvestmentDetailsBlock);
