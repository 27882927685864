import React from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import ActivityLogForInvestor from '../../../components/activity-log/investor/activity-log-for-investor';

function ActivityLogPage() {
  return (
    <>
      <Helmet>
        <title>Activity Log</title>
      </Helmet>
      <Box mb={24}>
        <ActivityLogForInvestor />
      </Box>
    </>
  );
}

export default observer(ActivityLogPage);
