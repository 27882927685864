import React, { useEffect, useRef } from 'react';
import { FormControl, FormHelperText, TextField, OutlinedInput } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import styled from '@emotion/styled';
import c from './style.module.scss';
import { FormikInputProps } from './formik-input.types';

const Input = styled(OutlinedInput)`
  border-radius: 8px;
  background-color: #f3f3f4;
  .MuiOutlinedInput-input {
    padding: 16px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    height: 24px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #f3f3f4;
    border-radius: 8px;
  }
  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 1px solid #f05555;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00bc68;
  }
`;

export { Input };

const FormikInput = (props: FormikInputProps) => {
  const { label, name, id, placeholder } = props;
  const [field, meta, helpers] = useField(name as string);
  const errorRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (!errorRef.current) return;
    if (!meta.error) return;
    errorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [meta.error]);
  return (
    <FormControl className={c.wrapper} ref={errorRef}>
      {label && <p className={c.label}>{label}</p>}
      <TextField
        id={id}
        {...field}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          helpers.setError('');
          helpers.setValue(e.target.value);
        }}
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        error={!!meta.error}
        className={c.input}
      />
      {meta.error && (
        <FormHelperText className={c.errorText}>
          <ErrorMessage name={name || ''} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikInput;
