import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import upload from '../../../shared/assets/createAsset/upload.svg';
import c from './style.module.scss';
import { useStores } from '../../../store';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 26214400; // 25MB

const boldFragment = (chunks: any) => <b>{`${chunks}`}</b>;

export interface IFiles {
  [fileName: string]: File;
}

function EditUploader(props: { id: string | undefined; blockId: string | undefined }) {
  const { id, blockId } = props;
  const intl = useIntl();
  const { assetStore } = useStores();
  const [isErrorShown, setIsErrorShown] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
    desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap: 1 }),
  });

  const showError = (message: { head: string; desc: string }) => {
    setErrorMessage(message);
    setIsErrorShown(true);
  };

  const fileInputField = useRef(null);

  const addNewFiles = async (newFiles: any) => {
    const allowedTypes = ['jpg', 'jpeg', 'png', 'gif'];
    for (const file of newFiles) {
      if (allowedTypes.indexOf(file.name.split('.').reverse()[0]) === -1) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.notSupported' }),
        });
        return;
      }
      if (newFiles.length > 1) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
          desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap: 1 }),
        });
        return;
      }
      if (file.size >= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.maxSize' }),
        });
        return;
      }
      const formData = new FormData();
      if (id && blockId) {
        formData.append('file', file);
        formData.append('position', '0');
        flowResult(assetStore.addMediaImage(id, blockId, formData));
      }
    }
  };

  const handleNewFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: any | null = event.target.files;
    if (newFiles.length) {
      addNewFiles(newFiles);
    }
  };

  const [inDropZone, setDropZone] = useState(false);
  const handleDragEnter = () => {
    setDropZone(true);
  };
  const handleDragLeave = () => {
    setDropZone(false);
  };
  const handleDrop = () => {
    setDropZone(false);
  };
  return (
    <div className={c.uploader}>
      <div className={`${c.upload} ${inDropZone ? c.drag : ''}`}>
        <div className={`${c.error} ${isErrorShown ? '' : c.hidden}`}>
          <div className={c.container}>
            <header>{errorMessage.head}</header>
            <p>{errorMessage.desc}</p>
            <IconButton className={c.close} onClick={() => setIsErrorShown(false)}>
              x
            </IconButton>
          </div>
        </div>
        <input
          id="file-input"
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          title=""
          value=""
          multiple
          accept="image/jpeg,image/gif,image/png,image/jpg,image/x-eps"
        />
        <img src={upload} alt="" />
        <p className={c.text}>
          <FormattedMessage
            id="uploader.text.header"
            values={{
              b: boldFragment,
            }}
          />
        </p>
        <label htmlFor="file-input" className={c.browse}>
          <FormattedMessage id="uploader.button.upload.document" />
        </label>
        <p className={c.help}>
          <FormattedMessage id="uploader.text.formats" values={{ formats: 'jpg, png, gif' }} />
        </p>
      </div>
    </div>
  );
}
export default observer(EditUploader);
