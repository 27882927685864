import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useEffect, useState } from 'react';
import c from './styles.module.scss';
import { tokenHoldersTestData } from '../testData/testData';
import { TokenHolderType } from '../../../../shared/types/issuerAssetType';
import { CURRENCY_SYMBOLS } from '../../../../shared/config/constants';

type Column = {
  id: keyof TokenHolderType;
  label: string;
  minWidth?: number;
  format?: (tokenHolder: TokenHolderType) => string;
};

const columns: readonly Column[] = [
  {
    id: 'fullName',
    label: 'Full Name',
  },
  {
    id: 'asset',
    label: 'Asset',
  },
  {
    id: 'tokenAllocation',
    label: 'Token Allocation',
    format: tokenHolder => `${tokenHolder.tokenAllocation.toLocaleString()}`,
  },
  {
    id: 'value',
    label: 'Value',
    format: tokenHolder => `${CURRENCY_SYMBOLS[tokenHolder.currency]}${tokenHolder.value.toLocaleString()}`,
  },
  {
    id: 'location',
    label: 'Location',
  },
];

function TopTokenHolders() {
  const [items, setItems] = useState<TokenHolderType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const loadItems = () => {
    setIsLoading(true);
    setTimeout(() => {
      setItems(tokenHoldersTestData);
      setIsLoading(false);
    }, 1000);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    loadItems();
  }, []);

  return (
    <div className={c.wrapper}>
      <TableContainer className={c.table_container}>
        <Table className={c.table} stickyHeader aria-label="sticky table">
          <TableHead className={c.head}>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                  <div className={c.flexHead}>{column.label}</div>
                </TableCell>
              ))}
            </TableRow>
            {isLoading && items.length === 0 && (
              <TableRow>
                <TableCell colSpan={20}>
                  <div className={c.firstLoading}>
                    <Spinner single />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          {items.length !== 0 && (
            <TableBody>
              {isLoading && (
                <div className={c.loading}>
                  <Spinner single />
                </div>
              )}
              {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align="center">
                          {column.format && typeof value === 'number' ? column.format(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || page === 0,
        }}
        nextIconButtonProps={{
          disabled: isLoading || Math.ceil(items.length / rowsPerPage) - 1 === page,
        }}
      />
    </div>
  );
}

export default TopTokenHolders;
