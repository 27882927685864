import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import React from 'react';
import AcceptActionDialog, { AcceptActionDialogProps } from '../../UI/dialogs/accept-action/accept-action-dialog';

interface IPortalAddAssetModal extends Omit<AcceptActionDialogProps, 'onAgreeHandler' | 'children'> {
  onAddAssetHandler: (assetId: string) => Promise<void>;
  menuItems: Array<{ id: string; name: string }>;
  placeholder: string;
}

const PortalAddAssetModal: React.FC<IPortalAddAssetModal> = ({
  onAddAssetHandler,
  menuItems,
  content,
  open,
  setOpen,
  title,
  actionButtonText,
  isActionLoading,
  placeholder,
}) => {
  const [selectValue, setSelectValue] = React.useState('');
  const [error, setError] = React.useState('');

  const onChangeHandler = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
  };

  const onAcceptActionHandler = async () => {
    setError('');
    if (!selectValue.trim()) {
      setError('Select Asset');
      return;
    }
    await onAddAssetHandler(selectValue);
  };

  return (
    <AcceptActionDialog
      open={open}
      setOpen={setOpen}
      title={title}
      content={content}
      isActionLoading={isActionLoading}
      actionButtonText={actionButtonText}
      onAgreeHandler={onAcceptActionHandler}
    >
      <Stack gap={20}>
        <FormControl sx={{ mt: 8 }}>
          <InputLabel>{placeholder}</InputLabel>
          <Select displayEmpty value={selectValue} onChange={onChangeHandler} label={placeholder}>
            {menuItems.length > 0 &&
              menuItems.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <p className="errorText">{error}</p>
      </Stack>
    </AcceptActionDialog>
  );
};

export default PortalAddAssetModal;
