import {
  SelectInput,
  TextInput,
  DateInput,
  AutocompleteInput,
  StatesAutocompleteInput,
  ICountryType,
} from '@token-suite/common-ui/src/components/Inputs/inputs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import c from './styles.module.scss';
import { countriesWithPhonecode, states } from '../../../shared/config/constants';
import IdentityFilesUploader from '../../../components/identityFilesUploader/identityFilesUploader';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

export default function KycPage() {
  const query = useQueryParams();
  const id = query.get('id');
  const navigate = useNavigate();
  const intl = useIntl();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const docRef = useRef<HTMLDivElement | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState(moment(new Date()).subtract(18, 'year'));
  const [dateError, setDateError] = useState(false);
  const [occupation, setOccupation] = useState('');
  const [country, setCountry] = useState<ICountryType | null>(null);
  const [phone, setPhone] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState(null);
  const [city, setCity] = useState('');
  const [streetAddress, setStreet] = useState('');
  const [idType, setIdType] = useState('Passport');
  const [isUSCitizen, setUSCitizen] = useState(true);
  const [ssn, setSsn] = useState('');
  const [jurisdiction, setJurisdiction] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [errorMessages, setErrors] = useState('');
  const [open, setOpen] = useState(false);
  const [sucessPassed, setPassed] = useState(false);
  const [loading, setLoading] = useState(false);

  const { portalStore } = useStores();

  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    dateError: false,
    occupation: false,
    address: false,
    zipCode: false,
    phone: false,
    state: false,
    city: false,
    streetAddress: false,
    ssn: false,
    country: false,
    documents: false,
    jurisdiction: false,
  });

  const getCommonFields = useCallback(() => {
    const commonFields = [
      { field: 'firstName', value: firstName },
      { field: 'lastName', value: lastName },
      { field: 'occupation', value: occupation },
      { field: 'country', value: country },
      { field: 'zipCode', value: zipCode },
      { field: 'phone', value: matchIsValidTel(phone) },
      { field: 'city', value: city },
      { field: 'streetAddress', value: streetAddress },
      { field: 'dateError', value: !dateError },
      { field: 'documents', value: documents.length > 0 },
    ];
    if (isUSCitizen) {
      commonFields.splice(commonFields.length - 1, 0, { field: 'ssn', value: ssn }, { field: 'state', value: state });
    } else {
      commonFields.splice(commonFields.length - 1, 0, { field: 'jurisdiction', value: jurisdiction });
    }
    return commonFields;
  }, [
    city,
    country,
    dateError,
    documents.length,
    firstName,
    isUSCitizen,
    lastName,
    occupation,
    ssn,
    state,
    streetAddress,
    jurisdiction,
    zipCode,
    phone,
  ]);

  const isValidDataHandler = () => {
    const commonFields = getCommonFields();
    const newErrorFields = {
      firstName: false,
      lastName: false,
      dateError: false,
      occupation: false,
      address: false,
      zipCode: false,
      phone: false,
      state: false,
      city: false,
      streetAddress: false,
      ssn: false,
      country: false,
      jurisdiction: false,
      documents: false,
    };
    commonFields.forEach(({ field, value }) => {
      if (field === 'documents') {
        newErrorFields[field] = !value as boolean;
        return;
      }
      if (field === 'phone') {
        newErrorFields[field] = !matchIsValidTel(phone);
        return;
      }
      newErrorFields[field as 'firstName'] = typeof value === 'string' ? !value?.trim() : !value;
    });
    setErrorFields(newErrorFields);
    return (
      !commonFields.some(({ value }) => (typeof value === 'string' ? !value?.trim() : !value)) &&
      documents.length > 0 &&
      matchIsValidTel(phone)
    );
  };

  const handleClose = () => {
    navigate('/portfolio', { replace: true });
  };

  useEffect(() => {
    setJurisdiction(false);
    if (!isUSCitizen) {
      setSsn('');
      setState(null);
    }
  }, [isUSCitizen]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!docRef.current) return;
    if (
      Object.entries(errorFields).some(([key, value]) => {
        return key === 'documents' ? false : value;
      })
    ) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return;
    }
    if (!errorFields.documents) return;
    docRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [errorFields]);

  useEffect(() => {
    const fieldsForChange: string[] = [];
    const commonFields = getCommonFields();
    commonFields.forEach(({ field, value }) => {
      if (errorFields[field as 'firstName'] && value) {
        fieldsForChange.push(field);
      }
    });
    if (!fieldsForChange.length) return;
    const newErrorFields = { ...errorFields };
    fieldsForChange.forEach(field => {
      newErrorFields[field as 'firstName'] = false;
    });
    setErrorFields(newErrorFields);
  }, [
    city,
    country,
    dateError,
    errorFields,
    firstName,
    getCommonFields,
    isUSCitizen,
    lastName,
    occupation,
    ssn,
    state,
    streetAddress,
    zipCode,
    phone,
  ]);

  useEffect(() => {
    if (isUSCitizen) return;
    setIdType('Passport');
  }, [isUSCitizen]);

  const submit = () => {
    if (!isValidDataHandler()) return;
    setLoading(true);
    const kycFormData = new FormData();
    kycFormData.append('isUSCitizen', `${isUSCitizen}`);
    kycFormData.append('firstName', firstName.trim());
    kycFormData.append('lastName', lastName.trim());
    kycFormData.append('dob', moment(birthDate).format('MM-DD-YYYY'));
    kycFormData.append('primCountry', `${country?.label}`);
    kycFormData.append('primAddress1', streetAddress.trim());
    kycFormData.append('primCity', city.trim());
    if (isUSCitizen) {
      kycFormData.append('socialSecurityNumber', ssn);
      kycFormData.append('primState', `${state}`);
    } else {
      kycFormData.append('isAccreditation', `${jurisdiction}`);
    }
    kycFormData.append('primZip', zipCode);
    kycFormData.append('phone', phone);
    kycFormData.append('occupation', occupation.trim());
    kycFormData.append('documentType', idType);
    kycFormData.append('files', documents[0]);
    if (documents[1]) {
      kycFormData.append('files', documents[1]);
    }
    if (id) {
      kycFormData.append('userId', id);
      tokenInstance
        .post('users/kyc/user', kycFormData)
        .then(() => {
          navigate(`/user/${id}`, { replace: true });
        })
        .catch(e => {
          setErrors(e.response.data.message);
          setLoading(false);
        });
    } else {
      tokenInstance
        .post('users/kyc', kycFormData)
        .then((data: any) => {
          setPassed(data.data.status);
          setOpen(true);
          setLoading(false);
        })
        .catch(e => {
          setErrors(e.response.data.message);
          setLoading(false);
        });
    }
  };

  const handleDismiss = () => {
    if (id) {
      tokenInstance.get(`users/ai/not-accredited/${id}`).then(() => {
        navigate(`/user/${id}`, { replace: true });
      });
    } else {
      tokenInstance.get('users/ai/not-accredited').then(() => {
        navigate('/portfolio', { replace: true });
      });
    }
  };

  function formatSSN(value: string) {
    if (!value) return value;
    const ssnFormat = value.replace(/[^\d]/g, '');
    const ssnLength = ssnFormat.length;
    if (ssnLength < 4) return ssnFormat;
    if (ssnLength < 6) {
      return `${ssnFormat.slice(0, 3)}-${ssnFormat.slice(3)}`;
    }
    return `${ssnFormat.slice(0, 3)}-${ssnFormat.slice(3, 5)}-${ssnFormat.slice(5, 9)}`;
  }

  const handleSsnInput = (e: any) => {
    const formattedSSN = formatSSN(e.target.value);
    setSsn(formattedSSN);
  };

  return (
    <>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title ? `KYC | ${portalStore.portalUI.portalUi.title}` : 'KYC | TokenSuite'}
        </title>
      </Helmet>
      {/* <button onClick={() => onBackClickHandler(id!)} className={c.back}>
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="kycPage.back" />
      </button> */}
      <div className={c.title}>
        <FormattedMessage id="kycPage.title" />
      </div>
      <div className={c.block} ref={wrapperRef}>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            <TextInput
              value={firstName}
              changeValue={setFirstName}
              label={intl.formatMessage({ id: 'kycPage.fields.firstName.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.firstName.placeholder' })}
              type="text"
              error={errorFields.firstName}
            />
            <TextInput
              value={lastName}
              changeValue={setLastName}
              label={intl.formatMessage({ id: 'kycPage.fields.lastName.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.lastName.placeholder' })}
              type="text"
              error={errorFields.lastName}
            />
            <DateInput
              value={birthDate}
              changeValue={setBirthDate}
              label={intl.formatMessage({ id: 'kycPage.fields.dateOfBirth.label' })}
              setError={setDateError}
              minDate={moment(new Date()).subtract(100, 'year')}
              maxDate={moment(new Date()).subtract(18, 'year')}
            />
            <TextInput
              value={occupation}
              changeValue={setOccupation}
              label={intl.formatMessage({ id: 'kycPage.fields.occupation.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.occupation.placeholder' })}
              type="text"
              error={errorFields.occupation}
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            <AutocompleteInput
              value={country}
              changeValue={setCountry}
              label={intl.formatMessage({ id: 'kycPage.fields.country.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.country.placeholder' })}
              options={countriesWithPhonecode}
              error={errorFields.country}
            />
            <div className={c.inputBlock}>
              <div className={c.inputLabel}>Phone number</div>
              <MuiTelInput
                disableDropdown
                value={phone}
                onChange={setPhone}
                className={c.input}
                error={errorFields.phone}
                helperText={errorFields.phone ? `enter a valid phone number` : null}
              />
            </div>
            <TextInput
              value={streetAddress}
              changeValue={setStreet}
              label={intl.formatMessage({ id: 'kycPage.fields.street.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.street.placeholder' })}
              type="text"
              error={errorFields.streetAddress}
            />
            <TextInput
              value={city}
              changeValue={setCity}
              label={intl.formatMessage({ id: 'kycPage.fields.city.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.city.placeholder' })}
              type="text"
              error={errorFields.city}
            />
            {isUSCitizen ? (
              <StatesAutocompleteInput
                value={state}
                changeValue={setState}
                label={intl.formatMessage({ id: 'kycPage.fields.state.label' })}
                placeholder={intl.formatMessage({ id: 'kycPage.fields.state.placeholder' })}
                options={states}
                error={errorFields.state}
              />
            ) : null}
            <TextInput
              value={zipCode}
              changeValue={setZipCode}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.zipCode.placeholder' })}
              label={intl.formatMessage({ id: 'kycPage.fields.zipCode.label' })}
              type="number"
              error={errorFields.zipCode}
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.checkboxes}>
            <FormControlLabel
              className={c.checkbox}
              label={intl.formatMessage({ id: 'kycPage.fields.isUSCitizen' })}
              control={<Checkbox checked={isUSCitizen} onChange={(e: any) => setUSCitizen(e.target.checked)} />}
            />
            {!isUSCitizen && (
              <div>
                <FormControlLabel
                  className={c.checkbox}
                  label={intl.formatMessage({ id: 'kycPage.fields.jurisdiction' })}
                  control={
                    <Checkbox
                      required
                      checked={jurisdiction}
                      onChange={(e: any) => setJurisdiction(e.target.checked)}
                      sx={{
                        color: `${errorFields.jurisdiction ? '#F05555' : '#8D8D8D'}`,
                      }}
                    />
                  }
                />
                {errorFields.jurisdiction && <p className={c.validationText}>Your confirmation is required</p>}
              </div>
            )}
          </div>
          <div className={c.inputsBlock}>
            <SelectInput
              value={idType}
              changeValue={setIdType}
              label={intl.formatMessage({ id: 'kycPage.fields.idType.label' })}
              defaultValue={intl.formatMessage({ id: 'kycPage.fields.idType.label' })}
              disabled={!isUSCitizen}
            >
              <MenuItem value="Passport">Passport</MenuItem>
              <MenuItem value="Drivers License">Drivers License</MenuItem>
              <MenuItem value="Identity Card">Identity Card</MenuItem>
            </SelectInput>
            {isUSCitizen ? (
              <div className={c.inputBlock}>
                <div className={c.inputLabel}>
                  <FormattedMessage id="kycPage.fields.ssn.label" />
                </div>
                <TextField
                  error={errorFields.ssn}
                  autoComplete="off"
                  className={c.input}
                  value={ssn}
                  onChange={handleSsnInput}
                  helperText={errorFields.ssn ? 'field is required' : null}
                  placeholder={intl.formatMessage({ id: 'kycPage.fields.ssn.placeholder' })}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div ref={docRef}>
          <div className={c.inputLabel}>
            Document Front View {idType === 'Passport' && '(page containing the user`s name and photograph)'}
          </div>
          <IdentityFilesUploader setDocuments={setDocuments} documents={documents} cap={2} />
          {errorFields.documents && (
            <Stack justifyContent="center" alignItems="center">
              <Typography fontSize="12px" lineHeight="14px" sx={{ color: '#F05555FF', fontFamily: 'Montserrat' }}>
                Upload documents
              </Typography>
            </Stack>
          )}
        </div>
        <div className={c.errors}>
          <div className={c.errorText}>{errorMessages}</div>
        </div>
      </div>
      <div className={c.btns}>
        <button className={c.btn} onClick={submit} disabled={loading}>
          {loading ? <>loading...</> : <FormattedMessage id="kycPage.submit" />}
        </button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div className={c.dialog}>
          {sucessPassed ? (
            <>
              <div className={c.title}>
                <FormattedMessage id="kycPage.dialog.title" />
              </div>
              <p className={c.text}>
                <FormattedMessage id="kycPage.dialog.text" />
              </p>
              <div className={c.actions}>
                {isUSCitizen ? (
                  <>
                    <Link to="/accreditation" className={c.submitButton}>
                      <FormattedMessage id="kycPage.dialog.submit" />
                    </Link>
                    <button onClick={handleDismiss} className={`${c.submitButton} ${c.outlined}`}>
                      <FormattedMessage id="kycPage.dialog.dismiss" />
                    </button>
                  </>
                ) : (
                  <Link to="/portfolio" className={c.submitButton}>
                    <FormattedMessage id="kycPage.dialog.continue" />
                  </Link>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={c.title}>
                <FormattedMessage id="kycPage.dialog.deniedTitle" />
              </div>
              <p className={c.text}>
                <FormattedMessage id="kycPage.dialog.deniedText" />
              </p>
              <div className={c.actions}>
                <Link to="/portfolio" className={c.submitButton}>
                  <FormattedMessage id="kycPage.dialog.denied" />
                </Link>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}
