import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from './style.module.scss';
import DefaultButton from '../../UI/buttons/default-button';
import { useStores } from '../../../store';
import '../../../app/styles/reusable/tables.scss';
import signDocumentsService from '../../../shared/services/sign-documents/signDocumentsService';
import { SignIssuer } from '../../../shared/services/sign-documents/types';
import PortalAddContractModal from './portal-add-contract-modal';

const PortalIssuers = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { portalStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issuerWithContract, setIssuerWithContract] = useState<SignIssuer | null>(null);

  const onSendIssuerContract = async (issuerId: string, templateId: string) => {
    setIsLoading(true);
    try {
      if (!(issuerId && templateId)) {
        throw new Error('You must select issuer and upload contract');
      }
      await signDocumentsService.signContractToIssuer(id!, {
        issuerId,
        templateId,
      });
      setIsDialogOpen(false);
      setIsLoading(false);
      const activeIssuer = portalStore.issuers.find(i => i.id === issuerId);
      if (!activeIssuer) return;
      setIssuerWithContract(activeIssuer);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const uploadIssuer = async () => {
      try {
        const issuer = await signDocumentsService.getIssuerWithContract(id!);
        setIssuerWithContract(issuer);
      } catch (e) {
        setIssuerWithContract(null);
      }
    };
    if (id) {
      flowResult(portalStore.getIssuersOnPortal(id));
      uploadIssuer();
    }
    return () => {
      portalStore.resetIssuers();
    };
  }, [id, portalStore]);

  const issuersForSelect =
    portalStore.issuers.length > 0
      ? portalStore.issuers.map(({ id: i, firstName, lastName }) => ({ text: `${firstName} ${lastName}`, value: i }))
      : [];

  return (
    <>
      <div className={c.wrapper}>
        <Stack alignItems="flex-end">
          <DefaultButton variant="contained" onClick={() => setIsDialogOpen(true)}>
            Add Contract
          </DefaultButton>
        </Stack>
        <Stack alignItems="center" mb={15} className={c.title}>
          Issuers
        </Stack>
        <TableContainer
          className="wrapperDT"
          sx={{
            borderRadius: 0,
            boxShadow: 'none',
          }}
          component={Paper}
        >
          <Table>
            <TableHead className="tableHeadDT">
              <TableRow>
                <TableCell className="headCellDT">Name</TableCell>
                <TableCell className="headCellDT">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portalStore.isLoading && (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Spinner single />
                  </TableCell>
                </TableRow>
              )}
              {portalStore.issuers.length > 0 &&
                portalStore.issuers.map(issuer => (
                  <TableRow
                    key={issuer.id}
                    className={`tableRowDT ${issuer.id === issuerWithContract?.id ? c.activeRow : ''}`}
                  >
                    <TableCell className="cellDT">
                      <Stack>
                        <div>
                          {issuer.firstName} {issuer.lastName}
                        </div>
                        {issuer.id === issuerWithContract?.id && (
                          <div className={c.activeCellTitle}>
                            <FormattedMessage id="issuers.portals.table.text.contract" />
                          </div>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell className="cellDT">{issuer.email}</TableCell>
                  </TableRow>
                ))}
              {!portalStore.issuers.length && !portalStore.isLoading && (
                <TableRow>
                  <TableCell className="emptyBlockDT" colSpan={2}>
                    <FormattedMessage id="issuers.portals.table.text.noIssuersMessage" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {isDialogOpen && (
        <PortalAddContractModal
          onAddContractHandler={onSendIssuerContract}
          placeholder="Select issuer"
          menuItems={issuersForSelect}
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          title="Send contract"
          content="Select issuer and upload contract"
          actionButtonText={isLoading ? 'Sending...' : 'Send'}
          isActionLoading={isLoading}
        />
      )}
    </>
  );
});

export default PortalIssuers;
