import React, { FC, useState } from 'react';
import { OutlinedInput } from '@mui/material';
import AcceptActionDialog, { AcceptActionDialogProps } from '../accept-action/accept-action-dialog';

interface AcceptActionWithInputProps extends Omit<AcceptActionDialogProps, 'onAgreeHandler' | 'children'> {
  onAgreeHandler: (value: string) => void;
  placeholder: string;
  inputType?: string;
  min?: string;
}

const AcceptActionWithInput: FC<AcceptActionWithInputProps> = ({
  open,
  setOpen,
  content,
  isActionLoading,
  onAgreeHandler,
  title,
  placeholder,
  actionButtonText,
  inputType,
}) => {
  const [text, setText] = useState('');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <AcceptActionDialog
      open={open}
      setOpen={setOpen}
      onAgreeHandler={() => onAgreeHandler(text)}
      title={title}
      content={content}
      isActionLoading={isActionLoading}
      actionButtonText={actionButtonText}
    >
      <OutlinedInput value={text} type={inputType ?? 'text'} onChange={onChangeHandler} placeholder={placeholder} />
    </AcceptActionDialog>
  );
};

export default AcceptActionWithInput;
