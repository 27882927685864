import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import styled from '@emotion/styled';
import c from '../formik-input/style.module.scss';
import { FormikAutocompleteProps, Option } from './formik-autocomplete.types';

const ErrorMessageWrapper = styled('div')`
  color: #f05555;
  font-size: 12px;
  margin-top: 8px;
`;

const Label = styled('div')`
  font-size: 16px;
  margin-bottom: 8px;
`;

// TODO add prop for styles control (all formik components)

const FormikAutocomplete = (props: FormikAutocompleteProps) => {
  const { options, name, label, placeholder, ...rest } = props;
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState<{ label: string; value: string } | null>(null);
  const errorRef = useRef<HTMLElement | null>(null);

  const onChangeHandler = (_: any, value: Option | null) => {
    helpers.setError('');
    helpers.setValue(value?.value || '');
    setInputValue(value || null);
  };

  useEffect(() => {
    if (!errorRef.current) return;
    if (!meta.error) return;
    errorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [meta.error]);

  useEffect(() => {
    setInputValue(options.find(({ value }) => value === field.value) || null);
  }, [field.value, options]);

  return (
    <div className={c.wrapper}>
      <Label>{label}</Label>
      <Autocomplete
        id={name}
        ref={errorRef}
        options={options}
        value={inputValue}
        getOptionLabel={(option: Option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        autoHighlight
        {...rest}
        onChange={onChangeHandler}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            placeholder={placeholder}
            error={!!meta.error}
            className={c.input}
          />
        )}
      />
      {meta.error && (
        <ErrorMessageWrapper>
          <ErrorMessage name={name || ''} />
        </ErrorMessageWrapper>
      )}
    </div>
  );
};

export default FormikAutocomplete;
