import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import setting from '../../shared/assets/icons/setting.svg';
import logoutIcon from '../../shared/assets/profileMenu/logout.svg';
import profileIcon from '../../shared/assets/profileMenu/profileIcon.svg';
import userPool from '../../shared/config/cognitoPool';
import c from './style.module.scss';
import { useStores } from '../../store';

function AccountNavCollapse({ userName, userRole }: { userName: string; userRole: string }) {
  const navigate = useNavigate();

  const { headerStore, walletStore } = useStores();

  const cognitoUser = userPool.getCurrentUser();

  const logout = () => {
    cognitoUser?.signOut();
    localStorage.clear();
    headerStore.reset();
    walletStore.reset();
    navigate('/login');
  };

  return (
    <div className={c.dropDownMenu}>
      <ul className={c.topmenu} id={c.topmenuId}>
        <li>
          <span className={c.dropController}>
            <img src={profileIcon} alt="profile" />
            <div className={c.profileInfo}>
              <div className={c.name}>{userName}</div>
              <div className={c.role}>{userRole}</div>
            </div>
          </span>
          <div className={c.submenu}>
            <Link to="/account-setting" className={c.logout}>
              <img src={setting} alt="setting" />
              Setting
            </Link>
            <Link to="/login" className={c.logout} onClick={logout}>
              <img src={logoutIcon} alt="logout" />
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default AccountNavCollapse;
