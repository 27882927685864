import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { NonMintedAssetType } from '../../../../shared/types/issuerAssetType';

// const translateResponce = (resp: NonMintedAsset): NonMintedAssetType => {
//   return {
//     id: resp.id,
//     imageSrc: resp.previewImage.url,
//     tickerSymbol: resp.tickerSymbol ? resp.tickerSymbol.toUpperCase() : '-',
//     unitPrice: resp.unitPrice,
//     capitalStack: resp.investmentDetail.capitalStack,
//     currency: resp.currency,
//     investors: resp.investorsCount,
//     minCap: resp.minAmount,
//     maxAmount: resp.targetAmount,
//     raised: 125000,
//     tokenQuantity: resp.tokenQuantity,
//     access: false,
//     fundedPercentage: 100,
//   };
// };

// type OrderingType = {
//   field: keyof NonMintedAssetType;
//   order: 'ASC' | 'DESC' | null;
// };

type Column = {
  id: keyof NonMintedAssetType;
  label: ReactElement | null;
  minWidth?: number;
  sortable?: true;
  format?: (asset: NonMintedAssetType) => string;
  render?: (asset: NonMintedAssetType) => JSX.Element;
};

const columns: readonly Column[] = [
  {
    id: 'imageSrc',
    label: null,
  },
  {
    id: 'tickerSymbol',
    label: <FormattedMessage id="issuersAssetPage.nonMinted.table.head.token" />,
    sortable: true,
  },
  {
    id: 'tokenQuantity',
    label: <FormattedMessage id="issuersAssetPage.nonMinted.table.head.tokenQuantity" />,
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'unitPrice',
    label: <FormattedMessage id="issuersAssetPage.nonMinted.table.head.unitPrice" />,
    sortable: true,
  },
  {
    id: 'capitalStack',
    label: <FormattedMessage id="issuersAssetPage.nonMinted.table.head.capitalStack" />,
    minWidth: 66,
    sortable: true,
  },
  {
    id: 'minCap',
    label: <FormattedMessage id="issuersAssetPage.nonMinted.table.head.minCap" />,
  },
  {
    id: 'maxAmount',
    label: <FormattedMessage id="issuersAssetPage.nonMinted.table.head.maxAmount" />,
  },
];

export { columns };
