import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import { ProfileData } from '../../shared/interfaces/headerInterface';
import c from './style.module.scss';
import adminService from '../../shared/services/admin/adminService';

interface BlockListRowProps {
  user: ProfileData;
  setUser: Dispatch<SetStateAction<ProfileData[]>>;
  setTotalItems: Dispatch<SetStateAction<number>>;
}

const BlockListRow: FC<BlockListRowProps> = ({ user, setUser, setTotalItems }) => {
  const { id, firstName, lastName, accountStatus, wallet, email } = user;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const onMenuClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuCloseHandler = () => {
    setAnchorEl(null);
  };
  const onUnblockUserHandler = async () => {
    setIsLoading(true);
    try {
      await adminService.unblockInvestor(id);
      setUser(prev => prev.filter(item => item.id !== id));
      setTotalItems(prev => prev - 1);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <TableRow className={c.tableRow}>
        <TableCell>
          {firstName} {lastName}
        </TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{wallet || 'None'}</TableCell>
        <TableCell>{accountStatus.description}</TableCell>
        <TableCell>
          {new Date(accountStatus.updatedAt).toLocaleDateString('ru', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}
        </TableCell>
        <TableCell align="center">
          <IconButton
            sx={{
              mt: -8,
            }}
            onClick={onMenuClickHandler}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuCloseHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem disabled={isLoading} onClick={onUnblockUserHandler}>
          {isLoading ? 'Reverting...' : 'Revert'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default BlockListRow;
