import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../../app/styles/reusable/tables.scss';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { runInAction } from 'mobx';
import { IssuerContract } from '../../../shared/services/sign-documents/types';
import signDocumentsService from '../../../shared/services/sign-documents/signDocumentsService';
import ContractList from './contracts-list';
import { useStores } from '../../../store';

const headCells = ['Asset name', 'Investor name', 'Email', 'Amount', 'Ticker symbol', 'Signed date'];

const ActiveContracts = () => {
  const [contracts, setContracts] = useState<IssuerContract[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { issuerStore } = useStores();

  useEffect(() => {
    const getIssuerContracts = async () => {
      try {
        setIsLoading(true);
        const data = await signDocumentsService.getContractsForIssuer();
        setContracts(data);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
      }
    };
    const onContractSigned = (contractId: string) => {
      setContracts(prev => prev.filter(({ id }) => id !== contractId));
    };
    runInAction(() => {
      issuerStore.deleteContractCb = onContractSigned;
    });
    getIssuerContracts();

    return () => {
      issuerStore.deleteContractCb = null;
    };
  }, [issuerStore]);

  return (
    <div>
      <TableContainer className="wrapperDT">
        <Table>
          <TableHead className="tableHeadDT">
            <TableRow>
              {headCells.map(cell => (
                <TableCell key={cell} className="headCellDT">
                  {cell}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Spinner single />
                </TableCell>
              </TableRow>
            )}
            <ContractList contracts={contracts} />
            {!contracts.length && !isLoading && (
              <TableRow>
                <TableCell className="emptyBlockDT" colSpan={7}>
                  There are no contracts for signing
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActiveContracts;
