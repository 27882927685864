interface SignContractToIssuerDto {
  issuerId: string;
  templateId: string;
}

interface SignIssuer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  wallet: string;
}

export enum EPandaDocDocumentStatuses {
  notSent = 'not sent',
  // The document has just been created or uploaded. It is in processing and will be in document.draft state momentarily.
  uploaded = 'uploaded',
  // The document is in a draft state. All aspects of the document can be edited in this state. Our API does not support edits after the document has been created, but it can still be edited manually on https://app.pandadoc.com
  draft = 'draft',
  // The document has been "sealed" and optionally sent. No further document edits can occur except for document recipient(s) filling out or signing the document.
  sent = 'sent',
  // Document recipient(s) have viewed sent the document.
  viewed = 'viewed',
  // The document has an automatic approval workflow and has not yet been approved.
  waiting_approval = 'waiting_approval',
  // The document has an automatic approval workflow and was rejected.
  rejected = 'rejected',
  // The document has an automatic approval workflow and was approved.
  approved = 'approved',
  // The document has a Stripe payment option and is awaiting payment.
  waiting_pay = 'waiting_pay',
  // The document has a Stripe payment option and was paid.
  paid = 'paid',
  // The document has been completed by all recipients.
  completed = 'completed',
  // The document expired and is no longer available for completion or signature.
  voided = 'voided',
  // The document was manually marked as "Declined"
  declined = 'declined',
}

export enum AgreementNames {
  fortressIntegratorPrivacyPolicy = 'fortressIntegratorPrivacyPolicy',
  fortressTrustAccountOpening = 'fortressTrustAccountOpening',
  fortressTrustConsumerDisclosures = 'fortressTrustConsumerDisclosures',
  fortressUSAPatriotActDisclosure = 'fortressUSAPatriotActDisclosure',
  eSign = 'eSign',
  termsOfUse = 'termsOfUse',
}

interface UserAgreements {
  id: string;
  fortressIntegratorPrivacyPolicy?: boolean;
  fortressTrustAccountOpening?: boolean;
  fortressTrustConsumerDisclosures?: boolean;
  fortressUSAPatriotActDisclosure?: boolean;
  eSign?: boolean;
  termsOfUse?: boolean;
}

interface ContractData {
  id: string;
  embeddedUrl: string;
  completed?: boolean;
  token?: number;
  price?: number;
}

export interface PurchaseAsset {
  id: string;
  name: string;
  tickerSymbol: string;
  unitPrice: number;
}

export interface SignedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IssuerContract {
  id: string;
  investorSignedDate: string;
  pandaDocIssuerEmbeddedUrl: string;
  tokenPrice: number;
  tokens: number;
  purchaseProvider: string;
  asset: PurchaseAsset;
  investor: SignedUser;
}

export interface IssuerBrokerContract {
  id: string;
  commission: number;
  pandaDocIssuerEmbeddedUrl: string;
  asset: PurchaseAsset;
  broker: SignedUser;
}

export type { SignContractToIssuerDto, SignIssuer, ContractData, UserAgreements };
