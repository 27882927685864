import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from '../styles.module.scss';
import DocIcon from '../../../shared/assets/asset-view/document.svg';
import { useStores } from '../../../store';
import OneDocumentUploader from '../../createAsset/oneDocumentUploader/OneDocumentUploader';

function PresentationBlock(props: { id: string }) {
  const { id } = props;
  const { assetStore } = useStores();

  const [presentation, setPresentation] = useState(null);

  const submit = () => {
    if (id && presentation) {
      const formData = new FormData();
      formData.append('file', presentation);
      flowResult(assetStore.savePresentatin(id, formData));
      setPresentation(null);
    }
  };

  const deletePresentation = () => {
    flowResult(assetStore.deletePresentation(id));
  };

  if (assetStore.isPresentationLoading)
    return (
      <div className={c.formPart}>
        <Spinner single />
      </div>
    );

  return (
    <div className={c.formPart}>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.ivestorPresentationBlock" />
      </div>
      {!assetStore.currentAsset.presentationDocument ? (
        <OneDocumentUploader setDocument={setPresentation} document={presentation} />
      ) : (
        <div className={c.imgWrapper}>
          <a href={assetStore.currentAsset.presentationDocument.url} target="_blank" rel="noreferrer">
            <img src={DocIcon} alt="" />
            Presentation Document
          </a>
          <Button variant="outlined" onClick={deletePresentation}>
            Delete Document
          </Button>
        </div>
      )}
      <button
        className={c.submitButton}
        onClick={submit}
        disabled={!!assetStore.currentAsset.presentationDocument?.url || !presentation}
      >
        Save
      </button>
    </div>
  );
}

export default observer(PresentationBlock);
