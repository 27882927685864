import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import c from './style.module.scss';
import { useStores } from '../../../store';
import { IInvestorDividend } from '../../../shared/interfaces/userInfo.interface';
import DividendRow from '../../../components/distribution-page/investorDividendRow/investorDividendRow';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import exportIcon from '../../../shared/assets/icons/export.svg';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

function DividendCalculationPage() {
  const { dividendStore, headerStore } = useStores();
  const { id } = useParams();
  const params = useQueryParams();
  const startDate = params.get('start-date');
  const endDate = params.get('end-date');
  const dividends = params.get('dividends');
  const navigate = useNavigate();
  const userData = headerStore.user;
  const userRole = userData.role;

  useEffect(() => {
    if (!id || !startDate || !endDate || !dividends) {
      navigate(-1);
      return;
    }
    if (userRole === 'admin') {
      dividendStore.calculateAdminDividends(id, startDate, endDate, +dividends);
    } else {
      dividendStore.calculateIssuerDividends(id, startDate, endDate, +dividends);
    }
  }, [userRole, dividendStore, navigate, dividends, id, startDate, endDate]);

  const exportCsvHandler = () => {
    if (!id || !startDate || !endDate || !dividends) {
      navigate(-1);
      return;
    }
    if (userRole === 'admin') {
      dividendStore.exportAdminReport(id, startDate, endDate, +dividends);
    } else {
      dividendStore.exportIssuerReport(id, startDate, endDate, +dividends);
    }
  };

  return (
    <>
      <Helmet>
        <title>Dividend Calculations | TokenSuite</title>
      </Helmet>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className={c.back}
      >
        <img src={ArrowBackImg} alt="" />
        Distribute
      </button>
      <div className={c.wrapper}>
        <div className={c.topPanel}>
          <div className={c.text}>Total Deposit</div>
          <div className={c.deposit}>{dividendStore.currentDeposit} USDC</div>
          <button className="defaultButton" onClick={exportCsvHandler} disabled={dividendStore.isLoading}>
            <img src={exportIcon} alt="exportIcon" />
            Export CSV
          </button>
        </div>
        <div className={c.table}>
          <div className={c.titleRow}>
            <div className={`${c.columnLeftTitle} ${c.noSort}`}>Name</div>
            <div className={`${c.columnRightTitleStatus} ${c.noSort}`}>Token Allocation</div>
            <div className={`${c.columnRightTitleStatus} ${c.noSort}`}>Allocation %</div>
            <div className={`${c.columnRightTitleStatus} ${c.noSort}`}>USD Value</div>
          </div>
          <div className={c.profilesRows}>
            <div className={c.loadingCell}>
              {dividendStore.isLoading && (
                <div className={c.loading}>
                  <Spinner single />
                </div>
              )}
            </div>
            {dividendStore.calculateDividends.length > 0 ? (
              dividendStore.calculateDividends.map((investor: IInvestorDividend) => (
                <DividendRow investorData={investor} key={investor.email} />
              ))
            ) : (
              <div className={c.emptyBlock}>
                <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(DividendCalculationPage);
