import { Button } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import { CURRENCY_SYMBOLS } from '../../../../shared/config/constants';
import c from './style.module.scss';

export type AssetType = {
  id: string;
  img: string;
  name: string;
  location: string;
  alreadyInvested: number;
  investmentCap: number;
  targetAmount: number;
  currency: string;
  pricePerToken: number;
  minIvesment: number;
  tags: string[];
  tickerSymbol: string;
  endDate: string;
};

function IvestorAssetCard({ asset }: { asset: AssetType }) {
  const navigate = useNavigate();
  const [load, setLoaded] = useState(true);

  const isExpired = () => {
    if (new Date(asset.endDate) > new Date()) {
      return null;
    }
    return <p className={c.tag}>Sale Finished</p>;
  };

  return (
    <Link to={`/assets/${asset.id}`} className={c.wrapper}>
      <div className={c.image}>
        {load ? (
          <div className={c.loading}>
            <Spinner single style={{ color: '#1B528A' }} />
          </div>
        ) : null}
        <img
          src={asset.img}
          alt={asset.name}
          onLoad={() => {
            setLoaded(false);
          }}
        />
        <div className={c.blackout} />
        <div className={c.tags}>{isExpired()}</div>
        <p className={c.name}>{asset.name}</p>
        <p className={c.location}>{asset.location}</p>
      </div>
      <div className={c.progress}>
        <p className={c.digits}>
          {CURRENCY_SYMBOLS[asset.currency] + addComma(String(asset.investmentCap))}{' '}
          <span className={c.target}>Total asset value</span>
        </p>
        <div className={c.prices}>
          <div className={c.row}>
            <div className={c.name}>
              {asset.tickerSymbol ? (
                <FormattedMessage
                  id="ivestorMarketplace.card.pricePerToken"
                  values={{ tickerSymbol: asset.tickerSymbol }}
                />
              ) : (
                <FormattedMessage id="ivestorMarketplace.card.pricePerToken" values={{ tickerSymbol: '' }} />
              )}
            </div>
            <div className={c.value}>{CURRENCY_SYMBOLS[asset.currency] + asset.pricePerToken.toFixed(2)}</div>
          </div>
          <div className={c.row}>
            <div className={c.name}>
              <FormattedMessage id="ivestorMarketplace.card.minInvesment" />
            </div>
            <div className={c.value}>{CURRENCY_SYMBOLS[asset.currency] + addComma(String(asset.minIvesment))}</div>
          </div>
        </div>
      </div>
      <Button className={c.button} onClick={() => navigate(`/assets/${asset.id}`)}>
        <FormattedMessage
          id={
            new Date(asset.endDate) > new Date()
              ? 'ivestorMarketplace.card.button.invest'
              : 'ivestorMarketplace.card.button.showAsset'
          }
        />
      </Button>
    </Link>
  );
}

export default IvestorAssetCard;
