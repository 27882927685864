import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import c from './style.module.scss';
import ActivityLog from '../../../components/userDetails/activityLog/activityLog';
import AddPaymentModal from '../../../components/userDetails/addTransaction/addTransaction';
import UserInfo from '../../../components/userDetails/userInfo/userInfo';
import KybInfo from '../../../components/userDetails/kyb-info/kyb-info';
import { useStores } from '../../../store';
import LockupTransactions from '../../../components/userDetails/lockupTransactions/lockupTransactions';
import ChangeLockupModal from '../../../components/userDetails/changeLockup/changeLockup';
import { EAccountStatusesNorthCapital } from '../../../shared/types/account.types';

function UserDetails() {
  const navigate = useNavigate();
  const { userInfoStore } = useStores();
  const { id } = useParams();
  const userInfo = userInfoStore.userInfo;

  useEffect(() => {
    if (!id) return;
    flowResult(userInfoStore.getUserActivityById(id as string));
    flowResult(userInfoStore.getUserInfoById(id as string));
  }, [userInfoStore, id]);

  return (
    <>
      <Helmet>
        <title>User Details | TokenSuite</title>
      </Helmet>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className={c.back}
      >
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="usersPage.back" />
      </button>
      <div className={c.title}>User details</div>
      <UserInfo />
      <KybInfo
        accountStatus={userInfo.accountStatus}
        userEntity={userInfo.userEntity}
        userVerifyData={userInfo.userVerifyData}
        userFiles={userInfoStore.currentFiles}
      />
      {userInfo.role === 'investor' && userInfo.accountStatus.status === EAccountStatusesNorthCapital.Verified && (
        <>
          <AddPaymentModal />
          <LockupTransactions />
        </>
      )}
      <ActivityLog />
      <ChangeLockupModal />
    </>
  );
}

export default observer(UserDetails);
