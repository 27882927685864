import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from './style.module.scss';
import DefaultButton from '../../UI/buttons/default-button';
import { useStores } from '../../../store';
import PortalAddAssetModal from './portal-add-asset-modal';

const PortalAssets = () => {
  const { id } = useParams<{ id: string }>();
  const { portalStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onAddAsset = async (assetId: string) => {
    if (!assetId || !id) {
      throw new Error('You must select asset');
    }
    flowResult(portalStore.addNewAssetToDealer(assetId, id));
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (id) {
      flowResult(portalStore.getAssetsOnPortal(id));
    }
  }, [id, portalStore]);

  return (
    <>
      <div className={c.wrapper}>
        <Stack alignItems="flex-end">
          <DefaultButton variant="contained" onClick={() => setIsDialogOpen(true)}>
            Add Asset
          </DefaultButton>
        </Stack>
        <Stack alignItems="center" mb={15} className={c.title}>
          Assets
        </Stack>
        <TableContainer
          className="wrapperDT"
          sx={{
            borderRadius: 0,
            boxShadow: 'none',
          }}
          component={Paper}
        >
          <Table>
            <TableHead className="tableHeadDT">
              <TableRow>
                <TableCell className="headCellDT">Asset</TableCell>
                <TableCell className="headCellDT">Portal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portalStore.isLoading && (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Spinner single />
                  </TableCell>
                </TableRow>
              )}
              {portalStore.linkedAssets.length > 0 &&
                !portalStore.isLoading &&
                portalStore.linkedAssets.map(linkedAsset => (
                  <TableRow key={linkedAsset.id} className="tableRowDT">
                    <TableCell className="cellDT">
                      <Stack>
                        <div>{linkedAsset.name}</div>
                      </Stack>
                    </TableCell>
                    <TableCell className="cellDT">{linkedAsset.portal?.portalName || 'deleted portal'}</TableCell>
                  </TableRow>
                ))}
              {!portalStore.linkedAssets.length && !portalStore.isLoading && (
                <TableRow>
                  <TableCell className="emptyBlockDT" colSpan={2}>
                    <FormattedMessage id="portals.table.text.noAssets" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {isDialogOpen && portalStore.notLinkedAssets && (
        <PortalAddAssetModal
          onAddAssetHandler={onAddAsset}
          placeholder="Select asset"
          menuItems={portalStore.notLinkedAssets}
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          title="Link Asset"
          content="Link new asset to all brokers from this broker dealer"
          actionButtonText={portalStore.isLoading ? 'Loading...' : 'Continue'}
          isActionLoading={portalStore.isLoading}
        />
      )}
    </>
  );
};

export default observer(PortalAssets);
