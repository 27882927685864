import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from '../styles.module.scss';
import { useStores } from '../../../store';
import Uploader from '../../createAsset/uploader/Uploader';

function EditImagesBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [uploadPreviewImage, setPreviewUpload] = useState(false);
  const [uploadMainImage, setMainUpload] = useState(false);
  const [previewImage, setPreview] = useState<{ image?: any }[]>([]);
  const [mainImage, setMain] = useState<{ linksToVideo?: string; image?: File }[]>([]);
  const [description, setDescription] = useState(assetStore.currentAsset.description);

  const [previewError, setPreviewError] = useState('');
  const [mainError, setMainError] = useState('');

  const submitPreview = () => {
    if (!previewImage[0]?.image) {
      setPreviewError(intl.formatMessage({ id: 'createAssetPage.errors.required.previewImage' }));
      return;
    }
    const formData = new FormData();
    formData.append('file', previewImage[0].image);
    flowResult(assetStore.savePreview(id, formData));
    setPreviewUpload(false);
    setPreview([]);
  };

  const backPreview = () => {
    setPreviewUpload(false);
    setPreview([]);
  };

  const submitMain = () => {
    if (!mainImage[0]) {
      setMainError(intl.formatMessage({ id: 'createAssetPage.errors.required.mainMedia' }));
      return;
    }
    const formData = new FormData();
    if (mainImage[0].image) {
      formData.append('file', mainImage[0].image);
    }
    if (mainImage[0].linksToVideo) {
      formData.append('mainVideo', mainImage[0].linksToVideo);
    }
    flowResult(assetStore.saveMainMedia(id, formData));
    setMainUpload(false);
    setMain([]);
  };

  const backMain = () => {
    setMainUpload(false);
    setMain([]);
  };

  const submitDescription = () => {
    if (id) {
      flowResult(assetStore.saveDescription(id, description));
    }
  };

  if (assetStore.isMainMediaLoading)
    return (
      <div className={c.formPart}>
        <Spinner single />
      </div>
    );

  return (
    <div className={c.formPart}>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.previewImageBlock" />
      </div>
      <p className={c.errorText}>{previewError}</p>
      {uploadPreviewImage ? (
        <Uploader
          setDocuments={setPreview}
          documents={previewImage}
          cap={1}
          withVideo={false}
          setError={setPreviewError}
        />
      ) : (
        <div className={c.imgWrapper}>
          <img src={assetStore.currentAsset.previewImage.url} alt="" />
          <Button
            variant="outlined"
            onClick={() => {
              setPreviewUpload(true);
            }}
          >
            Change Image
          </Button>
        </div>
      )}
      <div className={c.buttons}>
        {uploadPreviewImage && (
          <button className={c.submitButton} onClick={backPreview}>
            <UndoIcon />
          </button>
        )}
        <button className={c.submitButton} onClick={submitPreview} disabled={!uploadPreviewImage}>
          Save
        </button>
      </div>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.mainImageBlock" />
      </div>
      <p className={c.errorText}>{mainError}</p>
      {uploadMainImage ? (
        <Uploader setDocuments={setMain} documents={mainImage} cap={1} setError={setMainError} />
      ) : (
        <div className={c.imgWrapper}>
          {assetStore.currentAsset?.mainImage ? (
            <img src={assetStore.currentAsset.mainImage.url} alt="" />
          ) : (
            <p>{assetStore.currentAsset?.mainVideo}</p>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setMainUpload(true);
            }}
          >
            Change Image/Video
          </Button>
        </div>
      )}
      <div className={c.buttons}>
        {uploadMainImage && (
          <button className={c.submitButton} onClick={backMain}>
            <UndoIcon />
          </button>
        )}
        <button className={c.submitButton} onClick={submitMain} disabled={!uploadMainImage}>
          Save
        </button>
      </div>
      <TextInput
        value={description}
        changeValue={setDescription}
        label={intl.formatMessage({ id: 'createAssetPage.fields.description.label' })}
        placeholder={intl.formatMessage({ id: 'createAssetPage.fields.description.placeholder' })}
        type="text"
        fullWidth
        multiline
      />
      <button className={c.submitButton} onClick={submitDescription} disabled={!description.trim()}>
        Save
      </button>
    </div>
  );
}

export default observer(EditImagesBlock);
