import { Box, Modal, Paper, Stack } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useStores } from '../../../store';
import c from './style.module.scss';

interface IIssuerContractModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pandaDocLink: string;
  contractId: string;
}

const IssuerContractModal: FC<IIssuerContractModalProps> = ({ open, setOpen, pandaDocLink, contractId }) => {
  const { issuerStore, vereficationStore } = useStores();

  useEffect(() => {
    const checkDocumentSign = (event: any) => {
      const type = event.data && event.data.type;
      if (type === 'session_view.document.completed') {
        setOpen(false);
        if (issuerStore.deleteContractCb) {
          issuerStore.deleteContractCb(contractId);
        }
        vereficationStore.changeNotificationAfterSign(contractId);
      }
    };
    window.addEventListener('message', checkDocumentSign);
    return () => {
      window.removeEventListener('message', checkDocumentSign);
    };
  }, [contractId, issuerStore, vereficationStore, setOpen]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box className={c.modalWrapper}>
        <Paper className={c.paper}>
          <Stack>
            <iframe src={pandaDocLink} title="contract to sign" className={c.contract} />
          </Stack>
        </Paper>
      </Box>
    </Modal>
  );
};

export default IssuerContractModal;
