import React, { FC } from 'react';
import { IssuerContract } from '../../../shared/services/sign-documents/types';
import ContractRow from './contract-row';

interface ContractListProps {
  contracts: IssuerContract[];
}

const ContractList: FC<ContractListProps> = ({ contracts }) => {
  if (!contracts.length) return null;

  return (
    <>
      {contracts.map(contract => (
        <ContractRow contract={contract} key={contract.id} />
      ))}
    </>
  );
};

export default ContractList;
