import React from 'react';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import { FormikContainerProps } from './formik-container.types';

const FormikContainer = <T,>({ initialValues, children, onSubmitHandler, ...rest }: FormikContainerProps<T>) => {
  const onSubmit = async (values: FormikValues & T, formikHelpers: FormikHelpers<T & FormikValues>) => {
    formikHelpers.setSubmitting(true);
    await onSubmitHandler(values, formikHelpers);
    formikHelpers.setSubmitting(false);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...rest}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {formValues => <Form>{children(formValues)}</Form>}
    </Formik>
  );
};

export default FormikContainer;
