import React, { FC, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from './style.module.scss';
import { useStores } from '../../../../store';
import { IMarketPlaceAsset } from '../../../../shared/services/asset/asset.types';

interface AssetRowProps {
  asset: IMarketPlaceAsset;
}

const AssetRow: FC<AssetRowProps> = ({ asset }) => {
  const { dividendStore } = useStores();
  const [isImageLoad, setIsImageLoad] = useState(false);

  return (
    <TableRow className={`tableRowDT ${c.wrapper}`}>
      <TableCell>
        <div className={c.image}>
          {!isImageLoad && (
            <div className={c.load}>
              <Spinner single />
            </div>
          )}
          <img
            src={asset.previewImage.url}
            alt=""
            onLoad={() => setIsImageLoad(true)}
            style={{ display: isImageLoad ? 'block' : 'none' }}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className={c.name}>{asset.name}</div>
      </TableCell>
      <TableCell>
        {asset.paymentTokenRaised} / {asset.targetAmount} {asset.tickerSymbol}
      </TableCell>
      <TableCell>
        <div className={c.buttons}>
          <button
            className="defaultButton mainColor"
            onClick={() => {
              dividendStore.openDistributeModal(asset.id);
            }}
          >
            Distribute
          </button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default AssetRow;
