import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import c from './styles.module.scss';

function CheckoutStages() {
  const location = useLocation();
  const { id, tokens = 0 } = useParams();
  const { pathname } = location;
  const navigate = useNavigate();

  return (
    <div className={c.checkoutStages}>
      <div className={`${c.line}`}>
        <div className={`${c.before} ${pathname.includes('/onboarding') ? `${c.active}` : ''}`}>
          <span className={c.label}>Onboarding</span>
        </div>
        <button
          onClick={() => {
            if (pathname.includes('/payment') || pathname.includes('/confirmation')) {
              navigate(`/checkout/order/${id}/${tokens}`);
            }
          }}
          className={`${c.first} ${pathname.includes('/order') ? `${c.active}` : `${c.notClickable}`}`}
        >
          <span className={c.label}>Order</span>
        </button>
        <div className={`${c.second} ${pathname.includes('/payment') ? `${c.active}` : ''}`}>
          <span className={c.label}>Payment</span>
        </div>
        <div className={`${c.third} ${pathname.includes('/confirmation') ? `${c.active}` : ''}`}>
          <span className={c.label}>Confirmation</span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutStages;
