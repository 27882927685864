import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import AcceptActionDialog from '../../UI/dialogs/accept-action/accept-action-dialog';
import { InitialSaleAsset } from '../../../shared/services/portal/types';
import initialSaleService from '../../../shared/services/initial-sale/initialSaleService';

interface DeployInitialAssetModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tokenId: string;
  initialAssets: InitialSaleAsset[];
}

const DeployInitialAssetModal: FC<DeployInitialAssetModalProps> = ({ open, setOpen, tokenId, initialAssets }) => {
  const [assetId, setAssetId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const onAssetChangeHandler = (e: SelectChangeEvent) => {
    setAssetId(e.target.value);
    setError('');
  };
  const onDeployAssetHandler = async () => {
    if (!assetId) return;
    setIsLoading(true);
    try {
      await initialSaleService.deployInitialSaleCotract(assetId, tokenId);
      setOpen(false);
    } catch (e: any) {
      setError(e?.response?.data?.message || '');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AcceptActionDialog
      open={open}
      setOpen={setOpen}
      title="Deploy contract"
      content="Select asset for deploying contract"
      actionButtonText={isLoading ? 'Deploying...' : 'Deploy'}
      onAgreeHandler={onDeployAssetHandler}
      isActionLoading={isLoading}
    >
      <FormControl>
        <InputLabel>Choose asset</InputLabel>
        <Select onChange={onAssetChangeHandler} value={assetId} displayEmpty label="Choose asset">
          {initialAssets.map(asset => (
            <MenuItem key={asset.id} value={asset.id}>
              {asset.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && (
        <Typography mb={3} mt={3} fontWeight={400} fontSize="14px" color="error.main">
          {error}
        </Typography>
      )}
    </AcceptActionDialog>
  );
};

export default DeployInitialAssetModal;
