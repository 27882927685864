import { AxiosInstance } from 'axios';
import {
  InviteIssuerDto,
  InviteDemoIssuerDto,
  ActivateDemoIssuerDto,
  GetInvestorsResponse,
} from './adminService.types';
import tokenInstance from '../API/tokenApi';
import { RequestParams } from '../../types/investorTableType';

class AdminService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async inviteIssuer(dto: InviteIssuerDto) {
    await this.authInstance.post('users/issuers', dto);
  }

  async inviteDemoIssuer(dto: InviteDemoIssuerDto) {
    await this.authInstance.post('portal/demo', dto);
  }

  async activateDemoIssuer(dto: ActivateDemoIssuerDto, id: string) {
    await this.authInstance.post(`portal/demo/activate/${id}`, dto);
  }

  async addInvestorToBlockList(id: string, message: string): Promise<void> {
    await this.authInstance.post(`users/investors/blocklist/${id}`, {
      message,
    });
  }

  async unblockInvestor(id: string): Promise<void> {
    await this.authInstance.get(`users/investors/blocklist/${id}`);
  }

  async getInvestors(requestParams: RequestParams): Promise<GetInvestorsResponse> {
    const { data } = await this.authInstance.get<GetInvestorsResponse>('users/investors/all', {
      params: requestParams,
    });
    return data;
  }
}

export default new AdminService(tokenInstance);
