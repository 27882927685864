import { FormattedMessage, useIntl } from 'react-intl';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useEffect, useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from '../styles.module.scss';
import { useStores } from '../../../store';

function EditReviewsBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [reviews, setReviews] = useState<
    {
      id?: string;
      reviewer: string;
      text: string;
    }[]
  >([]);

  useEffect(() => {
    if (assetStore.currentAsset.reviews) {
      setReviews(assetStore.currentAsset.reviews);
    }
  }, [assetStore.currentAsset.reviews]);

  const addHighlight = () => {
    setReviews([...reviews, { reviewer: '', text: '' }]);
  };

  const removeFile = (index: number, reviewId: string | undefined) => {
    const newHighlights = reviews.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setReviews(newHighlights);
    if (reviewId) {
      flowResult(assetStore.deleteReview(id, reviewId));
    }
  };

  const perkAuthorHandler = (e: string, index: number) => {
    const tempArr = reviews;
    tempArr[index].reviewer = e;
    setReviews([...tempArr]);
  };
  const perkTextHandler = (e: string, index: number) => {
    const tempArr = reviews;
    tempArr[index].text = e;
    setReviews([...tempArr]);
  };

  const submit = () => {
    flowResult(assetStore.updateReviews(id, reviews));
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="mainInfo">
        <FormattedMessage id="createAssetPage.text.reviewsBlock" />
      </div>
      <div className={c.wrapper}>
        <div className={c.listHighlights}>
          <div className={c.wrapperBlock}>
            {reviews.map((review, index: number) => (
              <div className={c.review} key={index}>
                <div className={c.left}>
                  <TextIndexInput
                    value={review.reviewer}
                    changeValue={perkAuthorHandler}
                    label={intl.formatMessage(
                      { id: 'createAssetPage.reviews.field.reviewer.label' },
                      { count: `${index + 1}` },
                    )}
                    placeholder={intl.formatMessage({ id: 'createAssetPage.reviews.field.reviewer.placeholder' })}
                    type="text"
                    fullWidth
                    index={index}
                  />
                  <TextIndexInput
                    value={review.text}
                    changeValue={perkTextHandler}
                    label={intl.formatMessage(
                      { id: 'createAssetPage.reviews.field.review.label' },
                      { count: `${index + 1}` },
                    )}
                    placeholder={intl.formatMessage({ id: 'createAssetPage.reviews.field.review.placeholder' })}
                    type="text"
                    fullWidth
                    multiline
                    index={index}
                  />
                </div>
                <div className={c.right}>
                  <IconButton className={c.delete} onClick={() => removeFile(index, review.id)}>
                    <img src={closeIcon} alt="X" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className={c.addButton} onClick={addHighlight}>
          {reviews.length > 0 ? (
            <FormattedMessage id="createAssetPage.reviews.button.addAnother" />
          ) : (
            <FormattedMessage id="createAssetPage.reviews.button.addFirst" />
          )}
        </button>
      </div>
      <button className={c.submitButton} onClick={submit}>
        Save
      </button>
    </div>
  );
}

export default observer(EditReviewsBlock);
