import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { AccountStatus, UserEntity, UserVerifyData } from '../../../shared/interfaces/userInfo.interface';
import FileView from './file-view';

interface KybInfoProps {
  userVerifyData: UserVerifyData;
  accountStatus: AccountStatus;
  userEntity: UserEntity;
  userFiles: Blob[];
}

const KybInfo: FC<KybInfoProps> = observer(({ userVerifyData, userFiles, userEntity, accountStatus }) => {
  const statusData = [
    ['Kyc status:', userVerifyData.kycStatus],
    ['Accredited status:', userVerifyData.accreditedStatus],
  ];

  const entityData = [
    ['Domicile:', userEntity.domicile],
    ['Entity name:', userEntity.entityName],
    ['Entity type:', userEntity.entityType],
    ['Country:', userEntity.primCountry],
    ['City:', userEntity.primCity],
    ['Address:', userEntity.primAddress1],
    ['Zip:', userEntity.primZip],
  ];

  if (!userEntity.domicile) {
    return null;
  }

  return (
    <Box bgcolor="#fff" p={16} mt={24} borderRadius={1} border="1px solid #D2D2D2">
      <Typography fontSize="20px" fontWeight={600} mb={10}>
        Kyb information
      </Typography>
      <Divider />
      <Grid container rowSpacing={10}>
        <Grid item xs={12} display="flex" alignItems="center" gap="5px" mt={5}>
          <Typography fontWeight={600}>Account status:</Typography>
          <Typography>{accountStatus.status}</Typography>
        </Grid>
        {statusData.map(([key, value]) => (
          <Grid item key={key} xs={12} display="flex" alignItems="center" gap="5px">
            <Typography fontWeight={600}>{key}</Typography>
            <Typography>{value}</Typography>
          </Grid>
        ))}
        {entityData.map(([key, value]) => (
          <Grid item key={key} xs={12} display="flex" alignItems="center" gap="5px">
            <Typography fontWeight={600}>{key}</Typography>
            <Typography>{value}</Typography>
          </Grid>
        ))}
        {userEntity.primState && (
          <Grid item xs={12} display="flex" alignItems="center" gap="5px">
            <Typography fontWeight={600}>State:</Typography>
            <Typography>{userEntity.primState}</Typography>
          </Grid>
        )}
        {userFiles.map((file, index) => (
          <Grid item xs={12} key={index}>
            <FileView file={file} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default KybInfo;
