import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import fullLogo from '../../shared/assets/DIBS.png';
import signBg from '../../shared/assets/signBg.jpg';
import signBgMobile from '../../shared/assets/signBg_mobile.jpg';
import c from './style.module.scss';
import { useStores } from '../../store';

const SignInHeader = () => {
  const {
    portalStore: { portalUI, isCustomPortalLoading },
  } = useStores();

  const [load, setLoaded] = useState(true);

  return (
    <header className={c.wrapper}>
      <div className={load ? `${c.loading}` : `${c.loading} ${c.hide}`} />
      {!isCustomPortalLoading && (
        <>
          <img
            src={portalUI?.portalUi?.authBack?.url || signBg}
            className={c.bg}
            alt=""
            onLoad={() => {
              setLoaded(false);
            }}
          />
          <img src={portalUI?.portalUi?.authMobileBack?.url || signBgMobile} className={c.bgMobile} alt="" />
          <img src={portalUI?.portalUi?.logo?.url || fullLogo} className={c.logo} alt="DibsCapital" />
          <div className={c.tagline}>
            {portalUI?.portalUi?.authDescription ? (
              portalUI?.portalUi?.authDescription
            ) : (
              <FormattedMessage id="login.tagline" values={{ br: <br /> }} />
            )}
          </div>
        </>
      )}
    </header>
  );
};

export default observer(SignInHeader);
