import { FormattedMessage } from 'react-intl';

const headCells = [
  {
    id: 'date',
    text: <FormattedMessage id="activityLog.table.date" />,
  },
  {
    id: 'description',
    text: <FormattedMessage id="activityLog.table.description" />,
  },
];

export { headCells };
