import React from 'react';
import { Checkbox, FormHelperText, Stack } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import c from '../formik-input/style.module.scss';
import { FormikCheckboxProps } from './formik-checkbox.types';

const FormikCheckbox = (props: FormikCheckboxProps) => {
  const { name, label, ...rest } = props;
  const [field, meta] = useField(name as string);
  return (
    <div className={c.wrapper}>
      <Stack direction="row" alignItems="center">
        <Checkbox {...rest} {...field} name={name} id={name} checked={field.value} />
        <label htmlFor={name} style={{ cursor: 'pointer' }}>
          {label}
        </label>
      </Stack>
      {meta.error && (
        <FormHelperText className={c.errorText}>
          <ErrorMessage name={name || ''} />
        </FormHelperText>
      )}
    </div>
  );
};

export default FormikCheckbox;
