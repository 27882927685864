import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../store';
import c from './styles.module.scss';

function CheckoutCrypto() {
  const { checkoutStore } = useStores();
  const { tokens = 0 } = useParams();

  return (
    <div className={c.checkoutWire}>
      <div className={c.title}>Success!</div>
      <div className={c.descr}>
        {checkoutStore.checkoutInfo.isSoftCup
          ? `Your ${addComma(String(tokens))} ${
              checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`
            } 
          tokens will be distributed to your account soon.`
          : `Once the minimum market cap is reached, your ${addComma(String(tokens))} ${
              checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`
            } 
          tokens will be distributed to your account.`}
      </div>
    </div>
  );
}

export default CheckoutCrypto;
