import {
  EAccountAccreditationStatuses,
  EAccountStatuses,
  EAccountStatusesNorthCapital,
  EDocumentStatuses,
} from '../../types/account.types';

interface CreateKybDto {
  companyName: string;
  phone: string;
  primAddress1: string;
  primZip: string;
  primCity: string;
  primState?: string;
  primCountry: string;
  regionOfFormation: string;
  description: string;
  legalStructure: string;
  files: File[];
}

export interface IUserVerifyStatus {
  id: string;
  activated: boolean;
  kycStatus: EAccountStatuses | '';
  accreditationStatus: EAccountAccreditationStatuses | '';
  documentStatus: EDocumentStatuses | '';
  isBusiness: boolean;
  errors: string;
  fortressKybVerificationStatus?: {
    id: string;
    kybStatus: EAccountStatuses | '';
    errors: string;
  };
  status?: EAccountStatusesNorthCapital | '';
}

export interface IParsedNotification {
  userId: string;
  date: string;
  notificationStatus: NotificationStatus;
  notificationType: NotificationType;
  description: string;
  metadata: {
    contractId?: string;
  };
}

export interface INotification {
  userId: string;
  date: string;
  notificationStatus: NotificationStatus;
  notificationType: NotificationType;
  description: string;
  metadata: string;
}

export interface IUserNotifications {
  notifications: INotification[];
}

export enum NotificationStatus {
  NotDone = 'not done',
  Done = 'done',
}

export enum NotificationType {
  SignDocumentWithBroker = 'signDocumentWithBroker',
  SignDocumentWithInvestor = 'signDocumentWithInvestor',
  Payment = 'payment',
}

export interface IUserVerifyInfo {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  verificationStatus: IUserVerifyStatus;
}

export interface IUserVerifyInfoById {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  verificationStatus: IUserVerifyStatus;
  accountStatus: {
    status: string;
    activated: boolean;
  };
  fortressWallet?: {
    id: string;
  } | null;
}

export type { CreateKybDto };
