import { useState, useEffect } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import EyeClosedImg from '../../../shared/assets/icons/eye-off.svg';
import EyeOpenedImg from '../../../shared/assets/icons/eye.svg';
import Warning from '../../../shared/assets/icons/warning.svg';
import PasswordStatus from './PasswordStatus/PasswordStatus';
import { EMAIL_REGEX } from '../../../shared/config/constants';
import SignInHeader from '../../../components/SignInHeader/SignInHeader';
import userPool from '../../../shared/config/cognitoPool';
import c from './style.module.scss';
import tokenInstance from '../../../shared/services/API/tokenApi';
import withoutTokenAPI from '../../../shared/services/API/withoutTokenAPI';
import Powered from '../../../components/powered/powered';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

function RegisterPage() {
  const intl = useIntl();
  const query = useQueryParams();
  const emailFromLink = decodeURIComponent(`${query.get('email')}`);
  const issuerCode = query.get('code');
  const assetId = query.get('assetId');
  const name = decodeURIComponent(`${query.get('name')}`);
  const portalName = decodeURIComponent(`${query.get('portalName')}`);
  const userId = decodeURIComponent(`${query.get('userId')}`);

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { portalStore } = useStores();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [expiredError, setExpiredError] = useState(false);
  const [isEmailErrorShown, setIsEmailErrorShown] = useState(false);
  useEffect(() => {
    if (EMAIL_REGEX.test(email)) {
      setEmailError('');
      return;
    }
    if (!email) {
      setEmailError('Please Enter Email');
      return;
    }
    setEmailError('Please enter valid Email');
  }, [email]);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordErrorShown, setIsPasswordErrorShown] = useState(false);
  const passwordOnBlur = () => setIsPasswordErrorShown(true);
  useEffect(() => {
    if (isPasswordValid) {
      setPasswordError('');
      return;
    }
    setPasswordError('Weak password');
  }, [isPasswordValid]);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isConfirmPasswordErrorShown, setIsConfirmPasswordErrorShown] = useState(false);
  const confirmPasswordOnBlur = () => {
    setIsConfirmPasswordErrorShown(true);
  };
  useEffect(() => {
    if (password === confirmPassword) {
      setConfirmPasswordError('');
      return;
    }
    setConfirmPasswordError('Passwords do not match');
  }, [password, confirmPassword]);

  useEffect(() => {
    if (userId !== 'null') {
      withoutTokenAPI.get(`users/status/activated/${userId}`).then((data: any) => {
        if (data.data) {
          navigate('/login');
        }
      });
    }
  }, [navigate, userId]);

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  const submit = () => {
    if (issuerCode) {
      const authenticationData = {
        Username: emailFromLink,
        Password: issuerCode,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const userData = {
        Username: emailFromLink,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess() {},
        onFailure() {
          setExpiredError(true);
        },
        newPasswordRequired(userAttributes) {
          delete userAttributes.email_verified;
          delete userAttributes.email;

          cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess(result) {
              localStorage.setItem('accessToken', `${result.getAccessToken().getJwtToken()}`);
              localStorage.setItem('refreshToken', `${result.getRefreshToken().getToken()}`);
              tokenInstance.put('users/status/activate');
              navigate('/redirect', { replace: true });
            },
            onFailure() {
              setExpiredError(true);
            },
          });
        },
      });
      return;
    }

    const attributeList = [];

    const dataEmail = {
      Name: 'email',
      Value: email,
    };
    const dataFirstName = {
      Name: 'name',
      Value: firstName.trim(),
    };
    const dataLastName = {
      Name: 'family_name',
      Value: lastName.trim(),
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeFirstName = new CognitoUserAttribute(dataFirstName);
    const attributeLastName = new CognitoUserAttribute(dataLastName);

    attributeList.push(attributeEmail, attributeFirstName, attributeLastName);
    const clientMetaData: {
      [key: string]: string;
    } = {
      host: window.location.hostname,
      accountRole: 'investor',
      primaryColor: portalStore.portalUI.portalUi?.colorStyles
        ? portalStore.portalUI.portalUi?.colorStyles.primary
        : '#00BC68',
      portalName: portalStore.portalUI?.portalName ? portalStore.portalUI?.portalName : 'TokenSuite',
      portalBackground: portalStore.portalUI?.portalUi?.emailBack?.url!
        ? portalStore.portalUI.portalUi?.emailBack?.url!
        : portalStore.portalUI.portalUi?.authBack?.url!,
    };
    const referralId = localStorage.getItem('referralId');
    if (referralId) {
      clientMetaData.referralId = referralId;
    }
    if (!portalStore.portalUI?.portalUi?.authBack?.url! && !portalStore.portalUI?.portalUi?.emailBack?.url!) {
      delete clientMetaData.portalBackground;
    }
    userPool.signUp(
      email,
      password,
      attributeList,
      [],
      err => {
        if (err) {
          if (err.name === 'UsernameExistsException') {
            setEmailError('email is already registered');
            return;
          }
          return;
        }
        localStorage.removeItem('referralId');
        navigate(`/confirm?email=${encodeURIComponent(email)}`);
      },
      clientMetaData,
    );
  };

  return (
    <main className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Create Account | ${portalStore.portalUI.portalUi.title}`
            : 'Create Account | TokenSuite'}
        </title>
      </Helmet>
      <SignInHeader />
      <div className={c.rightBlock}>
        {expiredError && (
          <div className={c.warning}>
            <img src={Warning} alt="warning" />
            <p>Invitation link expired. Contact person who send it to you.</p>
          </div>
        )}
        <div className={c.data}>
          {assetId && (
            <>
              <header>
                <FormattedMessage id="signUp.text.welcomeWithName" values={{ fullName: name }} />
              </header>
              <p className={c.text}>
                <FormattedMessage id="signUp.text.invitedWithName" values={{ fullName: <b>{portalName}</b> }} />
              </p>
              <p className={c.subText}>
                <FormattedMessage id="signUp.text.pleaseFinish" />
              </p>
            </>
          )}
          {issuerCode && !assetId && (
            <>
              <header>
                <FormattedMessage id="signUp.text.welcomeWithName" values={{ fullName: name }} />
              </header>
              <p className={c.text}>
                <FormattedMessage id="signUp.text.invitedWithoutName" />
              </p>
              <p className={c.subText}>
                <FormattedMessage id="signUp.text.pleaseFinish" />
              </p>
            </>
          )}
          {!issuerCode && !assetId && (
            <>
              <header>
                <FormattedMessage id="signUp.text.welcomeWithoutName" />
              </header>
              <p className={c.subText}>
                <FormattedMessage id="signUp.text.pleaseFinish" />
              </p>
            </>
          )}
          <form className={c.inputsBlock}>
            <p className={c.inputLabel}>
              <FormattedMessage id="login.fields.email.name" />
            </p>
            <TextField
              type="email"
              disabled={emailFromLink.length > 4}
              onChange={e => setEmail(e.target.value.trim())}
              value={issuerCode ? emailFromLink : email}
              autoComplete="email"
              id="auth-email"
              className={c.input}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'login.fields.email.placeHolder' })}
              inputProps={{
                tabIndex: 1,
                maxLength: 127,
              }}
              error={!!(emailError && isEmailErrorShown)}
              helperText={isEmailErrorShown && emailError}
              onBlur={() => {
                setIsEmailErrorShown(true);
              }}
            />
            {!issuerCode && !assetId && (
              <>
                <p className={c.inputLabel}>
                  <FormattedMessage id="signUp.fields.firstName.name" />
                </p>
                <TextField
                  type="text"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  className={c.input}
                  margin="normal"
                  placeholder={intl.formatMessage({ id: 'signUp.fields.name.placeholder' })}
                  inputProps={{
                    tabIndex: 2,
                    maxLength: 127,
                  }}
                />
                <p className={c.inputLabel}>
                  <FormattedMessage id="signUp.fields.lastName.name" />
                </p>
                <TextField
                  type="text"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  className={c.input}
                  margin="normal"
                  placeholder={intl.formatMessage({ id: 'signUp.fields.name.placeholder' })}
                  inputProps={{
                    tabIndex: 2,
                    maxLength: 127,
                  }}
                />
              </>
            )}
            <p className={c.inputLabel}>
              <FormattedMessage id="login.fields.password.name" />
            </p>
            <TextField
              onChange={e => setPassword(e.target.value.trim())}
              value={password}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="new-password"
              id="new-password"
              className={c.input}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'login.fields.password.placeHolder' })}
              InputProps={{
                inputProps: {
                  tabIndex: 2,
                  maxLength: 127,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                      edge="end"
                    >
                      <img src={isPasswordShown ? EyeClosedImg : EyeOpenedImg} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={isPasswordErrorShown && passwordError}
              onBlur={passwordOnBlur}
              error={!!(isPasswordErrorShown && passwordError)}
            />
            <p className={c.inputLabel}>
              <FormattedMessage id="signUp.fields.confirmPassword.name" />
            </p>
            <TextField
              onChange={e => setConfirmPassword(e.target.value.trim())}
              value={confirmPassword}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="new-password"
              id="new-password-confirm"
              className={c.input}
              margin="normal"
              placeholder={intl.formatMessage({ id: 'signUp.fields.confirmPassword.placeholder' })}
              InputProps={{
                inputProps: {
                  tabIndex: 3,
                  maxLength: 127,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                      edge="end"
                    >
                      <img src={isPasswordShown ? EyeClosedImg : EyeOpenedImg} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onBlur={confirmPasswordOnBlur}
              helperText={isConfirmPasswordErrorShown && confirmPasswordError}
              error={!!confirmPasswordError && isConfirmPasswordErrorShown}
            />
            <PasswordStatus password={password} setIsValid={setIsPasswordValid} />
            <Button
              tabIndex={4}
              className={c.submitButton}
              onClick={submit}
              disabled={
                !(
                  isPasswordValid &&
                  (EMAIL_REGEX.test(email) || EMAIL_REGEX.test(emailFromLink)) &&
                  confirmPassword === password
                ) || expiredError
              }
            >
              <FormattedMessage id="signUp.fields.button.confirm" />
            </Button>
            {!issuerCode && !assetId && (
              <Link className={c.signUp} to="/login">
                <FormattedMessage id="signUp.signIn.button" />
              </Link>
            )}
          </form>
          <Powered />
        </div>
      </div>
    </main>
  );
}

export default observer(RegisterPage);
