import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import LoaderStore from './loaderStore';
import HeaderStore from './headerStore';
import AssetStore from './assetStore';
import UserInfoStore from './userInfoStore';
import CheckoutStore from './checkoutStore';
import { PortalStore } from './portalStore';
import AdminStore from './adminStore';
import IssuerStore from './issuerStore';
import WalletStore from './walletStore';
import TransactionsStore from './transactionsStore';
import DividendStore from './dividendStore';
import VereficationStore from './vereficationStore';

class RootStore {
  loaderStore: LoaderStore;

  headerStore: HeaderStore;

  assetStore: AssetStore;

  dividendStore: DividendStore;

  userInfoStore: UserInfoStore;

  vereficationStore: VereficationStore;

  checkoutStore: CheckoutStore;

  portalStore: PortalStore;

  adminStore: AdminStore;

  issuerStore: IssuerStore;

  walletStore: WalletStore;

  transactionsStore: TransactionsStore;

  constructor() {
    this.loaderStore = new LoaderStore(this);
    this.userInfoStore = new UserInfoStore(this);
    this.vereficationStore = new VereficationStore(this);
    this.headerStore = new HeaderStore(this);
    this.assetStore = new AssetStore(this);
    this.dividendStore = new DividendStore(this);
    this.checkoutStore = new CheckoutStore(this);
    this.portalStore = new PortalStore(this);
    this.adminStore = new AdminStore(this);
    this.issuerStore = new IssuerStore(this);
    this.walletStore = new WalletStore(this);
    this.transactionsStore = new TransactionsStore(this);
    makeAutoObservable(this);
  }
}

const Context = createContext<RootStore>({} as RootStore);

const useStores = () => useContext(Context);

export { useStores, Context };

export default RootStore;
