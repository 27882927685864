import ClipboardCopy from '../../checkout-payments/clipboard-copy/clipboardCopy';
import c from './style.module.scss';

function AssetDeposit() {
  return (
    <div className={c.wrapper}>
      <div className={`${c.block} ${c.left}`}>
        <div className={c.title}>Total Deposit</div>
        <div className={c.info}>
          <div className={c.text}>2000 USDC</div>
          <div className={c.buttons}>
            <button className="defaultButton mainColor">Distribute</button>
          </div>
        </div>
      </div>
      <div className={`${c.block} ${c.right}`}>
        <div className={c.title}>Wallet</div>
        <div className={c.info}>
          <div className={c.wallet}>
            jhko397hdos03fdg3safrw32j20s3
            <ClipboardCopy text="jhko397hdos03fdg3safrw32j20s3" />
          </div>
          <div className={c.buttons}>
            <button className="defaultButton mainColor">Deposit</button>
            <button className="defaultButton">Withdraw</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssetDeposit;
