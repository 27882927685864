import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import Back from '../../../shared/assets/icons/arrow-back.svg';
import PortalTokens from '../../../components/admin-portals/admin-tokens/portal-tokens';

const AdminPortalTokens = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Admin Assets | TokenSuite</title>
      </Helmet>
      <button className={c.backBtn} onClick={() => navigate(-1)}>
        <img src={Back} alt="" />
        Back
      </button>
      <h2>Tokens on portal</h2>
      <PortalTokens />
    </div>
  );
};

export default AdminPortalTokens;
