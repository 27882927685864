import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';

function ProtectedRoute() {
  const location = useLocation();
  const token = localStorage.getItem('accessToken');
  if (!token) {
    return <Navigate to="/login?expired=true" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default ProtectedRoute;
