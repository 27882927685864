import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { observer } from 'mobx-react-lite';
import { DateInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import closeIcon from '../../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import { useStores } from '../../../store';

function StartDividendDate() {
  const { dividendStore } = useStores();

  const [startDate, setDate] = useState<Date>(new Date());

  const [errors, setErrors] = useState<string[]>([]);
  const [dateError, setDateError] = useState(false);

  const handleClose = () => {
    setErrors([]);
    dividendStore.closeDateModal();
  };

  const handleSendClick = () => {
    dividendStore.sendNewDate(startDate);
    handleClose();
  };

  return (
    <Dialog open={dividendStore.isDateModalOpen} onClose={handleClose}>
      <div className={c.dialog}>
        <div className={c.title}>Set Start Date</div>
        <Button onClick={handleClose} className={c.closeIcon}>
          <img src={closeIcon} alt="" />
        </Button>
        <DateInput fullWidth value={startDate} changeValue={setDate} label="Set Date" setError={setDateError} />
        {errors &&
          errors.map((error, index) => {
            return (
              <div className={c.errorText} key={index}>
                {error}
              </div>
            );
          })}
        <div className={c.actions}>
          <button onClick={handleSendClick} className={c.submitButton} disabled={dateError}>
            Set
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default observer(StartDividendDate);
