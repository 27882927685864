import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { Helmet } from 'react-helmet-async';
import { columns } from './meta';
import BrokerRow from './broker-row';
import c from './style.module.scss';

import tokenInstance from '../../../shared/services/API/tokenApi';
import { IBrokerRow } from '../../../shared/interfaces/issuer/issuer.interfaces';
import BrokerContracts from '../../../components/active-contracts/active-contracts';

const BrokersTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [brokers, setBrokers] = useState<IBrokerRow[]>([]);

  const loadItems = async () => {
    setIsLoading(true);
    const { data } = await tokenInstance.get('users/brokers/issuer');
    setBrokers(data.brokers);
    setIsLoading(false);
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      <Helmet>
        <title>Brokers | TokenSuite</title>
      </Helmet>
      <div className={c.title}>Brokers</div>
      <div className={c.tableWrapper}>
        <TableContainer component={Paper} className={c.wrapper}>
          <Table aria-label="simple table">
            <TableHead className={c.tableHead}>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                    <div className={`${column.left ? `${c.flexHead} ${c.left}` : `${c.flexHead}`}`}>{column.label}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={c.body}>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={9} className={c.loading} align="center">
                    <Spinner single />
                  </TableCell>
                </TableRow>
              )}
              {brokers.length > 0 && brokers.map(broker => <BrokerRow brokerRow={broker} key={broker.id} />)}
              {!brokers.length && !isLoading && (
                <TableRow>
                  <TableCell className={c.emptyBlock} colSpan={9}>
                    Brokers don`t invite users to you portal
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <BrokerContracts />
    </>
  );
};

export default BrokersTable;
