import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, OutlinedInput, Stack } from '@mui/material';
import React from 'react';
import AcceptActionDialog, { AcceptActionDialogProps } from '../../UI/dialogs/accept-action/accept-action-dialog';

interface IPortalAddContractModal extends Omit<AcceptActionDialogProps, 'onAgreeHandler' | 'children'> {
  onAddContractHandler: (issuerId: string, templateId: string) => Promise<void>;
  menuItems: Array<{ value: string; text: string }>;
  placeholder: string;
}

const PortalAddContractModal: React.FC<IPortalAddContractModal> = ({
  onAddContractHandler,
  menuItems,
  content,
  open,
  setOpen,
  title,
  actionButtonText,
  isActionLoading,
  placeholder,
}) => {
  const [selectValue, setSelectValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  const onChangeHandler = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
  };

  const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onAcceptActionHandler = async () => {
    if (!selectValue.trim() || !inputValue.trim()) return;
    await onAddContractHandler(selectValue, inputValue);
  };
  return (
    <AcceptActionDialog
      open={open}
      setOpen={setOpen}
      title={title}
      content={content}
      isActionLoading={isActionLoading}
      actionButtonText={actionButtonText}
      onAgreeHandler={onAcceptActionHandler}
    >
      <Stack gap={20}>
        <FormControl sx={{ mt: 8 }}>
          <InputLabel>{placeholder}</InputLabel>
          <Select displayEmpty value={selectValue} onChange={onChangeHandler} label={placeholder}>
            {menuItems.length > 0 &&
              menuItems.map(({ value, text }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <OutlinedInput onChange={onChangeInputHandler} value={inputValue} placeholder="Input template id" />
      </Stack>
    </AcceptActionDialog>
  );
};

export default PortalAddContractModal;
