import { AlertProps, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';
import MuiAlert from '@mui/material/Alert';
import { ISuccessSnackbar } from './types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SuccessSnackbar: FC<ISuccessSnackbar> = ({ setOpen, open, autoHideDuration, message }) => {
  const onCloseHandler = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={onCloseHandler}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onCloseHandler} action={action}>
      <Alert onClose={onCloseHandler} severity="success" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessSnackbar;
