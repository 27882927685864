import React, { useCallback, useMemo } from 'react';
import { Editable, withReact, useSlate, Slate, useSelected } from 'slate-react';
import isUrl from 'is-url';
import { Editor, Range, Transforms, createEditor, Element as SlateElement, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import TitleIcon from '@mui/icons-material/Title';
import c from './style.module.scss';
import { CustomEditor, LinkElement } from '../../../shared/types';

interface TextInputProps {
  value: string;
  changeValue: any;
  placeholder: string;
  label?: string;
  fullWidth?: boolean;
  index?: number | null;
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];

const FormatEditor = (props: TextInputProps) => {
  const { value, changeValue, placeholder, label, index = null, fullWidth = false } = props;

  const setInitial = (initial: any) => {
    try {
      return JSON.parse(initial);
    } catch {
      return [
        {
          type: 'paragraph',
          children: [{ text: initial }],
        },
      ];
    }
  };

  const initialValue: Descendant[] = useMemo(() => setInitial(value), [value]);

  const renderElement = useCallback((elementProps: any) => <Element {...elementProps} />, []);
  const renderLeaf = useCallback((elementProps: any) => <Leaf {...elementProps} />, []);
  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);

  return (
    <div className={fullWidth ? `${c.inputBlock} ${c.fullwidth}` : `${c.inputBlock}`}>
      {label && <div className={c.inputLabel}>{label}</div>}
      <Slate
        editor={editor}
        value={initialValue}
        onChange={formattedText => {
          const isAstChange = editor.operations.some(op => op.type !== 'set_selection');
          if (isAstChange) {
            const content = JSON.stringify(formattedText);
            changeValue(content, index);
          }
        }}
      >
        <div className={c.toolBar}>
          <AddLinkButton />
          <RemoveLinkButton />
          <MarkButton format="bold" icon={<FormatBoldIcon />} />
          <MarkButton format="italic" icon={<FormatItalicIcon />} />
          <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
          <BlockButton format="heading-one" icon={<TitleIcon />} />
          <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
          <BlockButton format="bulleted-list" icon={<FormatListBulletedIcon />} />
          <BlockButton format="left" icon={<FormatAlignLeftIcon />} />
          <BlockButton format="center" icon={<FormatAlignJustifyIcon />} />
          <BlockButton format="right" icon={<FormatAlignRightIcon />} />
        </div>
        <Editable className={c.input} renderElement={renderElement} renderLeaf={renderLeaf} placeholder={placeholder} />
      </Slate>
    </div>
  );
};

const withInlines = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;
  editor.isInline = element => ['link'].includes(element.type) || isInline(element);
  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };
  editor.insertData = data => {
    const text = data.getData('text/plain');
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };
  return editor;
};

const toggleBlock = (editor: CustomEditor, format: string) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    let typeValue = format;
    if (isActive) {
      typeValue = 'paragraph';
    } else if (isList) {
      typeValue = 'list-item';
    }
    newProperties = {
      type: typeValue,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: CustomEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: Editor, format: string, blockType: 'type' | 'align') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    }),
  );
  return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor) as any;
  return marks ? marks[format] === true : false;
};

const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const isLinkActive = (editor: CustomEditor) => {
  const [link] = Array.from(
    Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    }),
  );
  return !!link;
};

const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const Element = (props: { attributes: any; children: any; element: any }) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />;
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: { attributes: any; children: any; leaf: any }) => {
  if (leaf.text === '') {
    children = (
      <span {...attributes} style={{ paddingLeft: '0.1px' }}>
        {children}
      </span>
    );
  }
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: { format: string; icon: JSX.Element }) => {
  const editor = useSlate();
  return (
    <button
      className={`${
        isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
          ? `${c.active} ${c.btn}`
          : `${c.btn}`
      }`}
      onClick={(event: any) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </button>
  );
};

const MarkButton = ({ format, icon }: { format: string; icon: JSX.Element }) => {
  const editor = useSlate();
  return (
    <button
      className={`${isMarkActive(editor, format) ? `${c.active} ${c.btn}` : `${c.btn}`}`}
      onClick={(event: any) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </button>
  );
};

const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: '0px' }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const LinkComponent = ({ attributes, children, element }: { attributes: any; children: any; element: any }) => {
  const selected = useSelected();
  return (
    <a {...attributes} href={element.url} className={`${selected ? `${c.selected}` : ``}`}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};

const AddLinkButton = () => {
  const editor = useSlate();
  return (
    <button
      className={`${isLinkActive(editor) ? `${c.active} ${c.btn}` : `${c.btn}`}`}
      onClick={event => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:'); // eslint-disable-line no-alert
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <InsertLinkIcon />
    </button>
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();
  return (
    <button
      className={`${isLinkActive(editor) ? `${c.active} ${c.btn}` : `${c.btn}`}`}
      onClick={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <LinkOffIcon />
    </button>
  );
};

export default FormatEditor;
