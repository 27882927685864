import { Button } from '@mui/material';
import DefaultButton from '../../buttons/default-button';
import c from './style.module.scss';
import closeIcon from '../../../../shared/assets/icons/closeIcon.svg';

interface IProps {
  setClose: () => void;
}

export const SuccessSignModal = ({ setClose }: IProps) => {
  return (
    <>
      <Button
        onClick={() => {
          setClose();
        }}
        className={c.closeIcon}
      >
        <img src={closeIcon} alt="" />
      </Button>
      <div className={c.text}>You are all set to start the initial sale.</div>
      <div className={c.contracts}>
        <div className={c.alert}>
          <DefaultButton
            variant="contained"
            onClick={() => {
              setClose();
            }}
            className={c.submitButton}
          >
            Close
          </DefaultButton>
        </div>
      </div>
    </>
  );
};
