import React, { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import { observer } from 'mobx-react-lite';
import Lock from '../../../shared/assets/asset-view/checkout-lock.svg';
import c from './styles.module.scss';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';
import { useStores } from '../../../store/index';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FAEDED',
    color: '#000',
    maxWidth: 384,
    fontSize: 15,
    border: '1px solid #F36969',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '6px 10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 24,
    width: 24,
    '&::before': {
      border: '1px solid #F36969',
      backgroundColor: '#FAEDED',
      boxSizing: 'border-box',
    },
  },
}));

function AssetCheckout(props: { toggleCheckout: () => void; checkout: boolean }) {
  const { toggleCheckout, checkout } = props;

  const {
    assetStore: { currentAsset },
    checkoutStore,
  } = useStores();

  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null);

  const [minimumTooltip, setMinimumTooltip] = useState(false);

  const [sliderValue, setSliderValue] = useState(String(currentAsset.minAmount));

  const sliderHandler = (event: any, value: number | number[]) => {
    setSliderValue(value.toString());
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      typeof +e.target.value === 'number' &&
      !Number.isNaN(+e.target.value) &&
      +e.target.value <= (currentAsset.maxAmount - +currentAsset.paymentTokenRaised) / +currentAsset.unitPrice
    ) {
      setSliderValue(e.target.value);
    }
  };

  const backHandler = () => {
    setSliderValue(currentAsset.minAmount.toString());
    toggleCheckout();
  };

  useEffect(() => {
    if (+sliderValue < +currentAsset.minAmount) {
      setMinimumTooltip(true);
    } else setMinimumTooltip(false);
  }, [sliderValue, currentAsset.minAmount]);

  const checkoutHandler = () => {
    if (+sliderValue < +currentAsset.minAmount) {
      return;
    }
    checkoutStore.resetContractData();
    localStorage.setItem('asset', currentAsset.id);
    localStorage.setItem('tokensToPurchase', sliderValue);
    navigate(`/checkout/order/${currentAsset.id}/${sliderValue}`);
  };

  useEffect(() => {
    if (localStorage.getItem('asset') === currentAsset.id) {
      setSliderValue(localStorage.getItem('tokensToPurchase') || currentAsset.minAmount.toString());
    }
  }, [currentAsset.id, currentAsset.minAmount]);

  return (
    <div className={`${c.assetCheckoutWrapper} ${checkout ? `${c.toCheckout}` : `${c.notCheckout}`}`}>
      <div className={c.assetTopRight}>
        <div className={c.top}>
          <div className={c.title}>How many digital shares are you buying?</div>
          <div className={c.tokens}>
            <CustomTooltip
              arrow
              open={minimumTooltip}
              placement="top-start"
              title={<div className={c.minimumTooltip}>Minimum Required {currentAsset.minAmount}</div>}
            >
              <input
                className={`${c.amount} ${minimumTooltip ? `${c.red}` : ''}`}
                value={sliderValue}
                ref={inputRef}
                onChange={inputHandler}
              />
            </CustomTooltip>
            <div className={c.type}>
              {currentAsset.tickerSymbol ? `${currentAsset.tickerSymbol.toUpperCase()}` : ''} Tokens
            </div>
          </div>
          <div className={c.slider}>
            <Slider
              step={+currentAsset.maxAmount < 15000 ? +currentAsset.minAmount : 5000}
              min={+currentAsset.minAmount}
              max={(currentAsset.maxAmount - +currentAsset.paymentTokenRaised) / +currentAsset.unitPrice}
              value={+sliderValue}
              onChange={sliderHandler}
            />
            <div className={c.remain}>
              {addComma(String((currentAsset.maxAmount - +currentAsset.paymentTokenRaised) / +currentAsset.unitPrice))}{' '}
              {currentAsset.tickerSymbol ? `${currentAsset.tickerSymbol.toUpperCase()}` : ''} tokens remaining
            </div>
          </div>
        </div>
        <div className={c.bottom}>
          <div className={c.total}>
            Total:
            <span className={c.amount}>
              {CURRENCY_SYMBOLS[currentAsset.currency]}
              {addComma(String(+sliderValue * +currentAsset.unitPrice))}
            </span>
          </div>
          <div className={c.checkout}>
            <button className={c.back} onClick={backHandler}>
              {'< '} Back
            </button>
            <button className={c.begin} onClick={checkoutHandler}>
              <img src={Lock} alt="" />
              Proceed to Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(AssetCheckout);
