import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import DefaultButton from '../../buttons/default-button';
import tokenInstance from '../../../../shared/services/API/tokenApi';
import { AgreementNames } from '../../../../shared/services/sign-documents/types';

const FortressDocumentsModal = ({ openModal, onClose }: { openModal: boolean; onClose: () => void }) => {
  const navigate = useNavigate();
  const [confirmPhrase, setConfirm] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCloseClick = () => {
    if (loading) {
      return;
    }
    navigate(-1);
  };

  const handleSubmitClick = () => {
    setLoading(true);
    tokenInstance
      .put('agreements', {
        agreements: [
          AgreementNames.termsOfUse,
          AgreementNames.eSign,
          AgreementNames.fortressUSAPatriotActDisclosure,
          AgreementNames.fortressTrustConsumerDisclosures,
          AgreementNames.fortressIntegratorPrivacyPolicy,
        ],
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className={c.wrapper}>
      <Dialog open={openModal} onClose={handleCloseClick}>
        <div className={c.dialog}>
          <Button
            onClick={() => {
              handleCloseClick();
            }}
            className={c.closeIcon}
          >
            <img src={closeIcon} alt="" />
          </Button>
          <div className={c.title}>Before KYC</div>
          <p className="text">Please confirm that:</p>
          <ul className={c.text}>
            <li>
              You have read and agree to the{' '}
              <a
                href="https://fortresstrust.com/disclosures-consumer"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Consumer Disclosures
              </a>
              ,{' '}
              <a href="https://fortresstrust.com/disclosures-e-sign" target="_blank" rel="noreferrer" className="link">
                E-Sign Disclosure
              </a>{' '}
              and{' '}
              <a href="https://fortresstrust.com/disclosures" target="_blank" rel="noreferrer" className="link">
                USA Patriot Act Disclosure
              </a>
            </li>
            <li>
              You have read and accepted the{' '}
              <a href="https://fortress.io/terms-of-use/" target="_blank" rel="noreferrer" className="link">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href="https://fortress.io/privacy-policy/" target="_blank" rel="noreferrer" className="link">
                Legal & Privacy
              </a>
            </li>
          </ul>
          <p className={c.smallTitle}>Please type &#34;I approve&#34;.</p>
          <TextField
            type="text"
            onChange={e => {
              setConfirm(e.target.value);
            }}
            value={confirmPhrase}
            className={c.input}
            margin="normal"
            placeholder="Enter confirmation"
            inputProps={{
              maxLength: 9,
            }}
          />
          <div className={c.actions}>
            <DefaultButton variant="contained" onClick={handleCloseClick} className={c.cancelButton}>
              Close
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={handleSubmitClick}
              disabled={confirmPhrase !== 'I approve' || loading}
              className={c.submitButton}
            >
              Continue {loading ? 'Loading...' : 'Continue'}
            </DefaultButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FortressDocumentsModal;
