import React, { useEffect } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { flowResult } from 'mobx';
import c from './style.module.scss';
import { MONTH_NAMES } from '../../../shared/config/constants';
import { ILockupTransaction, ITransactionItem } from '../../../shared/interfaces/userInfo.interface';
import { useStores } from '../../../store';

function LockupTransactions() {
  const { id } = useParams();
  const { userInfoStore } = useStores();

  useEffect(() => {
    if (!id) return;
    flowResult(userInfoStore.getUserLockupTransactions(id as string));
  }, [userInfoStore, id]);

  const pad = (num: number) => `0${num}`.slice(-2);

  const getTimeFromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${pad(hours)}:${pad(minutes)}`;
  };

  const getDateFromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = MONTH_NAMES[date.getMonth()];
    const days = date.getDate();
    return `${days} ${month}, ${year}`;
  };

  if (userInfoStore.currentTransactions.length === 0) return null;

  return (
    <div className={c.wrapper}>
      <div className={c.topPanel}>
        <div className={c.text}>Lockup Transactions</div>
      </div>
      <div className={c.table}>
        <div className={c.titleRow}>
          <div className={c.columnTitle}>Sale Date</div>
          <div className={c.columnTitle}>Lockup End</div>
          <div className={c.columnTitle}>Amount</div>
        </div>
        <div className={c.profilesRows}>
          <div className={c.loadingCell}>
            {userInfoStore.isLoading && (
              <div className={c.loading}>
                <Spinner single />
              </div>
            )}
          </div>
          {userInfoStore.currentTransactions.map((transaction: ILockupTransaction) => {
            return transaction.transactions.map((transactionRow: ITransactionItem) => (
              <div className={c.profileRow} key={transaction.tokenId}>
                <div className={c.column}>
                  <span className={c.mobileDescription}>Sale Date - </span>
                  <div className={c.date}>{getDateFromTimestamp(+transactionRow.saleTime)}</div>
                  <div className={c.time}>{getTimeFromTimestamp(+transactionRow.saleTime)}</div>
                </div>
                <div className={c.column}>
                  <span className={c.mobileDescription}>Lockup End - </span>
                  <div className={c.date}>{getDateFromTimestamp(+transactionRow.lockupEndTime)}</div>
                  <div className={c.time}>{getTimeFromTimestamp(+transactionRow.lockupEndTime)}</div>
                </div>
                <div className={c.column}>
                  <span className={c.mobileDescription}>Amount - </span>
                  <div className={c.text}>
                    {+transactionRow.amount} {transaction.tokenSymbol}
                  </div>
                </div>
                <div className={`${c.column} ${c.right}`}>
                  {+transactionRow.lockupEndTime < Math.floor(Date.now() / 1000) ? (
                    <button
                      className="defaultButton mainColor"
                      onClick={() => {
                        userInfoStore.unlockTransaction(
                          transaction.tokenAddress,
                          transactionRow.position,
                          id as string,
                        );
                      }}
                    >
                      Unlock Transaction
                    </button>
                  ) : (
                    <button
                      className="defaultButton"
                      onClick={() => {
                        userInfoStore.openLockupModal(
                          transaction.tokenAddress,
                          transactionRow.position,
                          +transactionRow.lockupEndTime,
                        );
                      }}
                    >
                      Change LockUp
                    </button>
                  )}
                </div>
              </div>
            ));
          })}
        </div>
      </div>
    </div>
  );
}

export default observer(LockupTransactions);
