import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import React, { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ProfileData } from './investorsAllowList';
import copyDataInBuffer from '../../../shared/lib/copy-data-in-buffer';
import c from './styles.module.scss';
import copyMail from '../../../shared/assets/icons/copyMail.svg';

interface IAllowListRowProps {
  row: ProfileData;
  isLoading: boolean;
}

const AllowListRow: FC<IAllowListRowProps> = ({ row, isLoading }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const onCloseMenuHandler = () => {
    setAnchorEl(null);
  };

  const onOpenMenuHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onRedirectToViewDetailsHandler = (id: string) => {
    navigate(`/investors/investor-details/${id}`);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      {/* {columns.map((column) => {
      const value = row.firstName;
      return (
        <TableCell key={column.id} align="center">
          {column.format && typeof value === 'number'
            ? column.format(row)
            : value}
        </TableCell>
      );
    })} */}
      <TableCell>
        <div className={c.name}>
          {row.firstName} {row.lastName}
        </div>
        <div className={c.email}>
          {row.email}
          <button
            onClick={() => {
              copyDataInBuffer(row.email);
            }}
          >
            <img src={copyMail} alt="copyMail" />
          </button>
        </div>
      </TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="right">
        <IconButton disabled={isLoading} onClick={onOpenMenuHandler}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={onCloseMenuHandler}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => onRedirectToViewDetailsHandler(row.id)}>
            <FormattedMessage id="investorsPage.menu.viewDetails" />
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default AllowListRow;
