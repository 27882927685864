import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage, useIntl } from 'react-intl';
import documentIcon from '../../shared/assets/createAsset/documentIcon.svg';
import closeIcon from '../../shared/assets/createAsset/closeIcon.svg';
import upload from '../../shared/assets/createAsset/upload.svg';
import c from './style.module.scss';

const boldFragment = (chunks: any) => <b>{`${chunks}`}</b>;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 26214400; // 25MB
const DEFAULT_MAX_FILE_SIZE_IN_BYTES_KYC = 10485760; // 10MB

export interface IFiles {
  [fileName: string]: File;
}

function formatBytes(bytes: number, decimals = 2) {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function IdentityFilesUploader({
  documents,
  setDocuments,
  cap,
  isKycPage = false,
}: {
  documents: any;
  setDocuments: any;
  cap: number;
  isKycPage?: boolean;
}) {
  const intl = useIntl();
  const [isErrorShown, setIsErrorShown] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
    desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap }),
  });

  const showError = (message: { head: string; desc: string }) => {
    setErrorMessage(message);
    setIsErrorShown(true);
  };

  const fileInputField = useRef(null);

  const addNewFiles = async (newFiles: any) => {
    const allowedTypes = ['pdf', 'jpg', 'jpeg', 'png'];
    const newDocs = [];
    for (const file of newFiles) {
      if (allowedTypes.indexOf(file.name.split('.').reverse()[0]) === -1) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.notSupported' }),
        });
        return;
      }
      if (documents.length + newFiles.length > cap) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
          desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap }),
        });
        return;
      }
      if (file.size >= (isKycPage ? DEFAULT_MAX_FILE_SIZE_IN_BYTES_KYC : DEFAULT_MAX_FILE_SIZE_IN_BYTES)) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: isKycPage ? 'uploader.errors.maxSize2' : 'uploader.errors.maxSize' }),
        });
        return;
      }
      newDocs.push(file);
    }
    setDocuments([...documents, ...newDocs]);
  };

  const removeFile = (index: number) => {
    const newDocuments = documents.filter((item: any, itemIndex: number) => {
      return itemIndex !== index;
    });
    setDocuments(newDocuments);
  };

  const handleNewFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: any | null = event.target.files;
    if (newFiles.length) {
      addNewFiles(newFiles);
    }
  };

  const [inDropZone, setDropZone] = useState(false);
  const handleDragEnter = () => {
    setDropZone(true);
  };
  const handleDragLeave = () => {
    setDropZone(false);
  };
  const handleDrop = () => {
    setDropZone(false);
  };
  return (
    <div className={c.uploader}>
      <div className={`${c.upload} ${inDropZone ? c.drag : ''}`}>
        <div className={`${c.error} ${isErrorShown ? '' : c.hidden}`}>
          <div className={c.container}>
            <header>{errorMessage.head}</header>
            <p>{errorMessage.desc}</p>
            <IconButton className={c.close} onClick={() => setIsErrorShown(false)}>
              x
            </IconButton>
          </div>
        </div>
        <input
          id="file-input"
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          title=""
          value=""
          multiple
          accept="application/pdf,image/jpeg,image/png,image/x-eps,image/jpg"
        />
        <img src={upload} alt="" />
        <p className={c.text}>
          <FormattedMessage
            id="uploader.text.header"
            values={{
              b: boldFragment,
            }}
          />
        </p>
        <label htmlFor="file-input" className={c.browse}>
          <FormattedMessage id="uploader.button.upload.document" />
        </label>
      </div>
      <div className={c.listFiles}>
        {documents.map((item: any, index: number) => {
          return (
            <div className={c.file} key={index}>
              <div className={c.left}>
                <img src={documentIcon} alt="documentIcon" />
                <div className={c.name}>{item.name}</div>
                <span>{formatBytes(+item.size)}</span>
              </div>
              <div className={c.right}>
                <IconButton className={c.delete} onClick={() => removeFile(index)}>
                  <img src={closeIcon} alt="X" />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default IdentityFilesUploader;
