import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import AcceptActionWithInput from '../../UI/dialogs/accept-action-with-input/accept-action-with-input';
import tokenService from '../../../shared/services/token/tokenService';

interface MintTokensAdminModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tokenId: string;
}

const MintTokensAdminModal: FC<MintTokensAdminModalProps> = ({ setOpen, open, tokenId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onMintHandler = async (amount: string) => {
    if (!amount || +amount <= 0) return;
    setIsLoading(true);
    try {
      await tokenService.mintTokenByAdmin(tokenId, +amount);
      setIsLoading(false);
      setOpen(false);
    } catch {
      setIsLoading(false);
    }
  };
  return (
    <AcceptActionWithInput
      onAgreeHandler={onMintHandler}
      placeholder="Input amount of tokens"
      open={open}
      setOpen={setOpen}
      title="Mint tokens"
      content="Input amount of tokens for minting"
      actionButtonText={isLoading ? 'Minting...' : 'Mint'}
      isActionLoading={isLoading}
      inputType="number"
      min="0"
    />
  );
};

export default MintTokensAdminModal;
