import { useIntl } from 'react-intl';
import { TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import c from '../styles.module.scss';
import { useStores } from '../../../store';
import CheckboxInput from '../../checkout-payments/checkbox-input/checkboxInput';

function EditLocationBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [location, setLocation] = useState(assetStore.currentAsset.location);
  const [lat, setLat] = useState(assetStore.currentAsset.lat);
  const [lng, setLng] = useState(assetStore.currentAsset.lng);
  const [showMap, setShow] = useState(assetStore.currentAsset.showMap);

  const submit = () => {
    flowResult(assetStore.saveLocation(id, location, lat, lng, showMap));
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="mainInfo">
        Location
      </div>
      <div className={c.inputsBlock}>
        <TextInput
          value={location}
          changeValue={setLocation}
          label={intl.formatMessage({ id: 'createAssetPage.fields.location.label' })}
          placeholder={intl.formatMessage({ id: 'createAssetPage.fields.location.placeholder' })}
          type="text"
          fullWidth
        />
        <TextInput
          value={lat}
          changeValue={setLat}
          label={intl.formatMessage({ id: 'createAssetPage.fields.lat.label' })}
          placeholder={intl.formatMessage({ id: 'createAssetPage.fields.lat.placeholder' })}
          type="number"
        />
        <TextInput
          value={lng}
          changeValue={setLng}
          label={intl.formatMessage({ id: 'createAssetPage.fields.lng.label' })}
          placeholder={intl.formatMessage({ id: 'createAssetPage.fields.lng.placeholder' })}
          type="number"
        />
        <label className={c.checkbox} htmlFor="showMap">
          <CheckboxInput
            disabled={false}
            id="showMap"
            checked={showMap}
            label=""
            checkBoxHandler={() => {
              setShow(!showMap);
            }}
          />
          <span className={c.label}>Show Map</span>
        </label>
      </div>
      <button className={c.submitButton} onClick={submit} disabled={!location.trim()}>
        Save
      </button>
    </div>
  );
}

export default observer(EditLocationBlock);
