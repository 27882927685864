import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import closeIcon from '../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import { EMAIL_REGEX } from '../../shared/config/constants';
import tokenInstance from '../../shared/services/API/tokenApi';

function InviteAdminModal({ refreshList }: { refreshList: () => void }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [emailError, setEmailError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendClick = () => {
    tokenInstance
      .post('users/admins', {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email,
      })
      .then(() => {
        refreshList();
        setEmail('');
        setLastName('');
        setFirstName('');
        setOpen(false);
      })
      .catch(e => setEmailError(e.response.data.message));
  };

  return (
    <div className={c.wrapper}>
      <button onClick={handleClickOpen} className={c.submitButton}>
        Invite Admin
      </button>
      <Dialog open={open} onClose={handleClose}>
        <div className={c.dialog}>
          <div className={c.title}>Invite an Admin</div>
          <Button onClick={handleClose} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <p className={c.text}>First Name</p>
          <TextField
            type="text"
            onChange={e => {
              setFirstName(e.target.value);
            }}
            value={firstName}
            className={c.input}
            margin="normal"
            placeholder="Enter name"
            inputProps={{
              maxLength: 35,
            }}
          />
          <p className={c.text}>Last Name</p>
          <TextField
            type="text"
            onChange={e => {
              setLastName(e.target.value);
            }}
            value={lastName}
            className={c.input}
            margin="normal"
            placeholder="Enter name"
            inputProps={{
              maxLength: 35,
            }}
          />
          <p className={c.text}>E-mail</p>
          <TextField
            type="email"
            onChange={e => {
              setEmail(e.target.value.trim());
              setEmailError('');
            }}
            value={email}
            autoComplete="email"
            className={c.input}
            margin="normal"
            placeholder="name@example.com"
            inputProps={{
              maxLength: 127,
            }}
            helperText={emailError}
          />
          <div className={c.actions}>
            <button
              onClick={handleSendClick}
              className={c.submitButton}
              disabled={!firstName.trim() || !lastName.trim() || !EMAIL_REGEX.test(email)}
            >
              Send Invite
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default InviteAdminModal;
