import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import c from './style.module.scss';
import Copy from '../../../../shared/assets/icons/copyMail.svg';
import Dots from '../../../../shared/assets/icons/verticalMenuDots.svg';
import { TableRowProps } from './table-row.types';

const TableRow = ({ mainTitle, subTitle, menuItems, label, isDemo }: TableRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onMenuClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const copySubTitleHandler = (vaule: string) => {
    if (!navigator) return;
    navigator.clipboard.writeText(vaule);
  };

  return (
    <div className={c.wrapper}>
      <div className={c.titles}>
        <div>{mainTitle}</div>
        <div className={c.subTitle}>
          {Array.isArray(subTitle) ? (
            <div className={c.values}>
              {subTitle.map(text => (
                <div key={text}>
                  {text}
                  <IconButton onClick={() => copySubTitleHandler(text)}>
                    <img alt="" src={Copy} />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <>
              {subTitle}
              <IconButton onClick={() => copySubTitleHandler(subTitle)}>
                <img alt="" src={Copy} />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <div className={c.label}>
        {isDemo ? (
          <div className={`${c.labelBlock} ${c.warning}`}>DEMO PORTAL</div>
        ) : (
          <div className={c.labelBlock}>{label}</div>
        )}
      </div>
      <div className={c.menuDots}>
        <IconButton onClick={onMenuClickHandler}>
          <img src={Dots} alt="" />
        </IconButton>
      </div>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map(item => (
          <MenuItem key={item.text} onClick={item.handler} disabled={item.disabled}>
            {item.children ? item.children() : item.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TableRow;
