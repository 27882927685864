import React from 'react';
import AssetDeposit from '../../../components/distribution-page/asset-deposit/assetDeposit';
import { DividendHistory } from '../../../components/distribution-page/dividendHistory/dividendHistory';

const DividendDistributionPage = () => {
  return (
    <>
      <AssetDeposit />
      <DividendHistory />
    </>
  );
};

export default DividendDistributionPage;
