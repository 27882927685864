interface IActivity {
  id?: string;
  userId: string;
  fullName: string;
  actionType: string;
  portalId: string;
  documentId: string;
  affectedUserId: string;
  affectedUserFullName: string;
  description: string;
  timestamp: string;
}

interface ILockupTransaction {
  tokenId: string;
  tokenAddress: string;
  tokenSymbol: string;
  transactions: ITransactionItem[];
}

interface INewLockup {
  tokenAddress: string;
  position: number;
  newLockupEndTime: number;
}

interface ITransactionItem {
  transactionType: string;
  amount: string;
  saleTime: string;
  lockupEndTime: string;
  position: number;
}

interface Portal {
  domains: Array<{ domain: string }>;
  portalName: string;
}

interface IAssetItem {
  id: string;
  name: string;
  previewImage: {
    url: string;
  };
  portal: Portal | null;
  custodialAccount: {
    id?: string;
  };
}

interface IDividendAssetItem {
  id: string;
  name: string;
  tickerSymbol: string;
  previewImage: {
    url: string;
  };
  dividendsDistributionStart: string | null;
}

interface IInvestorDividend {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  tokensAmount: number;
  tokenAllocation: string;
  dividends: string;
}

export enum Domicile {
  USCitizen = 'U.S. Citizen',
  USResident = 'U.S. Resident',
  NonResident = 'non-resident',
}

export enum KycProvider {
  'North Capital' = 'northCapital',
  'Fortress' = 'fortress',
}

export enum KycProviderResponse {
  'northCapital' = 'North Capital',
  'fortress' = 'Fortress',
}

export enum PersonalIdentityDocumentType {
  'Drivers License' = 'license',
  'Identity Card' = 'identificationCard',
  'Passport' = 'passport',
}

export enum KybDocumentType {
  'Other' = 'other',
  'Proof of Address' = 'proofOfAddress',
  'Proof of Company Formation' = 'proofOfCompanyFormation',
}

export enum LegalStructureType {
  'C Corporation' = 'ccorp',
  'Limited Liability Company' = 'llc',
  'Partnership' = 'partnership',
  'Nonprofit' = 'nonprofit',
  'S Corporation' = 'scorp',
  'Sole Proprietorship' = 'soleprop',
  'Other' = 'other',
}

export enum EntityType {
  RevocableTrust = 'Revocable Trust',
  IrrevocableTrust = 'Irrevocable Trust',
  LimitedPartnership = 'Limited Partnership',
  LLC = 'LLC',
  Corporation = 'Corporation',
}

interface UserInfoProperty {
  socialSecurityNumber: string | null;
  primState: null | string;
}

export interface UserVerifyData {
  partyId: string;
  kycStatus: string;
  amlStatus: string;
  accountId: null | string;
  accreditedStatus: string;
  aiRequestStatus: null | string;
  airequestId: null | string;
}

export interface AccountStatus {
  status: string;
  activated: boolean;
  description: string;
}

export interface UserEntity {
  domicile: Domicile;
  entityName: string;
  entityType: EntityType;
  primCountry: string;
  primAddress1: string;
  primCity: string;
  primState: string;
  primZip: string;
}

interface UserFile {
  id: string;
  description: string;
  file: {
    id: string;
    url: string;
    bucketName: string;
    documentType: string;
    createdAt: string;
    updatedAt: string;
  };
}

interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  userType: string | null;
  email: string;
  wallet: any | null;
  userInfo: UserInfoProperty;
  userVerifyData: UserVerifyData;
  accountStatus: AccountStatus;
  userEntity: UserEntity;
  userFiles: UserFile[] | null;
}

interface BrokerInfo {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  portalId: string;
  validated?: boolean;
  crd: string;
  firmName: string;
  firmCRD: string;
  firmSEC: string;
}

interface IVereficationStatus {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  userType: string | null;
  email: string;
  wallet: any | null;
  userInfo: UserInfoProperty;
  userVerifyData: UserVerifyData;
  accountStatus: AccountStatus;
  userEntity: UserEntity;
  userFiles: UserFile[] | null;
}

export type {
  IActivity,
  IAssetItem,
  IDividendAssetItem,
  IInvestorDividend,
  UserInfo,
  IVereficationStatus,
  ILockupTransaction,
  ITransactionItem,
  INewLockup,
  BrokerInfo,
};
