import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { useStores } from '../../../store';
import c from './style.module.scss';
import { Input } from '../../formik/formik-input/formik-input';

interface AddPortalDomainProps {
  portalId: string;
}

const isValidDomain = (domain: string): boolean => {
  return /^[a-zA-Z0-9][a-zA-Z0-9.-]*$/.test(domain);
};

const AddPortalDomain = observer(({ portalId }: AddPortalDomainProps) => {
  const [domain, setDomain] = useState('');
  const [domainError, setDomainError] = useState('');

  const { portalStore } = useStores();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
    setDomainError('');
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isValidDomain(domain)) {
      setDomainError('Input valid domain');
      return;
    }
    const result: boolean = await flowResult(portalStore.addDomainToPortal(portalId, domain, setDomainError));
    if (result) setDomain('');
  };

  return (
    <Stack gap="15px" mb="25px">
      <div>Add a domain</div>
      <Input
        value={domain}
        onChange={onChangeHandler}
        inputProps={{ maxLength: 30 }}
        error={!!domainError}
        className={c.input}
      />
      {domainError && <div className={c.error}>{domainError}</div>}
      <div className={c.btns}>
        <Button onClick={onSubmit} disabled={portalStore.isLoading} className={c.button}>
          Add domain
        </Button>
      </div>
    </Stack>
  );
});

export default AddPortalDomain;
