import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { flowResult } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import {
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import c from './style.module.scss';
import { useStores } from '../../../store';
import AssetRow from './asset-row/asset-row';
import '../../../app/styles/reusable/tables.scss';
import { usePagination } from '../../../shared/hooks/usePagination';
import { RequestParams } from '../../../shared/types/investorTableType';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import CalculateDividendModal from '../../../components/distribution-page/calculateDividendModal/calculateDividendModal';

const headeCells = ['Asset name', 'Domains', 'Portal'];

function AdminAssetsPage() {
  const { assetStore } = useStores();

  const loadAssets = (params: RequestParams) => {
    flowResult(assetStore.getAllAssets(params));
  };

  const { controlledParams, onPageSizeChange, onCurrentPageChange, loadItems, onAssetNameChange } = usePagination({
    callback: loadAssets,
  });

  const [filterAsset, setFilterAsset] = useState(controlledParams.assetName || '');

  const debouncedFilter = useDebounce<string>(filterAsset, 300);

  useEffect(() => {
    onAssetNameChange(debouncedFilter);
  }, [debouncedFilter]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  useEffect(() => {
    return () => {
      assetStore.resetAssets();
    };
  }, [assetStore]);

  return (
    <>
      <Helmet>
        <title>Admin Assets | TokenSuite</title>
      </Helmet>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={8}>
        <div className={c.title}>Assets</div>
        <div className={c.topPanel}>
          <Link to="/create-asset" className={c.createNew}>
            Create new asset
          </Link>
        </div>
      </Stack>
      <div className={c.wrapper}>
        <TableContainer className="wrapperDT">
          <Table>
            <TableHead>
              <TableRow className={c.headRow}>
                <TableCell colSpan={1}>
                  <OutlinedInput
                    fullWidth
                    placeholder="Search by asset name"
                    value={filterAsset}
                    className={c.filter}
                    onChange={e => setFilterAsset(e.target.value)}
                  />
                </TableCell>
                {headeCells.map(h => (
                  <TableCell key={h} className={c.headCellDT}>
                    {h}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {assetStore.isLoading && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Spinner single />
                  </TableCell>
                </TableRow>
              )}
              {assetStore.allAssets.length > 0 &&
                assetStore.allAssets.map(asset => <AssetRow asset={asset} key={asset.id} loadItems={loadItems} />)}
              {!assetStore.allAssets.length && !assetStore.isLoading && (
                <TableRow>
                  <TableCell className="emptyBlockDT" colSpan={5}>
                    <FormattedMessage id="usersPage.table.text.noUsersMessage" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={c.pagination}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={assetStore.totalItems}
          rowsPerPage={+controlledParams.pageSize}
          page={assetStore.totalItems <= 0 ? 0 : +controlledParams.currentPage}
          onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
          onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
          SelectProps={{
            disabled: assetStore.isLoading,
          }}
          backIconButtonProps={{
            disabled: assetStore.isLoading || +controlledParams.currentPage === 0,
          }}
          nextIconButtonProps={{
            disabled:
              assetStore.isLoading ||
              Math.ceil(assetStore.totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
              assetStore.totalItems === 0,
          }}
        />
      </div>
      <CalculateDividendModal />
    </>
  );
}

export default observer(AdminAssetsPage);
