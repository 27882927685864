import { adminRotes } from './admin-rotes';
import { issuerRoutes } from './issuer-routes';
import { investorRoutes } from './investor-routes';
import { PrivateRoutes } from '../model/private-route';

const privateRoutes: PrivateRoutes = {
  admin: adminRotes,
  issuer: issuerRoutes,
  demoIssuer: issuerRoutes,
  investor: investorRoutes,
};

export { privateRoutes };
