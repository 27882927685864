import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import scrollToTop from '@token-suite/common-ui/src/sharedFunctions/scrollToTop';
import RootStore from './index';
import assetService from '../shared/services/asset/assetService';
import { Asset } from '../shared/interfaces/assets/asset.interface';
import { IAssetItem } from '../shared/interfaces/userInfo.interface';
import { RequestParams } from '../shared/types/investorTableType';

class AssetStore {
  rootStore: RootStore;

  isLoading = false;

  isMainMediaLoading = false;

  isPresentationLoading = false;

  isMediaBlocksLoading = false;

  isDocumentsLoading = false;

  isMarketLoading = false;

  message = '';

  currentAsset: Asset = {
    createdAt: '',
    currency: '',
    description: '',
    documents: [],
    endDate: '',
    highlights: [],
    howItWorks: null,
    id: '',
    investmentDetail: {
      revenueType: '',
      capitalStack: '',
      propertyType: '',
    },
    investorPerks: {
      highlights: '',
      fields: [],
    },
    lat: 0,
    lng: 0,
    location: '',
    mainImage: {
      url: '',
    },
    mainVideo: '',
    showMap: true,
    marketerAnalysis: [],
    maxAmount: 0,
    media: [],
    minAmount: 0,
    name: '',
    presentationDocument: {
      url: '',
    },
    previewImage: {
      url: '',
    },
    reviews: [],
    startDate: '',
    targetAmount: 0,
    tickerSymbol: '',
    tokenQuantity: 0,
    unitPrice: 0,
    updatedAt: '',
    private: false,
    openForBrokers: false,
    isSoftCup: false,
    paymentTokenRaised: '0',
  };

  allAssets: IAssetItem[] = [];

  totalItems = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  resetAssets() {
    this.allAssets = [];
    this.totalItems = 0;
  }

  setAssetMainInfo(payload: Asset) {
    this.currentAsset = {
      ...payload,
      isSoftCup: payload.isSoftCup ? payload.isSoftCup : false,
      paymentTokenRaised: payload.paymentTokenRaised ? payload.paymentTokenRaised : '0',
    };
  }

  setAllAssets(payload: IAssetItem[]) {
    this.allAssets = payload;
  }

  *getAssetInfoById(id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'You`re not invited to this asset';
    }
  }

  *getAllAssets(dto: RequestParams) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const assets: { assets: IAssetItem[]; total: number } = yield assetService.getAllAssets(dto);
      this.setAllAssets(assets.assets);
      this.totalItems = assets.total;
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server error';
    }
  }

  *saveMainInfo(id: string, startDateProps: Date, endDateProps: Date) {
    const startDate = moment(startDateProps).toISOString();
    const endDate = moment(endDateProps).toISOString();
    try {
      this.message = '';
      yield assetService.saveMainInfo(id, startDate, endDate);
    } catch (e) {
      this.message = 'End Date should be greater than start date';
    }
  }

  *saveLocation(id: string, locationProps: string, latProps: number, lngProps: number, showMap: boolean) {
    try {
      this.message = '';
      yield assetService.saveLocation(id, locationProps, latProps, lngProps, showMap);
    } catch (e) {
      scrollToTop();
      this.message = 'Incorrect lat or lng';
    }
  }

  *savePreview(id: string, file: any) {
    this.isMainMediaLoading = true;
    this.message = '';
    try {
      yield assetService.savePreview(id, file);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isMainMediaLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Preview Error';
      this.isMainMediaLoading = false;
    }
  }

  *saveMainMedia(id: string, formData: any) {
    this.isMainMediaLoading = true;
    this.message = '';
    try {
      yield assetService.saveMainMedia(id, formData);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isMainMediaLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Main Media Error';
      this.isMainMediaLoading = false;
    }
  }

  *saveDescription(id: string, description: string) {
    try {
      this.message = '';
      yield assetService.saveDescription(id, description);
    } catch (e) {
      this.message = 'Description is required';
    }
  }

  *saveHighlights(id: string, highlights: Array<{ text: string; position?: number }>) {
    try {
      this.message = '';
      yield assetService.saveHighlights(id, highlights);
    } catch (e) {
      scrollToTop();
      this.message = 'Highlights Block: All fields should be filled';
    }
  }

  *saveDetails(id: string, revenueType: string, capitalStack: string, propertyType: string) {
    try {
      this.message = '';
      yield assetService.saveDetails(id, revenueType, capitalStack, propertyType);
    } catch (e) {
      scrollToTop();
      this.message = 'Investment Details: All fields required';
    }
  }

  *savePresentatin(id: string, file: any) {
    this.isPresentationLoading = true;
    this.message = '';
    try {
      yield assetService.savePresentation(id, file);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isPresentationLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Presentation Upload Error';
      this.isPresentationLoading = false;
    }
  }

  *deletePresentation(id: string) {
    this.isPresentationLoading = true;
    this.message = '';
    try {
      yield assetService.deletePresentation(id);
      this.currentAsset.presentationDocument = null;
      this.isPresentationLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Presentation Delete Error';
      this.isPresentationLoading = false;
    }
  }

  *addNewMediaBlock(id: string, title: string, description: string) {
    this.isMediaBlocksLoading = true;
    try {
      this.message = '';
      yield assetService.addNewMediaBlock(id, title, description);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isMediaBlocksLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Add new media block server error';
      this.isMediaBlocksLoading = false;
    }
  }

  *saveBlockChanges(id: string, blockId: string, title: string, description: string) {
    try {
      this.message = '';
      yield assetService.changeMediaBlock(id, blockId, title, description);
    } catch (e) {
      scrollToTop();
      this.message = 'Change Media Blocks: Server Error';
    }
  }

  *deleteMediaBlock(id: string, blockId: string) {
    try {
      this.message = '';
      yield assetService.deleteMediaBlock(id, blockId);
    } catch (e) {
      scrollToTop();
      this.message = 'Delete media block server error';
    }
  }

  *addMediaImage(id: string, blockId: string, formData: any) {
    this.isMediaBlocksLoading = true;
    this.message = '';
    try {
      yield assetService.addMediaImage(id, blockId, formData);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isMediaBlocksLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Add Media Error';
      this.isMediaBlocksLoading = false;
    }
  }

  *deleteMediaImage(id: string, blockId: string, mediaId: string) {
    try {
      this.message = '';
      yield assetService.deleteMediaImage(id, blockId, mediaId);
    } catch (e) {
      scrollToTop();
      this.message = 'Delete media image server error';
    }
  }

  *addHowItWorks(
    id: string,
    worksHighlight: string,
    works: {
      title: string;
      description: string;
    }[],
  ) {
    this.message = '';
    try {
      yield assetService.addHowItWorks(id, worksHighlight, works);
      this.currentAsset.howItWorks = {
        highlights: worksHighlight,
        fields: works,
      };
    } catch (e) {
      scrollToTop();
      this.message = 'How it works Block: all fields required';
    }
  }

  *deleteHowItWorks(id: string) {
    try {
      this.message = '';
      yield assetService.deleteHowItWorks(id);
      this.currentAsset.howItWorks = null;
    } catch (e) {
      scrollToTop();
      this.message = 'Delete how it works block server error';
    }
  }

  *updateReviews(
    id: string,
    reviews: {
      reviewer: string;
      text: string;
    }[],
  ) {
    try {
      this.message = '';
      yield assetService.updateReviews(id, reviews);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
    } catch (e) {
      this.message = 'Reviews Block: all fields required';
      scrollToTop();
    }
  }

  *deleteReview(id: string, reviewId: string) {
    try {
      this.message = '';
      yield assetService.deleteReview(id, reviewId);
    } catch (e) {
      scrollToTop();
      this.message = 'Delete review server error';
    }
  }

  *updatePerks(
    id: string,
    highlight: string,
    perks: {
      title: string;
      description: string;
    }[],
  ) {
    try {
      this.message = '';
      yield assetService.updatePerks(id, highlight, perks);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
    } catch (e) {
      this.message = 'Perks Block: all fields required';
      scrollToTop();
    }
  }

  *deletePerk(id: string, perkId: string) {
    try {
      this.message = '';
      yield assetService.deletePerk(id, perkId);
    } catch (e) {
      scrollToTop();
      this.message = 'Delete perk server error';
    }
  }

  *addDocument(id: string, formData: any) {
    this.isDocumentsLoading = true;
    this.message = '';
    try {
      yield assetService.addDocument(id, formData);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isDocumentsLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Add Document file Error';
      this.isDocumentsLoading = false;
    }
  }

  *deleteDocument(id: string, documentId: string) {
    try {
      this.message = '';
      yield assetService.deleteDocument(id, documentId);
    } catch (e) {
      scrollToTop();
      this.message = 'Delete document server error';
    }
  }

  *addMarket(id: string, formData: any) {
    this.isMarketLoading = true;
    this.message = '';
    try {
      yield assetService.addMarket(id, formData);
      const asset: Asset = yield assetService.getAssetById(id);
      this.setAssetMainInfo(asset);
      this.isMarketLoading = false;
    } catch (e) {
      scrollToTop();
      this.message = 'Add Market analysis file Error';
      this.isMarketLoading = false;
    }
  }

  *deleteMarket(id: string, documentId: string) {
    try {
      this.message = '';
      yield assetService.deleteMarket(id, documentId);
    } catch (e) {
      scrollToTop();
      this.message = 'Delete market analysis server error';
    }
  }

  *updatePrivate(id: string, status: boolean) {
    try {
      this.message = '';
      yield assetService.updatePrivate(id, status);
    } catch (e) {
      scrollToTop();
      this.message = 'Update Private server error';
    }
  }
}

export default AssetStore;
