import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { IActivityLogTableProps } from '../interfaces';
import { headCells } from '../meta-data';
import '../../../app/styles/reusable/tables.scss';
import ActivityLogRow from './activity-log-row';

const ActivityLogTable: FC<IActivityLogTableProps> = ({ logs, isLoading }) => {
  return (
    <TableContainer className="wrapperDT">
      <Table>
        <TableHead>
          <TableRow className="tableHeadDT">
            {headCells.map(cell => (
              <TableCell key={cell.id} className="headCellDT">
                {cell.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Spinner single />
              </TableCell>
            </TableRow>
          )}
          {logs.length > 0 && logs.map(log => <ActivityLogRow log={log} key={log.id} />)}
          {!logs.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={3} className="emptyBlockDT">
                <FormattedMessage id="activityLog.table.emptyBlock" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActivityLogTable;
