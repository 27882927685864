import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from './index';
import {
  BrokerInfo,
  IActivity,
  ILockupTransaction,
  INewLockup,
  UserEntity,
  UserInfo,
  UserVerifyData,
} from '../shared/interfaces/userInfo.interface';
import userInfoService from '../shared/services/userInfo/userInfoService';

class UserInfoStore {
  rootStore: RootStore;

  isLoading = false;

  message = '';

  currentUserActivity: IActivity[] = [];

  currentTransactions: ILockupTransaction[] = [];

  isLockupModalOpen = false;

  currentLockup: INewLockup = {
    tokenAddress: '',
    position: 0,
    newLockupEndTime: 0,
  };

  userInfo: UserInfo = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    userInfo: {
      primState: '',
      socialSecurityNumber: '',
    },
    accountStatus: {
      status: '',
      activated: false,
      description: '',
    },
    role: '',
    userEntity: {} as UserEntity,
    userFiles: [],
    userType: '',
    userVerifyData: {} as UserVerifyData,
    wallet: null,
  };

  brokerInfo: BrokerInfo = {
    id: '',
    firstName: '',
    lastName: '',
    portalId: '',
    email: '',
    role: '',
    validated: false,
    crd: '',
    firmName: '',
    firmCRD: '',
    firmSEC: '',
  };

  currentFiles: Blob[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setUserActivity(payload: IActivity[]) {
    this.currentUserActivity = payload;
  }

  setUserLockupTransactions(payload: ILockupTransaction[]) {
    this.currentTransactions = payload;
  }

  setUserInfo(payload: UserInfo) {
    this.userInfo = payload;
  }

  *getUserActivityById(id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const activity: IActivity[] = yield userInfoService.getUserActivity(id);
      this.setUserActivity(activity);
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server Error';
    }
  }

  *getUserLockupTransactions(id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const transactions: ILockupTransaction[] = yield userInfoService.getUserLockupTransactions(id);
      this.setUserLockupTransactions(transactions);
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server Error';
    }
  }

  async getUserFiles(idList: string[]) {
    try {
      const data = await Promise.all(idList.map(id => userInfoService.getUserFileById(id)));
      runInAction(() => {
        this.currentFiles = data;
      });
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    }
  }

  *getUserInfoById(id: string) {
    try {
      const userData: UserInfo = yield userInfoService.getUserById(id);
      this.setUserInfo(userData);
      if (userData.userFiles) {
        this.getUserFiles(userData.userFiles.map(({ file: { id: i } }) => i));
      }
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    }
  }

  *getBrokerInfoById(id: string) {
    try {
      const userData: BrokerInfo = yield userInfoService.getBrokerById(id);
      this.brokerInfo = userData;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    }
  }

  *deleteUser(id: string) {
    try {
      yield userInfoService.deleteUserById(id);
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    }
  }

  *deleteInvestor(id: string) {
    try {
      yield userInfoService.deleteInvestorById(id);
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    }
  }

  openLockupModal(tokenAddress: string, position: number, newLockupEndTime: number) {
    this.isLockupModalOpen = true;
    this.currentLockup = {
      tokenAddress,
      position,
      newLockupEndTime,
    };
  }

  closeLockupModal() {
    this.isLockupModalOpen = false;
  }

  *sendNewLockup(newLockupEndTime: Date, id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      yield userInfoService.sendNewLockup(this.currentLockup, this.userInfo.wallet, newLockupEndTime);
      const transactions: ILockupTransaction[] = yield userInfoService.getUserLockupTransactions(id);
      this.setUserLockupTransactions(transactions);
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server Error';
    }
  }

  *unlockTransaction(tokenAddress: string, position: number, id: string) {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      yield userInfoService.unlockTransaction(tokenAddress, this.userInfo.wallet, position);
      const transactions: ILockupTransaction[] = yield userInfoService.getUserLockupTransactions(id);
      this.setUserLockupTransactions(transactions);
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server Error';
    }
  }
}

export default UserInfoStore;
