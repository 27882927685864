import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { observer } from 'mobx-react-lite';
import closeIcon from '../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import { EMAIL_REGEX } from '../../shared/config/constants';
import { AssetItem } from '../../shared/interfaces/headerInterface';
import tokenInstance from '../../shared/services/API/tokenApi';
import { useStores } from '../../store';

function InviteInvestorModal({ refreshList }: { refreshList: () => void }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [asset, setAsset] = useState('');
  const { portalStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);

  const [assets, setAssets] = useState<AssetItem[]>([]);
  const [assetId, setAssetId] = useState('');

  const [emailError, setEmailError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendClick = () => {
    setIsLoading(true);
    tokenInstance
      .post('users/investors', {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email,
        assetId,
      })
      .then(() => {
        refreshList();
        setEmail('');
        setLastName('');
        setFirstName('');
        setAsset('');
        setOpen(false);
        setIsLoading(false);
      })
      .catch(e => {
        setEmailError(e.response.data.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    tokenInstance.get('assets/issuer').then(({ data }) => {
      setAssets(data);
    });
  }, []);

  const changeAsset = (assetSearch: string) => {
    setAsset(assetSearch);
    const selectedAsset = assets.find((assetItem: AssetItem) => assetItem.name === assetSearch);
    if (selectedAsset) {
      setAssetId(selectedAsset.id);
    }
  };

  return (
    <div className={c.wrapper}>
      <button onClick={handleClickOpen} className={c.submitButton} disabled={portalStore.portalUI.isDemo}>
        Invite Investor
      </button>
      <Dialog open={open} onClose={handleClose}>
        <div className={c.dialog}>
          <div className={c.title}>Invite an Investor</div>
          <Button onClick={handleClose} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <p className={c.text}>First Name</p>
          <TextField
            type="text"
            onChange={e => {
              setFirstName(e.target.value);
            }}
            value={firstName}
            className={c.input}
            margin="normal"
            placeholder="Enter name"
            inputProps={{
              maxLength: 35,
            }}
          />
          <p className={c.text}>Last Name</p>
          <TextField
            type="text"
            onChange={e => {
              setLastName(e.target.value);
            }}
            value={lastName}
            className={c.input}
            margin="normal"
            placeholder="Enter name"
            inputProps={{
              maxLength: 35,
            }}
          />
          <p className={c.text}>E-mail</p>
          <TextField
            type="email"
            onChange={e => {
              setEmail(e.target.value.trim());
              setEmailError('');
            }}
            value={email}
            autoComplete="email"
            className={c.input}
            margin="normal"
            placeholder="name@example.com"
            inputProps={{
              maxLength: 127,
            }}
            helperText={emailError}
          />
          <p className={c.text}>Choose Asset</p>
          <Select
            displayEmpty
            className={c.select}
            value={asset}
            onChange={e => {
              changeAsset(e.target.value);
            }}
            renderValue={selected => {
              if (selected.length === 0) {
                return <em style={{ color: '#8D8D8D' }}>Select</em>;
              }
              return selected;
            }}
          >
            {assets.map((assetItem: any) => (
              <MenuItem value={assetItem.name} key={assetItem.id}>
                {assetItem.name}
              </MenuItem>
            ))}
          </Select>
          {assets.length < 1 && <p className={c.errorText}>You have not an asset , please conect with admin</p>}
          <div className={c.actions}>
            <button
              onClick={handleSendClick}
              className={c.submitButton}
              disabled={!firstName.trim() || !lastName.trim() || !EMAIL_REGEX.test(email) || !asset || isLoading}
            >
              {isLoading ? 'Inviting...' : 'Send Invite'}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default observer(InviteInvestorModal);
