import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { flowResult, runInAction } from 'mobx';
import c from './style.module.scss';
import logoIcon from '../../../shared/assets/DIBS.png';
import logoText from '../../../shared/assets/DIBS-Letters.svg';
import menuIcon from '../../../shared/assets/profileMenu/mobileBurgerMenu.svg';
import menuCloseIcon from '../../../shared/assets/profileMenu/closeMenuIcon.svg';
import logoutIcon from '../../../shared/assets/profileMenu/logout.svg';
import AccountNavCollapse from '../../../components/accountNavCollapse/accountNavCollapse';
import MobileMenu from './mobileMenu/mobileMenu';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { useStores } from '../../../store';
import SignContractMetakeepModal from '../../../components/UI/dialogs/signContracts-modal/SignContractMetakeepModal';
import SignPandaDocContractModal from '../../../components/UI/dialogs/signPandaDocContracts-modal/signPandaDocContracts-modal';
import AccountNotificationsCollapse from '../../../components/accountNotificationsCollapse/accountNotificationsCollapse';
// import { useMetaKeep } from '../../../context/MetaKeepContext/MetaKeep.context';

const AccountNav = observer(() => {
  const {
    headerStore,
    portalStore: { portalUI, isCustomPortal },
    issuerStore,
    vereficationStore,
  } = useStores();

  const userData = headerStore.user;
  const userRole = userData.role;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [open, setOpen] = useState(false);
  const [openSignModal, setOpenSignModal] = useState(false);
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken) {
      tokenInstance.get('users/user').then(({ data }) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        headerStore.setPortals(data.portals);
        if (data.wallet) {
          headerStore.setWallet(true);
          if (data.role === 'role:issuer') {
            issuerStore.getTokensByPortalIssuer(data.wallet);
          }
        }

        runInAction(() => {
          headerStore.user.email = data.email || '';
        });
      });
      flowResult(vereficationStore.getUserVerifyStatus());
      flowResult(vereficationStore.getUserNotifications());
    }
  }, [accessToken, headerStore, issuerStore, vereficationStore, portalUI]);

  useEffect(() => {
    if (issuerStore.tokenContractsToSign.length > 0) {
      setOpenSignModal(true);
    }
  }, [issuerStore.tokenContractsToSign]);

  const handleMobileMenuClick = () => {
    setOpen(!open);
  };

  const isCustomLogo = isCustomPortal && portalUI?.portalUi?.logo?.url;
  const isNoCustomLogo = isCustomPortal && !portalUI?.portalUi?.logo?.url;
  return (
    <>
      <div className={c.nav}>
        <button
          className={c.logo}
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        >
          {isCustomLogo && (
            <div className={c.customLogo}>
              <img src={portalUI?.portalUi?.logo?.url} alt="" />
            </div>
          )}

          {(!isCustomPortal || isNoCustomLogo) && (
            <>
              <img src={logoIcon} alt="Web3RealEstate" className={c.mainLogo} />
              <img src={logoText} alt="Web3RealEstate" className={c.logoText} />
            </>
          )}
        </button>
        <div className={c.rightBlock}>
          {accessToken ? (
            <>
              <AccountNotificationsCollapse notifications={vereficationStore.notDoneNotifications} />
              <AccountNavCollapse userName={`${firstName} ${lastName}`} userRole={userRole} />
              <button className={c.mobileMenuIcon} onClick={handleMobileMenuClick}>
                {open ? <img alt="menu-icon" src={menuCloseIcon} /> : <img alt="menu-icon" src={menuIcon} />}
              </button>
              <div className={c.mobileMenu}>
                <MobileMenu userName={`${firstName} ${lastName}`} userRole={userRole} open={open} setOpen={setOpen} />
              </div>
            </>
          ) : (
            <Link to="/login" className={c.signIn}>
              Sign In
              <img alt="logout-icon" src={logoutIcon} />
            </Link>
          )}
        </div>
      </div>
      <SignContractMetakeepModal
        openModal={openSignModal}
        setClose={() => {
          setOpenSignModal(false);
        }}
      />
      {vereficationStore.contractData?.embeddedUrl && (
        <SignPandaDocContractModal
          open={vereficationStore.openSignContractsModal}
          pandaDocLink={vereficationStore.contractData?.embeddedUrl}
          contractId={vereficationStore.contractData?.id}
        />
      )}
    </>
  );
});

export default AccountNav;
