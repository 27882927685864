import { observer } from 'mobx-react-lite';

import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import BuyArrow from '../../../shared/assets/asset-view/buy.svg';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AssetTopRight(props: { toggleCheckout: () => void; checkout: boolean }) {
  const { toggleCheckout, checkout } = props;
  const {
    assetStore: { currentAsset },
    headerStore: { user },
  } = useStores();

  const timerField = (endDate: string) => {
    const endTime = Date.parse(endDate);
    const diff = +endTime - +new Date();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days < 0) {
      return 0;
    }
    return days;
  };

  function validate() {
    if (
      timerField(currentAsset.endDate) === 0 ||
      currentAsset.maxAmount - +currentAsset.paymentTokenRaised < currentAsset.minAmount
    )
      return true;
    return false;
  }

  const renderText = () => {
    if (validate()) {
      return 'Sale finished';
    }
    if (localStorage.getItem('accessToken')) {
      return 'Start the Purchasing Process';
    }
    return 'Authorize to Purchase';
  };

  return (
    <div className={`${c.assetTopRightWrapper} ${checkout ? `${c.toCheckout}` : ''}`}>
      <div className={c.assetTopRight}>
        <div className={c.raised}>
          {CURRENCY_SYMBOLS[currentAsset.currency]}
          {addComma(String(currentAsset.maxAmount))}
        </div>
        <div className={c.percentage}>Total Asset Value</div>
        <div className={c.infoField}>
          <div className={c.value}>
            {CURRENCY_SYMBOLS[currentAsset.currency]}
            {addComma(String(currentAsset.unitPrice))}
          </div>
          <div className={c.field}>
            Price per digital share / {currentAsset.tickerSymbol ? `${currentAsset.tickerSymbol.toUpperCase()}` : ''}{' '}
            token{' '}
          </div>
        </div>
        <div className={c.infoField}>
          <div className={c.value}>
            {CURRENCY_SYMBOLS[currentAsset.currency]}
            {addComma(String(currentAsset.minAmount))}
          </div>
          <div className={c.field}>Minimum Investment</div>
        </div>
        <button className={c.buy} onClick={toggleCheckout} disabled={user?.role !== 'investor' || validate()}>
          {renderText()}
          <img src={BuyArrow} alt="" />
        </button>
      </div>
    </div>
  );
}

export default observer(AssetTopRight);
