import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useStores } from '../../store';
import c from './style.module.scss';
import TableRow from '../UI/table/table-row/table-row';
import InviteIssuerModal from '../invite-issuer-modal/ivniteIssuerModal';

const Portals = observer(() => {
  const { portalStore } = useStores();

  const navigate = useNavigate();

  const [currentPortalId, setCurrentPortalId] = useState('');

  useEffect(() => {
    flowResult(portalStore.getAllPortals());
    return () => {
      portalStore.resetPortals();
    };
  }, [portalStore]);

  const navigateToPortal = (id: string) => () => {
    navigate(`/portals/${id}`);
  };

  const navigateToPortalIssuers = (id: string) => () => {
    navigate(`/portals/${id}/issuers`);
  };

  const navigateToPortalTokens = (id: string) => () => {
    navigate(`/portals/${id}/tokens`);
  };

  return (
    <Stack mt="40px" className={c.wrapper}>
      <div className={c.header}>
        <div className={c.name}>Name</div>
        <div className={c.status}>Status</div>
      </div>
      {portalStore.isLoading && (
        <Stack height="100%" justifyContent="center" alignItems="center">
          {' '}
          <Spinner />{' '}
        </Stack>
      )}
      {!!portalStore.portals.length &&
        portalStore.portals.map(({ portalName, domains, id, isDemo }) => (
          <TableRow
            id={id}
            subTitle={domains.map(({ domain }) => domain)}
            label={domains.map(({ status }) => status).join(', ')}
            isDemo={isDemo}
            key={id}
            mainTitle={portalName}
            menuItems={[
              {
                text: 'View details',
                disabled: false,
                handler: navigateToPortal(id),
              },
              {
                text: 'Invite issuer',
                disabled: isDemo,
                handler: () => setCurrentPortalId(id),
              },
              {
                text: 'Show issuers',
                disabled: false,
                handler: navigateToPortalIssuers(id),
              },
              {
                text: 'Show tokens',
                disabled: false,
                handler: navigateToPortalTokens(id),
              },
            ]}
          />
        ))}
      {currentPortalId && <InviteIssuerModal portalId={currentPortalId} resetPortalId={() => setCurrentPortalId('')} />}
    </Stack>
  );
});

export default Portals;
