import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { observer } from 'mobx-react-lite';
import { DateInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import { useStores } from '../../../store';

function CalculateDividendsModal() {
  const { dividendStore } = useStores();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dividends, setDividends] = useState(0);

  const [error, setError] = useState('');
  const [dateError, setDateError] = useState(false);

  const handleClose = () => {
    setError('');
    dividendStore.closeDistributeModal();
  };

  const handleSendClick = () => {
    setError('');
    if (endDate <= startDate) {
      setError('End Date should be greater than Start Date');
      return;
    }
    if (dividends < 1) {
      setError('Deposit should be greater than 0');
      return;
    }
    navigate(
      `/dividends/calculate/${
        dividendStore.currentAssetId
      }?dividends=${dividends}&start-date=${startDate.toISOString()}&end-date=${endDate.toISOString()}`,
    );
    handleClose();
  };

  return (
    <Dialog open={dividendStore.isDistributeModalOpen} onClose={handleClose}>
      <div className={c.dialog}>
        <div className={c.title}>Distribute</div>
        <Button onClick={handleClose} className={c.closeIcon}>
          <img src={closeIcon} alt="" />
        </Button>
        <DateInput fullWidth value={startDate} changeValue={setStartDate} label="Start Date" setError={setDateError} />
        <DateInput fullWidth value={endDate} changeValue={setEndDate} label="End Date" setError={setDateError} />
        <TextInput
          value={dividends}
          changeValue={setDividends}
          label="Deposit"
          placeholder="Enter number of USDC"
          type="number"
          fullWidth
        />
        {error && <div className={c.errorText}>{error}</div>}
        <div className={c.actions}>
          <button onClick={handleSendClick} className={c.submitButton} disabled={dateError}>
            Calculate
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default observer(CalculateDividendsModal);
