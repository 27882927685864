import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useEffect, useState } from 'react';
import { Area, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, TooltipProps } from 'recharts';
import { historicalPerformanceTestData } from '../testData/testData';
import c from './styles.module.scss';

const prettyfyNumber = (num: number) => {
  if (num >= 1000000000000000) {
    return `${(Math.round(num / 10000000000000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}Q`;
  }
  if (num >= 1000000000000) {
    return `${(Math.round(num / 10000000000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}T`;
  }
  if (num >= 1000000000) {
    return `${(Math.round(num / 10000000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}B`;
  }
  if (num >= 1000000) {
    return `${(Math.round(num / 10000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}M`;
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  const date = new Date(label);
  if (active) {
    return (
      <div className={c.tooltip}>
        <p className={c.value}>
          ${payload?.[0].value?.toLocaleString()}
        </p>
        <p className={c.date}>
          {`${date.getDate()}, ${date.toLocaleDateString('en-US', { month: 'long' })} ${date.getFullYear()}`}
        </p>
      </div>
    );
  }

  return null;
}

function HistoricalPerformance() {
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => { setTimeout(() => setIsloading(false), 500); }, []);
  if (isLoading) {
    return (
      <div className={`${c.wrapper} ${c.loading}`}>
        <Spinner single />
      </div>
    );
  }
  return (
    <div className={c.wrapper}>
      <ResponsiveContainer height={500} width="100%">
        <AreaChart data={historicalPerformanceTestData}>
          <CartesianGrid
            vertical={false}
          />
          <XAxis
            style={{ fontSize: 11 }}
            tickMargin={15}
            dataKey="date"
            minTickGap={50}
            tickFormatter={(a: string) => {
              const date = new Date(a);
              return `${date.getDate()}.${date.toLocaleDateString('en-US', { month: 'short' })}`;
            }}
          />
          <YAxis
            style={{ fontSize: 11 }}
            width={80}
            tickLine={false}
            tickFormatter={(a: number) => `$${prettyfyNumber(a)}`}
          />
          <Tooltip
            content={<CustomTooltip />}
            isAnimationActive={false}
            wrapperStyle={{ outline: 'none' }}
          />
          <Area
            dataKey="value"
            stroke="#0EC22B"
            fill="#0EC22B26"
            dot={{
              stroke: '#0EC22B',
              fill: '#0EC22B',
              strokeWidth: 1,
              r: 5,
              strokeDasharray: '',
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default HistoricalPerformance;
