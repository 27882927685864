import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import c from './style.module.scss';

const iconStyles = {
  color: '#fff',
  fontSize: 16,
};

const ProfileRowStateIcon = ({ isActive }: { isActive: boolean }) => {
  return (
    <div className={isActive ? c.done : c.undone}>
      {isActive ? <DoneIcon fontSize="small" sx={iconStyles} /> : <CloseIcon fontSize="small" sx={iconStyles} />}
    </div>
  );
};

export default ProfileRowStateIcon;
