import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { columns } from './meta';
import c from './style.module.scss';
import { ProfileData } from '../../shared/interfaces/headerInterface';
import { RequestParams } from '../../shared/types/investorTableType';
import adminService from '../../shared/services/admin/adminService';
import { usePagination } from '../../shared/hooks/usePagination';
import { EAccountStatuses } from '../../shared/types/account.types';
import filterDESC from '../../shared/assets/icons/filterArrowsDESC.svg';
import filterASC from '../../shared/assets/icons/filterArrowsASC.svg';
import filterDisabled from '../../shared/assets/icons/filterArrowsDisabled.svg';
import BlockListRow from './block-list-row';

const AdminBlockListTable = () => {
  const [users, setUsers] = useState<ProfileData[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = async (params: RequestParams) => {
    setIsLoading(true);
    try {
      params.status = EAccountStatuses.Blocked;
      const data = await adminService.getInvestors(params);
      setTotalItems(data.total);
      setUsers(data.users ?? []);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const { loadItems, onOrderChange, onPageSizeChange, onCurrentPageChange, controlledParams } = usePagination({
    callback: loadUsers,
  });

  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
        className={c.wrapper}
      >
        <Table aria-label="simple table">
          <TableHead className={c.tableHead}>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} className={c.headCell}>
                  <Stack direction="row" alignItems="center">
                    {column.label}
                    {column.sortable && (
                      <IconButton
                        disabled={isLoading}
                        onClick={() => onOrderChange(column.id as 'name')}
                        sx={{ py: 0 }}
                      >
                        {renderOrderIcon(column.id as 'name')}
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6} className={c.loading} align="center">
                  <Spinner single />
                </TableCell>
              </TableRow>
            )}
            {users.length > 0 &&
              users.map(user => (
                <BlockListRow user={user} key={user.id} setUser={setUsers} setTotalItems={setTotalItems} />
              ))}
            {!users.length && !isLoading && (
              <TableRow>
                <TableCell className={c.emptyBlock} colSpan={9}>
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={+controlledParams.pageSize}
        page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            isLoading ||
            Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
            totalItems === 0,
        }}
      />
    </>
  );
};

export default AdminBlockListTable;
