// import { Button } from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import OnbordingInvestors from '../../../components/investorTable/onboardingInvestors/onboardingInvestors';
import c from './styles.module.scss';
import { AllowList } from '../../../components/investorTable/investorAllowList/investorsAllowList';
import BlockList from '../../../components/investorTable/blocklist/blocklist';
import { useStores } from '../../../store';
import TokenHolders from '../../../components/investorTable/token-holders/token-holders';
import ActiveContracts from '../../../components/investorTable/active-contracts/active-contracts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function InvestorsPage() {
  const intl = useIntl();
  const [value, setValue] = React.useState(1);
  const navigate = useNavigate();
  const { portalStore } = useStores();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate('/investors', { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Investors | ${portalStore.portalUI.portalUi.title}`
            : 'Investors | TokenSuite'}
        </title>
      </Helmet>
      <div className={c.title}>
        <FormattedMessage id="investorsPage.text.header" />
      </div>
      <div className={c.table}>
        <nav>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            textColor="secondary"
            indicatorColor="secondary"
            scrollButtons="auto"
          >
            <Tab label={intl.formatMessage({ id: 'investorsPage.tabs.tokenHolders' })} className={`${c.tabButton}`} />
            <Tab
              label={intl.formatMessage({ id: 'investorsPage.tabs.onbordingInvestors' })}
              className={`${c.tabButton}`}
            />
            <Tab label={intl.formatMessage({ id: 'investorsPage.tabs.allowList' })} className={`${c.tabButton}`} />
            <Tab label={intl.formatMessage({ id: 'investorsPage.tabs.blocklist' })} className={`${c.tabButton}`} />
            <Tab
              label={intl.formatMessage({ id: 'investorsPage.tabs.activeContracts' })}
              className={`${c.tabButton}`}
            />
          </Tabs>
        </nav>
        <div className={c.body}>
          <TabPanel value={value} index={0}>
            <TokenHolders />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <OnbordingInvestors />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AllowList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <BlockList />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ActiveContracts />
          </TabPanel>
        </div>
      </div>
    </>
  );
}
