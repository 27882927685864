import axios from 'axios';

const url = process.env.REACT_APP_API_KEY;

const withoutTokenAPI = axios.create({
  baseURL: url,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0,
  },
});

export default withoutTokenAPI;
