import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useNavigate } from 'react-router-dom';
import { getChainID, getNetworkCode, switchToAvailableNetwork } from './switchNetwork.util';
import tokenInstance from '../../shared/services/API/tokenApi';
import tokenObject from './blockChainFunctions';

export interface IImportTokenPayload {
  address: string;
  symbol: string;
  decimals: number;
  image: string;
}

interface IUseHook {
  metamaskWallet: string;
  userWallet: string;
  provider: ethers.providers.Web3Provider | null;
  isLoading: boolean;
  isNetworkWrong: boolean;
  isNetworkChanging: boolean;
  balance: number;
  error: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  getBalance: () => Promise<void>;
  connectWallet?: () => void;
  disconnectMetamask?: () => void;
  switchNetwork?: () => void;
  authWallet?: () => void;
  setIsAuthWalletError?: Dispatch<SetStateAction<boolean>>;
  isAuthWalletError: boolean;
  authWalletError: string;
  signContract?: (tokenAddress: string, contractAddress: string, tokenQuantity: string) => void;
  cryptoPayment?: (
    tokenPaymentAddress: string,
    contractAddress: string,
    amount: string,
    id: string,
    unitPrice: string,
  ) => void;
  importToken?: (payload: IImportTokenPayload) => Promise<boolean>;
}

const initialState: IUseHook = {
  metamaskWallet: '',
  userWallet: '',
  provider: null,
  isLoading: false,
  isNetworkWrong: false,
  isNetworkChanging: false,
  balance: 0,
  error: '',
  authWalletError: '',
  isAuthWalletError: false,
  getBalance: async () => undefined,
  setLoading: () => undefined,
};

interface IProps {
  children: React.ReactNode;
}

export const WalletContext = createContext<IUseHook>(initialState);

export const useWallet = () => {
  return useContext(WalletContext);
};

const signMessage = async (provider: ethers.providers.Web3Provider, from: string, msg: string) => {
  try {
    const data = await provider?.send('personal_sign', [msg, from]);
    return data;
  } catch (_err) {
    return null;
  }
};

export const Wallet: React.FunctionComponent<IProps> = ({ children }) => {
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [isNetworkWrong, setIsNetworkWrong] = useState<boolean>(false);
  const [isNetworkChanging, setIsNetworkChanging] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [metamaskWallet, setMetamaskWallet] = useState<string>('');
  const [userWallet, setUserWallet] = useState<string>('');
  const [ethereum, setEthereum] = useState<any>(null);
  const [balance, setBalance] = useState<number>(0);
  const [error, setError] = useState('');
  const [isAuthWalletError, setIsAuthWalletError] = useState(false);
  const [authWalletError, setAuthWalletError] = useState('');
  const navigate = useNavigate();

  let metamaskProvider: any = null;
  let accounts: string[] = [];

  const getUserToken = useCallback(async () => {
    await tokenInstance.get('users/wallet').then(async ({ data }) => {
      setUserWallet(data.wallet);
    });
  }, [setUserWallet]);

  useEffect(() => {
    getUserToken();
  }, [getUserToken]);

  const chainChangedHandler = useCallback(async (event: string) => {
    if (getNetworkCode(event) === 'unknown') {
      setIsNetworkWrong(true);
      return;
    }
    setIsNetworkWrong(false);
  }, []);

  const removeListeners = useCallback(() => {
    if (provider) {
      if (ethereum.removeListener) {
        ethereum.removeListener('chainChanged', chainChangedHandler);
        ethereum.removeListener('accountsChanged', accountsChangedHandler);
      }
    }
  }, [chainChangedHandler, ethereum, provider]);

  const connectFailed = useCallback(() => {
    removeListeners();
    setProvider(null);
    setIsNetworkWrong(false);
    setIsNetworkChanging(false);
    setLoading(false);
    setMetamaskWallet('');
  }, [removeListeners]);

  const accountsChangedHandler = useCallback(
    async ([account]: string[]) => {
      if (provider) {
        let switchRes = true;
        if (getNetworkCode(await getChainID(provider)) === 'unknown') {
          switchRes = await switchToAvailableNetwork(provider);
        }
        if (switchRes) {
          setMetamaskWallet(account);
        } else {
          connectFailed();
          return;
        }
        setMetamaskWallet(account);
        setProvider(provider);
      }
    },
    [provider, connectFailed],
  );

  useEffect(() => {
    if (provider) {
      ethereum.on('chainChanged', chainChangedHandler);
      ethereum.on('accountsChanged', accountsChangedHandler);
    }
    return () => {
      removeListeners();
    };
  }, [provider, removeListeners, chainChangedHandler, accountsChangedHandler, ethereum]);

  const connectWallet = async () => {
    if (window.ethereum?.providers) {
      metamaskProvider = window.ethereum.providers.find((p: any) => p.isMetaMask);
    }
    if (!metamaskProvider && window?.ethereum?.isMetaMask) {
      metamaskProvider = window.ethereum;
    }
    if (!metamaskProvider) {
      // eslint-disable-next-line no-alert
      window.alert('Metamask extension is not installed.');
      return;
    }

    const web3provider = new ethers.providers.Web3Provider(metamaskProvider);
    setEthereum(web3provider.provider);
    if (web3provider) {
      try {
        accounts = await web3provider.send('eth_requestAccounts', []);
      } catch (e) {
        return;
      }

      const account = accounts[0];
      let switchRes = true;
      if (getNetworkCode(await getChainID(web3provider)) === 'unknown') {
        switchRes = await switchToAvailableNetwork(web3provider);
      }
      if (switchRes) {
        setMetamaskWallet(account);
        setProvider(web3provider);
      } else {
        removeListeners();
        connectFailed();
      }
    }
  };

  const disconnectMetamask = () => {
    connectFailed();
  };

  const authWallet = useCallback(async () => {
    setLoading(true);
    if (provider) {
      await tokenInstance.get(`users/wallet/${metamaskWallet}`).then(async ({ data }) => {
        const signature = await signMessage(provider, metamaskWallet, data.msg);
        await tokenInstance
          .put('users/wallet', {
            token: data.token,
            signature,
          })
          .then(() => {
            setUserWallet(metamaskWallet);
          })
          .catch((e: any) => {
            setIsAuthWalletError(true);
            const errorMessage = e.response?.data?.message || ('Something goes wrong' as string);
            setAuthWalletError(errorMessage);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [metamaskWallet, provider]);

  const switchNetwork = () => {
    setIsNetworkChanging(true);
    if (provider) {
      switchToAvailableNetwork(provider).finally(() => {
        setIsNetworkChanging(false);
      });
    }
  };

  const getBalance = async () => {
    if (provider && metamaskWallet) {
      if (metamaskWallet !== userWallet) {
        setBalance(0);
        return;
      }
      const abi = ['function balanceOf(address account) public view returns (uint)'];
      const contract = new ethers.Contract('0x0dc3462EAFB3b36218233F444FB90CFF7A5084Bf', abi, provider);
      const result = await contract.balanceOf(metamaskWallet);
      setBalance(+formatUnits(result, 6));
      return;
    }
    setBalance(0);
  };

  const importToken = async (payload: IImportTokenPayload) => {
    if (provider && metamaskWallet) {
      if (metamaskWallet !== userWallet) {
        return false;
      }
      try {
        const wasAdded = await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: payload,
          },
        });
        return wasAdded;
      } catch {
        return false;
      }
    }
    return false;
  };

  const returnBalance = useCallback(async () => {
    if (provider && metamaskWallet) {
      if (metamaskWallet !== userWallet) {
        return 0;
      }
      try {
        const abi = ['function balanceOf(address account) public view returns (uint)'];
        const contract = new ethers.Contract('0x0dc3462EAFB3b36218233F444FB90CFF7A5084Bf', abi, provider);
        const result = await contract.balanceOf(metamaskWallet);
        return +formatUnits(result, 6);
      } catch {
        setLoading(false);
      }
    }
    return 0;
  }, [provider, metamaskWallet, userWallet]);

  const cryptoPayment = useCallback(
    async (tokenPaymentAddress: string, contractAddress: string, amount: string, id: string, unitPrice: string) => {
      setLoading(true);
      setError('');
      const balanceNow = await returnBalance();
      if (balanceNow < +amount * +unitPrice) {
        setError('You do not have enough funds in your account');
        setTimeout(() => {
          setError('');
        }, 5000);
        setLoading(false);
        return;
      }
      if (provider) {
        const signer = await provider.getSigner();
        const abiSign = ['function approve(address spender, uint256 amount)'];
        const contractSIgn = new ethers.Contract(tokenPaymentAddress, abiSign, provider);
        const abiSale = ['function invest(uint256 paymentTokenAmount)'];
        const contractSale = new ethers.Contract(contractAddress, abiSale, provider);

        await contractSIgn
          .connect(signer)
          .approve(contractAddress, ethers.utils.parseUnits(String(+amount * +unitPrice), 6).toBigInt())
          .then(() => {
            setTimeout(async () => {
              const gasFee = await contractSale
                .connect(signer)
                .estimateGas.invest(ethers.utils.parseUnits(String(+amount * +unitPrice), 6).toBigInt());
              contractSale
                .connect(signer)
                .invest(ethers.utils.parseUnits(String(+amount * +unitPrice), 6).toBigInt(), {
                  gasLimit: Math.round(+gasFee * 1.1),
                })
                .then(() => {
                  navigate(`/checkout/confirmation/${id}/${amount}?method=blockchain`);
                })
                .finally(() => {
                  setLoading(false);
                });
            }, 3000);
          })
          .catch(() => {
            setLoading(false);
            setError('Something goes wrong');
            setTimeout(() => {
              setError('');
            }, 5000);
          });
      }
    },
    [provider, navigate, returnBalance],
  );

  const signContract = useCallback(
    async (tokenAddress: string, contractAddress: string, tokenQuantity: string) => {
      setLoading(true);
      if (provider) {
        const signer = await provider.getSigner();
        // const abi = ['function approve(address spender, uint256 addedValue) public view returns (uint256)'];
        const contract = new ethers.Contract(tokenAddress, tokenObject.abi, provider);
        await contract
          .connect(signer)
          .approve(contractAddress, ethers.utils.parseEther(tokenQuantity))
          .then(async (data: any) => {
            await tokenInstance.get(`initial-sale/approve/${data.hash}`);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [provider],
  );

  const value = {
    provider,
    isNetworkWrong,
    isNetworkChanging,
    isLoading,
    metamaskWallet,
    userWallet,
    balance,
    error,
  };

  return (
    <WalletContext.Provider
      value={{
        ...value,
        switchNetwork,
        connectWallet,
        disconnectMetamask,
        authWallet,
        getBalance,
        setLoading,
        signContract,
        cryptoPayment,
        importToken,
        isAuthWalletError,
        authWalletError,
        setIsAuthWalletError,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const WalletProvider = React.memo(Wallet);
