import { observer } from 'mobx-react-lite';
import Lock from '../../../shared/assets/asset-view/lock.svg';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AssetDocuments() {
  const {
    assetStore: { currentAsset },
  } = useStores();
  const openDoc = (link: string | null) => {
    if (!link) return;
    window.open(link, '_blank');
  };

  return (
    <div className={c.assetDocuments}>
      <div className={c.title}>Documents</div>
      {currentAsset.documents?.length > 0 &&
        currentAsset.documents.map((doc, i) => (
          <button
            key={i}
            className={`${c.document} ${doc.file.url ? '' : `${c.disabled}`}`}
            onClick={() => openDoc(doc.file.url)}
          >
            {doc.file.url ? (
              <div className={c.img}>
                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1V23H19V9.28986L10.4576 1H1Z" strokeWidth="0.5" />
                  <path d="M10 1V9H19" strokeWidth="0.5" />
                  <line x1="4" y1="13.75" x2="16" y2="13.75" strokeWidth="0.5" />
                  <line x1="4" y1="16.75" x2="16" y2="16.75" strokeWidth="0.5" />
                  <line x1="4" y1="19.75" x2="16" y2="19.75" strokeWidth="0.5" />
                </svg>
              </div>
            ) : (
              <img src={Lock} alt="" />
            )}
            <span>{doc.description}</span>
          </button>
        ))}
    </div>
  );
}

export default observer(AssetDocuments);
