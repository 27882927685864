import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useParams } from 'react-router-dom';
import c from './style.module.scss';
import { TokenOnPortal } from '../../../shared/services/token/types';
import tokenService from '../../../shared/services/token/tokenService';
import '../../../app/styles/reusable/tables.scss';
import DefaultButton from '../../UI/buttons/default-button';
import { SignIssuer } from '../../../shared/services/sign-documents/types';
import portalService from '../../../shared/services/portal/portalService';
import AcceptActionDialog from '../../UI/dialogs/accept-action/accept-action-dialog';
import DeployInitialAssetModal from './deploy-initial-asset-modal';
import { InitialSaleAsset } from '../../../shared/services/portal/types';
import TokenRows from './token-rows';
import TableTokenHead from './table-token-head';

const getIssuersWithWallet = (issuers: SignIssuer[]) => issuers.filter(issuer => !!issuer.wallet);

const PortalTokens = () => {
  const { id } = useParams<{ id: string }>();
  const [tokens, setTokens] = useState<TokenOnPortal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [issuers, setIssuers] = useState<SignIssuer[]>([]);
  const [initialAssets, setInitialAssets] = useState<InitialSaleAsset[]>([]);
  const [isCreateToken, setIsCreateToken] = useState(false);
  const [isCreatingLoading, setIsCreatingLoading] = useState(false);
  const [error, setError] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [isDeploying, setIsDeploying] = useState(false);
  const [dto, setDto] = useState({
    tickerSymbol: '',
    symbolName: '',
    issuerId: '',
    portalId: id,
  });
  const onDtoChangeHandler = (e: any) => {
    setError('');
    setDto(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onCreateTokenHandler = async () => {
    if (dto.tickerSymbol.length < 3 || dto.tickerSymbol.length > 5) {
      setError('Ticker symbol must be 3-5 letters');
      return;
    }
    setIsCreatingLoading(true);
    try {
      await tokenService.createToken({ ...dto, portalId: id! });
      setIsCreatingLoading(false);
      setIsCreateToken(false);
      setError('');
      setDto({
        tickerSymbol: '',
        symbolName: '',
        issuerId: '',
        portalId: id,
      });
      getTokens();
    } catch (e) {
      setIsCreatingLoading(false);
    }
  };
  const getTokens = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await tokenService.getTokensByPortalId(id!);
      const dIssuers = await portalService.getIssuersByPortal(id!);
      setIssuers(dIssuers);
      setTokens(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getTokens();
  }, [getTokens, id]);

  useEffect(() => {
    if (!isDeploying) {
      setTokenId('');
      (async () => {
        try {
          const assets = await portalService.getPortalInitialSaleAssets(id!);
          setInitialAssets(assets);
          getTokens();
        } catch (e) {
          setInitialAssets([]);
        }
      })();
    }
  }, [getTokens, id, isDeploying]);

  const issuersWithWallet = useMemo(() => {
    return getIssuersWithWallet(issuers);
  }, [issuers]);

  const getIssuerData = useCallback(
    (wallet: string) => {
      const issuer = issuersWithWallet.find(iss => iss.wallet === wallet);
      if (!issuer) return '-';
      return `${issuer.firstName} ${issuer.lastName}`;
    },
    [issuersWithWallet],
  );

  return (
    <div className={c.wrapper}>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={10}>
        <DefaultButton variant="contained" onClick={() => setIsCreateToken(true)} disabled={!issuersWithWallet.length}>
          {!issuersWithWallet.length
            ? 'Deploy smart contract for token (no issuers with wallet)'
            : 'Deploy smart contract for token'}
        </DefaultButton>
      </Stack>
      <TableContainer
        component={Paper}
        className="wrapperDT"
        sx={{
          borderRadius: 0,
          boxShadow: 'none',
        }}
      >
        <Table>
          <TableTokenHead />
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Spinner single />
                </TableCell>
              </TableRow>
            )}
            {tokens.length > 0 &&
              tokens.map(token => (
                <TokenRows
                  key={token.id}
                  token={token}
                  setTokenId={setTokenId}
                  setIsDeploying={setIsDeploying}
                  getIssuerData={getIssuerData}
                  initialAssets={initialAssets}
                />
              ))}
            {!tokens.length && !isLoading && (
              <TableRow>
                <TableCell className="emptyBlockDT" colSpan={8}>
                  There are no tokens on this portal
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isDeploying && (
          <DeployInitialAssetModal
            open={isDeploying}
            setOpen={setIsDeploying}
            tokenId={tokenId}
            initialAssets={initialAssets}
          />
        )}
        <AcceptActionDialog
          open={isCreateToken}
          setOpen={setIsCreateToken}
          onAgreeHandler={onCreateTokenHandler}
          title="Create token"
          content="Input token symbol(3-5 letters), symbol name and select issuer"
          isActionLoading={isCreatingLoading}
          actionButtonText={isCreatingLoading ? 'Creating...' : 'Create token'}
        >
          <Stack gap={10}>
            <OutlinedInput name="tickerSymbol" onChange={onDtoChangeHandler} placeholder="Input ticker symbol" />
            {error && <div className={c.error}>{error}</div>}
            <OutlinedInput name="symbolName" onChange={onDtoChangeHandler} placeholder="Input symbol name" />
            <FormControl sx={{ mt: 8 }}>
              <InputLabel>Select issuer</InputLabel>
              <Select
                disabled={!issuersWithWallet.length}
                name="issuerId"
                onChange={onDtoChangeHandler}
                value={dto.issuerId}
                displayEmpty
                label="Select issuer"
              >
                {issuers.map(i => (
                  <MenuItem value={i.id} key={i.id} disabled={!i.wallet}>
                    {i.firstName} {i.lastName} {!i.wallet && "(hasn't a saved wallet)"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </AcceptActionDialog>
      </TableContainer>
    </div>
  );
};

export default PortalTokens;
