import { AxiosInstance } from 'axios';
import TokenInstance from '../API/tokenApi';
import { IDividendAssetItem } from '../../interfaces/userInfo.interface';
import withoutTokenInstance from '../API/withoutTokenAPI';

class AssetService {
  authInstance: AxiosInstance;

  withoutAuth: AxiosInstance;

  constructor(authInstance: AxiosInstance, wAuth: AxiosInstance) {
    this.authInstance = authInstance;
    this.withoutAuth = wAuth;
  }

  async getAllAssets(): Promise<IDividendAssetItem[]> {
    const { data } = await this.authInstance.get<IDividendAssetItem[]>('assets/admin/achieved');
    return data;
  }

  async sendNewDate(id: string, startDate: string): Promise<void> {
    await this.authInstance.put<{ startDate: string }>(`dividends/asset/${id}/distribution-start`, {
      startDate,
    });
  }

  // async calculateTestDividends(id: string, startDate: string, endDate: string, dividends: number): Promise<void> {
  //   const { data } = await this.authInstance.get(`dividends/asset/${id}/test`, {
  //     params: {
  //       startDate,
  //       endDate,
  //       dividends,
  //     },
  //   });
  //   return data;
  // }

  async calculateAdminDividends(id: string, startDate: string, endDate: string, dividends: number): Promise<void> {
    const { data } = await this.authInstance.get(`dividends/asset/${id}/cash/calculator/admin`, {
      params: {
        startDate,
        endDate,
        dividends,
      },
    });
    return data;
  }

  async calculateIssuerDividends(id: string, startDate: string, endDate: string, dividends: number): Promise<void> {
    const { data } = await this.authInstance.get(`dividends/asset/${id}/cash/calculator/issuer`, {
      params: {
        startDate,
        endDate,
        dividends,
      },
    });
    return data;
  }

  async exportAdminDividends(id: string, startDate: string, endDate: string, dividends: number): Promise<void> {
    const { data } = await this.authInstance.get(`dividends/asset/${id}/cash/report/admin`, {
      params: {
        startDate,
        endDate,
        dividends,
      },
      responseType: 'blob',
    });
    return data;
  }

  async exportIssuerDividends(id: string, startDate: string, endDate: string, dividends: number): Promise<void> {
    const { data } = await this.authInstance.get(`dividends/asset/${id}/cash/report/issuer`, {
      params: {
        startDate,
        endDate,
        dividends,
      },
      responseType: 'blob',
    });
    return data;
  }
}

export default new AssetService(TokenInstance, withoutTokenInstance);
