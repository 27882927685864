import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { observer } from 'mobx-react-lite';
import closeIcon from '../../../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import DefaultButton from '../../buttons/default-button';
import { useStores } from '../../../../store';
import tokenInstance from '../../../../shared/services/API/tokenApi';
import { AgreementNames } from '../../../../shared/services/sign-documents/types';

const ConnectWalletModal = ({ openModal, onClose }: { openModal: boolean; onClose: () => void }) => {
  const { walletStore } = useStores();
  const [confirmPhrase, setConfirm] = useState('');
  const [documentUrl, setUrl] = useState('');

  const handleSendClick = () => {
    tokenInstance.put('agreements', {
      agreements: [AgreementNames.fortressTrustAccountOpening],
    });
    walletStore.createCastodialAccount(onClose);
  };

  useEffect(() => {
    tokenInstance.get('/agreements/fortress/custodial').then(({ data }) => {
      setUrl(data.url);
    });
  }, []);

  return (
    <div className={c.wrapper}>
      <Dialog open={openModal} onClose={onClose}>
        <div className={c.dialog}>
          <div className={c.title}>Create Wallet</div>
          <Button
            onClick={() => {
              onClose();
            }}
            className={c.closeIcon}
            disabled={walletStore.isLoading}
          >
            <img src={closeIcon} alt="" />
          </Button>
          <ul className={c.text}>
            <li>Approve the wallet creation.</li>
            <li>
              By accepting, you agree to{' '}
              <a href={documentUrl} target="_blank" rel="noreferrer" className="link">
                Account Opening Agreement
              </a>
            </li>
          </ul>
          <p className={c.smallTitle}>Please type &#34;I approve&#34;.</p>
          <TextField
            type="text"
            onChange={e => {
              setConfirm(e.target.value);
            }}
            value={confirmPhrase}
            className={c.input}
            margin="normal"
            placeholder="Enter confirmation"
            inputProps={{
              maxLength: 9,
            }}
          />
          <div className="errorText">{walletStore.errorMessage}</div>
          <div className={c.actions}>
            <DefaultButton
              variant="contained"
              onClick={onClose}
              className={c.cancelButton}
              disabled={walletStore.isLoading}
            >
              Close
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={handleSendClick}
              disabled={confirmPhrase !== 'I approve' || walletStore.isLoading}
              className={c.submitButton}
            >
              {walletStore.isLoading ? 'Loading...' : 'Create Wallet'}
            </DefaultButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default observer(ConnectWalletModal);
