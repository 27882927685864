import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckBoxProps } from '../../../shared/interfaces/checkoutInterfaces';

const CheckboxInput = (props: CheckBoxProps) => {
  const { label, checkBoxHandler, checked, disabled, id, style } = props;

  return (
    <FormControlLabel
      style={style}
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          name={label}
          id={id}
          onChange={e => {
            checkBoxHandler(e);
          }}
        />
      }
      label={label || null}
      className="notifications-checkbox"
    />
  );
};

export default CheckboxInput;
