import React, { useEffect, useState } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { IconButton, TablePagination } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { Helmet } from 'react-helmet-async';
import InviteAdminModal from '../../../components/invite-admin-modal/ivniteAdminModal';
import c from './style.module.scss';
import { ProfileData } from '../../../shared/interfaces/headerInterface';
import tokenInstance from '../../../shared/services/API/tokenApi';
import filterDisabled from '../../../shared/assets/icons/filterArrowsDisabled.svg';
import filterASC from '../../../shared/assets/icons/filterArrowsASC.svg';
import filterDESC from '../../../shared/assets/icons/filterArrowsDESC.svg';
import { RequestParams } from '../../../shared/types/investorTableType';
import ProfileRow from '../../../components/profileRow/profileRow';
import { usePagination } from '../../../shared/hooks/usePagination';

export default function AdminsPage() {
  const [items, setItems] = useState<ProfileData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [refresh, setRefresh] = useState({});

  const loadItems = async (requestParams: RequestParams) => {
    setIsLoading(true);
    const { data } = await tokenInstance.get<{ users: ProfileData[]; total: number }>('users/admins', {
      params: requestParams,
    });
    setItems(data.users);
    setTotalItems(data.total);
    setIsLoading(false);
  };
  const {
    loadItems: load,
    onPageSizeChange,
    onCurrentPageChange,
    controlledParams,
    onOrderChange,
  } = usePagination({
    callback: loadItems,
  });

  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  useEffect(() => {
    load();
  }, [load, refresh]);

  return (
    <>
      <Helmet>
        <title>Admins | TokenSuite</title>
      </Helmet>
      <div className={c.title}>
        <FormattedMessage id="adminsPage.text.header" />
      </div>
      <div className={c.wrapper}>
        <div className={c.topPanel}>
          <div className={c.filterPanel}>
            <div className={c.left}>
              {/* <div className={c.text}>
                <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.name" />
              </div>
              <Select
                className={c.select}
                value={ordering.filterValue}
                onChange={e => {
                  setOrdering((o) => ({
                    ...o,
                    page: 0,
                    filterValue: e.target.value,
                  }));
                }}
              >
                <MenuItem value="all">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.all" />
                </MenuItem>
                <MenuItem value="Invited">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.invited" />
                </MenuItem>
                <MenuItem value="Registered">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.registered" />
                </MenuItem>
                <MenuItem value="Verified">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verified" />
                </MenuItem>
              </Select> */}
            </div>
            <div className={c.right}>
              <InviteAdminModal
                refreshList={() => {
                  setRefresh({});
                }}
              />
            </div>
          </div>
        </div>
        <div className={c.table}>
          <div className={c.titleRow}>
            <div className={c.columnLeftTitle}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.name" />
              <IconButton className={c.orderButton} disabled={isLoading} onClick={() => onOrderChange('name')}>
                {renderOrderIcon('name')}
              </IconButton>
            </div>
            <div className={c.columnRightTitle}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.status" />
              <IconButton disabled={isLoading} onClick={() => onOrderChange('status')} className={c.orderButton}>
                {renderOrderIcon('status')}
              </IconButton>
            </div>
          </div>
          <div className={c.profilesRows}>
            <div className={c.loadingCell}>
              {isLoading && (
                <div className={c.loading}>
                  <Spinner single />
                </div>
              )}
            </div>
            {items.length > 0 ? (
              items.map((profile: ProfileData) => <ProfileRow profileData={profile} allAdmins key={profile.email} />)
            ) : (
              <div className={c.emptyBlock}>
                <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
              </div>
            )}
          </div>
          <TablePagination
            className={c.pagination}
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={+controlledParams.pageSize}
            page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
            onPageChange={(_, newPage: number) => {
              onCurrentPageChange(newPage);
            }}
            onRowsPerPageChange={event => {
              onPageSizeChange(event.target.value);
            }}
            SelectProps={{
              disabled: isLoading,
            }}
            backIconButtonProps={{
              disabled: isLoading || +controlledParams.currentPage === 0,
            }}
            nextIconButtonProps={{
              disabled:
                isLoading ||
                Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
                totalItems === 0,
            }}
          />
        </div>
      </div>
    </>
  );
}
