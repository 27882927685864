import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Stack, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import closeIcon from '../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import FormikControl from '../formik/formik-control/formik-control';
import FormikContainer from '../formik/formik-container/formik-container';
import { fields } from './invite-issuer.meta';
import { inviteDemoIssuerSchema } from '../../shared/lib/validation-schemas/invite-issuer-schema';
import { useStores } from '../../store';
import { InviteDemoIssuerDto } from '../../shared/services/admin/adminService.types';

type InitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  subDomain?: string;
  portalName?: string;
  description?: string;
};

function DemoIssuerModal({ refreshList }: { refreshList?: () => void }) {
  const { adminStore } = useStores();
  const [open, setOpen] = useState(false);
  const [serverError, setServerError] = useState('');

  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    subDomain: '',
    portalName: '',
    description: '',
  };

  const onSubmit = async (values: InitialValues, formikHelpers: FormikHelpers<any>) => {
    setServerError('');
    try {
      const payload = { ...values };
      await flowResult(adminStore.inviteDemoIssuer({ ...payload } as InviteDemoIssuerDto));
      formikHelpers.resetForm();
      if (refreshList) {
        refreshList();
      }
      setOpen(false);
    } catch (e: any) {
      const errors: string[] = e?.response?.data?.errors;
      if (errors?.length) {
        const fieldErrors = Object.fromEntries(errors.map(item => item.split(':')));
        formikHelpers.setErrors(fieldErrors);
        return;
      }
      if (e?.response?.data?.message) {
        setServerError(e?.response?.data?.message);
      }
    }
  };

  const closeModalHandler = () => {
    setOpen(false);
  };

  return (
    <div className={c.wrapper}>
      <button onClick={() => setOpen(true)} className={c.submitButton}>
        Invite Demo issuer
      </button>
      <Dialog open={open} onClose={closeModalHandler}>
        <div className={c.dialog}>
          <div className={c.title}>Invite a Demo Issuer</div>
          <Button onClick={closeModalHandler} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <FormikContainer<typeof initialValues>
            initialValues={initialValues}
            validationSchema={inviteDemoIssuerSchema}
            onSubmitHandler={onSubmit}
          >
            {({ isSubmitting }) => (
              <Stack gap="20px">
                <div>
                  {fields.map(({ id, ...field }) => {
                    return (
                      <Box key={id} sx={{ mt: '10px' }}>
                        <FormikControl {...field} />
                      </Box>
                    );
                  })}
                </div>
                <Button type="submit" className={c.submitButton} disabled={isSubmitting}>
                  {isSubmitting ? 'Loading...' : 'Invite'}
                </Button>
                {serverError && (
                  <Stack alignItems="center" mt="5px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        color: '#f05555',
                      }}
                    >
                      {serverError}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </FormikContainer>
        </div>
      </Dialog>
    </div>
  );
}

export default observer(DemoIssuerModal);
