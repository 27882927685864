import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import c from './style.module.scss';
import { useStores } from '../../../../store';
import logoutIcon from '../../../../shared/assets/profileMenu/logout.svg';
import userPool from '../../../../shared/config/cognitoPool';
import { useMetaKeep } from '../../../../context/MetaKeepContext/MetaKeep.context';
import { SignContractsTokens } from './SignContractsTokens';
import { SuccessSignModal } from './SuccessSignModal';
import { LoadingContractModal } from './LoadingContractModal';

interface IProps {
  openModal: boolean;
  setClose: () => void;
}

const SignContractMetakeepModal = ({ openModal, setClose }: IProps) => {
  const { signContract, wallet, isLoading, approve } = useMetaKeep();
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const { issuerStore, headerStore } = useStores();

  const handleSignClick = async (tokenAddress: string) => {
    if (signContract) {
      const currentContractToSign = issuerStore.tokenContractsToSign.length;
      await approve(tokenAddress);
      let seconds = 0;
      setLoading(true);
      setErrorText('');
      const interval = setInterval(() => {
        seconds++;
        issuerStore.getTokensByPortalIssuer(wallet);
        if (issuerStore.tokenContractsToSign.length === 0) {
          setSuccess(true);
          setLoading(false);
          clearInterval(interval);
          return;
        }
        if (issuerStore.tokenContractsToSign.length !== currentContractToSign) {
          setLoading(false);
          clearInterval(interval);
          return;
        }
        if (seconds >= 10) {
          setErrorText('Something went wrong, contract not signed');
          setLoading(false);
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  const closeModal = () => {
    if (success) {
      setClose();
    }
  };

  const navigate = useNavigate();
  const cognitoUser = userPool.getCurrentUser();

  const logout = () => {
    headerStore.reset();
    issuerStore.clearContractsToSign();
    cognitoUser?.signOut();
    localStorage.clear();
    navigate('/login');
  };

  if (loading) return <LoadingContractModal closeModal={closeModal} openModal={openModal} />;

  return (
    <div className={c.wrapper}>
      <Dialog open={openModal} onClose={closeModal}>
        <div className={c.dialog}>
          <div className={c.title}>Sign purchase agreements</div>
          {success ? (
            <SuccessSignModal setClose={setClose} />
          ) : (
            <>
              <Button onClick={logout} className={c.logoutIcon}>
                <img src={logoutIcon} alt="" />
                Logout
              </Button>
              <p className={c.text}>
                To start the token sale please grant your wallet to spend each one of the following tokens
              </p>
              <SignContractsTokens
                isLoading={isLoading}
                tokens={issuerStore.tokenContractsToSign}
                handleSignClick={handleSignClick}
                wallet={wallet}
              />
              <div className={c.errorText}>{errorText}</div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default observer(SignContractMetakeepModal);
