import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import Back from '../../../shared/assets/icons/arrow-back.svg';
import PortalAssets from '../../../components/broker-portals/portal-assets/portal-assets';

const BrokerPortalAssetsPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Broker Dealer Assets | TokenSuite</title>
      </Helmet>
      <button className={c.backBtn} onClick={() => navigate(-1)}>
        <img src={Back} alt="" />
        Back
      </button>
      <p className="title">Assets on Broker Dealer</p>
      <PortalAssets />
    </div>
  );
};

export default BrokerPortalAssetsPage;
