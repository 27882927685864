import React from 'react';
import { useNavigate } from 'react-router-dom';
import PortalDetails from '../../../components/admin-portals/portal-details/portal-details';
import Back from '../../../shared/assets/icons/arrow-back.svg';
import c from './style.module.scss';

const AdminPortalPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <button className={c.backBtn} onClick={() => navigate(-1)}>
        <img src={Back} alt="" />
        Back
      </button>
      <h2>Portal details</h2>
      <PortalDetails />
    </>
  );
};

export default AdminPortalPage;
