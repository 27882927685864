import React from 'react';
import { Helmet } from 'react-helmet-async';
import BrokerPortals from '../../../components/broker-portals/BrokerPortals';

const BrokerPortalsPage = () => {
  return (
    <>
      <Helmet>
        <title>Broker Dealers | TokenSuite</title>
      </Helmet>
      <h2>Broker Dealers</h2>
      <BrokerPortals />
    </>
  );
};

export default BrokerPortalsPage;
