import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

type Column = {
  id: string;
  label: ReactElement | null;
  sortable?: true;
};

const columns: Column[] = [
  {
    id: 'name',
    label: <FormattedMessage id="blockListPage.table.name" />,
    sortable: true,
  },
  {
    id: 'email',
    label: <FormattedMessage id="blockListPage.table.email" />,
  },
  {
    id: 'walletNumber',
    label: <FormattedMessage id="blockListPage.table.walletNumber" />,
  },
  {
    id: 'reason',
    label: <FormattedMessage id="blockListPage.table.reason" />,
  },
  {
    id: 'date',
    label: <FormattedMessage id="blockListPage.table.date" />,
  },
];

export { columns };
