import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import TablePagination from '@mui/material/TablePagination';
import filterASC from '../../../../shared/assets/icons/filterArrowsASC.svg';
import filterDESC from '../../../../shared/assets/icons/filterArrowsDESC.svg';
import filterDisabled from '../../../../shared/assets/icons/filterArrowsDisabled.svg';
import c from './style.module.scss';
import HolderRow from './holder-row';
import FilterPanel from '../filter-panel/filter-panel';
import { columns } from './meta';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { RequestParams } from '../../../../shared/types/investorTableType';
import { TokenHolderInvestor } from '../../../../shared/interfaces/issuer/issuer.interfaces';
import issuerService from '../../../../shared/services/issuer/issuerService';

const HolderTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenHolders, setTokenHolders] = useState<TokenHolderInvestor[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const loadItems = async (params: RequestParams) => {
    setIsLoading(true);
    try {
      const data = await issuerService.getTokenHolders(params);
      setTotalItems(data.total);
      setTokenHolders(data?.records ?? []);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const {
    controlledParams,
    loadItems: load,
    onPageSizeChange,
    onCurrentPageChange,
    onOrderChange,
  } = usePagination({
    callback: loadItems,
  });

  useEffect(() => {
    load();
  }, [load]);
  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  return (
    <>
      <FilterPanel value={controlledParams.value} sortOrder={controlledParams.sortOrder} />
      <TableContainer
        component={Paper}
        className={c.wrapper}
        sx={{
          borderRadius: 0,
          boxShadow: 'none',
        }}
      >
        <Table aria-label="simple table">
          <TableHead className={c.tableHead}>
            <TableRow>
              {columns.map(col => (
                <TableCell key={col.id} className={c.headCell}>
                  <Stack direction="row" alignItems="center">
                    {col.label}
                    {col.sortable && (
                      <IconButton disabled={isLoading} onClick={() => onOrderChange(col.id)}>
                        {renderOrderIcon(col.id)}
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={c.body}>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={9} className={c.loading} align="center">
                  <Spinner single />
                </TableCell>
              </TableRow>
            )}
            {tokenHolders.length > 0 && tokenHolders.map(holder => <HolderRow holder={holder} key={holder.id} />)}
            {!tokenHolders.length && !isLoading && (
              <TableRow>
                <TableCell className={c.emptyBlock} colSpan={9}>
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={+controlledParams.pageSize}
        page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            isLoading ||
            Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
            totalItems === 0,
        }}
      />
    </>
  );
};

export default HolderTable;
