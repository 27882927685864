import { Button, IconButton, Tooltip } from '@mui/material';
import { AreaChart, Area } from 'recharts';
import { useState } from 'react';
// import { useParams } from 'react-router-dom';
import c from './styles.module.scss';
import photo from './testData/image.png';
import copyIcon from '../../../shared/assets/icons/copyMail.svg';
import { tokenPriceGraph, totalValueGraph } from './testData/testData';
import TopTokenHolders from './TopTokenHolders/TopTokenHolders';
import HistoricalPerformance from './HistoricalPerformance/HistoricalPerformance';

function Asset() {
  const [isAddresCopied, setIsAddresCopied] = useState(false);
  // const { id } = useParams();

  return (
    <div className={c.wrapper}>
      <header>
        <div className={c.field}>
          <p className={c.name}>Assets Total Value</p>
          <p className={c.value}>
            $2.5B
            <span> </span>
          </p>
        </div>
        <div className={c.field}>
          <p className={c.name}>30 Day Change</p>
          <p className={c.value}>
            $2.5B
            <span> (+0.1%)</span>
          </p>
        </div>
        <div className={c.field}>
          <p className={c.name}>Assets Total Value</p>
          <p className={c.value}>
            $90.3M
            <span> (+15%)</span>
          </p>
        </div>
      </header>
      <main>
        <div className={c.left}>
          <div className={c.block}>
            <p className={c.title}>Manhattan Terrace</p>
            <div className={c.first}>
              <div className={c.image}>
                <img src={photo} alt="" />
              </div>
              <div className={c.list}>
                <div className={c.item}>
                  <p className={c.name}>Token Price</p>
                  <p className={c.value}>$1.20</p>
                  <div className={c.graph}>
                    <AreaChart width={105} height={50} data={tokenPriceGraph}>
                      <Area dataKey="value" stroke="#0EC22B" fill="#0EC22B26" />
                    </AreaChart>
                  </div>
                </div>
                <div className={c.item}>
                  <p className={c.name}>Total USD Value</p>
                  <p className={c.value}>$12,000,000</p>
                  <div className={c.graph}>
                    <AreaChart width={105} height={50} data={totalValueGraph}>
                      <Area dataKey="value" stroke="#F74141" fill="#F7414133" />
                    </AreaChart>
                  </div>
                </div>
                <div className={`${c.distribution} ${c.item}`}>
                  <p className={c.name}>Distribution</p>
                  <p className={c.value}>$423,745</p>
                  <Button className={c.graph}>Distribute</Button>
                </div>
              </div>
            </div>
          </div>
          <div className={c.block}>
            <p className={c.title}>Top Token Holders</p>
            <TopTokenHolders />
          </div>
          <div className={c.block}>
            <p className={c.title}>Historical Performance</p>
            <HistoricalPerformance />
          </div>
        </div>

        <div className={c.right}>
          <div className={c.block}>
            <p className={c.title}>TokenInfo</p>
            <div className={c.tokenInfo}>
              <div className={c.item}>
                <p className={c.name}>Contract Address</p>
                <p className={c.value}>
                  0x6c675231.......1322
                  <Tooltip title={isAddresCopied ? 'Copied' : 'Copy to clipboard'} arrow>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText('0x6c675231.......1322');
                        setIsAddresCopied(true);
                        setTimeout(() => {
                          setIsAddresCopied(false);
                        }, 3000);
                      }}
                    >
                      <img src={copyIcon} alt="" />
                    </IconButton>
                  </Tooltip>
                </p>
              </div>
              <div className={c.item}>
                <p className={c.name}>Total Supply</p>
                <p className={c.value}>10,000,000 MHT</p>
              </div>
              <div className={c.item}>
                <p className={c.name}>Token Holders</p>
                <p className={c.value}>24</p>
              </div>
              <div className={c.item}>
                <p className={c.name}>Market Cap.</p>
                <p className={c.value}>$5,000,000</p>
              </div>
              <div className={c.item}>
                <p className={c.name}>Unlock Date</p>
                <p className={c.value}>April 29, 2023 (183 days left)</p>
              </div>
            </div>
          </div>
          {/* <div className={c.block}>
            <p className={c.title}>Token Allocation</p>
            <TokenAllocation />
          </div> */}
        </div>
      </main>
    </div>
  );
}
export default Asset;
