import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import Button from '@mui/material/Button';
import documentIcon from '../../../shared/assets/createAsset/documentIcon.svg';
import c from '../styles.module.scss';
import { useStores } from '../../../store';
import { IMarketerAnalysis } from '../../../shared/interfaces/assets/asset.interface';
import OneDocumentUploader from '../../createAsset/oneDocumentUploader/OneDocumentUploader';

function EditMarketBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [newDocument, setDocument] = useState(null);
  const [addNewError, setError] = useState('');
  const [newDescription, setNewDescription] = useState('');

  const [documents, setDocuments] = useState<IMarketerAnalysis[]>([]);

  const addNewDocument = () => {
    if (newDocument && newDescription.trim()) {
      setError('');
      const formData = new FormData();
      formData.append('description', newDescription);
      formData.append('file', newDocument);
      flowResult(assetStore.addMarket(id, formData));
      setNewDescription('');
      setDocument(null);
    } else {
      setError('All filed are required');
    }
  };

  const removeDocument = (index: number, documentId: string | undefined) => {
    const newDocuments = documents.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    if (documentId) {
      flowResult(assetStore.deleteMarket(id, documentId));
      setDocuments(newDocuments);
    }
  };

  useEffect(() => {
    setDocuments(assetStore.currentAsset.marketerAnalysis);
  }, [assetStore.currentAsset.marketerAnalysis]);

  if (assetStore.isMarketLoading)
    return (
      <div className={c.formPart}>
        <Spinner single />
      </div>
    );

  return (
    <div className={c.formPart}>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.marketAnalysisBlock" />
      </div>
      <div className={c.wrapper}>
        {documents.map((document, index) => {
          return (
            <div className={c.imgWrapper} key={index}>
              <a href={document.document.url} target="_blank" rel="noreferrer">
                <img src={documentIcon} alt="" />
                {document.description}
              </a>
              <Button variant="outlined" onClick={() => removeDocument(index, document.id)}>
                Delete Document
              </Button>
            </div>
          );
        })}
        <div className={c.left}>
          <OneDocumentUploader setDocument={setDocument} document={newDocument} showAlready />
          <TextInput
            value={newDescription}
            changeValue={setNewDescription}
            label={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.label' })}
            placeholder={intl.formatMessage({ id: 'uploader.mediaAndText.fields.description.placeholder' })}
            type="text"
            fullWidth
            multiline
          />
          <p className={c.errorText}>{addNewError}</p>
          <button className={c.addButton} onClick={addNewDocument}>
            + Add New Document
          </button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditMarketBlock);
