import { observer } from 'mobx-react-lite';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AdvanceHighlights() {
  const {
    assetStore: { currentAsset },
  } = useStores();
  return (
    <div className={c.assetHighlights}>
      <h2 className={c.title}>Highlights</h2>
      <ul className={c.highlightsList}>
        {currentAsset.highlights?.length &&
          currentAsset.highlights.map((el, i: number) => {
            return (
              <li key={i} className={c.highlightItem}>
                <p>{el.text}</p>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default observer(AdvanceHighlights);
