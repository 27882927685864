import { Dialog } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from './style.module.scss';

interface IProps {
  closeModal: () => void;
  openModal: boolean;
}

export const LoadingContractModal = ({ closeModal, openModal }: IProps) => {
  return (
    <div className={c.wrapper}>
      <Dialog open={openModal} onClose={closeModal}>
        <div className={c.dialog}>
          <div className={c.title}>Sign purchase agreements</div>
          <Spinner medium />
        </div>
      </Dialog>
    </div>
  );
};
