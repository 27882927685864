import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { observer } from 'mobx-react-lite';
import { DateTimeInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import closeIcon from '../../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import { useStores } from '../../../store';

function ChangeLockupModal() {
  const { userInfoStore } = useStores();
  const { id } = useParams();

  const [lockupEndDate, setDate] = useState<Date>(new Date());
  const [minDate, setMinDate] = useState<Date>(new Date());

  const [errors, setErrors] = useState<string[]>([]);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    const newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() + 1);
    setMinDate(newDate);
    const newLockupDate = new Date(userInfoStore.currentLockup.newLockupEndTime * 1000);
    setDate(newLockupDate);
  }, [userInfoStore.currentLockup.newLockupEndTime]);

  const handleClose = () => {
    setErrors([]);
    userInfoStore.closeLockupModal();
  };

  const handleSendClick = () => {
    if (!id) return;
    userInfoStore.sendNewLockup(lockupEndDate, id as string);
    handleClose();
  };

  return (
    <Dialog open={userInfoStore.isLockupModalOpen} onClose={handleClose}>
      <div className={c.dialog}>
        <div className={c.title}>Change Lockup Date</div>
        <Button onClick={handleClose} className={c.closeIcon}>
          <img src={closeIcon} alt="" />
        </Button>
        <DateTimeInput
          fullWidth
          value={lockupEndDate}
          changeValue={setDate}
          label="Lockup End Date"
          setError={setDateError}
          minDate={moment(minDate)}
        />
        {errors &&
          errors.map((error, index) => {
            return (
              <div className={c.errorText} key={index}>
                {error}
              </div>
            );
          })}
        <div className={c.actions}>
          <button onClick={handleSendClick} className={c.submitButton} disabled={dateError}>
            Change
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default observer(ChangeLockupModal);
