import React, { createContext, useState, useContext, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { MetaKeep as ImportedMetaKeep } from 'metakeep';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import tokenInstance from '../../shared/services/API/tokenApi';
import tokenObject from '../walletContext/blockChainFunctions';
import { IGetAllBalance } from '../../shared/interfaces/HeaderWallet.interface';
import { IAssetTransfersResult } from '../../shared/services/transactions/transactions.types';

const USDC_CONTRACT_ADDRESS = '0xB283bb0c7f632Cc0407Ef7ADd68742D83D786642';
const APP_ID = '5813de2d-bf80-425b-8599-d017a0994ef8';
const RPC_NODE_URL = 'https://rpc.ankr.com/polygon_mumbai';
const CHAIN_ID = 80001;

interface IUseHook {
  provider: any;
  wallet: string;
  maticBalance: number;
  balance: IGetAllBalance | null;
  error: string;
  isLoading: boolean;
  transactions: IAssetTransfersResult[] | null;
  setError: Dispatch<SetStateAction<string>>;
  signContract?: (tokenAddress: string, contractAddress: string, tokenQuantity: string) => void;
  cryptoPayment?: (
    tokenPaymentAddress: string,
    contractAddress: string,
    amount: string,
    id: string,
    unitPrice: string,
  ) => void;
  getBalance: () => Promise<any>;
  getUsdcBalance?: () => Promise<number>;
  getEthWallet?: () => Promise<string>;
  getOrCreateWallet: () => Promise<void>;
  initialProvider: () => Promise<void>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  getMaticBalance?: () => Promise<void>;
  getCryptoTransactionsByWallet: () => Promise<void>;
  approve: (token: string) => Promise<void>;
  transferToken: (to: string, amount: string, token: string) => Promise<void>;
}

interface IProps {
  children: React.ReactNode;
}

const initialState: IUseHook = {
  provider: undefined,
  error: '',
  isLoading: false,
  balance: null,
  wallet: '',
  maticBalance: 0,
  transactions: null,
  setError: () => undefined,
  getOrCreateWallet: async () => {},
  initialProvider: async () => {},
  setLoading: () => undefined,
  getBalance: async () => {},
  getMaticBalance: async () => {},
  getCryptoTransactionsByWallet: async () => {},
  approve: async () => {},
  transferToken: async () => {},
};

export const MetaKeepContext = createContext<IUseHook>(initialState);

export const useMetaKeep = () => {
  return useContext(MetaKeepContext);
};

export const MetaKeep: React.FC<IProps> = ({ children }) => {
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [sdkMetakeep, setSdk] = useState<any>(null);
  const [balance, setBalance] = useState<IGetAllBalance | null>(null);
  const [wallet, setWallet] = useState<string>('');
  const [error, setError] = useState('');
  const [maticBalance, setMaticBalance] = useState<number>(0);
  const [isLoading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<IAssetTransfersResult[] | null>(null);

  const navigate = useNavigate();

  const getOrCreateWallet = useCallback(async () => {
    await tokenInstance
      .get('users/wallet/metakeep')
      .then(async (data: any) => {
        setWallet(data);
      })
      .catch(() => {});
  }, []);

  const getMaticBalance = useCallback(async () => {
    setLoading(true);
    if (!provider) return;
    try {
      const balanceWei = await provider.getBalance(wallet);
      const balanceMatic = ethers.utils.formatEther(balanceWei);
      setLoading(false);
      setMaticBalance(+balanceMatic);
    } catch (err) {
      setLoading(false);
    }
  }, [provider, wallet]);

  const getBalance = useCallback(async (): Promise<void> => {
    await tokenInstance.get('users/wallet/metakeep/balance').then(async (data: any) => {
      setBalance(data.data);
    });
  }, []);

  const initialProvider = useCallback(async () => {
    setLoading(true);
    await tokenInstance
      .get('users/wallet/metakeep')
      .then(async (data: any) => {
        const sdk = new ImportedMetaKeep({
          appId: APP_ID,
          user: { email: data.data.email },
          chainId: CHAIN_ID,
          // @ts-ignore
          rpcNodeUrls: { 80001: RPC_NODE_URL },
        });

        const prov = await sdk.ethereum;
        await prov.enable();
        await getBalance();
        setWallet(data.data.wallet);
        setProvider(new ethers.providers.Web3Provider(prov));
        setSdk(sdk);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [getBalance]);

  const getEthWallet = useCallback(async () => {
    const userWallet = await sdkMetakeep.getWallet();
    return userWallet.wallet.ethAddress;
  }, [sdkMetakeep]);

  const getUsdcBalance = useCallback(async (): Promise<number> => {
    if (!provider) return 0;

    const abi = ['function balanceOf(address account) public view returns (uint)'];
    const contract = new ethers.Contract(USDC_CONTRACT_ADDRESS, abi, provider);
    const result = await contract.balanceOf(wallet);
    const walletBalance = await ethers.utils.formatUnits(result, 6);
    return +walletBalance || 0;
  }, [provider, wallet]);

  /* NOT WORK */
  const cryptoPayment = useCallback(
    async (tokenPaymentAddress: string, contractAddress: string, amount: string, id: string, unitPrice: string) => {
      setLoading(true);
      setError('');
      const balanceNow = await getUsdcBalance();
      if (balanceNow < +amount * +unitPrice) {
        setError('You do not have enough funds in your account');
        setTimeout(() => {
          setError('');
        }, 5000);
        setLoading(false);
        return;
      }
      if (provider) {
        const signer = await provider.getSigner();
        const abiSign = ['function approve(address spender, uint256 amount)'];
        const contractSIgn = new ethers.Contract(tokenPaymentAddress, abiSign, provider);
        const abiSale = ['function invest(uint256 paymentTokenAmount)'];
        const contractSale = new ethers.Contract(contractAddress, abiSale, provider);

        await contractSIgn
          .connect(signer)
          .approve(contractAddress, ethers.utils.parseUnits(String(+amount * +unitPrice), 6).toBigInt())
          .then(() => {
            setTimeout(async () => {
              const gasFee = await contractSale
                .connect(signer)
                .estimateGas.invest(ethers.utils.parseUnits(String(+amount * +unitPrice), 6).toBigInt());
              contractSale
                .connect(signer)
                .invest(ethers.utils.parseUnits(String(+amount * +unitPrice), 6).toBigInt(), {
                  gasLimit: Math.round(+gasFee * 1.1),
                })
                .then(() => {
                  navigate(`/checkout/confirmation/${id}/${amount}?method=blockchain`);
                })
                .finally(() => {
                  setLoading(false);
                });
            }, 3000);
          })
          .catch(() => {
            setLoading(false);
            setError('Something goes wrong');
            setTimeout(() => {
              setError('');
            }, 5000);
          });
      }
    },
    [provider, navigate, getUsdcBalance],
  );

  /* NOT WORK */
  const signContract = useCallback(
    async (tokenAddress: string, contractAddress: string, tokenQuantity: string) => {
      setLoading(true);
      if (provider) {
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(tokenAddress, tokenObject.abi, provider);
        await contract
          .connect(signer)
          .approve(contractAddress, ethers.utils.parseEther(tokenQuantity))
          .then(async (data: any) => {
            await tokenInstance.get(`initial-sale/approve/${data.hash}`);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [provider],
  );

  const getCryptoTransactionsByWallet = useCallback(async () => {
    setLoading(true);
    await tokenInstance.get(`transactions/wallet/${wallet}`).then(async response => {
      const data = response.data;

      setTransactions(data);
    });
  }, [wallet]);

  const transferToken = useCallback(
    async (to: string, amount: string, token: string) => {
      await tokenInstance
        .get(`token/transfer?to=${to}&amount=${amount}&token=${token}`)
        .then(async (data: any) => {
          const result = sdkMetakeep.getConsent(data.data.consentToken);

          if (result.status !== 'QUEUED') {
            setError('Something went wrong, contract not signed');
          }
        })
        .catch(() => {
          setError('Something went wrong, contract not signed');
          setLoading(false);
        });
    },
    [sdkMetakeep],
  );

  const approve = useCallback(
    async (token: string) => {
      setLoading(true);
      await tokenInstance
        .get(`token/approve/${token}`)
        .then(async (data: any) => {
          const result = await sdkMetakeep.getConsent(data.data.consentToken);
          if (result.status !== 'QUEUED') {
            setError('Something went wrong, contract not signed');
          }
        })
        .catch(() => {
          setError('Something went wrong, contract not signed');
          setLoading(false);
        });
    },
    [sdkMetakeep],
  );

  return (
    <MetaKeepContext.Provider
      value={useMemo(
        () => ({
          provider,
          maticBalance,
          balance,
          error,
          isLoading,
          wallet,
          transactions,
          setError,
          getOrCreateWallet,
          signContract,
          cryptoPayment,
          getBalance,
          getUsdcBalance,
          getEthWallet,
          initialProvider,
          setLoading,
          getMaticBalance,
          getCryptoTransactionsByWallet,
          approve,
          transferToken,
        }),
        [
          provider,
          wallet,
          balance,
          error,
          isLoading,
          maticBalance,
          transactions,
          setError,
          getOrCreateWallet,
          signContract,
          cryptoPayment,
          getBalance,
          getUsdcBalance,
          getEthWallet,
          initialProvider,
          setLoading,
          getMaticBalance,
          getCryptoTransactionsByWallet,
          approve,
          transferToken,
        ],
      )}
    >
      {children}
    </MetaKeepContext.Provider>
  );
};

export const MetaKeepProvider = MetaKeep;
