import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js';

class AppCognitoUser {
  private readonly cognitoUser: CognitoUser | null;

  constructor(cognitoPool: CognitoUserPool) {
    this.cognitoUser = cognitoPool.getCurrentUser();
  }

  getUserAttributes(): Promise<CognitoUserAttribute[] | undefined> {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.getUserAttributes((err, result?: CognitoUserAttribute[]) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  }

  updateAttributes(list: CognitoUserAttribute[]): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.updateAttributes(list, (err, result?: string) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  }

  sendMFACode(code: string, mfaType?: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.sendMFACode(
        code,
        {
          onSuccess: session => {
            resolve(session);
          },
          onFailure: err => {
            reject(err);
          },
        },
        mfaType,
      );
    });
  }

  getUserData(): Promise<UserData | undefined> {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.getUserData((err, result?: UserData) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  }

  getSession() {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.getSession((err: Error | null, session: null | CognitoUserSession) => {
        if (err) reject(err);
        resolve(session);
      });
    });
  }

  getSecretForQRcode() {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.associateSoftwareToken({
        associateSecretCode: (secretCode: string) => {
          resolve(secretCode);
        },
        onFailure: err => {
          reject(err);
        },
      });
    });
  }

  /*   authentificate(){
    return new Promise((resolve, reject) => {

    })
  } */

  verifyToken(code: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.verifySoftwareToken(code, 'This device', {
        onFailure: err => {
          reject(err);
        },
        onSuccess: result => {
          resolve(result);
        },
      });
    });
  }

  enableMFA() {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.setUserMfaPreference(
        null,
        {
          Enabled: true,
          PreferredMfa: true,
        },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        },
      );
    });
  }

  disableMFA(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.setUserMfaPreference(null, { Enabled: false, PreferredMfa: false }, (err, result?: string) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  }
}

export default AppCognitoUser;
