export const getFormmatedDateAndTime = (date: string) => {
  const temporaryDate = new Date(date);
  const dateFormatted = Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(temporaryDate);
  const timeFormatted = Intl.DateTimeFormat('en', {
    hour12: true,
    hour: '2-digit',
    minute: 'numeric',
  }).format(temporaryDate);
  return {
    dateFormatted,
    timeFormatted,
  };
};
