import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import DoneIcon from '@mui/icons-material/Done';
import BuyArrow from '../../../shared/assets/icons/buy.svg';
import { useStores } from '../../../store';
import CheckoutStages from '../../../components/checkout-payments/checkout-stages/checkoutStages';
import c from './style.module.scss';
import {
  EAccountAccreditationStatuses,
  EAccountStatuses,
  EAccountStatusesNorthCapital,
} from '../../../shared/types/account.types';
import ConnectFortressWalletModal from '../../../components/UI/dialogs/connectFortressWallet-modal/connectFortressWalletModal';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';

const iconStyles = {
  color: '#fff',
  fontSize: 16,
};

function CheckoutVerification() {
  const navigate = useNavigate();
  const { checkoutStore, portalStore, vereficationStore, headerStore } = useStores();
  const { id, tokens = 0 } = useParams();
  const [openConnectFortress, setOpenConnectFortress] = useState(false);

  useEffect(() => {
    flowResult(vereficationStore.getUserVerifyStatus());
  }, [vereficationStore]);

  useEffect(() => {
    if (!tokens || !id) {
      navigate('/assets');
    }
    if (checkoutStore.checkoutInfo.id !== id) {
      flowResult(checkoutStore.getCheckoutInfoById(id as string));
    }
  }, [id, tokens, navigate, checkoutStore]);

  const verifyStatus = vereficationStore.userInfo.verificationStatus;

  const continuePurchase = () => {
    if (id && tokens) {
      navigate(`/checkout/order/${id}/${tokens}`);
    }
  };

  const checkNotVerified = () => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return (
        verifyStatus.kycStatus !== EAccountStatuses.Verified ||
        (verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.Verified &&
          verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded) ||
        (verifyStatus.isBusiness &&
          verifyStatus.fortressKybVerificationStatus?.kybStatus !== EAccountStatuses.Verified) ||
        !headerStore.user.wallet
      );
    }
    return verifyStatus.status !== EAccountStatusesNorthCapital.Verified;
  };

  const renderSteps = () => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return (
        <>
          {(verifyStatus.kycStatus === EAccountStatuses.VerificationDenied ||
            verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationDenied ||
            verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Denied) && (
            <div className={`${c.step} ${c.error}`}>
              <div>
                <span>Your Identity Verefication check failed, contact the administrator</span>
              </div>
            </div>
          )}
          <div className={`${c.step} ${verifyStatus.kycStatus === EAccountStatuses.Verified && `${c.completed}`}`}>
            <div>
              <span>Complete your identity verification.</span>
              <button
                onClick={() => {
                  navigate('/kyc');
                }}
                className={c.link}
                disabled={verifyStatus.kycStatus !== EAccountStatuses.VerificationNotStartedYet}
              >
                {verifyStatus.kycStatus === EAccountStatuses.VerificationPending
                  ? 'Verification check is ongoing, please wait'
                  : 'Verify Now'}
              </button>
            </div>
            {verifyStatus.kycStatus === EAccountStatuses.Verified && (
              <div className={c.done}>
                <DoneIcon fontSize="small" sx={iconStyles} />
              </div>
            )}
          </div>
          {verifyStatus.kycStatus === EAccountStatuses.Verified &&
            verifyStatus.fortressKybVerificationStatus?.kybStatus && (
              <div
                className={`${c.step} ${
                  verifyStatus.fortressKybVerificationStatus.kybStatus === EAccountStatuses.Verified && `${c.completed}`
                }`}
              >
                <div>
                  <span>Complete your KYB verification.</span>
                  <button
                    onClick={() => {
                      navigate('/beneficiars');
                    }}
                    className={c.link}
                    disabled={
                      verifyStatus.fortressKybVerificationStatus?.kybStatus !==
                      EAccountStatuses.VerificationNotStartedYet
                    }
                  >
                    {verifyStatus.fortressKybVerificationStatus.kybStatus === EAccountStatuses.VerificationPending
                      ? 'Verification check is ongoing, please wait'
                      : 'Verify Now'}
                  </button>
                </div>
                {verifyStatus.fortressKybVerificationStatus.kybStatus === EAccountStatuses.Verified && (
                  <div className={c.done}>
                    <DoneIcon fontSize="small" sx={iconStyles} />
                  </div>
                )}
              </div>
            )}
          {verifyStatus.kycStatus === EAccountStatuses.Verified &&
            (!verifyStatus.isBusiness ||
              verifyStatus.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.Verified) &&
            verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded && (
              <div
                className={`${c.step} ${
                  verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Verified && `${c.completed}`
                }`}
              >
                <div>
                  <span>Complete your accreditation verification.</span>
                  <button
                    onClick={() => {
                      navigate('/accreditation');
                    }}
                    className={c.link}
                    disabled={verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.NotStartedYet}
                  >
                    {verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Pending
                      ? 'Verification check is ongoing, please wait'
                      : 'Verify Now'}
                  </button>
                </div>
                {verifyStatus.accreditationStatus === EAccountAccreditationStatuses.Verified && (
                  <div className={c.done}>
                    <DoneIcon fontSize="small" sx={iconStyles} />
                  </div>
                )}
              </div>
            )}
          <div className={`${c.step} ${headerStore.user.wallet && `${c.completed}`}`}>
            <div>
              <span>Create your Wallet</span>
              <button
                onClick={() => setOpenConnectFortress(true)}
                className={c.link}
                disabled={
                  verifyStatus.kycStatus !== EAccountStatuses.Verified ||
                  (verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.Verified &&
                    verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded) ||
                  (verifyStatus.isBusiness &&
                    verifyStatus.fortressKybVerificationStatus?.kybStatus !== EAccountStatuses.Verified) ||
                  headerStore.user.wallet
                }
              >
                Create
              </button>
              <ConnectFortressWalletModal
                openModal={openConnectFortress}
                onClose={() => {
                  setOpenConnectFortress(false);
                }}
              />
            </div>
            {headerStore.user.wallet && (
              <div className={c.done}>
                <DoneIcon fontSize="small" sx={iconStyles} />
              </div>
            )}
          </div>
        </>
      );
    }
    return (
      <>
        {(verifyStatus.status === EAccountStatusesNorthCapital.VerificationDenied ||
          verifyStatus.status === EAccountStatusesNorthCapital.VerificationDeniedKYCAML ||
          verifyStatus.status === EAccountStatusesNorthCapital.VerificationDeniedAccreditation) && (
          <div className={`${c.step} ${c.error}`}>
            <div>
              <span>Your Identity Verefication check failed, contact the administrator</span>
            </div>
          </div>
        )}
        <div
          className={`${c.step} ${
            verifyStatus.status !== EAccountStatusesNorthCapital.VerificationNotStartedYet &&
            verifyStatus.status !== EAccountStatusesNorthCapital.Verification &&
            `${c.completed}`
          }`}
        >
          <div>
            <span>Complete your identity verification.</span>
            <button
              onClick={() => {
                navigate('/kyc-nc');
              }}
              className={c.link}
              disabled={verifyStatus.status !== EAccountStatusesNorthCapital.VerificationNotStartedYet}
            >
              {verifyStatus.status === EAccountStatusesNorthCapital.Verification
                ? 'Verification check is ongoing, please wait'
                : 'Verify Now'}
            </button>
          </div>
          {verifyStatus.status !== EAccountStatusesNorthCapital.VerificationNotStartedYet &&
            verifyStatus.status !== EAccountStatusesNorthCapital.Verification && (
              <div className={c.done}>
                <DoneIcon fontSize="small" sx={iconStyles} />
              </div>
            )}
        </div>
        {(verifyStatus.status === EAccountStatusesNorthCapital.VerificationPending ||
          verifyStatus.status === EAccountStatusesNorthCapital.VerificationInProgress) && (
          <div className={`${c.step}`}>
            <div>
              <span>Complete your accreditation verification.</span>
              <button
                onClick={() => {
                  navigate('/accreditation');
                }}
                className={c.link}
                disabled={verifyStatus.status !== EAccountStatusesNorthCapital.VerificationPending}
              >
                {verifyStatus.status === EAccountStatusesNorthCapital.VerificationInProgress
                  ? 'Verification check is ongoing, please wait'
                  : 'Verify Now'}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={c.checkout}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Checkout Verification | ${portalStore.portalUI.portalUi.title}`
            : 'Checkout Verification | TokenSuite'}
        </title>
      </Helmet>
      <CheckoutStages />
      {vereficationStore.isLoading || checkoutStore.isLoading ? (
        <Spinner />
      ) : (
        <div className={c.container}>
          <div className={c.left}>
            <div className={c.title}>Before placing an order please finish next steps</div>
            <div className={c.steps}>{renderSteps()}</div>
          </div>
          <div className={c.right}>
            <div className={c.wrapper}>
              <div className={c.title}>Order Summary</div>
              <div className={c.image}>
                <img src={checkoutStore.checkoutInfo.previewImage.url} alt="" />
              </div>
              <div className={c.asset}>
                <span className={c.name}>{`${checkoutStore.checkoutInfo.name.slice(0, 22).trim()}...`}</span>
                <span className={c.cost}>
                  {addComma(String(+tokens * +checkoutStore.checkoutInfo.unitPrice))}{' '}
                  {checkoutStore.checkoutInfo.currency}
                </span>
              </div>
              <div className={c.tokens}>
                {+tokens}{' '}
                {checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`}{' '}
                tokens
              </div>
              <div className={c.total}>
                <span className={c.title}>Total:</span>
                <div className={c.amount}>
                  {CURRENCY_SYMBOLS[checkoutStore.checkoutInfo.currency]}
                  {addComma(String(+checkoutStore.checkoutInfo.unitPrice * +tokens))}{' '}
                  <span className={c.currency}>{checkoutStore.checkoutInfo.currency}</span>
                </div>
              </div>
              <button disabled={checkNotVerified()} className={c.next} onClick={continuePurchase}>
                Continue Order
                <img src={BuyArrow} alt="" />
              </button>
              <div className={c.verifyText}>
                <span className={c.errorText}>{checkoutStore.message}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(CheckoutVerification);
