import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { ICheckoutInfo, IPaymentMethodWithRequisites, IPaymentInfo } from '../../interfaces/checkout.interface';

class ChechoutService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async getCheckoutInfo(id: string): Promise<ICheckoutInfo> {
    const { data } = await this.authInstance.get<ICheckoutInfo>(`assets/${id}/checkout-info`);
    return data;
  }

  async getPaymentMethods(id: string): Promise<IPaymentMethodWithRequisites[]> {
    const { data } = await this.authInstance.get<IPaymentMethodWithRequisites[]>(`payments/requisites/asset/${id}`);
    return data;
  }

  async stripePurchase(payload: IPaymentInfo): Promise<IPaymentInfo[]> {
    const { data } = await this.authInstance.post('payments/stripe/checkout', payload);
    return data;
  }

  async fortressPurchase(payload: IPaymentInfo): Promise<void> {
    await this.authInstance.post('payments/custodial/fiat', payload);
  }
}

export default new ChechoutService(tokenInstance);
