import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import UndoIcon from '@mui/icons-material/Undo';
import { flowResult } from 'mobx';
import { SelectInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import MenuItem from '@mui/material/MenuItem';
import c from './style.module.scss';
import { useStores } from '../../../store';

interface IObjectKeys {
  [key: string]: string;
}

function PortalTheme({ id }: { id: string }) {
  const defaultColorSсheme = {
    primary: '#00BC68',
    'primary-dark': '#0DA75D',
    'hover-green': '#E5F8E8',
    'light-green': '#F6FFF8',
    bg: '#FFFFFF',
    'input-bg': '#F3F3F4',
    text: '#27303E',
    'text-dark': '#000000',
    'menu-text': '#0F1014',
    'text-secondary': '#8D8D8D',
    'text-grey': '#7D7D7D',
    'button-disabled': '#D2D2D2',
    'table-head-bg': '#E6E6EA',
    'table-head-text': '#494949',
  };

  const [colorTheme, setColor] = useState<IObjectKeys>(defaultColorSсheme);
  const [font, setFont] = useState('Montserrat');
  const [successMessage, setSuccessMessage] = useState(false);

  const { portalStore } = useStores();

  useEffect(() => {
    if (portalStore.currentPortal?.portalUi?.colorStyles) {
      setColor({
        primary: portalStore.currentPortal.portalUi.colorStyles.primary,
        'primary-dark': portalStore.currentPortal.portalUi.colorStyles.primaryDark,
        'hover-green': portalStore.currentPortal.portalUi.colorStyles.hoverGreen,
        'light-green': portalStore.currentPortal.portalUi.colorStyles.lightGreen,
        bg: portalStore.currentPortal.portalUi.colorStyles.bg,
        text: portalStore.currentPortal.portalUi.colorStyles.text,
        'text-dark': portalStore.currentPortal.portalUi.colorStyles.textDark,
        'menu-text': portalStore.currentPortal.portalUi.colorStyles.menuText,
        'text-secondary': portalStore.currentPortal.portalUi.colorStyles.textSecondary,
        'text-grey': portalStore.currentPortal.portalUi.colorStyles.textGrey,
        'button-disabled': portalStore.currentPortal.portalUi.colorStyles.buttonDisabled,
        'input-bg': portalStore.currentPortal.portalUi.colorStyles.inputBg,
        'table-head-bg': portalStore.currentPortal.portalUi.colorStyles.tableHeadBg,
        'table-head-text': portalStore.currentPortal.portalUi.colorStyles.tableHeadText,
      });
    }
    if (portalStore.currentPortal?.portalUi?.fontStyle) {
      setFont(portalStore.currentPortal?.portalUi?.fontStyle);
    }
  }, [portalStore.currentPortal?.portalUi?.colorStyles, portalStore.currentPortal?.portalUi?.fontStyle]);

  const resetColors = () => {
    setColor(defaultColorSсheme);
  };

  const changeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor({
      ...colorTheme,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = () => {
    const colors = {
      primary: colorTheme.primary,
      primaryDark: colorTheme['primary-dark'],
      bg: colorTheme.bg,
      text: colorTheme.text,
      textDark: colorTheme['text-dark'],
      menuText: colorTheme['menu-text'],
      textSecondary: colorTheme['text-secondary'],
      buttonDisabled: colorTheme['button-disabled'],
      hoverGreen: colorTheme['hover-green'],
      lightGreen: colorTheme['light-green'],
      inputBg: colorTheme['input-bg'],
      textGrey: colorTheme['text-grey'],
      tableHeadBg: colorTheme['table-head-bg'],
      tableHeadText: colorTheme['table-head-text'],
    };
    flowResult(portalStore.editColorScheme(colors, id));
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
    }, 5000);
  };

  const submitFont = () => {
    flowResult(portalStore.editPortalFont(font, id));
  };

  return (
    <div className={c.wrapper}>
      <h3>Color Theme</h3>
      <div className={c.colors}>
        {Object.keys(colorTheme).map((keyName, i) => (
          <div className={c.color} key={i}>
            <input
              type="color"
              name={keyName}
              value={colorTheme[keyName as keyof IObjectKeys]}
              onChange={e => changeColor(e)}
            />
            <p>{keyName}</p>
          </div>
        ))}
      </div>
      <div className={c.btns}>
        <button className={c.button} onClick={resetColors}>
          <UndoIcon /> Default Colors
        </button>
        <button className={c.button} onClick={submitForm}>
          Save Color Theme
        </button>
      </div>
      {successMessage && <p className={c.successMessage}>Colors Changed Successfully</p>}
      <SelectInput value={font} changeValue={setFont} label="Change Font Family" defaultValue={font} fullWidth>
        <MenuItem value="Montserrat" style={{ fontFamily: 'Montserrat' }}>
          Montserrat
        </MenuItem>
        <MenuItem value="Kulim Park" style={{ fontFamily: 'Kulim Park' }}>
          Kulim Park
        </MenuItem>
        <MenuItem value="Roboto" style={{ fontFamily: 'Roboto' }}>
          Roboto
        </MenuItem>
        <MenuItem value="Arial" style={{ fontFamily: 'Arial' }}>
          Arial
        </MenuItem>
        <MenuItem value="Verdana" style={{ fontFamily: 'Verdana' }}>
          Verdana
        </MenuItem>
        <MenuItem value="Courier New" style={{ fontFamily: 'Courier New' }}>
          Courier New
        </MenuItem>
        <MenuItem value="Tahoma" style={{ fontFamily: 'Tahoma' }}>
          Tahoma
        </MenuItem>
      </SelectInput>
      <div className={c.btns}>
        <button className={c.button} onClick={submitFont}>
          Change Font
        </button>
      </div>
    </div>
  );
}

export default observer(PortalTheme);
