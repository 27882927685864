import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { flowResult } from 'mobx';
import { FormattedMessage } from 'react-intl';

import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import AssetMap from '../../../components/asset-advansed-view/asset-map/assetMap';
import AdvanceInfo from '../../../components/asset-advansed-view/advance-info/advanceItemInfo';

// import AdvanceProjection from '../../../components/asset-advansed-view/advance-projection/advanceProjection';
import AdvanceContent from '../../../components/asset-advansed-view/advance-content/advanceItemContent';
import AdvanceHighlights from '../../../components/asset-advansed-view/advance-highlight/advanceHighlight';
import AdvanceInvestmentDetails from '../../../components/asset-advansed-view/advance-investment-details/advanceInvestmentDetails';
import AdvanceInvestorPerks from '../../../components/asset-advansed-view/advance-investor-perks/advance-investor-perks';
import AssetFullAccess from '../../../components/asset-advansed-view/asset-full-access/assetFullAccess';
import AssetDocuments from '../../../components/asset-advansed-view/asset-documents/assetDocuments';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';

function AssetPage() {
  const { id } = useParams();
  const {
    assetStore,
    portalStore,
    headerStore: {
      user: { role },
    },
  } = useStores();
  useEffect(() => {
    if (!id) return;
    flowResult(assetStore.getAssetInfoById(id as string));
  }, [assetStore, id]);

  useEffect(() => {
    if (document.documentElement.scrollTop > 0) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  if (assetStore.isLoading) return <Spinner />;
  if (assetStore.currentAsset.private) return <div>This asset is not available now</div>;
  if (assetStore.message) return <div>{assetStore.message}</div>;

  return (
    <div className={c.assetPage}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Asset | ${portalStore.portalUI.portalUi.title}`
            : 'Asset | TokenSuite'}
        </title>
      </Helmet>
      <Link to={role === 'issuer' || role === 'demoIssuer' ? '/issuer-assets' : '/assets'} className={c.back}>
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="createAssetPage.text.back" />
      </Link>
      <div className={c.assetTop}>
        <AdvanceInfo />
      </div>
      <div className={c.content}>
        <div className={c.left}>
          {assetStore.currentAsset.highlights?.length !== 0 && <AdvanceHighlights />}
          <AdvanceContent />
          {assetStore.currentAsset.showMap && <AssetMap />}
        </div>
        <div className={c.right}>
          <AdvanceInvestmentDetails />
          {localStorage.getItem('accessToken') ? (
            <>
              {/* <AdvanceProjection /> */}
              {assetStore.currentAsset.documents?.length !== 0 && <AssetDocuments />}
            </>
          ) : (
            <AssetFullAccess />
          )}
          {assetStore.currentAsset.investorPerks && <AdvanceInvestorPerks />}
        </div>
      </div>
    </div>
  );
}

export default observer(AssetPage);
