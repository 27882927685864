import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';

const getObjectFromURLSearchParams = (searchParams: URLSearchParams) => {
  const result: { [key: string]: string } = {};
  searchParams.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

const useFrontPagination = <T>(items: T[]) => {
  const [params, setParams] = useSearchParams();
  const [controlledParams, setControlledParams] = useState({
    pageSize: params.get('pageSize') || 10,
    currentPage: params.get('currentPage') || 0,
  });

  const paginationItems = useMemo(() => {
    const { pageSize, currentPage } = controlledParams;
    return items.slice(+pageSize * +currentPage, +pageSize * (+currentPage + 1));
  }, [controlledParams, items]);

  const onUrlParamChange = useCallback(
    (key: string, value: string | number, isRestCurrentPage = false) => {
      const newUrlSearchParams = new URLSearchParams(params);
      newUrlSearchParams.set(key, value.toString());
      if (isRestCurrentPage) {
        newUrlSearchParams.set('currentPage', (0).toString());
      }
      setParams(newUrlSearchParams);
      setControlledParams({ ...controlledParams, ...getObjectFromURLSearchParams(newUrlSearchParams) });
    },
    [controlledParams, params, setParams],
  );

  const onCurrentPageChange = useCallback(
    (value: number | string) => {
      onUrlParamChange('currentPage', value);
    },
    [onUrlParamChange],
  );

  const onPageSizeChange = useCallback(
    (value: number | string) => {
      onUrlParamChange('pageSize', value, true);
    },
    [onUrlParamChange],
  );

  return {
    onCurrentPageChange,
    onPageSizeChange,
    paginationItems,
    controlledParams,
  };
};

export { useFrontPagination };
