import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flowResult } from 'mobx';
import { useEffect } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import c from './styles.module.scss';
import { useStores } from '../../../store';
import EditMainBlock from '../../../components/editAsset/mainBlock/mainBlock';
import EditLocationBlock from '../../../components/editAsset/locationBlock/locationBlock';
import EditImagesBlock from '../../../components/editAsset/imagesBlock/imagesBlock';
import EditHighlightsBlock from '../../../components/editAsset/highlightsBlock/highlightsBlock';
import InvestmentDetailsBlock from '../../../components/editAsset/investmentDetailsBlock/investmentDetailsBlock';
import PresentationBlock from '../../../components/editAsset/presentationBlock/presentationBlock';
import EditMediaBlock from '../../../components/editAsset/mediaBlock/mediaBlock';
import HowItWorkBlock from '../../../components/editAsset/howItWorkBlock/howItWorkBlock';
import EditReviewsBlock from '../../../components/editAsset/reviewsBlock/reviewsBlock';
import EditPerksBlock from '../../../components/editAsset/perksBlock/perksBlock';
import EditDocumentsBlock from '../../../components/editAsset/documentsBlock/documentsBlock';
import EditMarketBlock from '../../../components/editAsset/marketBlock/marketBlock';
import AssetHideBlock from '../../../components/editAsset/assetHideBlock/assetHideBlock';

function EditAssetPage() {
  const { id } = useParams();
  const {
    assetStore,
    portalStore,
    headerStore: {
      user: { role },
    },
  } = useStores();
  useEffect(() => {
    if (!id) return;
    flowResult(assetStore.getAssetInfoById(id as string));
  }, [assetStore, id]);

  if (assetStore.isLoading) return <Spinner />;

  return (
    <>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Edit Asset | ${portalStore.portalUI.portalUi.title}`
            : 'Edit Asset | TokenSuite'}
        </title>
      </Helmet>
      <Link to={role === 'issuer' || role === 'demoIssuer' ? '/issuer-assets' : '/admin-assets'} className={c.back}>
        <img src={ArrowBackImg} alt="" />
        <FormattedMessage id="createAssetPage.text.back" />
      </Link>
      <div className={c.title}>Edit Asset - {assetStore.currentAsset.name}</div>
      <div className={c.block}>
        <p className={c.errorText}>{assetStore.message}</p>
        {id && (
          <>
            <EditMainBlock id={id} />
            {role === 'admin' && <AssetHideBlock id={id} />}
            <EditLocationBlock id={id} />
            <EditImagesBlock id={id} />
            <EditHighlightsBlock id={id} />
            <InvestmentDetailsBlock id={id} />
            <PresentationBlock id={id} />
            <EditMediaBlock id={id} />
            <HowItWorkBlock id={id} />
            <EditReviewsBlock id={id} />
            <EditPerksBlock id={id} />
            {!assetStore.currentAsset?.initialSale && <EditDocumentsBlock id={id} />}
            <EditMarketBlock id={id} />
          </>
        )}
      </div>
    </>
  );
}

export default observer(EditAssetPage);
