import { IconButton, Stack, TableCell, TableRow } from '@mui/material';
import React, { FC, useState } from 'react';
import copyIcon from '../../shared/assets/icons/copyMail.svg';
import { IssuerBrokerContract } from '../../shared/services/sign-documents/types';
import { copyDataInBuffer } from '../../shared/lib';
import IssuerContractModal from '../investorTable/active-contracts/issuer-contract-modal';

interface ContractRowProps {
  contract: IssuerBrokerContract;
}

const ContractRow: FC<ContractRowProps> = ({ contract }) => {
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);

  return (
    <TableRow className="tableRowDT">
      <TableCell className="nameCell">
        <Stack>
          <Stack direction="row" alignItems="center">
            {contract.broker.firstName} {contract.broker.lastName}
          </Stack>
          <Stack direction="row" alignItems="center" gap={8}>
            <div className="email">{contract.broker.email}</div>
            <IconButton onClick={() => copyDataInBuffer(contract.broker.email)} sx={{ p: 0 }}>
              <img src={copyIcon} alt="" />
            </IconButton>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell className="cellDT">{contract.asset.name}</TableCell>
      <TableCell className="cellDT">{contract.commission}%</TableCell>
      <TableCell
        className="cellDT"
        onClick={() => {
          setIsContractModalOpen(true);
        }}
      >
        <button className="defaultButton mainColor">Sign Contract</button>
      </TableCell>
      {isContractModalOpen && (
        <IssuerContractModal
          contractId={contract.id}
          open={isContractModalOpen}
          setOpen={setIsContractModalOpen}
          pandaDocLink={contract.pandaDocIssuerEmbeddedUrl}
        />
      )}
    </TableRow>
  );
};

export default ContractRow;
