import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, TablePagination } from '@mui/material';
import ActivityLogTable from '../common/activity-log-table';
import c from '../style.module.scss';
import { IActivityLog } from '../../../shared/services/activity-log/interfaces';
import activityLogService from '../../../shared/services/activity-log/activityLogService';
import { useFrontPagination } from '../../../shared/hooks/useFrontPagination';

const ActivityLogForInvestor = () => {
  const [items, setItems] = useState<IActivityLog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const { paginationItems, onPageSizeChange, onCurrentPageChange, controlledParams } = useFrontPagination(items);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const allActivities = await activityLogService.getAllByInvestor();
        setItems(allActivities);
        setTotalItems(allActivities?.length || 0);
      } catch {
        // handle error
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Stack className="nestedContainer">
      <Stack pt={24} px={16} pb={16}>
        <h3 className={c.title}>
          <FormattedMessage id="activityLog.title" />
        </h3>
      </Stack>
      <ActivityLogTable isLoading={isLoading} logs={paginationItems as IActivityLog[]} />
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={+controlledParams.pageSize}
        page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            isLoading ||
            Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
            totalItems === 0,
        }}
      />
    </Stack>
  );
};

export default ActivityLogForInvestor;
