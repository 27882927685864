import { IconButton, Stack, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { IssuerContract, SignedUser } from '../../../shared/services/sign-documents/types';
import CopyIcon from '../../../shared/assets/icons/copyMail.svg';
import { copyDataInBuffer } from '../../../shared/lib';
import ContractRowMenu from './contract-row-menu';

interface ContractRowProps {
  contract: IssuerContract;
}

const getInvestorFullName = (investor: SignedUser) => `${investor.firstName} ${investor.lastName}`;

const getFormattedDate = (date: string) =>
  new Date(date).toLocaleDateString('ru', {
    hour: '2-digit',
    minute: '2-digit',
  });

const ContractRow: FC<ContractRowProps> = ({ contract }) => {
  return (
    <TableRow className="tableRowDT">
      <TableCell className="cellDT">{contract.asset.name}</TableCell>
      <TableCell className="cellDT">{getInvestorFullName(contract.investor)}</TableCell>
      <TableCell className="cellDT">
        <Stack direction="row" alignItems="center">
          {contract.investor.email}
          <IconButton onClick={() => copyDataInBuffer(contract.investor.email)}>
            <img src={CopyIcon} alt="" />
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell className="cellDT">{Intl.NumberFormat('en-US').format(contract.tokens)}</TableCell>
      <TableCell className="cellDT">{contract.asset.tickerSymbol}</TableCell>
      <TableCell className="cellDT">{getFormattedDate(contract.investorSignedDate)}</TableCell>
      <TableCell align="center">
        <ContractRowMenu contractId={contract.id} pandaDocIssuerEmbeddedUrl={contract.pandaDocIssuerEmbeddedUrl} />
      </TableCell>
    </TableRow>
  );
};

export default ContractRow;
