import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import c from './style.module.scss';
import { useStores } from '../../../store';
import {
  EAccountAccreditationStatuses,
  EAccountStatuses,
  EAccountStatusesNorthCapital,
} from '../../../shared/types/account.types';

function UserInfo({ isIssuer = false }: { isIssuer?: boolean }) {
  const { id } = useParams();
  const { vereficationStore, portalStore } = useStores();

  useEffect(() => {
    if (!id) return;
    flowResult(vereficationStore.getUserVerifyStatusWithId(id as string));
  }, [id, vereficationStore]);

  const createWallet = () => {
    if (!id) return;
    vereficationStore.createWallet(id);
  };

  const verifyStatusFortress = vereficationStore.userInfoById.verificationStatus;
  const verifyStatusNC = vereficationStore.userInfoById.accountStatus;

  const renderButtons = () => {
    if (isIssuer) {
      if (portalStore.portalUI.features?.kycKyb === 'fortress') {
        return (
          <>
            <div className={c.text}>
              <b>Fortress KYC</b> - {verifyStatusFortress.kycStatus}
            </div>
            {verifyStatusFortress.isBusiness && (
              <div className={c.text}>
                <b>Fortress KYB</b> - {verifyStatusFortress.fortressKybVerificationStatus?.kybStatus}
              </div>
            )}
            {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
              verifyStatusFortress.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded && (
                <div className={c.text}>
                  <b>InvestReady Accreditation</b> - {verifyStatusFortress.accreditationStatus}
                </div>
              )}
            {verifyStatusFortress.kycStatus === EAccountStatuses.VerificationNotStartedYet && (
              <Link to={`/kyc?id=${id}`} className={c.button}>
                Complete Fortress KYC
              </Link>
            )}
            {verifyStatusFortress.kycStatus === EAccountStatuses.VerificationDenied && (
              <Link to={`/kyc?id=${id}`} className={c.button}>
                Resubmit Fortress KYC
              </Link>
            )}
            {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
              verifyStatusFortress.fortressKybVerificationStatus?.kybStatus ===
                EAccountStatuses.VerificationNotStartedYet && (
                <Link to={`/beneficiars?id=${id}`} className={c.button}>
                  Complete Fortress KYB
                </Link>
              )}
            {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
              verifyStatusFortress.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationDenied && (
                <Link to={`/beneficiars?resubmit=true&id=${id}`} className={c.button}>
                  Resubmit Fortress KYB
                </Link>
              )}
            {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
              (!verifyStatusFortress.isBusiness ||
                verifyStatusFortress.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.Verified) &&
              verifyStatusFortress.accreditationStatus === EAccountAccreditationStatuses.NotStartedYet && (
                <Link to={`/accreditation?id=${id}`} className={c.button}>
                  Complete Accreditation
                </Link>
              )}
          </>
        );
      }
      return (
        <>
          <div className={c.text}>
            <b>North Capital</b> - {verifyStatusNC.status}
          </div>
          {verifyStatusNC.status === EAccountStatusesNorthCapital.VerificationNotStartedYet && (
            <Link to={`/kyc-nc?id=${id}`} className={c.button}>
              Complete NorthCapital KYC
            </Link>
          )}
          {verifyStatusNC.status === EAccountStatusesNorthCapital.VerificationPending && (
            <Link to={`/accreditation?id=${id}`} className={c.button}>
              Complete Accreditation
            </Link>
          )}
        </>
      );
    }
    return (
      <>
        <div className={c.text}>
          <b>North Capital</b> - {verifyStatusNC.status}
        </div>
        <div className={c.text}>
          <b>Fortress KYC</b> - {verifyStatusFortress.kycStatus}
        </div>
        {verifyStatusFortress.isBusiness && (
          <div className={c.text}>
            <b>Fortress KYB</b> - {verifyStatusFortress.fortressKybVerificationStatus?.kybStatus}
          </div>
        )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
          verifyStatusFortress.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded && (
            <div className={c.text}>
              <b>InvestReady Accreditation</b> - {verifyStatusFortress.accreditationStatus}
            </div>
          )}
        {verifyStatusNC.status === EAccountStatusesNorthCapital.VerificationNotStartedYet && (
          <Link to={`/kyc-nc?id=${id}`} className={c.button}>
            Complete NorthCapital KYC
          </Link>
        )}
        {verifyStatusNC.status === EAccountStatusesNorthCapital.VerificationPending && (
          <Link to={`/accreditation?id=${id}`} className={c.button}>
            Complete NorthCapital Accreditation
          </Link>
        )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.VerificationNotStartedYet && (
          <Link
            to={vereficationStore.userInfoById.role === 'role:issuer' ? `/kyc?id=${id}&issuer=true` : `/kyc?id=${id}`}
            className={c.button}
          >
            Complete Fortress KYC
          </Link>
        )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.VerificationDenied && (
          <Link
            to={vereficationStore.userInfoById.role === 'role:issuer' ? `/kyc?id=${id}&issuer=true` : `/kyc?id=${id}`}
            className={c.button}
          >
            Resubmit Fortress KYC
          </Link>
        )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
          verifyStatusFortress.fortressKybVerificationStatus?.kybStatus ===
            EAccountStatuses.VerificationNotStartedYet && (
            <Link to={`/beneficiars?id=${id}`} className={c.button}>
              Complete Fortress KYB
            </Link>
          )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
          verifyStatusFortress.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.VerificationDenied && (
            <Link to={`/beneficiars?resubmit=true&id=${id}`} className={c.button}>
              Resubmit Fortress KYB
            </Link>
          )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
          (!verifyStatusFortress.isBusiness ||
            verifyStatusFortress.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.Verified) &&
          verifyStatusFortress.accreditationStatus === EAccountAccreditationStatuses.NotStartedYet && (
            <Link to={`/accreditation?id=${id}`} className={c.button}>
              Complete Accreditation
            </Link>
          )}
        {verifyStatusFortress.kycStatus === EAccountStatuses.Verified &&
          (!verifyStatusFortress.isBusiness ||
            verifyStatusFortress.fortressKybVerificationStatus?.kybStatus === EAccountStatuses.Verified) &&
          !vereficationStore.userInfoById.fortressWallet?.id &&
          vereficationStore.userInfoById.role === 'role:issuer' && (
            <button className={c.button} onClick={createWallet}>
              Create Crypto Wallet
            </button>
          )}
      </>
    );
  };

  return (
    <div className={c.wrapper}>
      <div className={c.topPanel}>
        <div className={c.text}>
          <b>
            {vereficationStore.userInfoById.firstName} {vereficationStore.userInfoById.lastName} (
            {vereficationStore.userInfoById.role.slice(5)})
          </b>{' '}
          - {vereficationStore.userInfoById.accountStatus.activated ? 'Activated' : 'Not Activated'}
        </div>
        <div className={c.text}>{vereficationStore.userInfoById.email}</div>
        {renderButtons()}
      </div>
    </div>
  );
}

export default observer(UserInfo);
