import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Stack, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import closeIcon from '../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import tokenInstance from '../../shared/services/API/tokenApi';
import FormikControl from '../formik/formik-control/formik-control';
import FormikContainer from '../formik/formik-container/formik-container';
import { fields } from './invite-broker.meta';
import { inviteBrokerSchema } from '../../shared/lib/validation-schemas/invite-issuer-schema';

type InitialValues = {
  firstName: string;
  lastName: string;
  email: string;
};

interface InviteBrokerDTO {
  firstName: string;
  lastName: string;
  email: string;
  isMainBroker: boolean;
  brokeragePortalId?: string;
}

function InviteBrokerModal({
  refreshList,
  portalId,
  resetPortalId,
  isMainBroker = false,
}: {
  refreshList?: () => void;
  portalId?: string;
  resetPortalId?: () => void;
  isMainBroker?: boolean;
}) {
  const [open, setOpen] = useState(!!portalId);
  const [serverError, setServerError] = useState('');

  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const onSubmit = async (values: InitialValues, formikHelpers: FormikHelpers<any>) => {
    setServerError('');
    try {
      const payload: InviteBrokerDTO = { ...values, isMainBroker };
      if (portalId) {
        payload.brokeragePortalId = portalId;
      }
      await tokenInstance.post('users/brokers/invitation', payload);
      formikHelpers.resetForm();
      if (refreshList) {
        refreshList();
      }
      setOpen(false);
    } catch (e: any) {
      const errors: string[] = e?.response?.data?.errors;
      if (errors?.length) {
        const fieldErrors = Object.fromEntries(errors.map(item => item.split(':')));
        formikHelpers.setErrors(fieldErrors);
        return;
      }
      if (e?.response?.data?.message) {
        setServerError(e?.response?.data?.message);
      }
    }
  };

  const closeModalHandler = () => {
    setOpen(false);
    if (resetPortalId) {
      resetPortalId();
    }
  };

  return (
    <div className={c.wrapper}>
      {!portalId && (
        <button onClick={() => setOpen(true)} className={c.submitButton}>
          Invite {isMainBroker ? 'Main Broker' : 'Broker Rep'}
        </button>
      )}
      <Dialog open={open} onClose={closeModalHandler}>
        <div className={c.dialog}>
          <div className={c.title}>Invite a {isMainBroker ? 'Main Broker' : 'Broker Rep'}</div>
          <Button onClick={closeModalHandler} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <FormikContainer<typeof initialValues>
            initialValues={initialValues}
            validationSchema={inviteBrokerSchema}
            onSubmitHandler={onSubmit}
          >
            {({ isSubmitting }) => (
              <Stack gap="20px">
                <div>
                  {fields.map(({ id, ...field }) => {
                    return (
                      <Box key={id} sx={{ mt: '10px' }}>
                        <FormikControl {...field} />
                      </Box>
                    );
                  })}
                </div>
                <Button type="submit" className={c.submitButton} disabled={isSubmitting}>
                  {isSubmitting ? 'Loading...' : 'Invite'}
                </Button>
                {serverError && (
                  <Stack alignItems="center" mt="5px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        color: '#f05555',
                      }}
                    >
                      {serverError}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </FormikContainer>
        </div>
      </Dialog>
    </div>
  );
}

export default InviteBrokerModal;
