import React from 'react';
import { useIntl } from 'react-intl';
import SecuritySetting from './security/SecuritySetting';
import SettingBlock from './UI/SettingBlock';

const AccountSetting = () => {
  const intl = useIntl();

  return (
    <div>
      <SettingBlock title={intl.formatMessage({ id: 'accountSetting.security' })}>
        <SecuritySetting />
      </SettingBlock>
    </div>
  );
};

export default AccountSetting;
