import React from 'react';
import HolderTable from './holder-table/holder-table';

const TokenHolders = () => {
  return (
    <div>
      <HolderTable />
    </div>
  );
};

export default TokenHolders;
