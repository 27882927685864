import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import React, { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { RecordData } from './dividendHistory';
import c from './styles.module.scss';
import { MONTH_NAMES } from '../../../shared/config/constants';

interface IAllowListRowProps {
  row: RecordData;
  isLoading: boolean;
}

const DividendHistoryRow: FC<IAllowListRowProps> = ({ row, isLoading }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const onCloseMenuHandler = () => {
    setAnchorEl(null);
  };

  const onOpenMenuHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const pad = (num: number) => `0${num}`.slice(-2);

  const getTimeFromISO = (isoDate: string) => {
    const date = new Date(isoDate);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${pad(hours)}:${pad(minutes)}`;
  };

  const getDateFromISO = (isoDate: string) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = MONTH_NAMES[date.getMonth()];
    const days = date.getDate();
    return `${days} ${month}, ${year}`;
  };

  const onRedirectToViewDetailsHandler = (id: string) => {
    navigate(`/investors/investor-details/${id}`);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      <TableCell>
        <div className={c.name}>
          <div className={c.date}>{getDateFromISO(row.reportingDate)}</div>
          <div className={c.time}>{getTimeFromISO(row.reportingDate)}</div>
        </div>
      </TableCell>
      <TableCell>
        <div className={c.name}>{row.amountOfMoney}</div>
      </TableCell>
      <TableCell align="right">
        <IconButton disabled={isLoading} onClick={onOpenMenuHandler}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={onCloseMenuHandler}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => onRedirectToViewDetailsHandler(row.id)} disabled>
            <FormattedMessage id="investorsPage.menu.viewDetails" />
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default DividendHistoryRow;
