import { FormikControlProps } from '../../formik/formik-control/formik-control.types';

const fields: Array<FormikControlProps & { id: number }> = [
  {
    id: 1,
    control: 'input',
    inputProps: {
      name: 'title',
      placeholder: 'Enter title',
      label: 'Title',
    },
  },
  {
    id: 2,
    control: 'input',
    inputProps: {
      name: 'authDescription',
      placeholder: 'Enter auth description',
      label: 'Auth description',
    },
  },
  {
    id: 3,
    control: 'input',
    inputProps: {
      name: 'metaDescription',
      placeholder: 'Enter meta description',
      label: 'Meta description',
    },
  },
  {
    id: 4,
    control: 'input',
    inputProps: {
      name: 'googleTag',
      placeholder: 'Enter google tag manager id ',
      label: 'Google Tag Manager ID',
    },
  },
];

export { fields };
