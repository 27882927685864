import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const FileView = ({ file }: { file: Blob }) => {
  const fileUrl = URL.createObjectURL(file);
  const isImage = ['jpeg', 'png', 'jpg', 'gif'].includes(file.type.split('/')[1]);

  const getRenderedSize = () => {
    const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const fileSize = file.size;
    const i = Math.floor(Math.log(fileSize) / Math.log(1024));
    return `${parseFloat((fileSize / 1024 ** i).toFixed(2))} ${suffixes[i]}`;
  };

  const onDownloadFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.download = `${file.size}_${Math.random()}.${file.type.split('/')[1]}`;
    link.href = fileUrl;
    link.click();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={20}
      sx={{
        border: theme => `1.5px solid ${theme.palette.primary.main}`,
        p: 10,
        borderRadius: 1,
      }}
    >
      <Box display="flex" alignItems="center" gap={15}>
        <Box
          sx={[
            {
              width: 60,
              height: 60,
              overflow: 'hidden',
            },
            {
              '& img,svg': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            },
          ]}
        >
          {isImage ? <img src={fileUrl} alt="" /> : <UploadFileIcon />}
        </Box>
        <Typography fontWeight={500} fontSize="1rem">
          {`${file.type.split('/')[1]} ${getRenderedSize()}`}
        </Typography>
      </Box>
      <Button variant="contained" onClick={onDownloadFile}>
        Download
      </Button>
    </Stack>
  );
};

export default FileView;
