import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';
import BuyArrow from '../../../shared/assets/icons/buy.svg';
import { useStores } from '../../../store';
import CheckoutStages from '../../../components/checkout-payments/checkout-stages/checkoutStages';
import CheckboxInput from '../../../components/checkout-payments/checkbox-input/checkboxInput';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';
import c from './style.module.scss';
import {
  EAccountAccreditationStatuses,
  EAccountStatuses,
  EAccountStatusesNorthCapital,
} from '../../../shared/types/account.types';
import { IPaymentMethodWithRequisites } from '../../../shared/interfaces/checkout.interface';
import { useMetaKeep } from '../../../context/MetaKeepContext/MetaKeep.context';

function CheckoutPayment() {
  const navigate = useNavigate();
  const { checkoutStore, portalStore, vereficationStore, headerStore, walletStore } = useStores();
  const { wallet, isLoading, provider, initialProvider, cryptoPayment } = useMetaKeep();
  const { id, tokens = 0 } = useParams();

  useEffect(() => {
    flowResult(vereficationStore.getUserVerifyStatus());
  }, [vereficationStore]);

  useEffect(() => {
    if (!tokens || !id) {
      navigate('/assets');
      return;
    }
    if (checkoutStore.checkoutInfo.id !== id) {
      flowResult(checkoutStore.getCheckoutInfoById(id as string));
    }
    flowResult(checkoutStore.isContractExist());
    flowResult(checkoutStore.getPortalContractStatus(id));
    flowResult(checkoutStore.getPaymentMethods(id));
  }, [checkoutStore, id, tokens, navigate]);

  useEffect(() => {
    flowResult(walletStore.getFiatBalances());
    flowResult(walletStore.getCryptoBalances());
  }, [walletStore]);

  const verifyStatus = vereficationStore.userInfo.verificationStatus;

  const checkVerified = () => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return (
        !selectedMethod ||
        isLoading ||
        verifyStatus.kycStatus !== EAccountStatuses.Verified ||
        (verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.Verified &&
          verifyStatus.accreditationStatus !== EAccountAccreditationStatuses.NotNeeded) ||
        (verifyStatus.isBusiness &&
          verifyStatus.fortressKybVerificationStatus?.kybStatus !== EAccountStatuses.Verified) ||
        portalStore.portalUI.isDemo ||
        +checkoutStore.checkoutInfo.paymentTokenRaised + +tokens > +checkoutStore.checkoutInfo.maxAmount ||
        (!checkoutStore.contractData?.completed && checkoutStore.isExist) ||
        !headerStore.user.wallet ||
        (walletStore.fiatBalance < +checkoutStore.checkoutInfo.unitPrice * +tokens && selectedMethod === 'fiat')
      );
    }
    return (
      !selectedMethod ||
      isLoading ||
      verifyStatus.status !== EAccountStatusesNorthCapital.Verified ||
      portalStore.portalUI.isDemo ||
      +checkoutStore.checkoutInfo.paymentTokenRaised + +tokens > +checkoutStore.checkoutInfo.maxAmount ||
      (!checkoutStore.contractData?.completed && checkoutStore.isExist) ||
      (!wallet &&
        (process.env.REACT_APP_SHOW_CONNECT_WALLET !== 'false' ||
          window.location.hostname === 'prod-test.dibscapital.com' ||
          window.location.hostname === 'assets-portal.solidblock.co' ||
          window.location.hostname === 'issuer1.dibscapital.com' ||
          window.location.hostname === 'f4.dibscapital.com' ||
          window.location.hostname === 'johndoeassets.dibscapital.com'))
    );
  };

  const [selectedMethod, setSelectedMethod] = useState('');

  const postPurchase = async () => {
    if (checkoutStore.contractData?.id && checkoutStore.isExist) {
      await checkoutStore.closeContract(
        checkoutStore.contractData?.id,
        selectedMethod === 'fiat' ? 'wire' : selectedMethod.toLowerCase(),
      );
    }
    if (selectedMethod === 'fiat' && id) {
      await flowResult(
        checkoutStore.fortressPurchase({
          assetId: id,
          tokensCount: +tokens,
        }),
      );
      if (checkoutStore.successPayment) {
        navigate(`/checkout/confirmation/${id}/${tokens}?method=fiat`);
      }
      return;
    }
    if (selectedMethod === 'blockchain' && cryptoPayment && id && checkoutStore.checkoutInfo.initialSale) {
      cryptoPayment(
        checkoutStore.checkoutInfo.initialSale.token.tokenPaymentAddress,
        checkoutStore.checkoutInfo.initialSale.contractAddress,
        tokens.toString(),
        id,
        checkoutStore.checkoutInfo.unitPrice,
      );
      return;
    }
    if (selectedMethod === 'stripe' && id) {
      checkoutStore.stripePurchase({
        assetId: id,
        tokensCount: +tokens,
      });
      return;
    }
    navigate(`/checkout/confirmation/${id}/${tokens}?method=${selectedMethod}`);
  };

  function renderTitle() {
    if (portalStore.portalUI.isDemo) {
      return <div className={c.head}>Payment disabled on demo portal</div>;
    }
    return <div className={c.head}>Select a Payment Method</div>;
  }

  const renderFortressBanner = (className?: string) => {
    if (!checkoutStore.contractData?.completed && checkoutStore.isExist) {
      return (
        <div
          className={className ? `${c.verifyText}` : `${c.banner}`}
          style={className ? undefined : { backgroundColor: '#F64A00' }}
        >
          <span>You don`t have a signed Purchase Agreement or it is out of date</span>
          <Link className={c.link} to={`/checkout/order/${id}/${tokens}`}>
            Sign again
          </Link>
        </div>
      );
    }
    if (walletStore.fiatBalance < +checkoutStore.checkoutInfo.unitPrice * +tokens && selectedMethod === 'fiat') {
      return (
        <div className={className ? `${c.verifyText}` : `${c.banner}`}>
          <span>You don`t have enough funds on your balance. Please pop up your account </span>
          <Link className={c.link} to="/wallet?enroll=true">
            Wallet
          </Link>
        </div>
      );
    }
    return null;
  };

  const renderNCBanner = (className?: string) => {
    if (!checkoutStore.contractData?.completed && checkoutStore.isExist) {
      return (
        <div
          className={className ? `${c.verifyText}` : `${c.banner}`}
          style={className ? undefined : { backgroundColor: '#F64A00' }}
        >
          <span>You don`t have a signed Purchase Agreement or it is out of date</span>
          <Link className={c.link} to={`/checkout/order/${id}/${tokens}`}>
            Sign again
          </Link>
        </div>
      );
    }
    if (
      (!wallet || !provider) &&
      selectedMethod === 'blockchain' &&
      (process.env.REACT_APP_SHOW_CONNECT_WALLET !== 'false' ||
        window.location.hostname === 'prod-test.dibscapital.com' ||
        window.location.hostname === 'assets-portal.solidblock.co' ||
        window.location.hostname === 'issuer1.dibscapital.com' ||
        window.location.hostname === 'f4.dibscapital.com' ||
        window.location.hostname === 'johndoeassets.dibscapital.com')
    ) {
      return (
        <div
          className={className ? `${c.verifyText}` : `${c.banner}`}
          style={className ? undefined : { backgroundColor: '#F64A00' }}
        >
          <span>In order to complete your order, you must connect your Wallet.</span>
          <button className={c.link} disabled={isLoading} onClick={initialProvider}>
            <FormattedMessage id="header.wallet.connect" />
            {isLoading && <Spinner single size={20} style={{ marginLeft: 10, minWidth: 20 }} />}
          </button>
        </div>
      );
    }
    return <> </>;
  };

  const renderMethods = () => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return (
        <label className={c.methodItem} htmlFor="fiat">
          <CheckboxInput
            checked={selectedMethod === 'fiat'}
            id="fiat"
            label=""
            disabled={!headerStore.user.wallet}
            checkBoxHandler={() => {
              setSelectedMethod('fiat');
            }}
          />
          <span className={c.label}>Fiat</span>
        </label>
      );
    }
    return (
      <>
        {checkoutStore.paymentMethods.map((method: IPaymentMethodWithRequisites) => (
          <label className={c.methodItem} key={method.name} htmlFor={method.name}>
            <CheckboxInput
              disabled={false}
              checked={selectedMethod === method.name}
              id={method.name}
              label=""
              checkBoxHandler={() => {
                setSelectedMethod(method.name);
              }}
            />
            <span className={c.label}>{method.name}</span>
          </label>
        ))}
        {(window.location.hostname === 'invest.airbc.co' ||
          window.location.hostname === 'airbc.solidblock.co' ||
          window.location.hostname === 'localhost') &&
          (+tokens * +checkoutStore.checkoutInfo.unitPrice <= 999999 &&
          +tokens * +checkoutStore.checkoutInfo.unitPrice >= 1000 ? (
            <label className={c.methodItem} key="stripe" htmlFor="stripe">
              <CheckboxInput
                checked={selectedMethod === 'stripe'}
                id="stripe"
                label=""
                checkBoxHandler={() => {
                  setSelectedMethod('stripe');
                }}
              />
              <span className={c.label}>Credit Card</span>
            </label>
          ) : (
            <Tooltip
              title={`Acceptable amounts: 1000-1000000 ${checkoutStore.checkoutInfo.currency}`}
              placement="bottom"
              arrow
              disableInteractive
              enterDelay={600}
            >
              <label className={`${c.methodItem} ${c.disabled}`} key="stripe" htmlFor="stripe">
                <CheckboxInput
                  disabled
                  checked={selectedMethod === 'stripe'}
                  id="stripe"
                  label=""
                  checkBoxHandler={() => {
                    setSelectedMethod('stripe');
                  }}
                />
                <span className={c.label}>Credit Card</span>
              </label>
            </Tooltip>
          ))}
        {/* {checkoutStore.checkoutInfo.initialSale?.status === 'APPROVE' && (
          <label className={c.methodItem} htmlFor="blockchain">
            <CheckboxInput
              checked={selectedMethod === 'blockchain'}
              id="blockchain"
              label=""
              checkBoxHandler={() => {
                setSelectedMethod('blockchain');
              }}
            />
            <span className={c.label}>Crypto (USDC)</span>
          </label>
        )} */}
      </>
    );
  };

  return (
    <div className={c.checkout}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Payment | ${portalStore.portalUI.portalUi.title}`
            : 'Payment | TokenSuite'}
        </title>
      </Helmet>
      {!portalStore.portalUI.isDemo && portalStore.portalUI.features?.kycKyb !== 'fortress' && renderNCBanner()}
      {!portalStore.portalUI.isDemo && portalStore.portalUI.features?.kycKyb === 'fortress' && renderFortressBanner()}
      <CheckoutStages />
      {checkoutStore.isLoading ? (
        <Spinner />
      ) : (
        <div className={c.container}>
          <div className={c.left}>
            {renderTitle()}
            <div className={c.methods}>{renderMethods()}</div>
          </div>
          <div className={c.right}>
            <div className={c.wrapper}>
              <div className={c.title}>Order Summary</div>
              <div className={c.image}>
                <img src={checkoutStore.checkoutInfo.previewImage.url} alt="" />
              </div>
              <div className={c.asset}>
                <span className={c.name}>{`${checkoutStore.checkoutInfo.name.slice(0, 22).trim()}...`}</span>
                <span className={c.cost}>
                  {addComma(String(+tokens * +checkoutStore.checkoutInfo.unitPrice))}{' '}
                  {checkoutStore.checkoutInfo.currency}
                </span>
              </div>
              <div className={c.tokens}>
                {+tokens}{' '}
                {checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`}{' '}
                tokens
              </div>
              <div className={c.total}>
                <span className={c.title}>Total:</span>
                <div className={c.amount}>
                  {CURRENCY_SYMBOLS[checkoutStore.checkoutInfo.currency]}
                  {addComma(String(+checkoutStore.checkoutInfo.unitPrice * +tokens))}{' '}
                  <span className={c.currency}>{checkoutStore.checkoutInfo.currency}</span>
                </div>
              </div>
              <button disabled={checkVerified()} className={c.next} onClick={postPurchase}>
                Continue Order
                <img src={BuyArrow} alt="" />
              </button>
              {!portalStore.portalUI.isDemo &&
                portalStore.portalUI.features?.kycKyb === 'fortress' &&
                renderFortressBanner('verifyText')}
              <div className={c.verifyText}>
                <span className={c.errorText}>{checkoutStore.message}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default observer(CheckoutPayment);
