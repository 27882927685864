import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { FC, useState } from 'react';
import MenuIcon from '../../../shared/assets/icons/verticalMenuDots.svg';
import IssuerContractModal from './issuer-contract-modal';

interface IContractRowMenuProps {
  pandaDocIssuerEmbeddedUrl: string | null;
  contractId: string;
}

const ContractRowMenu: FC<IContractRowMenuProps> = ({ pandaDocIssuerEmbeddedUrl, contractId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <img src={MenuIcon} alt="" />
      </IconButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem disabled={!pandaDocIssuerEmbeddedUrl} onClick={() => setIsContractModalOpen(true)}>
          Sign contract
        </MenuItem>
      </Menu>
      {isContractModalOpen && (
        <IssuerContractModal
          contractId={contractId}
          open={isContractModalOpen}
          setOpen={setIsContractModalOpen}
          pandaDocLink={pandaDocIssuerEmbeddedUrl!}
        />
      )}
    </>
  );
};

export default ContractRowMenu;
