import React, { useEffect, useState } from 'react';
import qrcode from 'qrcode';
import { Box, Checkbox, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import userPool from '../../../shared/config/cognitoPool';
import AppCognitoUser from '../../../shared/lib/cognito-wrapper';
import c from '../style.module.scss';
import MfaQrCode from './MfaQrCode';

const SecuritySetting = () => {
  const [qr, setQr] = useState('');
  const [cUser, setCUser] = useState<null | AppCognitoUser>(null);
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);

  useEffect(() => {
    (async () => {
      const user = new AppCognitoUser(userPool);
      await user.getSession();
      const attr = await user.getUserAttributes();
      setIsMfaEnabled(attr?.find(att => att.Name === 'custom:isMfaEnabled')?.Value === 'true');
      setCUser(user);
    })();
  }, []);

  const onEnableMfaHandler = async () => {
    try {
      const code = (await cUser?.getSecretForQRcode()) as string;
      const qrcodeDataUrl = await qrcode.toDataURL(`otpauth://totp/TokenSuite?secret=${code}&issuer=TokenSuite`);
      setQr(qrcodeDataUrl);
    } catch (e) {
      setIsMfaEnabled(false);
    }
  };

  const onDisableMfaHandler = async () => {
    try {
      const data = await cUser?.disableMFA();
      await cUser?.updateAttributes([
        new CognitoUserAttribute({
          Name: 'custom:isMfaEnabled',
          Value: 'false',
        }),
      ]);
      if (data === 'SUCCESS') setIsMfaEnabled(false);
    } catch (e) {
      setIsMfaEnabled(true);
    }
  };

  const onMFAChangeHandler = isMfaEnabled ? onDisableMfaHandler : onEnableMfaHandler;

  return (
    <div className={c.securityWrapper}>
      <Stack direction="row" alignItems="center" ml={-9}>
        <Checkbox checked={isMfaEnabled} onChange={onMFAChangeHandler} />
        <div>
          <FormattedMessage id="accountSetting.security.multi" />
        </div>
      </Stack>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: 16,
        }}
      >
        <FormattedMessage id="accountSetting.security.multiDescription" />
      </Box>
      {qr && <MfaQrCode qrcode={qr} setQrCode={setQr} cUser={cUser} setIsMfaEnabled={setIsMfaEnabled} />}
    </div>
  );
};

export default SecuritySetting;
