import { MONTH_NAMES } from '../constants/date.constants';

const pad = (num: number) => `0${num}`.slice(-2);
export const getFormattedDateAndTime = (timestamp: number) => {
  const date = new Date(timestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const timeFormatted = `${pad(hours)}:${pad(minutes)}`;

  const year = date.getFullYear();
  const month = MONTH_NAMES[date.getMonth()].slice(0, 3);
  const days = date.getDate();
  const dateFormatted = `${pad(days)} ${month}, ${year}`;

  return {
    dateFormatted,
    timeFormatted,
  };
};
