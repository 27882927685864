import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../app/styles/reusable/tables.scss';
import { runInAction } from 'mobx';
import ContractList from './contracts-list';
import signDocumentsService from '../../shared/services/sign-documents/signDocumentsService';
import { useStores } from '../../store';
import { IssuerBrokerContract } from '../../shared/services/sign-documents/types';

const headCells = ['Broker', 'Asset', 'Commission'];

const BrokerContracts = () => {
  const [contracts, setContracts] = useState<IssuerBrokerContract[]>([]);
  const { issuerStore } = useStores();

  useEffect(() => {
    const getIssuerContracts = async () => {
      const data = await signDocumentsService.getBrokerContractsForIssuer();
      setContracts(data);
    };
    const onContractSigned = (contractId: string) => {
      setContracts(prev => prev.filter(({ id }) => id !== contractId));
    };
    runInAction(() => {
      issuerStore.deleteContractCb = onContractSigned;
    });
    getIssuerContracts();

    return () => {
      issuerStore.deleteContractCb = null;
    };
  }, [issuerStore]);

  if (contracts.length === 0) return null;

  return (
    <div>
      <div className="titleForBlock">Contracts To Sign</div>
      <TableContainer className="wrapperDT singleTable">
        <Table>
          <TableHead className="tableHeadDT">
            <TableRow>
              {headCells.map(cell => (
                <TableCell key={cell} className="headCellDT">
                  {cell}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <ContractList contracts={contracts} />
            {!contracts.length && (
              <TableRow>
                <TableCell className="emptyBlockDT" colSpan={7}>
                  There are no contracts for signing
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BrokerContracts;
