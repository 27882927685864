import CreateAssetPage from '../../../pages/admin/create-asset/createAsset';
import AdminAssetsPage from '../../../pages/admin/admin-assets/adminAssets';
import IssuersPage from '../../../pages/admin/issuers/issuers';
import AdminsPage from '../../../pages/admin/admins/admins';
import BrokersPage from '../../../pages/admin/brokers/brokers';
import AdminInvestorsPage from '../../../pages/admin/admin-investors/admin-investors';
import UserDetails from '../../../pages/admin/userDetails/userDetails';
import BrokerDetails from '../../../pages/admin/brokerDetails/brokerDetails';
import PaymentMethodsPage from '../../../pages/admin/payment-methods/paymentMethods';
import AdminPortalsPage from '../../../pages/admin/admin-portals/admin-portals';
import AdminPortalPage from '../../../pages/admin/admin-portals/admin-portal';
import EditAssetPage from '../../../pages/admin/edit-asset/editAsset';
import AdminInvestorsBlockListPage from '../../../pages/admin/admin-investors-blocklist/admin-investors-blocklist';
import AdminPortalIssuersPage from '../../../pages/admin/admin-portals/admin-portal-issuers';
import AdminPortalTokensPage from '../../../pages/admin/admin-portals/admin-portal-tokens';
// import AdminDistributeAssetsPage from '../../../pages/admin/admin-distribute-assets/adminDistributeAssets';
import DividendCalculationPage from '../../../pages/issuer/dividend-calculations/dividendCalculations';
import { IRoute } from '../model/private-route';
import BrokerPortalsPage from '../../../pages/admin/broker-portals/broker-portals';
import BrokerPortalAssetsPage from '../../../pages/admin/broker-portals/broker-portal-assets';

const adminRotes: IRoute[] = [
  {
    path: '/admin-assets',
    element: AdminAssetsPage,
  },
  // {
  //   path: '/dividends/assets',
  //   element: AdminDistributeAssetsPage,
  // },
  // ,
  {
    path: '/dividends/calculate/:id',
    element: DividendCalculationPage,
  },
  {
    path: '/asset-payment-methods/:id',
    element: PaymentMethodsPage,
  },
  {
    path: '/create-asset',
    element: CreateAssetPage,
  },
  {
    path: '/issuers',
    element: IssuersPage,
  },
  {
    path: '/brokers',
    element: BrokersPage,
  },
  {
    path: '/admins',
    element: AdminsPage,
  },
  {
    path: '/admin-investors',
    element: AdminInvestorsPage,
  },
  {
    path: '/user/:id',
    element: UserDetails,
  },
  {
    path: '/broker/:id',
    element: BrokerDetails,
  },
  {
    path: '/portals',
    element: AdminPortalsPage,
  },
  {
    path: '/broker-portals',
    element: BrokerPortalsPage,
  },
  {
    path: '/portals/:id',
    element: AdminPortalPage,
  },
  {
    path: '/portals/:id/issuers',
    element: AdminPortalIssuersPage,
  },
  {
    path: '/portals/:id/tokens',
    element: AdminPortalTokensPage,
  },
  {
    path: '/portals/:id/assets',
    element: BrokerPortalAssetsPage,
  },
  {
    path: '/edit-asset/:id',
    element: EditAssetPage,
  },
  {
    path: '/block-list',
    element: AdminInvestorsBlockListPage,
  },
];

export { adminRotes };
