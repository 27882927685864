import { makeAutoObservable } from 'mobx';
import {
  IAssetTransfersResult,
  ICryptoTransaction,
  ICryptoTransactions,
  IFortressTransactions,
  ITransactionFortressHistory,
  ITransactionHistory,
  ITransactions,
} from '../shared/services/transactions/transactions.types';
import transactionsService from '../shared/services/transactions/transactionsService';
import RootStore from './index';
import { StoreState } from './store-types/common.types';
import { RequestParams, RequestParamsSecond } from '../shared/types/investorTableType';

class TransactionsStore {
  rootStore: RootStore;

  state: StoreState = 'done';

  stateCrypto: StoreState = 'done';

  totalTransactions = 0;

  totalTransactionsCrypto = 0;

  transactions: ITransactionHistory[] = [];

  transactionsFortress: ITransactionFortressHistory[] = [];

  transactionsCrypto: ICryptoTransaction[] = [];

  transactionsAlchemy: IAssetTransfersResult[] = [];

  transactionDetails: ITransactionHistory | null = null;

  isDetailsModalOpen = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  openViewModal(transaction: ITransactionHistory) {
    this.transactionDetails = transaction;
    this.isDetailsModalOpen = true;
  }

  closeViewModal() {
    this.isDetailsModalOpen = false;
  }

  *getTransactions(params: RequestParams, isIssuer = false) {
    this.state = 'pending';
    try {
      const data: ITransactions = yield isIssuer
        ? transactionsService.getIssuerTransactions(params)
        : transactionsService.getInvestorTransactions(params);
      this.totalTransactions = data.total;
      this.transactions = data.records;
      this.state = 'done';
    } catch {
      this.state = 'error';
    }
  }

  *getTransactionsFortress(params: RequestParamsSecond) {
    this.state = 'pending';
    try {
      const newParams = {
        pageSize: params.pageSizeSecond,
        currentPage: params.currentPageSecond,
      };
      const data: IFortressTransactions = yield transactionsService.getTransactionsFortress(newParams);
      this.totalTransactions = data.resourceCount;
      this.transactionsFortress = data.transactions;
      this.state = 'done';
    } catch {
      this.state = 'error';
    }
  }

  *getTransactionsCrypto(params: RequestParams) {
    this.stateCrypto = 'pending';
    try {
      const data: ICryptoTransactions = yield transactionsService.getTransactionsCrypto(params);
      this.totalTransactionsCrypto = data.meta.resourceCount;
      this.transactionsCrypto = data.data;
      this.stateCrypto = 'done';
    } catch {
      this.stateCrypto = 'error';
    }
  }

  *getTransactionsFortressReload() {
    try {
      const params = {
        pageSize: 10,
        currentPage: 0,
      };
      const data: IFortressTransactions = yield transactionsService.getTransactionsFortress(params);
      this.totalTransactions = data.resourceCount;
      this.transactionsFortress = data.transactions;
    } catch {
      this.state = 'error';
    }
  }

  *getTransactionsCryptoReload() {
    try {
      const params = {
        pageSize: 10,
        currentPage: 0,
      };
      const data: ICryptoTransactions = yield transactionsService.getTransactionsCrypto(params);
      this.totalTransactionsCrypto = data.meta.resourceCount;
      this.transactionsCrypto = data.data;
    } catch {
      this.stateCrypto = 'error';
    }
  }

  *getTransactionsFortressForUser(params: RequestParamsSecond, userId: string) {
    this.state = 'pending';
    try {
      const newParams = {
        pageSize: params.pageSizeSecond,
        currentPage: params.currentPageSecond,
      };
      const data: IFortressTransactions = yield transactionsService.getTransactionsFortressForUser(newParams, userId);
      this.totalTransactions = data.resourceCount;
      this.transactionsFortress = data.transactions;
      this.state = 'done';
    } catch {
      this.state = 'error';
    }
  }

  *getCryptoTransactionsByWallet(wallet: string) {
    this.state = 'pending';
    try {
      const data: IAssetTransfersResult[] = yield transactionsService.getCryptoTransactionsByWallet(wallet);
      this.transactionsAlchemy = data;
      this.totalTransactionsCrypto = data.length;
      console.log('DFSDFSDFSFSDFSDF', data);
      this.state = 'done';
    } catch (error) {
      this.state = 'error';
    }
  }

  resetTransactionsFiat() {
    this.transactions = [];
    this.transactionsFortress = [];
    this.totalTransactions = 0;
  }

  resetTransactionsCrypto() {
    this.transactions = [];
    this.transactionsCrypto = [];
    this.totalTransactionsCrypto = 0;
    this.transactionsAlchemy = [];
  }
}

export default TransactionsStore;
