import { IconButton, Stack, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { copyDataInBuffer, getFormmatedDateAndTime } from '../../../shared/lib';
import { ITransactionHistory } from '../../../shared/services/transactions/transactions.types';
import CopyIcon from '../../../shared/assets/icons/copyMail.svg';
import c from '../styles.module.scss';
import { useStores } from '../../../store';

interface ITransactionHistoryRowProps {
  transaction: ITransactionHistory;
}

const getStringForSentCell = (send: number | null, currency: string | null) => {
  if (send === null || !currency) return '-';
  const newSend = Intl.NumberFormat('en').format(send);
  return `${newSend} ${currency}`;
};

const TransactionHistoryRow: FC<ITransactionHistoryRowProps> = ({ transaction }) => {
  const { transactionsStore } = useStores();
  const { dateFormatted, timeFormatted } = getFormmatedDateAndTime(transaction.createdAt);

  return (
    <TableRow key={transaction.id} className={`tableRowDT ${c.cells}`}>
      <TableCell className="cellDT">
        <Stack>
          <div>{dateFormatted}</div>
          <div className={c.time}>{timeFormatted}</div>
        </Stack>
      </TableCell>
      <TableCell className="cellDT">{getStringForSentCell(transaction.send, transaction.sendCurrency)}</TableCell>
      <TableCell className="cellDT">{getStringForSentCell(transaction.receive, transaction.receiveCurrency)}</TableCell>
      <TableCell className="cellDT">
        {transaction.transactionHash ? (
          <>
            <a
              href={`https://mumbai.polygonscan.com/tx/${transaction.transactionHash}`}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {transaction.transactionHash?.slice(0, 4)}...{transaction.transactionHash?.slice(-4)}
            </a>
            <IconButton onClick={() => copyDataInBuffer(transaction.transactionHash!)}>
              <img src={CopyIcon} alt="" />
            </IconButton>
          </>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell className="cellDT" style={{ textTransform: 'capitalize' }}>
        {transaction.transactionType}
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() => {
            transactionsStore.openViewModal(transaction);
          }}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TransactionHistoryRow;
