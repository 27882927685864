import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { useState } from 'react';
import c from './style.module.scss';

interface TextInputProps {
  value?: string | number;
  changeValue: any;
  placeholder: string;
  label?: string;
  type: string;
  fullWidth?: boolean;
  multiline?: boolean;
  index?: number;
  error?: boolean;
  errorText?: string;
  disabled?: boolean
}
interface DateInputProps {
  value: any | null;
  fullWidth?: boolean;
  changeValue: any;
  label?: string;
  setError?: any;
  maxDate?: any | null;
  minDate?: any | null;
}

export interface ICountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export interface IStateType {
  name: string;
  abbreviation: string;
}

interface AutoCompleteProps {
  value: ICountryType | null;
  changeValue: any;
  label: string;
  placeholder: string;
  options: ICountryType[];
  error?: boolean;
  errorText?: string;
  disabled?: boolean
}
interface StatesAutoCompleteProps {
  value: IStateType | null;
  changeValue: any;
  label: string;
  placeholder: string;
  options: IStateType[];
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
}
interface SelectInputProps {
  value: any;
  changeValue: any;
  label: string;
  children: any;
  defaultValue: string;
  fullWidth?: boolean;
  disabled?: boolean
}

export function TextInput(props: TextInputProps) {
  const { value, changeValue, placeholder, label, type, fullWidth = false, multiline = false, error = false, errorText = 'field is required', disabled } = props;
  return (
    <div className={fullWidth ? `${c.inputBlock} ${c.fullwidth}` : `${c.inputBlock}`}>
      {label && <div className={c.inputLabel}>{label}</div>}
      <TextField
        type={type}
        onChange={e => {
          changeValue(e.target.value);
        }}
        value={value}
        className={c.input}
        margin="normal"
        placeholder={placeholder}
        multiline={multiline}
        maxRows={4}
        error={error}
        helperText={error ? `${errorText}` : null}
        disabled={disabled}
      />
    </div>
  );
}

export function TextIndexInput(props: TextInputProps) {
  const { value, changeValue, placeholder, label, type, fullWidth = false, multiline = false, index, error = false, errorText = 'field is reqired' } = props;
  return (
    <div className={fullWidth ? `${c.inputBlock} ${c.fullwidth}` : `${c.inputBlock}`}>
      {label && <div className={c.inputLabel}>{label}</div>}
      <TextField
        type={type}
        onChange={e => {
          changeValue(e.target.value, index);
        }}
        value={value}
        className={c.input}
        margin="normal"
        placeholder={placeholder}
        multiline={multiline}
        maxRows={8}
        error={error}
        helperText={error ? `${errorText}` : null}
      />
    </div>
  );
}

export function AutocompleteInput(props: AutoCompleteProps) {
  const { value, changeValue, label, options, placeholder, error = false, errorText = 'field is required', disabled } = props;
  return (
    <div className={c.inputBlock}>
      <div className={c.inputLabel}>{label}</div>
      <Autocomplete
        disablePortal
        options={options}
        value={value}
        onChange={(event: any, newValue: any | null) => {
          changeValue(newValue);
        }}
        disabled={disabled}
        renderInput={(params: any) => (
          <TextField
            {...params}
            className={c.input}
            placeholder={placeholder}
            type="text"
            error={error}
            helperText={error ? errorText : null}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
}

export function StatesAutocompleteInput(props: StatesAutoCompleteProps) {
  const { changeValue, label, options, placeholder, error = false, errorText = 'field is required', disabled } = props;
  return (
    <div className={c.inputBlock}>
      <div className={c.inputLabel}>{label}</div>
      <Autocomplete
        options={options}
        autoHighlight
        getOptionLabel={(option) => option.name}
        onChange={(event, option) => {
          changeValue(option?.abbreviation);
        }}
        renderOption={(statesProps, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...statesProps}>
            {option.name} - {option.abbreviation}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            className={c.input}
            error={error}
            helperText={error ? errorText : null}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
}

export function ControlledStatesAutocompleteInput(props: StatesAutoCompleteProps) {
  const { changeValue, label, options, placeholder, error = false, errorText = 'field is required', disabled, value } = props;
  const [currentValue, setCurrentValue] = useState(value);
  return (
    <div className={c.inputBlock}>
      <div className={c.inputLabel}>{label}</div>
      <Autocomplete
        options={options}
        autoHighlight
        getOptionLabel={(option) => option.name}
        value={currentValue}
        disabled={disabled}
        onChange={(event, option) => {
          changeValue(option);
          setCurrentValue(option);
        }}
        renderOption={(statesProps, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...statesProps}>
            {option.name} - {option.abbreviation}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            className={c.input}
            error={error}
            helperText={error ? errorText : null}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
}

export function DateInput(props: DateInputProps) {
  const { value, changeValue, label, fullWidth = false, setError, maxDate = null, minDate = null } = props;
  return (
    <div className={fullWidth ? `${c.inputBlock} ${c.fullwidth}` : `${c.inputBlock}`}>
      {label && <div className={c.inputLabel}>{label}</div>}
      <DesktopDatePicker
        className={c.input}
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={changeValue}
        renderInput={(params: any) => <TextField {...params} />}
        onError={(error) => { setError(error); }}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export function DateTimeInput(props: DateInputProps) {
  const { value, changeValue, label, fullWidth = false, setError, minDate } = props;
  return (
    <div className={fullWidth ? `${c.inputBlock} ${c.fullwidth}` : `${c.inputBlock}`}>
      {label && <div className={c.inputLabel}>{label}</div>}
      <DateTimePicker
        className={c.input}
        inputFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        value={value}
        onChange={changeValue}
        renderInput={(params: any) => <TextField {...params} />}
        onError={(error) => { setError(error); }}
        minDateTime={minDate}
      />
    </div>
  );
}

export function SelectInput(props: SelectInputProps) {
  const { value, changeValue, label, children, defaultValue, fullWidth = false, disabled = false } = props;
  return (
    <div className={fullWidth ? `${c.selectBlock} ${c.fullwidth}` : `${c.selectBlock}`}>
      <div className={c.inputLabel}>{label}</div>
      <Select
        displayEmpty
        className={c.select}
        value={value}
        disabled={disabled}
        onChange={e => {
          changeValue(e.target.value);
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em style={{ color: '#8D8D8D' }}>{defaultValue}</em>;
          }
          return selected;
        }}
      >
        {children}
      </Select>
    </div>
  );
}
