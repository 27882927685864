import { Box, Modal, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import c from './style.module.scss';
import { useStores } from '../../../../store';

interface ContractModalProps {
  open: boolean;
  pandaDocLink: string;
  contractId: string;
}

const SignPandaDocContractModal = ({ open, pandaDocLink, contractId }: ContractModalProps) => {
  const { issuerStore, vereficationStore } = useStores();

  useEffect(() => {
    const checkDocumentSign = (event: any) => {
      const type = event.data && event.data.type;
      if (type === 'session_view.document.completed') {
        if (issuerStore.deleteContractCb) {
          issuerStore.deleteContractCb(contractId);
        }
        vereficationStore.closeSignModal();
        vereficationStore.changeNotificationAfterSign(contractId);
      }
    };
    window.addEventListener('message', checkDocumentSign);
    return () => {
      window.removeEventListener('message', checkDocumentSign);
    };
  }, [contractId, issuerStore, vereficationStore]);

  return (
    <Modal
      open={open}
      onClose={() => vereficationStore.closeSignModal()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box className={c.modalWrapper}>
        <Paper className={c.paper}>
          <Stack>
            <iframe src={pandaDocLink} title="contract to sign" className={c.contract} />
          </Stack>
        </Paper>
      </Box>
    </Modal>
  );
};

export default observer(SignPandaDocContractModal);
