import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import '../../../app/styles/reusable/buttons.scss';
import { observer } from 'mobx-react-lite';
import ExportIcon from '../../../shared/assets/icons/export.svg';
import c from '../styles.module.scss';
import { DatesRange } from './transactions-history.meta';
import { IMarketPlaceAsset } from '../../../shared/services/asset/asset.types';
import assetService from '../../../shared/services/asset/assetService';

interface ITransactionsFilter {
  onAssetIdChange: (value: string) => void;
  onDatesChange: (value: string | number) => void;
}

const TransactionsFilter: FC<ITransactionsFilter> = ({ onAssetIdChange, onDatesChange }) => {
  const [assetId, setAssetId] = useState('');
  const [dateRange, setDateRange] = useState(0);
  const [assetsMarketPlace, setAssetsMarketPlace] = useState<IMarketPlaceAsset[]>([]);
  const onAssetIdChangeHandler = (e: SelectChangeEvent) => {
    setAssetId(e.target.value);
    onAssetIdChange(e.target.value);
  };

  const onDateRangeChangeHandler = (e: SelectChangeEvent) => {
    setDateRange(+e.target.value);
    onDatesChange(e.target.value);
  };

  useEffect(() => {
    (async () => {
      const assets = await assetService.getAssetsForPortal();
      setAssetsMarketPlace(assets);
    })();
  }, []);

  return (
    <div className={c.filterPanel}>
      <div className={`${c.left} ${c.double}`}>
        <div className={c.block}>
          <div className={c.text}>Assets</div>
          <Select
            placeholder="Assets"
            className={c.select}
            displayEmpty
            fullWidth
            value={assetId}
            onChange={onAssetIdChangeHandler}
            sx={{
              flex: '0 0 40px',
            }}
          >
            <MenuItem value="">All</MenuItem>
            {assetsMarketPlace.length > 0 &&
              assetsMarketPlace.map(asset => (
                <MenuItem value={asset.id} key={asset.id}>
                  {asset.name} {asset.tickerSymbol}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className={c.block}>
          <div className={c.text}>Dates</div>
          <Select
            placeholder="Dates"
            displayEmpty
            className={c.select}
            value={String(dateRange)}
            onChange={onDateRangeChangeHandler}
            fullWidth
            sx={{
              flex: '0 0 40px',
            }}
          >
            {Object.entries(DatesRange).map(([key, value]) => (
              <MenuItem key={value} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={c.right}>
        <button className="defaultButton" disabled>
          <img src={ExportIcon} alt="exportIcon" />
          Export CSV
        </button>
      </div>
    </div>
  );
};

export default observer(TransactionsFilter);
