import { FormattedMessage, useIntl } from 'react-intl';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from '../styles.module.scss';
import { useStores } from '../../../store';

function EditHighlightsBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [highlights, setHighlights] = useState<
    {
      text: string;
      position?: number;
    }[]
  >(assetStore.currentAsset.highlights);

  const addHighlight = () => {
    setHighlights([...highlights, { text: '', position: highlights.length + 1 }]);
  };

  const removeFile = (index: number) => {
    const newHighlights = highlights.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setHighlights(newHighlights);
  };

  const highlightHandler = (e: string, index: number) => {
    const tempArr = [...highlights];
    tempArr[index].text = e;
    setHighlights(tempArr);
  };

  const submit = () => {
    flowResult(assetStore.saveHighlights(id, highlights));
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="mainInfo">
        <FormattedMessage id="createAssetPage.text.highlihtsBlock" />
      </div>
      <div className={c.wrapper}>
        <div className={c.listHighlights}>
          <div className={c.wrapperBlock}>
            {highlights.map((highlight, index: number) => (
              <div className={c.highlight} key={index}>
                <div className={c.left}>
                  <TextIndexInput
                    value={highlight.text}
                    changeValue={highlightHandler}
                    label={intl.formatMessage({ id: 'createAssetPage.highlights.field.label' }, { count: index + 1 })}
                    placeholder={intl.formatMessage({ id: 'createAssetPage.highlights.field.placeholder' })}
                    type="text"
                    fullWidth
                    multiline
                    index={index}
                  />
                </div>
                <div className={c.right}>
                  <IconButton className={c.delete} onClick={() => removeFile(index)}>
                    <img src={closeIcon} alt="X" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className={c.addButton} onClick={addHighlight}>
          {highlights.length > 0 ? (
            <FormattedMessage id="createAssetPage.highlights.button.addOnother" />
          ) : (
            <FormattedMessage id="createAssetPage.highlights.button.addFirst" />
          )}
        </button>
      </div>
      <button className={c.submitButton} onClick={submit}>
        Save
      </button>
    </div>
  );
}

export default observer(EditHighlightsBlock);
