import { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { observer } from 'mobx-react-lite';
import Opportunity from '../../../shared/assets/asset-view/opportunity.png';
import Tokenization from '../../../shared/assets/asset-view/tokenization.png';
import Trade from '../../../shared/assets/asset-view/trade.png';
import AdvancePresentaion from '../advance-presentation/advancePresentaion';
import 'swiper/scss';
import 'swiper/scss/navigation';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';
import ModalImage from '../../UI/modal-image/modal-image';
import { serialize } from '../../../shared/lib/serializeFunctions';

function AdvanceItemContent() {
  const [howWorksOpen, setHowWorksOpen] = useState(false);
  const { assetStore } = useStores();

  const renderDescription = (index: number) => {
    try {
      return (
        <div
          className={c.text}
          dangerouslySetInnerHTML={{ __html: serialize(JSON.parse(assetStore.currentAsset.media[index].description)) }}
        />
      );
    } catch {
      return <div className={c.text}>{assetStore.currentAsset.media[index].description}</div>;
    }
  };

  const renderDescriptionWithItem = (description: string) => {
    try {
      return <div className={c.text} dangerouslySetInnerHTML={{ __html: serialize(JSON.parse(description)) }} />;
    } catch {
      return <div className={c.text}>{description}</div>;
    }
  };

  return (
    <div className={c.assetContent}>
      {assetStore.currentAsset.media?.length > 0 && (
        <>
          {assetStore.currentAsset.media[0].mediaFiles &&
            assetStore.currentAsset.media[0].mediaFiles?.map((item, i) =>
              item.image ? (
                <div className={c.image1} key={i}>
                  <ModalImage src={item.image?.url} alt="" modalHeight={500} modalWidth={900} />
                </div>
              ) : (
                <div className={c.assetImage} key={i}>
                  <iframe src={item.linksToVideo} title="asset video" />
                </div>
              ),
            )}
          <h2 className={c.title}>{assetStore.currentAsset.media[0].title}</h2>
          {renderDescription(0)}
        </>
      )}
      {assetStore.currentAsset.presentationDocument && <AdvancePresentaion />}
      {assetStore.currentAsset.media?.length > 1 && (
        <>
          {assetStore.currentAsset.media[1].mediaFiles &&
            assetStore.currentAsset.media[1].mediaFiles?.map((item, i) =>
              item.image ? (
                <div className={c.image1} key={i}>
                  <ModalImage src={item.image?.url} alt="" modalHeight={500} modalWidth={900} />
                </div>
              ) : (
                <div className={c.assetImage} key={i}>
                  <iframe src={item.linksToVideo} title="asset video" />
                </div>
              ),
            )}
          <h2 className={c.title}>{assetStore.currentAsset.media[1].title}</h2>
          {renderDescription(1)}
        </>
      )}
      {assetStore.currentAsset.media?.length > 2 && (
        <>
          {assetStore.currentAsset.media[2].mediaFiles &&
            assetStore.currentAsset.media[2].mediaFiles?.map((item, i) =>
              item.image ? (
                <div className={c.image1} key={i}>
                  <ModalImage src={item.image?.url} alt="" modalHeight={500} modalWidth={900} />
                </div>
              ) : (
                <div className={c.assetImage} key={i}>
                  <iframe src={item.linksToVideo} title="asset video" />
                </div>
              ),
            )}
          <h2 className={c.title}>{assetStore.currentAsset.media[2].title}</h2>
          {renderDescription(2)}
        </>
      )}
      {assetStore.currentAsset.howItWorks && (
        <div className={c.howWorks}>
          <button className={c.top} onClick={() => setHowWorksOpen(!howWorksOpen)}>
            <svg
              width="32"
              height="27"
              viewBox="0 0 32 27"
              xmlns="http://www.w3.org/2000/svg"
              className={`${c.arrow} ${howWorksOpen ? `${c.active}` : ''}`}
            >
              <path d="M16 27L0.411547 -2.9364e-06L31.5885 -2.10824e-07L16 27Z" />
            </svg>
            <div className={c.worksTitle}>{assetStore.currentAsset.howItWorks.highlights}</div>
          </button>
          <div className={`${c.dropdown} ${howWorksOpen ? `${c.shown}` : ''}`}>
            <div className={c.worksItem}>
              <div className={c.worksImg}>
                <ModalImage src={Opportunity} alt="" modalHeight={500} modalWidth={900} />
              </div>
              <div className={c.worksContent}>
                <div className={c.itemTitle}>{assetStore.currentAsset.howItWorks.fields[0].title}</div>
                <div className={c.itemDescr}>{assetStore.currentAsset.howItWorks.fields[0].description}</div>
              </div>
            </div>
            <div className={c.worksItem}>
              <div className={c.worksImg}>
                <ModalImage src={Tokenization} alt="" modalHeight={500} modalWidth={900} />
              </div>
              <div className={c.worksContent}>
                <div className={c.itemTitle}>{assetStore.currentAsset.howItWorks.fields[1].title}</div>
                <div className={c.itemDescr}>{assetStore.currentAsset.howItWorks.fields[1].description}</div>
              </div>
            </div>
            <div className={c.worksItem}>
              <div className={c.worksImg}>
                <ModalImage src={Trade} alt="" modalHeight={500} modalWidth={900} />
              </div>
              <div className={c.worksContent}>
                <div className={c.itemTitle}>{assetStore.currentAsset.howItWorks.fields[2].title}</div>
                <div className={c.itemDescr}>{assetStore.currentAsset.howItWorks.fields[2].description}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {assetStore.currentAsset.media?.length > 3 && (
        <>
          {assetStore.currentAsset.media[3].mediaFiles &&
            assetStore.currentAsset.media[3].mediaFiles?.map((item, i) =>
              item.image ? (
                <div className={c.image1} key={i}>
                  <ModalImage src={item.image?.url} alt="" modalHeight={500} modalWidth={900} />
                </div>
              ) : (
                <div className={c.assetImage} key={i}>
                  <iframe src={item.linksToVideo} title="asset video" />
                </div>
              ),
            )}
          <h2 className={c.title}>{assetStore.currentAsset.media[3].title}</h2>
          {renderDescription(3)}
        </>
      )}
      {assetStore.currentAsset.reviews?.length !== 0 && (
        <div className={c.reviewsSlider}>
          <Swiper
            modules={[Navigation]}
            className={c.reviewsSwiper}
            navigation={{
              nextEl: '.testimonials-forward',
              prevEl: '.testimonials-back',
            }}
            loop
          >
            {assetStore.currentAsset.reviews.map((quote, i) => (
              <SwiperSlide key={i} className={c.slide}>
                <div className={c.slideInner}>
                  <div className={c.review}>“{quote.text}”</div>
                  <div className={c.name}>~ {quote.reviewer}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="testimonials-back"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.1911 4.48663C21.5578 3.83779 20.5311 3.83779 19.8978 4.48663L9.80763 14.8252C9.17437 15.474 9.17437 16.526 9.80763 17.1748L19.8978 27.5134C20.5311 28.1622 21.5578 28.1622 22.1911 27.5134C22.8243 26.8645 22.8243 25.8125 22.1911 25.1637L13.2475 16L22.1911 6.8363C22.8243 6.18745 22.8243 5.13547 22.1911 4.48663Z"
            />
          </svg>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="testimonials-forward"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.1911 4.48663C21.5578 3.83779 20.5311 3.83779 19.8978 4.48663L9.80763 14.8252C9.17437 15.474 9.17437 16.526 9.80763 17.1748L19.8978 27.5134C20.5311 28.1622 21.5578 28.1622 22.1911 27.5134C22.8243 26.8645 22.8243 25.8125 22.1911 25.1637L13.2475 16L22.1911 6.8363C22.8243 6.18745 22.8243 5.13547 22.1911 4.48663Z"
            />
          </svg>
        </div>
      )}

      {assetStore.currentAsset.media?.length > 4 && (
        <>
          {assetStore.currentAsset.media[4].mediaFiles &&
            assetStore.currentAsset.media[4].mediaFiles?.map((item, i) =>
              item.image ? (
                <div className={c.image1} key={i}>
                  <ModalImage src={item.image?.url} alt="" modalHeight={500} modalWidth={900} />
                </div>
              ) : (
                <div className={c.assetImage} key={i}>
                  <iframe src={item.linksToVideo} title="asset video" />
                </div>
              ),
            )}
          <h2 className={c.title}>{assetStore.currentAsset.media[4].title}</h2>
          {renderDescription(4)}
        </>
      )}
      {assetStore.currentAsset.media?.slice(5) &&
        assetStore.currentAsset.media.slice(5).map((item, key) => (
          <div key={key}>
            {item.mediaFiles &&
              item.mediaFiles?.map((element, i) =>
                element.image ? (
                  <div className={c.image1} key={i}>
                    <ModalImage src={element.image?.url} alt="" modalHeight={500} modalWidth={900} />
                  </div>
                ) : (
                  <div className={c.assetImage} key={i}>
                    <iframe src={element.linksToVideo} title="asset video" />
                  </div>
                ),
              )}
            <h2 className={c.title}>{item.title}</h2>
            {renderDescriptionWithItem(item.description)}
          </div>
        ))}
    </div>
  );
}

export default observer(AdvanceItemContent);
