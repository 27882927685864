import { FormikControlProps } from '../formik/formik-control/formik-control.types';
import { countryList, states } from '../../shared/config/constants';

const fields: Array<FormikControlProps & { id: number }> = [
  {
    id: 0,
    control: 'input',
    inputProps: {
      name: 'occupation',
      id: 'occupation',
      label: 'Occupation',
      placeholder: 'Enter occupation',
    },
  },
  {
    id: 1,
    control: 'date',
    dateProps: {
      name: 'dob',
      label: 'Date of birthday',
    },
  },
  {
    id: 2,
    control: 'autocomplete',
    autocompleteProps: {
      name: 'primCountry',
      options: countryList.map(item => ({ label: item, value: item })),
      label: 'Country',
      placeholder: 'Select country',
    },
  },
  {
    id: 3,
    control: 'input',
    inputProps: {
      name: 'primCity',
      id: 'primCity',
      label: 'City',
      placeholder: 'Enter city',
    },
  },
  {
    id: 4,
    control: 'input',
    inputProps: {
      name: 'primAddress1',
      id: 'primAddress1',
      label: 'Address',
      placeholder: 'Enter address',
    },
  },
  {
    id: 5,
    control: 'input',
    inputProps: {
      name: 'primZip',
      id: 'primZip',
      label: 'Zip code',
      placeholder: 'Enter zip code',
    },
  },
  {
    id: 6,
    control: 'autocomplete',
    autocompleteProps: {
      name: 'primState',
      options: states.map(({ name, abbreviation }) => ({ label: `${name} - ${abbreviation}`, value: abbreviation })),
      label: 'State',
      placeholder: 'Select state',
    },
  },
  {
    id: 7,
    control: 'checkbox',
    checkboxProps: {
      name: 'isUsCitizen',
      label: 'Is US Citizen',
    },
  },
];

export { fields };
