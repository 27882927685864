import React, { FC, memo } from 'react';
import { IconButton, TableCell, TableRow, Stack } from '@mui/material';
import { NonMintedAsset } from '../../../../../shared/types/investorTableType';
import c from '../styles.module.scss';
import NonMintedRowMenu from './NonMintedRowMenu';
import CopyIcon from '../../../../../shared/assets/icons/copyMail.svg';
import { copyDataInBuffer, getPrettyfyNumber } from '../../../../../shared/lib';

interface NonMintedRowProps {
  asset: NonMintedAsset;
}

const getFormattedAddress = (value: string) => {
  return `${value.slice(0, 4)}...${value.slice(-4)}`;
};

const NonMintedRow: FC<NonMintedRowProps> = ({ asset }) => {
  return (
    <TableRow>
      <TableCell align="center">
        <img src={asset.previewImage.url} className={c.image} alt="" width={80} height={60} />
      </TableCell>
      <TableCell>{asset.tickerSymbol?.toUpperCase() || '-'}</TableCell>
      <TableCell>{getPrettyfyNumber(asset.tokenQuantity)}</TableCell>
      <TableCell>${(+getPrettyfyNumber(asset.unitPrice)).toFixed(2)}</TableCell>
      <TableCell>{asset.investmentDetail.capitalStack}</TableCell>
      <TableCell>${getPrettyfyNumber(asset.minAmount)}</TableCell>
      <TableCell>${getPrettyfyNumber(asset.targetAmount)}</TableCell>
      {asset.initialSale?.contractAddress && asset.paymentTokenRaised && (
        <>
          <TableCell>{asset.investorsCount}</TableCell>
          <TableCell>{Math.ceil((+asset.paymentTokenRaised / asset.maxAmount) * 100)}%</TableCell>
          <TableCell>
            <Stack direction="row" alignItems="center">
              {getFormattedAddress(asset.initialSale.token?.tokenAddress)}
              <IconButton onClick={() => copyDataInBuffer(asset.initialSale?.token?.tokenAddress!)}>
                <img alt="" src={CopyIcon} />
              </IconButton>
            </Stack>
          </TableCell>
        </>
      )}
      <TableCell align="right">
        <NonMintedRowMenu id={asset.id} />
      </TableCell>
    </TableRow>
  );
};

export default memo(NonMintedRow);
