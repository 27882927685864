import { adminMenu } from './admin-menu';
import { issuerMenu } from './issuer-menu';
import { investorMenu } from './investor-menu';
import { MenuRoutes } from '../model/menu-route';

const menuRoutes: MenuRoutes = {
  admin: adminMenu,
  issuer: issuerMenu,
  demoIssuer: issuerMenu,
  investor: investorMenu,
};

export { menuRoutes };
