import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import videoIcon from '../../../shared/assets/createAsset/videoIcon.svg';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import upload from '../../../shared/assets/createAsset/upload.svg';
import c from './style.module.scss';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 26214400; // 25MB

const boldFragment = (chunks: any) => <b>{`${chunks}`}</b>;

export interface IFiles {
  [fileName: string]: File;
}

function formatBytes(bytes: number, decimals = 2) {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function FilesUploader({
  documents,
  setDocuments,
  cap = 20,
  withVideo = true,
  setError,
  withIcoFormat = false,
  withSvgFormat = false,
}: {
  documents: Array<{ linksToVideo?: string; image?: any }>;
  setDocuments: any;
  setError?: any;
  cap?: number;
  withVideo?: boolean;
  withIcoFormat?: boolean;
  withSvgFormat?: boolean;
}) {
  const intl = useIntl();
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [uploadNotFinished, setNotFinished] = useState(true);
  const [videoLink, setVideoLink] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
    desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap }),
  });

  const showError = (message: { head: string; desc: string }) => {
    setErrorMessage(message);
    setIsErrorShown(true);
  };

  const checkVideo = () => {
    if (videoLink.length >= 34) {
      setNotFinished(false);
      setDocuments([...documents, { linksToVideo: videoLink }]);
      setVideoLink('');
      setError('');
    }
  };

  const fileInputField = useRef(null);

  const addNewFiles = async (newFiles: any) => {
    const allowedTypes = withIcoFormat ? ['png', 'ico'] : ['jpg', 'jpeg', 'png', 'gif'];
    if (withSvgFormat) {
      allowedTypes.push('svg');
    }
    const newDocuments = documents;
    for (const file of newFiles) {
      if (allowedTypes.indexOf(file.name.split('.').reverse()[0]) === -1) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.notSupported' }),
        });
        return;
      }
      if (file.size >= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        showError({
          head: intl.formatMessage({ id: 'uploader.errors.uploadFailed' }),
          desc: intl.formatMessage({ id: 'uploader.errors.maxSize' }),
        });
        return;
      }
      newDocuments.push({ image: file });
    }
    setDocuments(newDocuments);
    setError('');
    setNotFinished(false);
  };

  const removeFile = (index: number) => {
    const newDocuments = documents.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setDocuments(newDocuments);
    if (newDocuments.length === 0) {
      setNotFinished(true);
    }
  };

  const handleNewFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: any | null = event.target.files;
    if (newFiles.length + documents.length > cap) {
      showError({
        head: intl.formatMessage({ id: 'uploader.errors.tooMany' }),
        desc: intl.formatMessage({ id: 'uploader.errors.cap' }, { cap }),
      });
    } else {
      addNewFiles(newFiles);
    }
  };

  const [inDropZone, setDropZone] = useState(false);
  const handleDragEnter = () => {
    setDropZone(true);
  };
  const handleDragLeave = () => {
    setDropZone(false);
  };
  const handleDrop = () => {
    setDropZone(false);
  };

  const accepPictureFormats = withIcoFormat
    ? 'image/png,image/x-icon'
    : `image/jpeg,image/gif,image/png,image/jpg,image/x-eps${withSvgFormat ? ',image/svg+xml' : ''}`;

  return (
    <div className={c.uploader}>
      {uploadNotFinished && (
        <>
          <div className={`${c.upload} ${inDropZone ? c.drag : ''}`}>
            <div className={`${c.error} ${isErrorShown ? '' : c.hidden}`}>
              <div className={c.container}>
                <header>{errorMessage.head}</header>
                <p>{errorMessage.desc}</p>
                <IconButton className={c.close} onClick={() => setIsErrorShown(false)}>
                  x
                </IconButton>
              </div>
            </div>
            <input
              id="file-input"
              type="file"
              ref={fileInputField}
              onChange={handleNewFileUpload}
              onDrop={handleDrop}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              title=""
              value=""
              multiple
              accept={accepPictureFormats}
            />
            <img src={upload} alt="" />
            <p className={c.text}>
              <FormattedMessage
                id="uploader.image.text.header"
                values={{
                  b: boldFragment,
                }}
              />
            </p>
            <label htmlFor="file-input" className={c.browse}>
              <FormattedMessage id="uploader.button.upload.image" />
            </label>
            <p className={c.help}>
              <FormattedMessage
                id="uploader.text.formats"
                values={{ formats: withIcoFormat ? 'png, ico' : `jpg, png, gif${withSvgFormat ? ', svg' : ''}` }}
              />
            </p>
          </div>
          {withVideo && (
            <div className={c.inputBlock}>
              <div className={c.inputLabel}>
                <FormattedMessage id="uploader.text.urlUpload" />
              </div>
              <TextField
                type="text"
                onChange={e => {
                  setVideoLink(e.target.value);
                }}
                value={videoLink}
                className={c.input}
                margin="normal"
                multiline
                maxRows={4}
                placeholder="https://www.youtube.com/embed/..."
                InputProps={{
                  endAdornment: (
                    <Button className={c.endButton} onClick={checkVideo} disabled={videoLink.length < 34}>
                      <FormattedMessage id="uploader.button.embed" />
                    </Button>
                  ),
                }}
                helperText={
                  videoLink.length > 0 && videoLink.length < 34 && <FormattedMessage id="uploader.text.embed" />
                }
              />
              <button className={c.endButtonMobile} onClick={checkVideo} disabled={videoLink.length < 34}>
                <FormattedMessage id="uploader.button.embed" />
              </button>
            </div>
          )}
        </>
      )}
      <div className={c.listFiles}>
        <div className={c.wrapperBlock}>
          {documents.map((item, index) => {
            return (
              <div className={c.file} key={index}>
                <div className={c.left}>
                  {item.image ? (
                    <img src={URL.createObjectURL(item.image)} alt="" className={c.assetImage} />
                  ) : (
                    <img src={videoIcon} alt="" />
                  )}
                  <div className={c.name}>{item.image ? item.image.name : item.linksToVideo}</div>
                  <span>{item.image && formatBytes(+item.image.size)}</span>
                </div>
                <div className={c.right}>
                  <IconButton className={c.delete} onClick={() => removeFile(index)}>
                    <img src={closeIcon} alt="X" />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {documents.length < cap && !uploadNotFinished && (
        <button
          className={c.addButton}
          onClick={() => {
            setNotFinished(true);
          }}
        >
          <FormattedMessage id="uploader.button.addAnotherImgOrVideo" />
        </button>
      )}
    </div>
  );
}
export default FilesUploader;
