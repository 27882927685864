import React from 'react';
import { getFormattedDateAndTime } from '@token-suite/common-ui/src/sharedFunctions/formatData';
import { observer } from 'mobx-react-lite';
import c from './styles.module.scss';
import {
  IParsedNotification,
  NotificationStatus,
  NotificationType,
} from '../../../shared/services/verefication/verefication.types';
import { useStores } from '../../../store';

const NotificationItem = ({ notification }: { notification: IParsedNotification }) => {
  const { vereficationStore } = useStores();
  const { dateFormatted, timeFormatted } = getFormattedDateAndTime(Number(notification.date));

  const signWithInvestor = () => {
    if (notification.metadata?.contractId) {
      vereficationStore.getContractData(notification.metadata.contractId, notification.notificationType);
    }
  };

  const signWithBroker = () => {
    if (notification.metadata?.contractId) {
      vereficationStore.getContractData(notification.metadata.contractId, notification.notificationType);
    }
  };

  return (
    <div
      className={`${c.notification} ${
        notification.notificationStatus === NotificationStatus.NotDone ? `${c.notDone}` : ''
      }`}
    >
      {notification.description}
      <div className={c.buttons}>
        {notification.notificationStatus === NotificationStatus.NotDone &&
          notification.notificationType === NotificationType.SignDocumentWithInvestor && (
            <button className="defaultButton mainColor" onClick={signWithInvestor}>
              Sign Purchase Agreement
            </button>
          )}
        {notification.notificationStatus === NotificationStatus.NotDone &&
          notification.notificationType === NotificationType.SignDocumentWithBroker && (
            <button className="defaultButton mainColor" onClick={signWithBroker}>
              Sign Agreement with Broker
            </button>
          )}
      </div>
      <p className={c.time}>
        {dateFormatted}, {timeFormatted}
      </p>
    </div>
  );
};

export default observer(NotificationItem);
