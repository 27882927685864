import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import userPool from '../../../shared/config/cognitoPool';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

export default function ConfirmCodePage() {
  const query = useQueryParams();
  const navigate = useNavigate();
  const code = query.get('code');
  const email = query.get('email');
  const referralId = query.get('referralId') || '';
  const decodeEmail = decodeURIComponent(`${email}`);
  const encodeEmail = encodeURIComponent(`${email}`);

  useEffect(() => {
    const userData = {
      Username: `${decodeEmail}`,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(
      `${code}`,
      true,
      () => {
        navigate(`/login?email=${encodeEmail}`);
      },
      { host: window.location.hostname, accountRole: 'investor', referralId },
    );
  }, [decodeEmail, code, navigate, encodeEmail, referralId]);

  return <Spinner />;
}
