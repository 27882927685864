import * as yup from 'yup';

const inviteIssuerSchema = yup.object().shape({
  firstName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
  lastName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
  email: yup
    .string()
    .required('This field is required')
    .email('Input correct email')
    .max(127, 'Max length 127 letters'),
  withPortal: yup.boolean(),
  subDomain: yup.string().when('withPortal', {
    is: (withPortal: boolean) => withPortal,
    then: yup
      .string()
      .required('This field is required')
      .max(127, 'Max length 127 letters')
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9.-]*$/, 'Input correct sub domain'),
  }),
  portalName: yup.string().when('withPortal', {
    is: (withPortal: boolean) => withPortal,
    then: yup
      .string()
      .required('This field is required')
      .max(127, 'Max length 127 letters')
      .matches(/[a-zA-Z\d.]+/gi, 'Input correct portal'),
  }),
  description: yup.string().when('withPortal', {
    is: (withPortal: boolean) => withPortal,
    then: yup.string().required('This field is required').max(127, 'Max length 127 letters'),
  }),
  primCountry: yup.string().required('This field is required'),
  primCity: yup.string().required('This field is required'),
  primAddress1: yup.string().required('This field is required'),
  occupation: yup.string().required('This field is required'),
  primZip: yup.string().required('This field is required').matches(/^\d+$/, 'Zip code must be number'),
  dob: yup.string().matches(/\d{1,2}-\d{1,2}-\d{1,4}/i, 'Input correct date'),
  isUsCitizen: yup.boolean(),
  primState: yup.string().when('isUsCitizen', {
    is: (isUsCitizen: boolean) => isUsCitizen,
    then: yup.string().required('This field is required'),
  }),
});

const inviteDemoIssuerSchema = yup.object().shape({
  firstName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
  lastName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
  email: yup
    .string()
    .required('This field is required')
    .email('Input correct email')
    .max(127, 'Max length 127 letters'),
  subDomain: yup
    .string()
    .required('This field is required')
    .max(127, 'Max length 127 letters')
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9.-]*$/, 'Input correct sub domain'),
  portalName: yup
    .string()
    .required('This field is required')
    .max(127, 'Max length 127 letters')
    .matches(/[a-zA-Z\d.]+/gi, 'Input correct portal'),
  description: yup.string().required('This field is required').max(127, 'Max length 127 letters'),
});

const activateDemoIssuer = yup.object().shape({
  dob: yup.string().matches(/\d{1,2}-\d{1,2}-\d{1,4}/i, 'Input correct date'),
  primCountry: yup.string().required('This field is required'),
  primAddress1: yup.string().required('This field is required'),
  primCity: yup.string().required('This field is required'),
  primState: yup.string().when('isUsCitizen', {
    is: (isUsCitizen: boolean) => isUsCitizen,
    then: yup.string().required('This field is required'),
  }),
  primZip: yup.string().required('This field is required').matches(/^\d+$/, 'Zip code must be number'),
  occupation: yup.string().required('This field is required'),
  isUsCitizen: yup.boolean(),
});

const inviteBrokerSchema = yup.object().shape({
  firstName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
  lastName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
  email: yup
    .string()
    .required('This field is required')
    .email('Input correct email')
    .max(127, 'Max length 127 letters'),
});

const inviteBrokerageSchema = yup.object().shape(
  {
    firstName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
    lastName: yup.string().required('This field is required').max(35, 'Max length 35 letters'),
    email: yup
      .string()
      .required('This field is required')
      .email('Input correct email')
      .max(127, 'Max length 127 letters'),
    subDomain: yup
      .string()
      .required('This field is required')
      .max(127, 'Max length 127 letters')
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9.-]*$/, 'Input correct sub domain'),
    portalName: yup
      .string()
      .required('This field is required')
      .max(127, 'Max length 127 letters')
      .matches(/[a-zA-Z\d.]+/gi, 'Input correct portal'),
    firmName: yup.string().required('This field is required').max(127, 'Max length 127 letters'),
    firmCRD: yup.string().when('firmSEC', {
      is: (firmSEC: string) => !firmSEC || firmSEC.length === 0,
      then: yup.string().required('This field is required').max(127, 'Max length 127 letters'),
      otherwise: yup.string(),
    }),
    firmSEC: yup.string().when('firmCRD', {
      is: (firmCRD: string) => !firmCRD || firmCRD.length === 0,
      then: yup.string().required('This field is required').max(127, 'Max length 127 letters'),
      otherwise: yup.string(),
    }),
    description: yup.string().required('This field is required').max(127, 'Max length 127 letters'),
  },
  [['firmSEC', 'firmCRD']],
);

export { inviteIssuerSchema, inviteDemoIssuerSchema, activateDemoIssuer, inviteBrokerSchema, inviteBrokerageSchema };
