import React, { FC } from 'react';
import { RequestParams } from '../../../shared/types/investorTableType';
import filterDisabled from '../../../shared/assets/icons/filterArrowsDisabled.svg';
import filterDESC from '../../../shared/assets/icons/filterArrowsDESC.svg';
import filterASC from '../../../shared/assets/icons/filterArrowsASC.svg';

interface IHeaderIcon {
  controlledParams: RequestParams;
  id: string;
}

const HeaderIcon: FC<IHeaderIcon> = ({ controlledParams, id }) => {
  if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
  if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
  return <img src={filterDisabled} alt="" />;
};

export default HeaderIcon;
