import { makeAutoObservable } from 'mobx';
import RootStore from './index';
import vereficationService from '../shared/services/verefication/vereficatinService';
import {
  IParsedNotification,
  IUserNotifications,
  IUserVerifyInfo,
  IUserVerifyInfoById,
  IUserVerifyStatus,
  NotificationStatus,
  NotificationType,
} from '../shared/services/verefication/verefication.types';
import { BenefeciarData } from '../shared/interfaces/headerInterface';
import signDocumentsService from '../shared/services/sign-documents/signDocumentsService';
import { ContractData } from '../shared/services/sign-documents/types';

class VereficationStore {
  rootStore: RootStore;

  isLoading = false;

  message = '';

  userInfo: IUserVerifyInfo = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    verificationStatus: {
      id: '',
      activated: false,
      kycStatus: '',
      accreditationStatus: '',
      documentStatus: '',
      isBusiness: false,
      errors: '',
      fortressKybVerificationStatus: {
        id: '',
        kybStatus: '',
        errors: '',
      },
      status: '',
    },
  };

  userInfoById: IUserVerifyInfoById = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    accountStatus: {
      status: '',
      activated: false,
    },
    verificationStatus: {
      id: '',
      activated: false,
      kycStatus: '',
      accreditationStatus: '',
      documentStatus: '',
      isBusiness: false,
      errors: '',
      fortressKybVerificationStatus: {
        id: '',
        kybStatus: '',
        errors: '',
      },
    },
    fortressWallet: {
      id: '',
    },
  };

  currentFiles: Blob[] = [];

  benefeciars: BenefeciarData[] = [];

  notDoneNotifications: IParsedNotification[] = [];

  allNotifications: IParsedNotification[] = [];

  openSignContractsModal = false;

  contractData: ContractData = {
    id: '',
    embeddedUrl: '',
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  closeSignModal() {
    this.openSignContractsModal = false;
  }

  changeNotificationAfterSign(contractId: string) {
    const changedNotifications = this.allNotifications.map(c => {
      if (c.metadata.contractId === contractId) {
        return {
          ...c,
          notificationStatus: NotificationStatus.Done,
        };
      }
      return c;
    });
    this.allNotifications = changedNotifications;
    this.notDoneNotifications = this.notDoneNotifications.filter(item => {
      return item.metadata.contractId !== contractId;
    });
  }

  *getContractData(contractId: string, contractType: NotificationType) {
    this.isLoading = true;
    try {
      let data: ContractData;
      if (contractType === NotificationType.SignDocumentWithBroker) {
        data = yield signDocumentsService.getBrokerContractDataById(contractId);
      } else {
        data = yield signDocumentsService.getInvestorContractDataById(contractId);
      }
      this.contractData = data;
      this.openSignContractsModal = true;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getUserVerifyStatus() {
    this.isLoading = true;
    try {
      const userData: IUserVerifyStatus = yield vereficationService.getUserVerifyStatus();
      this.userInfo.verificationStatus = userData;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getUserNotifications() {
    this.isLoading = true;
    try {
      const userData: IUserNotifications = yield vereficationService.getUserNotifications();
      const parsedData: IParsedNotification[] = userData.notifications.map(item => {
        return {
          ...item,
          metadata: JSON.parse(item.metadata),
        };
      });
      this.notDoneNotifications = parsedData.filter(item => {
        return item.notificationStatus === NotificationStatus.NotDone;
      });
      this.allNotifications = parsedData;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getUserVerifyStatusWithId(id: string) {
    this.isLoading = true;
    try {
      const userData: IUserVerifyInfoById = yield vereficationService.getUserVerifyStatusById(id);
      this.userInfoById = userData;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *createWallet(id: string) {
    this.isLoading = true;
    try {
      yield vereficationService.createWallet(id);
      this.getUserVerifyStatusWithId(id);
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getBeneficiars() {
    this.isLoading = true;
    try {
      const data: BenefeciarData[] = yield vereficationService.getBeneficiars();
      this.benefeciars = data;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getBeneficiarsByAdmin(id: string) {
    this.isLoading = true;
    try {
      const data: BenefeciarData[] = yield vereficationService.getBeneficiarsById(id);
      this.benefeciars = data;
    } catch (e: any) {
      this.message = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }
}

export default VereficationStore;
