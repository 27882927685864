import { observer } from 'mobx-react-lite';
import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useStores } from '../../store';
import c from './style.module.scss';
import RedirectPage from '../../pages/auth/redirectPage/redirect';
import Layout from '../../widgets/layout/Layout';
import { privateRoutes } from './private-routes';
import SettingPage from '../../pages/common/setting';
import NotificationsPage from '../../pages/common/notifications/notifications';
import InvestorAssets from '../../pages/investor/assetsPage/InvestorAssets';
import AssetPage from '../../pages/investor/assetPage/assetPage';
import RegistrationPage from '../../pages/auth/register/register';
import Login from '../../pages/auth/login/login';
import ConfirmationPage from '../../pages/auth/confirm/confirm';
import ConfirmCodePage from '../../pages/auth/confirm-code/confirm';
import ForgotPasswordPage from '../../pages/auth/forgot-password/ForgotPasswordPage';
import SetNewPasswordPage from '../../pages/auth/set-new-password/setNewPasswordPage';
import ProtectedRoute from './ProtectedRoute';
import AuthedRoute from './AuthedRoute';
import KycNCPage from '../../pages/investor/kyc-nc/kyc';
import KycPage from '../../pages/investor/kyc/kyc';
import KybPage from '../../pages/investor/kyc/kyb';
import Benefeciars from '../../pages/investor/benefeciars/benefeciars';
import AccreditationPage from '../../pages/investor/accreditation/accreditation';
import ReferalsPage from '../../pages/auth/referalsPage/referals';

function Routing() {
  const {
    headerStore: {
      user: { role },
    },
    portalStore,
  } = useStores();

  return (
    <Router>
      <div
        className={
          portalStore.isCustomPortal && portalStore.isCustomPortalLoading ? `${c.loading}` : `${c.loading} ${c.hide}`
        }
      >
        {portalStore.isCustomPortalLoading && <Spinner single />}
      </div>
      <div className={c.wrapper}>
        <Routes>
          <Route path="/" element={<Navigate to="/redirect" replace />} />
          {/* not found page route */}
          <Route path="*" element={<Navigate to="/redirect" replace />} />
          <Route path="/redirect" element={<RedirectPage />} />
          <Route path="/referrals" element={<ReferalsPage />} />
          {/* routes with left menu and header */}
          <Route element={<Layout />} path="/">
            <Route element={<ProtectedRoute />}>
              {role &&
                privateRoutes[role as 'admin'].map(({ path, element: Component }) => (
                  <Route key={path} path={path} element={<Component />} />
                ))}
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route path="/account-setting" element={<SettingPage />} />
              <Route path="/kyc" element={<KycPage />} />
              <Route path="/beneficiars" element={<Benefeciars />} />
              <Route path="/kyb" element={<KybPage />} />
              <Route path="/kyc-nc" element={<KycNCPage />} />
              <Route path="/accreditation" element={<AccreditationPage />} />
            </Route>
            <Route path="/assets" element={<InvestorAssets />} />
            <Route path="/assets/:id" element={<AssetPage />} />
          </Route>
          {/* notAuth routes without header and left menu */}
          <Route path="/sign-up" element={<RegistrationPage />} />
          <Route element={<AuthedRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/confirm" element={<ConfirmationPage />} />
            <Route path="/confirm-code" element={<ConfirmCodePage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/update-password" element={<SetNewPasswordPage />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default observer(Routing);
