import React, { useEffect } from 'react';
import '../../../app/styles/reusable/containers.scss';
import Box from '@mui/material/Box';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import TransactionsFilter from './transactions-filter';
import TransactionsTable from './transactions-table';
import { RequestParams } from '../../../shared/types/investorTableType';
import { useStores } from '../../../store';
import { usePagination } from '../../../shared/hooks/usePagination';
import ViewDetailsModal from '../../UI/dialogs/viewDetailsModal/viewDetailsModal';

const TransactionHistory = () => {
  const { headerStore, transactionsStore } = useStores();

  const loadTransactions = async (params: RequestParams) => {
    const isIssuer = headerStore.user.role === 'issuer';
    flowResult(transactionsStore.getTransactions(params, isIssuer));
  };

  const {
    controlledParams,
    loadItems,
    onCurrentPageChange,
    onPageSizeChange,
    onAssetIdChange,
    onDatesChange,
    onOrderChange,
  } = usePagination({
    callback: loadTransactions,
    pageName: 'transactions-history',
  });

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Box className="nestedContainer">
      <TransactionsFilter onAssetIdChange={onAssetIdChange} onDatesChange={onDatesChange} />
      <TransactionsTable
        transactions={transactionsStore.transactions}
        isLoading={transactionsStore.state === 'pending'}
        totalTransactions={transactionsStore.totalTransactions}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        controlledParams={controlledParams}
        onOrderChange={onOrderChange}
      />
      {transactionsStore.transactionDetails && (
        <ViewDetailsModal
          open={transactionsStore.isDetailsModalOpen}
          transaction={transactionsStore.transactionDetails}
        />
      )}
    </Box>
  );
};

export default observer(TransactionHistory);
