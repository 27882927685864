import React, { useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import copyMail from '../../shared/assets/icons/copyMail.svg';
import verticalMenuDots from '../../shared/assets/icons/verticalMenuDots.svg';
import c from './style.module.scss';
import { BrokerData } from '../../shared/interfaces/headerInterface';
import { copyDataInBuffer } from '../../shared/lib';

function BrokerProfileRow({ profileData }: { profileData: BrokerData }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { firstName, lastName, email, portalName, role } = profileData;
  const emailRef = useRef<null | HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  const [isEmailTooltipOpen, setIsEmailTooltipOpen] = useState(false);

  const mouseOnEmailHandler = () => {
    if (!emailRef.current) return;
    if (emailRef.current.offsetWidth >= emailRef.current.scrollWidth) return;
    setIsEmailTooltipOpen(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onRedirectToViewDetailsHandler = (investorId: string) => {
    navigate(`/broker/${investorId}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`${c.profileRow}`}>
      <div className={c.columnLeft}>
        <div className={c.name}>
          {firstName} {lastName}
        </div>
        <div className={c.email}>
          <Tooltip
            open={isEmailTooltipOpen}
            onClose={() => setIsEmailTooltipOpen(false)}
            title={email}
            onOpen={mouseOnEmailHandler}
            arrow
          >
            <div className={c.emailText} ref={emailRef}>
              {email}
            </div>
          </Tooltip>
          <button
            onClick={() => {
              copyDataInBuffer(email);
            }}
          >
            <img src={copyMail} alt="copyMail" />
          </button>
        </div>
      </div>
      <div className={c.columnRight}>
        <div className={c.itemColType}>{role.slice(5)}</div>
        <div className={c.itemColType}>{portalName}</div>
        <div className={c.menu}>
          <IconButton
            id="positioned-button"
            aria-controls={open ? 'positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={c.menuBtn}
          >
            <img src={verticalMenuDots} alt="verticalMenuDots" />
          </IconButton>
          <Menu
            id="positioned-menu"
            aria-labelledby="positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => onRedirectToViewDetailsHandler(profileData.id)}>
              <FormattedMessage id="investorsPage.menu.viewDetails" />
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
export default BrokerProfileRow;
