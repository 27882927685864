// TODO made dynamic currency symbol

const getPrettyfyNumber = (num: number) => {
  const prefixes = ['fictive', 'M', 'B', 'T', 'Q'];
  const ratio = Math.floor(Math.log(num) / Math.log(1000));
  const prefix = prefixes[ratio > 0 ? ratio - 1 : 0];
  if (prefix !== 'fictive') {
    return `${num / 1000 ** ratio}${prefix}`;
  }

  return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export default getPrettyfyNumber;
