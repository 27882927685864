import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { IconButton, TablePagination } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import exportCsv from '@token-suite/common-ui/src/sharedFunctions/exportCsv';
import { observer } from 'mobx-react-lite';
import InviteInvestorModal from '../../invite-investor-modal/ivniteInvestorModal';
import c from './style.module.scss';
import { ProfileData } from '../../../shared/interfaces/headerInterface';
import tokenInstance from '../../../shared/services/API/tokenApi';
import filterDisabled from '../../../shared/assets/icons/filterArrowsDisabled.svg';
import filterASC from '../../../shared/assets/icons/filterArrowsASC.svg';
import filterDESC from '../../../shared/assets/icons/filterArrowsDESC.svg';
import exportIcon from '../../../shared/assets/icons/export.svg';
import ProfileRow from '../../profileRow/profileRow';
import { RequestParams } from '../../../shared/types/investorTableType';
import { usePagination } from '../../../shared/hooks/usePagination';
import { useStores } from '../../../store';
import ProfileRowFortress from '../../profileRow/profileRowFortress';

function OnbordingInvestors() {
  const [items, setItems] = useState<ProfileData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [refresh, setRefresh] = useState({});

  const loadItems = async (requestParams: RequestParams) => {
    setIsLoading(true);
    const { data } = await tokenInstance.get<{ users: ProfileData[]; total: number }>('users/investors', {
      params: requestParams,
    });
    setItems(data.users);
    setTotalItems(data.total);
    setIsLoading(false);
  };

  const {
    loadItems: load,
    onPageSizeChange,
    onCurrentPageChange,
    controlledParams,
    onOrderChange,
    onStatusChange,
  } = usePagination({
    callback: loadItems,
  });

  const { portalStore } = useStores();

  const renderRows = () => {
    if (portalStore.portalUI.features?.kycKyb === 'fortress') {
      return items.map((profile: ProfileData) => (
        <ProfileRowFortress profileData={profile} onboardingInvestors key={profile.email} setItems={setItems} />
      ));
    }
    return items.map((profile: ProfileData) => (
      <ProfileRow profileData={profile} onboardingInvestors key={profile.email} setItems={setItems} />
    ));
  };

  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  useEffect(() => {
    load();
  }, [load, refresh]);

  const exportCsvHandler = () => {
    tokenInstance.get('users/investors/csv').then((data: any) => {
      exportCsv('OnboardingInvestors', data.data);
    });
  };

  return (
    <div className={c.wrapper}>
      <div className={c.topPanel}>
        <div className={c.filterPanel}>
          <div className={c.left}>
            <div className={c.text}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.name" />
            </div>
            <Select
              className={c.select}
              value={controlledParams.status || 'all'}
              onChange={e => {
                onStatusChange(e.target.value);
              }}
            >
              <MenuItem value="all">
                <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.all" />
              </MenuItem>
              <MenuItem value="Verification Not Started Yet">
                <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.notStarted" />
              </MenuItem>
              <MenuItem value="Verified">
                <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verified" />
              </MenuItem>
              <MenuItem value="Verification Pending">
                <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedPending" />
              </MenuItem>
              {portalStore.portalUI.features?.kycKyb !== 'fortress' && (
                <MenuItem value="Verification in Progress">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedProcess" />
                </MenuItem>
              )}
              {portalStore.portalUI.features?.kycKyb !== 'fortress' && (
                <MenuItem value="Verification Denied, KYC/AML">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedDeniedKyc" />
                </MenuItem>
              )}
              {portalStore.portalUI.features?.kycKyb !== 'fortress' && (
                <MenuItem value="Verification Denied, Accreditation">
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedDeniedAccreditation" />
                </MenuItem>
              )}
              <MenuItem value="Verification Denied">
                <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedDenied" />
              </MenuItem>
            </Select>
          </div>
          <div className={c.right}>
            <button className={c.button} onClick={exportCsvHandler}>
              <img src={exportIcon} alt="exportIcon" />
              Export CSV
            </button>
            <InviteInvestorModal
              refreshList={() => {
                setRefresh({});
              }}
            />
          </div>
        </div>
      </div>
      <div className={c.table}>
        <div className={`${c.titleRow} ${c.titleRowIssuer}`}>
          <div className={c.columnLeftTitle}>
            <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.name" />
            <IconButton className={c.orderButton} disabled={isLoading} onClick={() => onOrderChange('name')}>
              {renderOrderIcon('name')}
            </IconButton>
          </div>
          <div className={c.columnRightTitle}>
            <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.status" />
            <IconButton disabled={isLoading} onClick={() => onOrderChange('status')} className={c.orderButton}>
              {renderOrderIcon('status')}
            </IconButton>
          </div>
          <div className={`${c.columnRightTitle} ${c.noSort}`}>
            <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.type" />
          </div>
          <div className={`${c.columnRightTitle} ${c.noSort}`}>
            <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.isActive" />
          </div>
          <div className={`${c.columnRightTitle} ${c.noSort}`}>
            <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.wallet" />
          </div>
        </div>
        <div className={c.profilesRows}>
          <div className={c.loadingCell}>
            {isLoading && (
              <div className={c.loading}>
                <Spinner single />
              </div>
            )}
          </div>
          {items.length > 0 ? (
            renderRows()
          ) : (
            <div className={c.emptyBlock}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
            </div>
          )}
        </div>
        <TablePagination
          className={c.pagination}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={+controlledParams.pageSize}
          page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
          onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
          onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
          SelectProps={{
            disabled: isLoading,
          }}
          backIconButtonProps={{
            disabled: isLoading || +controlledParams.currentPage === 0,
          }}
          nextIconButtonProps={{
            disabled:
              isLoading ||
              Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
              totalItems === 0,
          }}
        />
      </div>
    </div>
  );
}
export default observer(OnbordingInvestors);
