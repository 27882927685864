import { FormattedMessage, useIntl } from 'react-intl';
import { DateInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import c from '../styles.module.scss';
import { useStores } from '../../../store';

function EditMainBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [dateError, setDateError] = useState(false);

  const [startDate, setStartDate] = useState(new Date(assetStore.currentAsset.startDate));
  const [endDate, setEndDate] = useState(new Date(assetStore.currentAsset.endDate));

  const submit = () => {
    if (!dateError) {
      flowResult(assetStore.saveMainInfo(id, startDate, endDate));
    }
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="mainInfo">
        <FormattedMessage id="createAssetPage.text.mainBlock" />
      </div>
      <div className={c.inputsBlock}>
        <DateInput
          value={startDate}
          changeValue={setStartDate}
          label={intl.formatMessage({ id: 'createAssetPage.fields.startDate.label' })}
          setError={setDateError}
        />
        <DateInput
          value={endDate}
          changeValue={setEndDate}
          label={intl.formatMessage({ id: 'createAssetPage.fields.endDate.label' })}
          setError={setDateError}
        />
      </div>
      <button className={c.submitButton} onClick={submit} disabled={dateError}>
        Save
      </button>
    </div>
  );
}

export default observer(EditMainBlock);
