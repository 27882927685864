import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import c from './style.module.scss';
import { IAssetItem } from '../../../../shared/interfaces/userInfo.interface';
import { useStores } from '../../../../store';

interface AssetRowProps {
  asset: IAssetItem;
  loadItems: () => void;
}

const AssetRow: FC<AssetRowProps> = ({ asset }) => {
  const [isImageLoad, setIsImageLoad] = useState(false);
  const { dividendStore } = useStores();

  return (
    <TableRow className={`tableRowDT ${c.wrapper}`}>
      <TableCell>
        <div className={c.image}>
          {!isImageLoad && (
            <div className={c.load}>
              <Spinner single />
            </div>
          )}
          <img
            src={asset.previewImage.url}
            alt=""
            onLoad={() => setIsImageLoad(true)}
            style={{ display: isImageLoad ? 'block' : 'none' }}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className={c.name}>{asset.name}</div>
      </TableCell>
      <TableCell>
        <div className={c.domains}>{asset.portal?.domains.map(i => i.domain).join(', ') || '-'}</div>
      </TableCell>
      <TableCell>
        <div className={c.portalName}>{asset.portal?.portalName || '-'}</div>
      </TableCell>
      <TableCell>
        <div className={c.buttons}>
          <Link to={`/asset-payment-methods/${asset.id}`} className={c.link}>
            Payment methods
          </Link>
          <Link to={`/edit-asset/${asset.id}`} className={c.link}>
            Edit Asset
          </Link>
          <button
            className={c.link}
            onClick={() => {
              dividendStore.openDistributeModal(asset.id);
            }}
          >
            Distribute
          </button>
          {/* {!asset.custodialAccount?.id && (
            <button
              className={c.link}
              onClick={() => {
                assetService.createCustodialForAsset(asset.id).then(() => {
                  loadItems();
                });
              }}
            >
              Create Custodial
            </button>
          )} */}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default AssetRow;
