import { makeAutoObservable } from 'mobx';
import RootStore from './index';
import { TokenHolderInvestor } from '../shared/interfaces/issuer/issuer.interfaces';
import tokenService from '../shared/services/token/tokenService';
import { TokenOnPortal, TokenToSign } from '../shared/services/token/types';

class IssuerStore {
  rootStore: RootStore;

  tokenHolders: TokenHolderInvestor[] = [];

  total: number = 0;

  state: 'pending' | 'done' | 'error' = 'done';

  message: string = '';

  allTokenContracts: TokenOnPortal[] = [];

  tokenContractsToSign: TokenToSign[] = [];

  /**
   * Use only on active contracts page for issuer (TODO remake it)
   */
  deleteContractCb: ((id: string) => void) | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  clearContractsToSign() {
    this.tokenContractsToSign = [];
    this.allTokenContracts = [];
  }

  *getTokensByPortalIssuer(wallet: string) {
    try {
      const data: TokenOnPortal[] = yield tokenService.getTokensByPortalIssuer();
      this.allTokenContracts = data;
      this.tokenContractsToSign = [];
      for (const token of data) {
        if (token.initialSales && token.issuerAccount.toLowerCase() === wallet.toLowerCase()) {
          for (const sale of token.initialSales) {
            if (sale.status === 'PENDING') {
              this.tokenContractsToSign.push({
                id: sale.id,
                assetSymbol: sale.asset.tickerSymbol,
                tokenSymbol: token.tokenSymbol,
                tokenQuantity: sale.asset.tokenQuantity.toString(),
                contractAddress: sale.contractAddress,
                tokenAddress: token.tokenAddress,
              });
            }
          }
        }
      }
    } catch (e) {
      this.message = 'Server error';
    }
  }
}

export default IssuerStore;
