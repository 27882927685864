import { Outlet } from 'react-router-dom';
import React from 'react';
import AccountNav from '../../features/user-navigation/ui/accountNav';
import LeftMenu from '../../components/leftMenu/leftMenu';
import c from './style.module.scss';
import { WalletProvider } from '../../context/walletContext/Wallet.context';
import WalletErrorWrapper from './wallet-error-wrapper';
import { MetaKeepProvider } from '../../context/MetaKeepContext/MetaKeep.context';

const Layout = () => {
  return (
    <WalletProvider>
      <MetaKeepProvider>
        <>
          <div className={c.wrapper}>
            {localStorage.getItem('accessToken') ? (
              <>
                <AccountNav />
                <LeftMenu />
                <div className={c.mainBlock}>
                  <div className={c.wrapper}>
                    <Outlet />
                  </div>
                </div>
              </>
            ) : (
              <>
                <AccountNav />
                <div className={c.fullBlock}>
                  <div className={c.wrapper}>
                    <Outlet />
                  </div>
                </div>
              </>
            )}
          </div>
          <WalletErrorWrapper />
        </>
      </MetaKeepProvider>
    </WalletProvider>
  );
};

export default Layout;
