import { Box } from '@mui/material';
import NonMintedAsstes from './NonMintedAsstes/NonMintedAsstes';
import c from './styles.module.scss';
import IssuerAssetsSlider from './IssuerAssetsSlider/IssuerAssetsSlider';

function IssuerAssetsPage() {
  return (
    <div>
      <h1 className={c.title}>Assets</h1>
      <IssuerAssetsSlider />
      <NonMintedAsstes />
      <Box mt={20}>
        <NonMintedAsstes minted />
      </Box>
    </div>
  );
}

export default IssuerAssetsPage;
