import { FormikControlProps } from '../formik/formik-control/formik-control.types';

const fields: Array<FormikControlProps & { id: number }> = [
  {
    id: 0,
    control: 'input',
    inputProps: {
      name: 'firstName',
      id: 'firstName',
      label: 'First name',
      placeholder: 'Enter issuer first name',
    },
  },
  {
    id: 1,
    control: 'input',
    inputProps: {
      name: 'lastName',
      id: 'lastName',
      label: 'Last name',
      placeholder: 'Enter issuer last name',
    },
  },
  {
    id: 2,
    control: 'input',
    inputProps: {
      name: 'email',
      id: 'email',
      label: 'Email',
      placeholder: 'name@example.com',
    },
  },
  {
    id: 3,
    control: 'input',
    inputProps: {
      name: 'subDomain',
      id: 'subDomain',
      label: 'Sub Domain',
      placeholder: 'Enter Sub Domain',
    },
  },
  {
    id: 4,
    control: 'input',
    inputProps: {
      name: 'portalName',
      id: 'portalName',
      label: 'Portal Name',
      placeholder: 'Enter Portal Name',
    },
  },
  {
    id: 5,
    control: 'input',
    inputProps: {
      name: 'description',
      id: 'description',
      label: 'Description',
      placeholder: 'Enter description',
      multiline: true,
      rows: 4,
    },
  },
];

export { fields };
