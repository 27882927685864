import { TokenHolderType } from '../../../../shared/types/issuerAssetType';

export const tokenPriceGraph = [
  {
    date: '01.09.22',
    value: 1,
  },
  {
    date: '02.09.22',
    value: 5,
  },
  {
    date: '03.09.22',
    value: 3,
  },
  {
    date: '04.09.22',
    value: 2,
  },
];

export const totalValueGraph = [
  {
    date: '01.09.22',
    value: 5,
  },
  {
    date: '02.09.22',
    value: 7,
  },
  {
    date: '03.09.22',
    value: 3,
  },
  {
    date: '04.09.22',
    value: 5,
  },
];

export const tokenHoldersTestData: TokenHolderType[] = [
  {
    id: '1',
    fullName: 'Betty Simpson',
    asset: 'MHT',
    tokenAllocation: 3000000,
    value: 1200000,
    location: 'USA',
    currency: 'USD',
  },
  {
    id: '2',
    fullName: 'Dwane Stevens',
    asset: 'MHT',
    tokenAllocation: 1340000,
    value: 1000000,
    location: 'CAN',
    currency: 'USD',
  },
  {
    id: '3',
    fullName: 'Betty Simpson',
    asset: 'MHT',
    tokenAllocation: 3000000,
    value: 1200000,
    location: 'USA',
    currency: 'USD',
  },
  {
    id: '4',
    fullName: 'Dwane Stevens',
    asset: 'MHT',
    tokenAllocation: 1340000,
    value: 1000000,
    location: 'CAN',
    currency: 'USD',
  },
];

export const historicalPerformanceTestData = [
  {
    date: '06.10.2022',
    value: 1000000,
  },
  {
    date: '06.11.2022',
    value: 7000000,
  },
  {
    date: '06.12.2022',
    value: 50000000,
  },
  {
    date: '06.13.2022',
    value: 98000000,
  },
  {
    date: '06.14.2022',
    value: 80000000,
  },
  {
    date: '06.15.2022',
    value: 30000000,
  },
  {
    date: '06.16.2022',
    value: 90000000,
  },
  {
    date: '06.17.2022',
    value: 110000000,
  },
  {
    date: '06.18.2022',
    value: 150000000,
  },
];
