import React from 'react';
import AdminBlockListTable from '../../../components/admin-block-list/admin-block-list-table';
import c from './style.module.scss';

const AdminInvestorsBlockListPage = () => {
  return (
    <div className={c.wrapper}>
      <h2 className={c.title}>Block list</h2>
      <div className={c.body}>
        <AdminBlockListTable />
      </div>
    </div>
  );
};

export default AdminInvestorsBlockListPage;
