type Column = {
  id: any;
  label: string;
  minWidth?: string;
  sortable?: true;
  left?: true;
  format?: (asset: any) => string;
  render?: (asset: any) => JSX.Element;
};

const columns: readonly Column[] = [
  {
    id: 'name',
    left: true,
    label: 'Name',
    minWidth: '100px',
  },
  {
    id: 'asset',
    left: true,
    label: 'Asset',
  },
  {
    id: 'clicks',
    label: 'Clicks',
  },
  {
    id: 'registered',
    label: 'Registered',
  },
  {
    id: 'kycPassed',
    label: 'KYC Passed',
  },
  {
    id: 'purchases',
    label: 'Purchases',
  },
];

export { columns };
