import { FormattedMessage, useIntl } from 'react-intl';
import { TextIndexInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { useEffect, useState } from 'react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from '../styles.module.scss';
import { useStores } from '../../../store';

function EditPerksBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [perks, setPerks] = useState<
    {
      id?: string;
      title: string;
      description: string;
    }[]
  >([]);

  const [perksHighlight, setPerksHighlight] = useState('');

  useEffect(() => {
    if (assetStore.currentAsset.investorPerks) {
      setPerks(assetStore.currentAsset.investorPerks.fields);
      setPerksHighlight(assetStore.currentAsset.investorPerks.highlights);
    }
  }, [assetStore.currentAsset.investorPerks]);

  const addHighlight = () => {
    setPerks([...perks, { title: '', description: '' }]);
  };

  const removeFile = (index: number, perkId: string | undefined) => {
    const newHighlights = perks.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setPerks(newHighlights);
    if (perkId) {
      flowResult(assetStore.deletePerk(id, perkId));
    }
  };

  const perkAuthorHandler = (e: string, index: number) => {
    const tempArr = perks;
    tempArr[index].title = e;
    setPerks([...tempArr]);
  };
  const perkTextHandler = (e: string, index: number) => {
    const tempArr = perks;
    tempArr[index].description = e;
    setPerks([...tempArr]);
  };

  const submit = () => {
    flowResult(assetStore.updatePerks(id, perksHighlight, perks));
  };

  return (
    <div className={c.formPart}>
      <div className={c.title} id="mainInfo">
        <FormattedMessage id="createAssetPage.text.investorPerksBlock" />
      </div>
      <div className={c.wrapper}>
        <TextInput
          value={perksHighlight}
          changeValue={setPerksHighlight}
          label={intl.formatMessage({ id: 'createAssetPage.investorPerks.field.highlights.label' })}
          placeholder={intl.formatMessage({ id: 'createAssetPage.investorPerks.field.highlights.placeholder' })}
          type="text"
          fullWidth
        />
        <div className={c.listHighlights}>
          <div className={c.wrapperBlock}>
            {perks.map((review, index: number) => (
              <div className={c.review} key={index}>
                <div className={c.left}>
                  <TextIndexInput
                    value={review.title}
                    changeValue={perkAuthorHandler}
                    label={intl.formatMessage(
                      { id: 'createAssetPage.investorPerks.field.title.label' },
                      { count: `${index + 1}` },
                    )}
                    placeholder={intl.formatMessage({ id: 'createAssetPage.investorPerks.field.title.placeholder' })}
                    type="text"
                    fullWidth
                    index={index}
                  />
                  <TextIndexInput
                    value={review.description}
                    changeValue={perkTextHandler}
                    label={intl.formatMessage(
                      { id: 'createAssetPage.investorPerks.field.description.label' },
                      { count: `${index + 1}` },
                    )}
                    placeholder={intl.formatMessage({
                      id: 'createAssetPage.investorPerks.field.description.placeholder',
                    })}
                    type="text"
                    fullWidth
                    multiline
                    index={index}
                  />
                </div>
                <div className={c.right}>
                  <IconButton className={c.delete} onClick={() => removeFile(index, review.id)}>
                    <img src={closeIcon} alt="X" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className={c.addButton} onClick={addHighlight}>
          <FormattedMessage id="createAssetPage.investorPerks.button.addPerk" />
        </button>
      </div>
      <button className={c.submitButton} onClick={submit}>
        Save
      </button>
    </div>
  );
}

export default observer(EditPerksBlock);
