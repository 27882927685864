import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { Helmet } from 'react-helmet-async';
import TablePagination from '@mui/material/TablePagination';
import c from './style.module.scss';
import { BrokerData } from '../../../shared/interfaces/headerInterface';
import tokenInstance from '../../../shared/services/API/tokenApi';
import BrokerProfileRow from '../../../components/brokerProfileRow/brokerProfileRow';
import InviteBrokerageModal from '../../../components/create-brokerage-modal/createBrokerageModal';
import { useFrontPagination } from '../../../shared/hooks/useFrontPagination';

export default function IssuersPage() {
  const [items, setItems] = useState<BrokerData[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState({});

  const loadItems = async () => {
    setIsLoading(true);
    const { data } = await tokenInstance.get<{ brokers: BrokerData[]; total: number }>('users/brokers/list');
    setItems(data.brokers);
    setTotalItems(data.brokers.length);
    setIsLoading(false);
  };

  const { paginationItems, onPageSizeChange, onCurrentPageChange, controlledParams } = useFrontPagination(items);

  useEffect(() => {
    loadItems();
  }, [refresh]);

  return (
    <>
      <Helmet>
        <title>Brokers | TokenSuite</title>
      </Helmet>
      <div className={c.title}>
        <FormattedMessage id="brokersPage.text.header" />
      </div>
      <div className={c.wrapper}>
        <div className={c.topPanel}>
          <div className={c.filterPanel}>
            <div className="left">{}</div>
            <div className={c.right}>
              <InviteBrokerageModal
                refreshList={() => {
                  setRefresh({});
                }}
              />
            </div>
          </div>
        </div>
        <div className={c.table}>
          <div className={c.titleRow}>
            <div className={c.columnLeftTitle}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.name" />
            </div>
            <div className={`${c.columnRightTitle} ${c.noSort}`}>Role</div>
            <div className={`${c.columnRightTitle} ${c.noSort}`}>Portal</div>
          </div>
          <div className={c.profilesRows}>
            <div className={c.loadingCell}>
              {isLoading && (
                <div className={c.loading}>
                  <Spinner single />
                </div>
              )}
            </div>
            {items.length > 0 ? (
              paginationItems.map((profile: BrokerData) => <BrokerProfileRow profileData={profile} key={profile.id} />)
            ) : (
              <div className={c.emptyBlock}>No Brokers Yet</div>
            )}
          </div>
          <TablePagination
            className={c.pagination}
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={+controlledParams.pageSize}
            page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
            onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
            onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
            SelectProps={{
              disabled: isLoading,
            }}
            backIconButtonProps={{
              disabled: isLoading || +controlledParams.currentPage === 0,
            }}
            nextIconButtonProps={{
              disabled:
                isLoading ||
                Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
                totalItems === 0,
            }}
          />
        </div>
      </div>
    </>
  );
}
