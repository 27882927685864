import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Stack, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import closeIcon from '../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import FormikControl from '../formik/formik-control/formik-control';
import FormikContainer from '../formik/formik-container/formik-container';
import { fields } from './invite-issuer.meta';
import { activateDemoIssuer } from '../../shared/lib/validation-schemas/invite-issuer-schema';
import { useStores } from '../../store';

type InitialValues = {
  occupation: string;
  dob: string;
  primCountry: string;
  primCity: string;
  primAddress1: string;
  primZip: string;
  primState?: string | undefined;
  isUsCitizen: boolean;
};

function DemoIssuerModal({ portalId }: { portalId: string }) {
  const { adminStore } = useStores();
  const [open, setOpen] = useState(false);
  const [serverError, setServerError] = useState('');

  const initialValues: InitialValues = {
    dob: moment().format('MM-DD-YYYY'),
    primCountry: '',
    primCity: '',
    occupation: '',
    primAddress1: '',
    primZip: '',
    primState: '',
    isUsCitizen: true,
  };

  const onSubmit = async (values: InitialValues, formikHelpers: FormikHelpers<any>) => {
    setServerError('');
    try {
      const payload = { ...values };
      if (!values.isUsCitizen) {
        delete payload.primState;
      }
      await flowResult(adminStore.activateDemoIssuer(payload, portalId));
      formikHelpers.resetForm();
    } catch (e: any) {
      const errors: string[] = e?.response?.data?.errors;
      if (errors?.length) {
        const fieldErrors = Object.fromEntries(errors.map(item => item.split(':')));
        formikHelpers.setErrors(fieldErrors);
        return;
      }
      if (e?.response?.data?.message) {
        setServerError(e?.response?.data?.message);
      }
    }
  };

  const closeModalHandler = () => {
    setOpen(false);
  };

  return (
    <div className={c.wrapper}>
      <button onClick={() => setOpen(true)} className={c.submitButton}>
        Activate Portal
      </button>
      <Dialog open={open} onClose={closeModalHandler}>
        <div className={c.dialog}>
          <div className={c.title}>Activate Portal</div>
          <Button onClick={closeModalHandler} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <FormikContainer<typeof initialValues>
            initialValues={initialValues}
            validationSchema={activateDemoIssuer}
            onSubmitHandler={onSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Stack gap="20px">
                <div>
                  {fields.map(({ id, ...field }) => {
                    if (field?.autocompleteProps?.name === 'primState' && !values.isUsCitizen) {
                      if (values.primState) setFieldValue(field.autocompleteProps.name, '');
                      return null;
                    }
                    return (
                      <Box key={id} sx={{ mt: '10px' }}>
                        <FormikControl {...field} />
                      </Box>
                    );
                  })}
                </div>
                <Button type="submit" className={c.submitButton} disabled={isSubmitting}>
                  {isSubmitting ? 'Loading...' : 'Activate'}
                </Button>
                {serverError && (
                  <Stack alignItems="center" mt="5px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        color: '#f05555',
                      }}
                    >
                      {serverError}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </FormikContainer>
        </div>
      </Dialog>
    </div>
  );
}

export default observer(DemoIssuerModal);
