export enum EAccountStatuses {
  VerificationNotStartedYet = 'Verification Not Started Yet',
  VerificationPending = 'Verification Pending', // waiting for results
  VerificationDenied = 'Verification Denied',
  Blocked = 'Blocked',
  Verified = 'Verified',
}

export enum EAccountStatusesNorthCapital {
  VerificationNotStartedYet = 'Verification Not Started Yet',
  Verification = 'Verification',
  VerificationPending = 'Verification Pending', // finish KYC\KYB, not start Accreditation
  VerificationInProgress = 'Verification in Progress', // finish Accreditation, dont know results
  VerificationDeniedKYCAML = 'Verification Denied, KYC/AML',
  VerificationDeniedAccreditation = 'Verification Denied, Accreditation',
  VerificationDenied = 'Verification Denied',
  Blocked = 'Blocked',
  Verified = 'Verified',
}

export enum EAccountAccreditationStatuses {
  NotStartedYet = 'Not Started Yet',
  Pending = 'Pending', // waiting for results
  Denied = 'Denied',
  Blocked = 'Blocked',
  Verified = 'Verified',
  NotNeeded = 'Not Needed',
}

export enum EDocumentStatuses {
  NotStartedYet = 'Not Started Yet',
  Pending = 'Pending', // waiting for results
  Approved = 'Approved',
  Disapproved = 'Disapproved',
  NeedUpdate = 'Need Update',
}
