import PortfolioPage from '../../../pages/investor/portfolio/portfolio';
import CheckoutOverview from '../../../pages/investor/checkout/checkoutOverview';
import CheckoutPayment from '../../../pages/investor/checkout/checkoutPayment';
import CheckoutConfirmation from '../../../pages/investor/checkout/checkoutConfirmation';
import { IRoute } from '../model/private-route';
import WalletPage from '../../../pages/common/wallet/wallet';
import ActivityLogPage from '../../../pages/investor/activity/activity-log-page';
import CheckoutVerification from '../../../pages/investor/checkout/checkoutVerification';

const investorRoutes: IRoute[] = [
  {
    path: '/portfolio',
    element: PortfolioPage,
  },
  {
    path: '/checkout/onboarding/:id/:tokens',
    element: CheckoutVerification,
  },
  {
    path: '/checkout/order/:id/:tokens',
    element: CheckoutOverview,
  },
  {
    path: '/checkout/payment/:id/:tokens',
    element: CheckoutPayment,
  },
  {
    path: '/checkout/confirmation/:id/:tokens',
    element: CheckoutConfirmation,
  },
  {
    path: '/wallet',
    element: WalletPage,
  },
  {
    path: '/activity-log',
    element: ActivityLogPage,
  },
];

export { investorRoutes };
