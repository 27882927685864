import { Button, TextField } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import SignInHeader from '../../../components/SignInHeader/SignInHeader';
import userPool from '../../../shared/config/cognitoPool';
import ArrowBackImg from '../../../shared/assets/icons/arrow-back.svg';
import { EMAIL_REGEX } from '../../../shared/config/constants';
import Powered from '../../../components/powered/powered';
import { useStores } from '../../../store';

function ForgotPasswordPage() {
  const intl = useIntl();

  const { portalStore } = useStores();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('Please Enter Email');
  const [isEmailErrorShown, setIsEmailErrorShown] = useState(false);
  const [diableResendSeconds, setDiableResendSeconds] = useState(30);

  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setDiableResendSeconds(seconds => (seconds === 0 ? 0 : seconds - 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (EMAIL_REGEX.test(email)) {
      setEmailError('');
      return;
    }
    if (!email) {
      setEmailError('Please Enter Email');
      return;
    }
    setEmailError('Please enter valid Email');
  }, [email]);

  const submit = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const clientMetaData: {
      [key: string]: string;
    } = {
      host: window.location.hostname,
      accountRole: 'investor',
      primaryColor: portalStore.portalUI.portalUi?.colorStyles
        ? portalStore.portalUI.portalUi?.colorStyles.primary
        : '#00BC68',
      portalName: portalStore.portalUI?.portalName ? portalStore.portalUI?.portalName : 'TokenSuite',
      portalBackground: portalStore.portalUI?.portalUi?.emailBack?.url!
        ? portalStore.portalUI.portalUi?.emailBack?.url!
        : portalStore.portalUI.portalUi?.authBack?.url!,
    };
    if (!portalStore.portalUI?.portalUi?.authBack?.url! && !portalStore.portalUI?.portalUi?.emailBack?.url!) {
      delete clientMetaData.portalBackground;
    }

    cognitoUser.forgotPassword(
      {
        onSuccess() {
          setIsEmailSent(true);
          setDiableResendSeconds(60);
        },

        onFailure(forgotError) {
          if (typeof forgotError.message === 'string') {
            setEmailError(forgotError.message);
          }
        },
      },
      clientMetaData,
    );
  };

  return (
    <main className={c.wrapper}>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Forgot Password | ${portalStore.portalUI.portalUi.title}`
            : 'Forgot Password | TokenSuite'}
        </title>
      </Helmet>
      <SignInHeader />
      <div className={c.rightBlock}>
        <div className={c.data}>
          <Link to="/login" className={c.back}>
            <img src={ArrowBackImg} alt="" />
            <FormattedMessage id="forgotPasswordPage.button.backToLogin" />
          </Link>
          <header>
            <FormattedMessage
              id={
                isEmailSent ? 'forgotPasswordPage.emailSent.text.header' : 'forgotPasswordPage.emailNotSent.text.header'
              }
            />
          </header>
          <FormattedMessage
            id={
              isEmailSent
                ? 'forgotPasswordPage.emailSent.text.dontWorry'
                : 'forgotPasswordPage.emailNotSent.text.dontWorry'
            }
            values={{ email: <b>{email}</b> }}
          />
          {!isEmailSent && (
            <form
              className={c.inputsBlock}
              onSubmit={e => {
                e.preventDefault();
                submit();
              }}
            >
              <p className={c.inputLabel}>
                <FormattedMessage id="login.fields.email.name" />
              </p>
              <TextField
                type="email"
                onChange={e => {
                  setEmail(e.target.value.trim());
                  setEmailError('');
                }}
                value={email}
                autoComplete="email"
                id="auth-email"
                className={c.input}
                margin="normal"
                placeholder={intl.formatMessage({ id: 'login.fields.email.placeHolder' })}
                inputProps={{
                  maxLength: 127,
                }}
                error={!!(emailError && isEmailErrorShown)}
                helperText={isEmailErrorShown && emailError}
                onBlur={() => {
                  setIsEmailErrorShown(true);
                }}
              />
              <Button className={c.submitButton} onClick={submit} disabled={!!emailError}>
                <FormattedMessage id="forgotPasswordPage.emailNotSent.button.submit" />
              </Button>
            </form>
          )}
          {isEmailSent && (
            <div className={c.resend}>
              <FormattedMessage id="forgotPasswordPage.emailSent.text.didntRecieve" />{' '}
              <Button className={c.button} onClick={submit} disabled={!!diableResendSeconds}>
                <FormattedMessage
                  id={
                    diableResendSeconds
                      ? 'forgotPasswordPage.emailNotSent.button.resend.disabled'
                      : 'forgotPasswordPage.emailNotSent.button.resend.enabled'
                  }
                  values={{ sec: diableResendSeconds }}
                />
              </Button>
            </div>
          )}
          <Powered />
        </div>
      </div>
    </main>
  );
}

export default observer(ForgotPasswordPage);
