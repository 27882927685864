import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { CryptoBalances, CustodialAccount, FiatBalances } from '../../interfaces/walletInterface';

class WalletService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async getUserSavedWallet(): Promise<{ wallet: string }> {
    const { data } = await this.authInstance.get<{ wallet: string }>('users/wallet');
    return data;
  }

  async createCastodialAccount(): Promise<void> {
    await this.authInstance.post('payments/custodial/investor');
  }

  async getCryptoBalances(): Promise<CryptoBalances> {
    const { data } = await this.authInstance.get<CryptoBalances>('users/wallet/balance');
    return data;
  }

  async getCustodialAccounts(): Promise<CustodialAccount[]> {
    const { data } = await this.authInstance.get<CustodialAccount[]>('payments/custodial/issuer/balance');
    return data;
  }

  async getFiatBalance(): Promise<FiatBalances> {
    const { data } = await this.authInstance.get<FiatBalances>('payments/custodial/investor/balance');
    return data;
  }

  async getJWT(element: string): Promise<string> {
    const { data } = await this.authInstance.get<{ jwt: string }>(`payments/custodial/jwt?element=${element}`);
    return data.jwt;
  }

  async getJWTforAsset(element: string, id: string): Promise<string> {
    const { data } = await this.authInstance.get<{ jwt: string }>(
      `payments/custodial/jwt?element=${element}&assetId=${id}`,
    );
    return data.jwt;
  }
}

export default new WalletService(tokenInstance);
