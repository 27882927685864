import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import { Box, Stack, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import closeIcon from '../../shared/assets/icons/closeIcon.svg';
import c from './style.module.scss';
import tokenInstance from '../../shared/services/API/tokenApi';
import FormikControl from '../formik/formik-control/formik-control';
import FormikContainer from '../formik/formik-container/formik-container';
import { fields } from './invite-issuer.meta';
import { inviteIssuerSchema } from '../../shared/lib/validation-schemas/invite-issuer-schema';
import { useStores } from '../../store';
import { InviteIssuerDto } from '../../shared/services/admin/adminService.types';

type InitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  subDomain?: string;
  portalName?: string;
  occupation: string;
  description?: string;
  dob: string;
  primCountry: string;
  primCity: string;
  primAddress1: string;
  primZip: string;
  primState?: string;
  isUsCitizen: boolean;
  withPortal?: boolean;
};

function InviteIssuerModal({
  refreshList,
  portalId,
  resetPortalId,
}: {
  refreshList?: () => void;
  portalId?: string;
  resetPortalId?: () => void;
}) {
  const { adminStore } = useStores();
  const [open, setOpen] = useState(!!portalId);
  const [serverError, setServerError] = useState('');

  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    subDomain: '',
    portalName: '',
    description: '',
    dob: moment().format('MM-DD-YYYY'),
    primCountry: '',
    primCity: '',
    occupation: '',
    primAddress1: '',
    primZip: '',
    primState: '',
    isUsCitizen: true,
    withPortal: !portalId,
  };

  const onSubmit = async (values: InitialValues, formikHelpers: FormikHelpers<any>) => {
    setServerError('');
    try {
      const payload = { ...values };
      delete payload.withPortal;
      if (!values.isUsCitizen) {
        delete payload.primState;
      }
      if (values.withPortal) {
        await tokenInstance.post('portal', payload);
      } else {
        delete payload.description;
        delete payload.subDomain;
        delete payload.portalName;
        await flowResult(adminStore.inviteIssuer({ ...payload, portalId } as InviteIssuerDto));
        if (resetPortalId) {
          resetPortalId();
        }
      }
      formikHelpers.resetForm();
      if (refreshList) {
        refreshList();
      }
      setOpen(false);
    } catch (e: any) {
      const errors: string[] = e?.response?.data?.errors;
      if (errors?.length) {
        const fieldErrors = Object.fromEntries(errors.map(item => item.split(':')));
        formikHelpers.setErrors(fieldErrors);
        return;
      }
      if (e?.response?.data?.message) {
        setServerError(e?.response?.data?.message);
      }
    }
  };

  const closeModalHandler = () => {
    setOpen(false);
    if (resetPortalId) {
      resetPortalId();
    }
  };

  return (
    <div className={c.wrapper}>
      {!portalId && (
        <button onClick={() => setOpen(true)} className={c.submitButton}>
          Invite issuer
        </button>
      )}
      <Dialog open={open} onClose={closeModalHandler}>
        <div className={c.dialog}>
          <div className={c.title}>Invite an Issuer</div>
          <Button onClick={closeModalHandler} className={c.closeIcon}>
            <img src={closeIcon} alt="" />
          </Button>
          <FormikContainer<typeof initialValues>
            initialValues={initialValues}
            validationSchema={inviteIssuerSchema}
            onSubmitHandler={onSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Stack gap="20px">
                <div>
                  {fields.map(({ id, ...field }) => {
                    if (field?.checkboxProps?.name === 'withPortal') {
                      return null;
                    }
                    if (field?.autocompleteProps?.name === 'primState' && !values.isUsCitizen) {
                      if (values.primState) setFieldValue(field.autocompleteProps.name, '');
                      return null;
                    }
                    if (
                      !values.withPortal &&
                      (field?.inputProps?.name === 'subDomain' ||
                        field?.inputProps?.name === 'portalName' ||
                        field?.inputProps?.name === 'description')
                    ) {
                      return null;
                    }
                    return (
                      <Box key={id} sx={{ mt: '10px' }}>
                        <FormikControl {...field} />
                      </Box>
                    );
                  })}
                </div>
                <Button type="submit" className={c.submitButton} disabled={isSubmitting}>
                  {isSubmitting ? 'Loading...' : 'Invite'}
                </Button>
                {serverError && (
                  <Stack alignItems="center" mt="5px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        color: '#f05555',
                      }}
                    >
                      {serverError}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </FormikContainer>
        </div>
      </Dialog>
    </div>
  );
}

export default observer(InviteIssuerModal);
