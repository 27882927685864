import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../store';
import c from './styles.module.scss';

function CheckoutStripe() {
  const { checkoutStore } = useStores();
  const { tokens = 0 } = useParams();

  return (
    <div className={c.checkoutWire}>
      <div className={c.title}>Success!</div>
      <div className={c.descr}>
        Your {addComma(String(tokens))}{' '}
        {checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`} token
        allocation should appear in your account any moment now.
      </div>
      <div className={c.divider}> </div>
      <div className={c.title}>What to expect next:</div>
      <div className={c.descr}>
        <ul>
          <li>You will receive a payment confirmation email.</li>
          <li>This allocation will remain in reserved status until the minimum market cap is reached. </li>
          <li>
            Once the minimum market cap is reached, your {addComma(String(tokens))}{' '}
            {checkoutStore.checkoutInfo.tickerSymbol && `${checkoutStore.checkoutInfo.tickerSymbol.toUpperCase()}`}{' '}
            tokens will be distributed to your account.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CheckoutStripe;
