import { makeAutoObservable } from 'mobx';
import jwtDecode from 'jwt-decode';
import RootStore from './index';
import { UserData } from '../shared/interfaces/headerInterface';

class HeaderStore {
  rootStore: RootStore;

  user: UserData = {
    userImage: null,
    name: '',
    role: '',
    email: '',
    id: '',
    loading: false,
    isRoleLoaded: false,
    error: false,
    portals: [],
    wallet: false,
    cryptoWallet: false,
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const sessionIdInfo: any = jwtDecode(accessToken);
      const tokenRole = sessionIdInfo['cognito:groups'].find((elem: string) => elem.includes('role')).slice(5);
      this.setUserRoleAndName({ role: tokenRole, name: sessionIdInfo.username });
    }
    makeAutoObservable(this);
  }

  reset() {
    this.user = {
      userImage: null,
      name: '',
      role: '',
      email: '',
      id: '',
      loading: false,
      isRoleLoaded: false,
      error: false,
      portals: [],
      wallet: false,
      cryptoWallet: false,
    };
  }

  setWallet(value: boolean) {
    this.user.wallet = value;
  }

  setCryptoWallet(value: boolean) {
    this.user.cryptoWallet = value;
  }

  setUserRoleAndName(payload: { role: string; name: string; email?: string }) {
    this.user.role = payload.role;
    this.user.name = payload.name;
    if (payload.email) {
      this.user.email = payload.email;
    }
  }

  setPortals(portals: string[]) {
    this.user.portals = portals;
  }
}

export default HeaderStore;
