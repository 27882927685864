import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import c from './style.module.scss';

const DefaultButton: FC<ButtonProps> = ({ className = '', children, ...rest }) => {
  return (
    <Button className={`${c.defaultBtn} ${className}`} {...rest}>
      {children}
    </Button>
  );
};

export default DefaultButton;
