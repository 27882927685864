import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useStores } from '../../store';
import c from './style.module.scss';
import TableRow from '../UI/table/table-row/table-row';
import InviteBrokerModal from '../invite-broker-modal/ivniteBrokerModal';

const BrokerPortals = observer(() => {
  const { portalStore } = useStores();

  const navigate = useNavigate();

  const [currentPortalId, setCurrentPortalId] = useState('');
  const [isMainBroker, setIsMain] = useState(false);

  useEffect(() => {
    flowResult(portalStore.getAllBrokerPortals());
    return () => {
      portalStore.resetPortals();
    };
  }, [portalStore]);

  const navigateToPortal = (id: string) => () => {
    navigate(`/portals/${id}`);
  };

  const navigateToDealerAssets = (id: string) => () => {
    navigate(`/portals/${id}/assets`);
  };

  return (
    <Stack mt="40px" className={c.wrapper}>
      <div className={c.header}>
        <div className={c.name}>Name</div>
        <div className={c.status}>Status</div>
      </div>
      {portalStore.isLoading && (
        <Stack height="100%" justifyContent="center" alignItems="center">
          {' '}
          <Spinner />{' '}
        </Stack>
      )}
      {!portalStore.isLoading && portalStore.brokerPortals.length === 0 && (
        <div className={c.emptyBlock}>There is no Portals yet</div>
      )}
      {!!portalStore.brokerPortals.length &&
        portalStore.brokerPortals.map(({ portalName, domains, id, isDemo }) => (
          <TableRow
            id={id}
            subTitle={domains.map(({ domain }) => domain)}
            label={domains.map(({ status }) => status).join(', ')}
            isDemo={isDemo}
            key={id}
            mainTitle={portalName}
            menuItems={[
              {
                text: 'View details',
                disabled: false,
                handler: navigateToPortal(id),
              },
              {
                text: 'Assets',
                disabled: false,
                handler: navigateToDealerAssets(id),
              },
              {
                text: 'Invite Broker Rep',
                disabled: isDemo,
                handler: () => {
                  setIsMain(false);
                  setCurrentPortalId(id);
                },
              },
              {
                text: 'Invite Broker Admin',
                disabled: isDemo,
                handler: () => {
                  setIsMain(true);
                  setCurrentPortalId(id);
                },
              },
            ]}
          />
        ))}
      {currentPortalId && (
        <InviteBrokerModal
          portalId={currentPortalId}
          resetPortalId={() => setCurrentPortalId('')}
          isMainBroker={isMainBroker}
        />
      )}
    </Stack>
  );
});

export default BrokerPortals;
