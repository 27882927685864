import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';

class InitialSaleService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async deployInitialSaleCotract(assetId: string, tokenId: string): Promise<void> {
    await this.authInstance.get('initial-sale/deploy', {
      params: {
        assetId,
        tokenId,
      },
    });
  }
}

export default new InitialSaleService(tokenInstance);
