import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';

function AuthedRoute() {
  const location = useLocation();
  const token = localStorage.getItem('accessToken');
  if (token) {
    return <Navigate to="/redirect" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default AuthedRoute;
