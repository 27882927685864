import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import c from './style.module.scss';
import { BenefeciarData } from '../../../shared/interfaces/headerInterface';
import BenefeciarRow from './benefeciarRow/benefeciarRow';
import { useStores } from '../../../store';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { EAccountStatuses } from '../../../shared/types/account.types';

function BenefeciarsPage() {
  const { vereficationStore } = useStores();
  const query = useQueryParams();
  const id = query.get('id');
  const resubmit = query.get('resubmit');
  const navigate = useNavigate();
  const [benefeciars, setBenefeciars] = useState<BenefeciarData[]>([]);
  const [badCheck, setBad] = useState(false);

  useEffect(() => {
    if (id) {
      flowResult(vereficationStore.getBeneficiarsByAdmin(id));
    } else {
      flowResult(vereficationStore.getBeneficiars());
    }
  }, [vereficationStore, id]);

  useEffect(() => {
    setBenefeciars(vereficationStore.benefeciars);
    vereficationStore.benefeciars.forEach(el => {
      if (el.kycStatus !== EAccountStatuses.Verified) {
        setBad(true);
      }
    });
  }, [benefeciars, vereficationStore.benefeciars]);

  const handleClick = () => {
    if (id) {
      navigate(`/kyc?beneficiary=true&id=${id}`);
    } else {
      navigate('/kyc?beneficiary=true');
    }
  };

  const finishClick = () => {
    if (id) {
      navigate(`/kyb?id=${id}`);
    } else {
      navigate('/kyb');
    }
  };

  return (
    <>
      <Helmet>
        <title>Beneficiars</title>
      </Helmet>
      <div className={c.title}>Beneficiars</div>
      <div className={c.wrapper}>
        <div className={c.topPanel}>
          <div className={c.filterPanel}>
            <div className={c.right}>
              <button onClick={handleClick} className={c.button}>
                Add Beneficiary
              </button>
            </div>
          </div>
        </div>
        <div className={c.table}>
          <div className={c.titleRow}>
            <div className={c.columnLeftTitle}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.name" />
            </div>
            <div className={c.columnRightTitle}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.status" />
            </div>
          </div>
          <div className={c.profilesRows}>
            <div className={c.loadingCell}>
              {vereficationStore.isLoading && (
                <div className={c.loading}>
                  <Spinner single />
                </div>
              )}
            </div>
            {benefeciars.length > 0 ? (
              benefeciars.map((profile: BenefeciarData) => (
                <BenefeciarRow profileData={profile} key={profile.email} resubmit={resubmit !== null} />
              ))
            ) : (
              <div className={c.emptyBlock}>
                <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noBenefeciarsMessage" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={c.confirm}>
        {badCheck ? (
          <div className={c.text}>
            All beneficiaries must successfully pass KYC verification, in case of errors, contact the administrator
          </div>
        ) : (
          <div className={c.text}>
            By clicking on this button you confirm that you have entered all the beneficiaries of your company
          </div>
        )}
        <button className={c.submitButton} onClick={finishClick} disabled={badCheck}>
          Complete KYB
        </button>
      </div>
    </>
  );
}

export default observer(BenefeciarsPage);
