import React from 'react';
import IconButton from '@mui/material/IconButton';
import { TextIndexInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { FormattedMessage, useIntl } from 'react-intl';
import closeIcon from '../../../shared/assets/createAsset/closeIcon.svg';
import c from './style.module.scss';
import UploaderForDocuments from '../uploaderForDocuments/uploaderForDocuments';

function Documents({
  documents,
  setDocuments,
}: {
  documents: Array<{ description: string; document: any }>;
  setDocuments: any;
}) {
  const intl = useIntl();
  const addDocument = () => {
    setDocuments([...documents, { description: '', document: {} }]);
  };

  const removeFile = (index: number) => {
    const newDocuments = documents.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setDocuments(newDocuments);
  };

  const setIndexDocument = (file: any, index: number) => {
    const tempArr = documents;
    tempArr[index].document = file;
    setDocuments([...tempArr]);
  };

  const documentDescriptionHandler = (e: string, index: number) => {
    const tempArr = documents;
    tempArr[index].description = e;
    setDocuments([...tempArr]);
  };

  return (
    <div className={c.uploader}>
      <div className={c.listFiles}>
        <div className={c.wrapperBlock}>
          {documents.map((item, index) => {
            return (
              <div className={c.documentBlock} key={index}>
                <div className={c.left}>
                  <UploaderForDocuments setDocument={setIndexDocument} document={item.document} index={index} />
                  <TextIndexInput
                    value={item.description}
                    changeValue={documentDescriptionHandler}
                    label={intl.formatMessage(
                      { id: 'createAssetPage.marketAnalysis.field.description.label' },
                      { count: `${index + 1}` },
                    )}
                    placeholder={intl.formatMessage({
                      id: 'createAssetPage.marketAnalysis.field.description.placeholder',
                    })}
                    type="text"
                    fullWidth
                    multiline
                    index={index}
                  />
                  <div className={c.right}>
                    <IconButton className={c.delete} onClick={() => removeFile(index)}>
                      <img src={closeIcon} alt="X" />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
          <button className={c.addButton} onClick={addDocument}>
            {documents.length > 0 ? (
              <FormattedMessage id="createAssetPage.marketAnalysis.button.addAnother" />
            ) : (
              <FormattedMessage id="createAssetPage.marketAnalysis.button.addFirst" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
export default Documents;
