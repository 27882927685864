import React, { useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import StoreProvider from '../store/provider/StoreProvider';
import PortalMetaInfo from '../components/portal-meta-info/portal-meta-info';
import { theme } from '../mui-theme';
import Routing from './router/Routing';

function App() {
  // For localhost
  useEffect(() => {
    if (
      document.body?.firstChild?.nodeValue ===
      `__FAVICON_TAGS__
    __META_TAGS__
    `
    ) {
      document.body.firstChild?.remove();
    }
  }, []);
  return (
    <StoreProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Routing />
            <PortalMetaInfo />
          </LocalizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
