import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuItem, Select } from '@mui/material';
import exportCsv from '@token-suite/common-ui/src/sharedFunctions/exportCsv';
import c from './style.module.scss';
import exportIcon from '../../../../shared/assets/icons/export.svg';
import issuerService from '../../../../shared/services/issuer/issuerService';

const FilterPanel = ({ value, sortOrder }: { value?: string; sortOrder?: string }) => {
  const onExportCSVHandler = async () => {
    try {
      const data = await issuerService.getCSV({ value, sortOrder });
      exportCsv('TokenHolderInvestors', data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={c.filterPanel}>
      <div className={c.left}>
        <div className={c.text}>
          <FormattedMessage id="investorsPage.allowList.table.assetFilter.name" />
        </div>
        <Select className={c.select} value="All" disabled>
          <MenuItem value="All" disabled>
            All
          </MenuItem>
        </Select>
      </div>
      <div className={c.right}>
        <button className="defaultButton" onClick={onExportCSVHandler}>
          <img src={exportIcon} alt="exportIcon" />
          Export CSV
        </button>
      </div>
    </div>
  );
};

export default FilterPanel;
