import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RequestParams } from '../types/investorTableType';

type UsePaginationParams = {
  callback: (params: RequestParams) => void;
  pageName?: string;
};

type UsePaginationType = (params: UsePaginationParams) => {
  loadItems: () => Promise<void>;
  controlledParams: RequestParams;
  onOrderChange: (value: 'name' | 'status') => void;
  onCurrentPageChange: (value: string | number) => void;
  onPageSizeChange: (value: string | number) => void;
  onFilterChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  onAssetChange: (value: string) => void;
  onAssetNameChange: (value: string) => void;
  onAssetIdChange: (value: string) => void;
  onDatesChange: (value: string | number) => void;
};

const usePagination: UsePaginationType = ({ callback, pageName }) => {
  const [params, setParams] = useSearchParams();
  const [controlledParams, setControlledParams] = useState<RequestParams>({
    pageSize: params.get('pageSize') || 10,
    currentPage: params.get('currentPage') || 0,
    status: params.get('status') || undefined,
    sortOrder: (params.get('sortOrder') as 'ASC' | 'DESC' | 'none') || undefined,
    value: (params.get('value') as 'name' | 'status') || undefined,
    filter: params.get('filter') || undefined,
    asset: params.get('asset') || undefined,
    assetName: params.get('assetName') || undefined,
    assetId: params.get('assetId') || undefined,
    dates: params.get('dates') || 0,
  });

  const loadItems = useCallback(async () => {
    const {
      value,
      sortOrder,
      status,
      currentPage,
      pageSize,
      filter,
      asset: assetValue,
      assetName,
      assetId,
      dates,
    } = controlledParams;
    const requestParams: RequestParams = {
      pageSize,
      currentPage,
      status: status === 'all' ? undefined : status,
      sortOrder: sortOrder === 'none' ? undefined : sortOrder,
      value: (value as 'name' | 'status' | 'none') === 'none' ? undefined : value,
      filter: filter || undefined,
      asset: assetValue === 'all' ? undefined : assetValue,
      assetName: assetName || undefined,
      assetId: assetId || undefined,
      dates: pageName === 'transactions-history' ? dates : undefined,
    };
    callback(requestParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledParams]);

  const getObjectFromURLSearchParams = (searchParams: URLSearchParams) => {
    const result: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  };

  const onUrlParamChange = useCallback(
    (key: string, value: string | number, isRestCurrentPage = false) => {
      const newUrlSearchParams = new URLSearchParams(params);
      newUrlSearchParams.set(key, value.toString());
      if (isRestCurrentPage) {
        newUrlSearchParams.set('currentPage', (0).toString());
      }
      setParams(newUrlSearchParams);
      setControlledParams({ ...controlledParams, ...getObjectFromURLSearchParams(newUrlSearchParams) });
    },
    [controlledParams, params, setParams],
  );

  const onCurrentPageChange = useCallback(
    (value: number | string) => {
      onUrlParamChange('currentPage', value);
    },
    [onUrlParamChange],
  );

  const onStatusChange = (value: string) => {
    onUrlParamChange('status', value);
  };

  const onPageSizeChange = useCallback(
    (value: number | string) => {
      onUrlParamChange('pageSize', value, true);
    },
    [onUrlParamChange],
  );

  const onFilterChange = (value: string) => {
    onUrlParamChange('filter', value, true);
  };

  const onAssetChange = (value: string) => {
    onUrlParamChange('asset', value);
  };

  const onAssetNameChange = (value: string) => {
    onUrlParamChange('assetName', value, true);
  };

  const onAssetIdChange = useCallback(
    (value: string) => {
      onUrlParamChange('assetId', value, true);
    },
    [onUrlParamChange],
  );

  const onDatesChange = useCallback(
    (value: string | number) => {
      onUrlParamChange('dates', value, true);
    },
    [onUrlParamChange],
  );

  const onOrderChange = useCallback(
    (value: 'name' | 'status') => {
      const newUrlSearchParams = new URLSearchParams(params);
      newUrlSearchParams.set('value', value);
      if (value !== controlledParams.value) {
        newUrlSearchParams.set('sortOrder', 'ASC');
      } else {
        const defaultSortOrder = ['ASC', 'DESC', 'none'];
        const getOrderingIndex = defaultSortOrder.findIndex(item => item === controlledParams.sortOrder);
        const newSortOrder =
          getOrderingIndex === defaultSortOrder.length - 1
            ? defaultSortOrder[0]
            : defaultSortOrder[getOrderingIndex + 1];

        newUrlSearchParams.set('sortOrder', newSortOrder.toString());
      }
      newUrlSearchParams.set('currentPage', (0).toString());
      setParams(newUrlSearchParams);
      setControlledParams({ ...controlledParams, ...getObjectFromURLSearchParams(newUrlSearchParams) });
    },
    [controlledParams, params, setParams],
  );

  return {
    onCurrentPageChange,
    onPageSizeChange,
    loadItems,
    controlledParams,
    onOrderChange,
    onFilterChange,
    onStatusChange,
    onAssetChange,
    onAssetNameChange,
    onAssetIdChange,
    onDatesChange,
  };
};

export { usePagination };
