// import { DiscFull } from "@material-ui/icons";
import { observer } from 'mobx-react-lite';
import React from 'react';
// import Perks from '../../../assets/asset-view/perks.png';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AdvanceInvestorPerks() {
  const {
    assetStore: { currentAsset },
  } = useStores();
  return (
    <div className={c.investorPerks}>
      <div className={c.icon}>
        <svg width="125" height="125" viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
          <circle cx="62.5" cy="62.5" r="62.5" />
          <g clipPath="url(#clip0_4278_2654)">
            <path
              d="M70.5235 39.6534C70.6464 39.6869 70.7733 39.7034 70.9008 39.7026C71.2204 39.7023 71.5309 39.5967 71.7845 39.4021C72.038 39.2075 72.2203 38.9348 72.3033 38.6261L76.5355 22.8373C76.5916 22.6509 76.6098 22.4551 76.5888 22.2616C76.5679 22.0681 76.5082 21.8807 76.4135 21.7107C76.3188 21.5406 76.1909 21.3913 76.0374 21.2716C75.8839 21.152 75.7079 21.0643 75.5199 21.0139C75.3319 20.9635 75.1357 20.9514 74.9429 20.9782C74.7501 21.0051 74.5646 21.0704 74.3976 21.1703C74.2305 21.2701 74.0852 21.4025 73.9702 21.5596C73.8553 21.7167 73.773 21.8953 73.7284 22.0847L69.4962 37.8736C69.4466 38.0579 69.4338 38.2502 69.4586 38.4395C69.4834 38.6287 69.5453 38.8112 69.6407 38.9765C69.7361 39.1419 69.8632 39.2867 70.0147 39.4029C70.1661 39.519 70.3391 39.6042 70.5235 39.6534Z"
              fill="white"
            />
            <path
              d="M81.8218 46.1781C82.0945 46.4486 82.463 46.6003 82.8471 46.6003C83.2311 46.6003 83.5997 46.4486 83.8723 46.1781L95.4372 34.6133C95.6771 34.3354 95.8031 33.9769 95.7897 33.61C95.7762 33.243 95.6245 32.8947 95.3648 32.6351C95.1052 32.3755 94.7569 32.2237 94.39 32.2103C94.023 32.1968 93.6646 32.3228 93.3867 32.5628L81.8218 44.1255C81.5512 44.3986 81.3994 44.7674 81.3994 45.1518C81.3994 45.5362 81.5512 45.9051 81.8218 46.1781Z"
              fill="white"
            />
            <path
              d="M39.6923 79.712C40.0152 79.514 40.2481 79.1978 40.3413 78.8307C40.4346 78.4636 40.381 78.0746 40.1918 77.7465C40.0026 77.4184 39.6929 77.177 39.3284 77.0738C38.964 76.9706 38.5737 77.0137 38.2406 77.1939L30.8875 81.4405C30.5682 81.6404 30.339 81.9564 30.2481 82.3219C30.1571 82.6875 30.2117 83.074 30.4001 83.4002C30.5886 83.7263 30.8962 83.9666 31.2583 84.0703C31.6204 84.1741 32.0087 84.1333 32.3413 83.9565L39.6923 79.712Z"
              fill="white"
            />
            <path
              d="M77.7245 40.2255C78.0582 40.418 78.4546 40.4702 78.8268 40.3707C79.1989 40.2711 79.5164 40.0279 79.7093 39.6944L83.9539 32.3413C84.1307 32.0087 84.1715 31.6205 84.0677 31.2584C83.9639 30.8963 83.7237 30.5886 83.3975 30.4002C83.0714 30.2117 82.6848 30.1572 82.3193 30.2481C81.9538 30.339 81.6378 30.5682 81.4379 30.8875L77.1934 38.2406C77.0977 38.4058 77.0357 38.5882 77.0106 38.7774C76.9856 38.9665 76.9981 39.1588 77.0474 39.3432C77.0967 39.5275 77.1819 39.7003 77.2981 39.8517C77.4142 40.0031 77.5591 40.1301 77.7245 40.2255Z"
              fill="white"
            />
            <path
              d="M105.921 73.7286L90.1319 69.4964C89.7641 69.4097 89.377 69.4697 89.0526 69.6636C88.7283 69.8574 88.4922 70.17 88.3943 70.535C88.2965 70.9 88.3446 71.2887 88.5285 71.6189C88.7123 71.949 89.0175 72.1946 89.3794 72.3035L105.168 76.5357C105.291 76.5695 105.418 76.5867 105.546 76.587C105.898 76.587 106.239 76.4588 106.504 76.2261C106.77 75.9934 106.941 75.6722 106.987 75.3224C107.033 74.9726 106.95 74.618 106.754 74.3247C106.558 74.0315 106.262 73.8196 105.921 73.7286Z"
              fill="white"
            />
            <path
              d="M97.1099 81.4405L89.7589 77.1939C89.4251 77.0036 89.0296 76.9532 88.6587 77.0536C88.2879 77.1541 87.9718 77.3972 87.7797 77.73C87.5876 78.0627 87.5351 78.458 87.6335 78.8293C87.7319 79.2007 87.9734 79.5181 88.3051 79.712L95.6581 83.9565C95.8769 84.088 96.1268 84.1588 96.382 84.1615C96.7023 84.1627 97.014 84.0578 97.2685 83.8633C97.523 83.6689 97.7061 83.3957 97.7892 83.0863C97.8722 82.7769 97.8506 82.4488 97.7278 82.153C97.6049 81.8571 97.3877 81.6103 97.1099 81.4508V81.4405Z"
              fill="white"
            />
            <path
              d="M88.3055 48.2901C87.9862 48.49 87.7569 48.806 87.666 49.1715C87.5751 49.537 87.6296 49.9236 87.8181 50.2497C88.0065 50.5759 88.3142 50.8161 88.6763 50.9199C89.0384 51.0237 89.4267 50.9829 89.7593 50.8061L97.1103 46.5615C97.4332 46.3635 97.6661 46.0473 97.7593 45.6803C97.8526 45.3132 97.799 44.9242 97.6098 44.5961C97.4206 44.2679 97.1108 44.0266 96.7464 43.9234C96.382 43.8202 95.9917 43.8633 95.6586 44.0435L88.3055 48.2901Z"
              fill="white"
            />
            <path
              d="M92.9049 62.5472C92.7095 62.5399 92.5147 62.572 92.332 62.6417C92.1493 62.7114 91.9826 62.8172 91.8418 62.9529C91.701 63.0885 91.589 63.2511 91.5125 63.4311C91.4359 63.611 91.3965 63.8045 91.3965 64C91.3965 64.1955 91.4359 64.389 91.5125 64.5689C91.589 64.7488 91.701 64.9115 91.8418 65.0471C91.9826 65.1827 92.1493 65.2886 92.332 65.3583C92.5147 65.428 92.7095 65.4601 92.9049 65.4528H101.394C101.589 65.4601 101.784 65.428 101.967 65.3583C102.15 65.2886 102.316 65.1827 102.457 65.0471C102.598 64.9115 102.71 64.7488 102.786 64.5689C102.863 64.389 102.902 64.1955 102.902 64C102.902 63.8045 102.863 63.611 102.786 63.4311C102.71 63.2511 102.598 63.0885 102.457 62.9529C102.316 62.8172 102.15 62.7114 101.967 62.6417C101.784 62.572 101.589 62.5399 101.394 62.5472H92.9049Z"
              fill="white"
            />
            <path
              d="M46.1765 81.824C45.9043 81.5525 45.5356 81.4001 45.1512 81.4001C44.7668 81.4001 44.3981 81.5525 44.126 81.824L32.5632 93.3867C32.4119 93.5173 32.2892 93.6778 32.2027 93.8579C32.1163 94.0381 32.0678 94.2342 32.0605 94.4339C32.0532 94.6337 32.0872 94.8327 32.1603 95.0188C32.2334 95.2048 32.3441 95.3737 32.4854 95.515C32.6267 95.6564 32.7956 95.767 32.9817 95.8401C33.1677 95.9132 33.3668 95.9472 33.5665 95.9399C33.7662 95.9326 33.9623 95.8842 34.1425 95.7977C34.3227 95.7112 34.4831 95.5885 34.6137 95.4372L46.1765 83.8745C46.4479 83.6023 46.6004 83.2336 46.6004 82.8492C46.6004 82.4648 46.4479 82.0961 46.1765 81.824Z"
              fill="white"
            />
            <path
              d="M88.3463 57.4763C88.4288 57.7854 88.611 58.0586 88.8646 58.2535C89.1182 58.4485 89.429 58.5545 89.7488 58.5549C89.8763 58.5549 90.0032 58.5376 90.1261 58.5036L105.915 54.2714C106.104 54.2268 106.283 54.1445 106.44 54.0296C106.597 53.9146 106.73 53.7693 106.829 53.6022C106.929 53.4352 106.995 53.2497 107.021 53.0569C107.048 52.8641 107.036 52.6679 106.986 52.4799C106.935 52.2919 106.848 52.1159 106.728 51.9624C106.608 51.8089 106.459 51.681 106.289 51.5863C106.119 51.4916 105.932 51.432 105.738 51.411C105.545 51.39 105.349 51.4082 105.162 51.4643L89.3736 55.6965C89.0014 55.7965 88.6842 56.0401 88.4916 56.3738C88.2989 56.7075 88.2467 57.1041 88.3463 57.4763Z"
              fill="white"
            />
            <path
              d="M57.4761 88.3466C57.1042 88.2469 56.7078 88.2989 56.3742 88.4911C56.0405 88.6833 55.7967 89.0001 55.6963 89.3719L51.4641 105.161C51.4144 105.345 51.4016 105.537 51.4263 105.726C51.4509 105.915 51.5126 106.098 51.6078 106.263C51.703 106.428 51.8299 106.573 51.9812 106.69C52.1324 106.806 52.3051 106.891 52.4893 106.941C52.6124 106.973 52.7393 106.99 52.8666 106.99C53.1861 106.989 53.4966 106.883 53.75 106.689C54.0035 106.494 54.1859 106.222 54.2691 105.913L58.5014 90.1244C58.6004 89.7527 58.5481 89.3569 58.356 89.0237C58.1638 88.6905 57.8474 88.447 57.4761 88.3466Z"
              fill="white"
            />
            <path
              d="M63.9987 36.5489C64.3843 36.5489 64.7541 36.3958 65.0267 36.1231C65.2994 35.8505 65.4525 35.4807 65.4525 35.0951V26.604C65.4525 26.2184 65.2994 25.8487 65.0267 25.576C64.7541 25.3034 64.3843 25.1502 63.9987 25.1502C63.6131 25.1502 63.2434 25.3034 62.9707 25.576C62.6981 25.8487 62.5449 26.2184 62.5449 26.604V35.0951C62.5455 35.4805 62.6988 35.85 62.9713 36.1225C63.2439 36.3951 63.6133 36.5484 63.9987 36.5489Z"
              fill="white"
            />
            <path
              d="M50.2726 87.7745C49.9386 87.5821 49.542 87.5302 49.1698 87.6302C48.7976 87.7302 48.4803 87.9738 48.2877 88.3076L44.0432 95.6587C43.8663 95.9913 43.8255 96.3795 43.9293 96.7416C44.0331 97.1037 44.2733 97.4114 44.5995 97.5998C44.9256 97.7883 45.3122 97.8428 45.6777 97.7519C46.0433 97.661 46.3593 97.4317 46.5591 97.1125L50.8057 89.7594C50.9981 89.4255 51.05 89.0288 50.95 88.6566C50.85 88.2844 50.6064 87.9671 50.2726 87.7745Z"
              fill="white"
            />
            <path
              d="M74.9652 58.963L68.1063 58.2719L65.3299 51.9626C65.2169 51.7031 65.0306 51.4822 64.7938 51.3271C64.5571 51.172 64.2802 51.0894 63.9971 51.0894C63.7141 51.0894 63.4372 51.172 63.2004 51.3271C62.9637 51.4822 62.7773 51.7031 62.6643 51.9626L59.8859 58.2719L53.029 58.963C52.7486 58.9917 52.4827 59.1014 52.2635 59.2785C52.0443 59.4557 51.8814 59.6928 51.7945 59.9609C51.7076 60.229 51.7005 60.5166 51.774 60.7886C51.8476 61.0607 51.9986 61.3055 52.2088 61.4933L57.3515 66.0843L55.8874 72.8202C55.827 73.096 55.8484 73.3834 55.9488 73.6472C56.0492 73.911 56.2244 74.1398 56.4529 74.3056C56.6814 74.4714 56.9532 74.567 57.2352 74.5807C57.5171 74.5944 57.797 74.5256 58.0404 74.3827L63.9869 70.9112L69.9333 74.3827C70.1768 74.5256 70.4566 74.5944 70.7386 74.5807C71.0205 74.567 71.2924 74.4714 71.5209 74.3056C71.7493 74.1398 71.9245 73.911 72.0249 73.6472C72.1254 73.3834 72.1467 73.096 72.0864 72.8202L70.6243 66.0843L75.7875 61.4851C75.9977 61.2973 76.1487 61.0525 76.2223 60.7804C76.2958 60.5083 76.2887 60.2208 76.2018 59.9527C76.1149 59.6846 75.952 59.4475 75.7328 59.2703C75.5136 59.0932 75.2476 58.9835 74.9673 58.9548L74.9652 58.963ZM68.0776 64.4788C67.8857 64.65 67.7426 64.8691 67.663 65.1137C67.5834 65.3583 67.5701 65.6197 67.6245 65.8711L68.5656 70.2099L64.7312 67.9728C64.5088 67.8439 64.2563 67.7761 63.9992 67.7761C63.7421 67.7761 63.4896 67.8439 63.2672 67.9728L59.4327 70.2099L60.3739 65.8711C60.4288 65.6199 60.4159 65.3586 60.3366 65.1139C60.2574 64.8693 60.1145 64.6501 59.9228 64.4788L56.6112 61.524L61.028 61.0791C61.2837 61.0529 61.528 60.9595 61.7358 60.8082C61.9437 60.6569 62.1077 60.4532 62.2111 60.2179L63.9992 56.1558L65.7872 60.2179C65.891 60.4534 66.0554 60.6571 66.2636 60.8084C66.4718 60.9597 66.7164 61.0531 66.9724 61.0791L71.3871 61.524L68.0776 64.4788Z"
              fill="white"
            />
            <path
              d="M83.8766 81.824C83.5987 81.584 83.2403 81.458 82.8733 81.4715C82.5064 81.4849 82.1581 81.6367 81.8984 81.8963C81.6388 82.1559 81.487 82.5042 81.4736 82.8712C81.4602 83.2381 81.5861 83.5966 81.8261 83.8745L93.3786 95.4413C93.5093 95.5926 93.6697 95.7153 93.8499 95.8018C94.0301 95.8883 94.2261 95.9367 94.4259 95.944C94.6256 95.9513 94.8247 95.9173 95.0107 95.8442C95.1967 95.7711 95.3656 95.6605 95.507 95.5191C95.6483 95.3778 95.759 95.2089 95.8321 95.0229C95.9052 94.8368 95.9391 94.6378 95.9318 94.438C95.9245 94.2383 95.8761 94.0422 95.7896 93.862C95.7031 93.6819 95.5804 93.5215 95.4291 93.3908L83.8766 81.824Z"
              fill="white"
            />
            <path
              d="M63.9987 91.4531C63.6131 91.4531 63.2434 91.6063 62.9707 91.8789C62.6981 92.1516 62.5449 92.5213 62.5449 92.9069V101.396C62.5449 101.782 62.6981 102.151 62.9707 102.424C63.2434 102.697 63.6131 102.85 63.9987 102.85C64.3843 102.85 64.7541 102.697 65.0267 102.424C65.2994 102.151 65.4525 101.782 65.4525 101.396V92.9069C65.4528 92.7159 65.4154 92.5268 65.3424 92.3502C65.2694 92.1737 65.1624 92.0134 65.0273 91.8783C64.8923 91.7433 64.7319 91.6362 64.5554 91.5632C64.3789 91.4903 64.1897 91.4528 63.9987 91.4531Z"
              fill="white"
            />
            <path
              d="M79.7104 88.3077C79.5178 87.974 79.2007 87.7305 78.8286 87.6307C78.4566 87.5309 78.0602 87.583 77.7265 87.7756C77.3929 87.9681 77.1494 88.2852 77.0496 88.6573C76.9498 89.0293 77.0019 89.4258 77.1944 89.7594L81.4389 97.1125C81.5668 97.3336 81.7507 97.5171 81.9721 97.6445C82.1935 97.772 82.4446 97.8388 82.7 97.8384C82.9552 97.8356 83.2051 97.7648 83.4238 97.6333C83.5891 97.538 83.734 97.411 83.8502 97.2596C83.9664 97.1082 84.0516 96.9353 84.1009 96.751C84.1502 96.5666 84.1627 96.3744 84.1377 96.1852C84.1126 95.996 84.0505 95.8136 83.9549 95.6484L79.7104 88.3077Z"
              fill="white"
            />
            <path
              d="M72.3029 89.3719C72.194 89.01 71.9484 88.7048 71.6183 88.5209C71.2881 88.337 70.8994 88.2889 70.5344 88.3868C70.1694 88.4846 69.8568 88.7208 69.663 89.0451C69.4691 89.3695 69.4091 89.7566 69.4958 90.1244L73.728 105.913C73.8078 106.225 73.9885 106.501 74.2419 106.698C74.4953 106.896 74.8071 107.004 75.1285 107.006C75.2558 107.006 75.3826 106.989 75.5058 106.957C75.8776 106.857 76.1943 106.613 76.3865 106.279C76.5787 105.945 76.6307 105.549 76.531 105.177L72.3029 89.3719Z"
              fill="white"
            />
            <path
              d="M63.9982 42.9814C59.8409 42.9814 55.777 44.2141 52.3203 46.5238C48.8636 48.8335 46.1695 52.1163 44.5785 55.9571C42.9876 59.798 42.5714 64.0243 43.3824 68.1018C44.1935 72.1792 46.1954 75.9245 49.1351 78.8642C52.0747 81.8038 55.8201 83.8058 59.8975 84.6168C63.9749 85.4279 68.2012 85.0116 72.0421 83.4207C75.8829 81.8298 79.1657 79.1356 81.4754 75.6789C83.7851 72.2223 85.0179 68.1583 85.0179 64.001C85.0113 58.4283 82.7947 53.0856 78.8541 49.1451C74.9136 45.2045 69.571 42.9879 63.9982 42.9814ZM63.9982 82.1151C60.4156 82.1151 56.9134 81.0528 53.9345 79.0624C50.9557 77.072 48.634 74.2429 47.2629 70.933C45.8919 67.6231 45.5332 63.9809 46.2321 60.4671C46.9311 56.9533 48.6563 53.7257 51.1896 51.1924C53.7229 48.6591 56.9505 46.9339 60.4643 46.235C63.9781 45.536 67.6202 45.8948 70.9302 47.2658C74.2401 48.6368 77.0691 50.9585 79.0595 53.9374C81.0499 56.9162 82.1123 60.4184 82.1123 64.001C82.1069 68.8035 80.1967 73.4078 76.8008 76.8036C73.4049 80.1995 68.8007 82.1097 63.9982 82.1151Z"
              fill="white"
            />
            <path
              d="M26.6041 62.5472C26.4087 62.5399 26.2139 62.572 26.0312 62.6417C25.8486 62.7114 25.6818 62.8172 25.541 62.9529C25.4002 63.0885 25.2882 63.2511 25.2116 63.4311C25.1351 63.611 25.0957 63.8045 25.0957 64C25.0957 64.1955 25.1351 64.389 25.2116 64.5689C25.2882 64.7488 25.4002 64.9115 25.541 65.0471C25.6818 65.1827 25.8486 65.2886 26.0312 65.3583C26.2139 65.428 26.4087 65.4601 26.6041 65.4528H35.0932C35.2885 65.4601 35.4834 65.428 35.6661 65.3583C35.8487 65.2886 36.0154 65.1827 36.1563 65.0471C36.2971 64.9115 36.4091 64.7488 36.4856 64.5689C36.5621 64.389 36.6016 64.1955 36.6016 64C36.6016 63.8045 36.5621 63.611 36.4856 63.4311C36.4091 63.2511 36.2971 63.0885 36.1563 62.9529C36.0154 62.8172 35.8487 62.7114 35.6661 62.6417C35.4834 62.572 35.2885 62.5399 35.0932 62.5472H26.6041Z"
              fill="white"
            />
            <path
              d="M39.6552 70.5237C39.5544 70.1522 39.3105 69.8358 38.9769 69.6437C38.6434 69.4515 38.2473 69.3993 37.8754 69.4984L22.0866 73.7306C21.7457 73.8217 21.4495 74.0336 21.2533 74.3268C21.0571 74.6201 20.9743 74.9746 21.0202 75.3245C21.0661 75.6743 21.2377 75.9955 21.5029 76.2282C21.7682 76.4608 22.109 76.5891 22.4618 76.589C22.5893 76.5892 22.7162 76.5719 22.8391 76.5378L38.6279 72.3055C38.8124 72.2561 38.9854 72.1707 39.1369 72.0544C39.2884 71.938 39.4154 71.793 39.5108 71.6275C39.6063 71.462 39.6681 71.2793 39.6929 71.0899C39.7177 70.9005 39.7049 70.7081 39.6552 70.5237Z"
              fill="white"
            />
            <path
              d="M22.0768 54.2714L37.8656 58.5036C37.9885 58.5378 38.1154 58.555 38.2429 58.5549C38.5955 58.5544 38.936 58.4258 39.2009 58.193C39.4657 57.9602 39.6369 57.639 39.6826 57.2893C39.7282 56.9396 39.6452 56.5853 39.449 56.2922C39.2528 55.9992 38.9568 55.7875 38.6161 55.6965L22.8272 51.4643C22.4594 51.3776 22.0723 51.4376 21.748 51.6315C21.4236 51.8253 21.1875 52.1379 21.0896 52.5029C20.9918 52.8679 21.0399 53.2566 21.2238 53.5868C21.4077 53.9169 21.7129 54.1625 22.0747 54.2714H22.0768Z"
              fill="white"
            />
            <path
              d="M55.6969 38.6261C55.7795 38.9349 55.9618 39.2078 56.2154 39.4025C56.469 39.5971 56.7797 39.7026 57.0994 39.7026C57.2268 39.7032 57.3537 39.6866 57.4767 39.6534C57.8485 39.553 58.1652 39.3092 58.3574 38.9755C58.5496 38.6418 58.6016 38.2455 58.502 37.8736L54.2697 22.0847C54.1608 21.7229 53.9152 21.4177 53.5851 21.2338C53.255 21.0499 52.8662 21.0018 52.5012 21.0996C52.1362 21.1975 51.8236 21.4336 51.6298 21.758C51.4359 22.0823 51.3759 22.4694 51.4626 22.8373L55.6969 38.6261Z"
              fill="white"
            />
            <path
              d="M48.2874 39.6944C48.4153 39.9155 48.5992 40.099 48.8206 40.2264C49.0419 40.3539 49.293 40.4207 49.5484 40.4203C49.8036 40.4175 50.0535 40.3467 50.2723 40.2152C50.6061 40.0226 50.8497 39.7053 50.9497 39.3331C51.0497 38.9609 50.9978 38.5643 50.8054 38.2303L46.5588 30.8772C46.4672 30.7048 46.3417 30.5527 46.19 30.4298C46.0382 30.3069 45.8633 30.2159 45.6756 30.1621C45.4879 30.1083 45.2914 30.0929 45.0976 30.1167C44.9038 30.1406 44.7168 30.2032 44.5477 30.3009C44.3787 30.3986 44.2311 30.5293 44.1136 30.6853C43.9962 30.8413 43.9114 31.0193 43.8643 31.2088C43.8172 31.3982 43.8087 31.5953 43.8393 31.7881C43.87 31.9809 43.9392 32.1656 44.0428 32.3311L48.2874 39.6944Z"
              fill="white"
            />
            <path
              d="M44.1213 46.1781C44.3992 46.4181 44.7577 46.544 45.1246 46.5306C45.4916 46.5172 45.8398 46.3654 46.0995 46.1058C46.3591 45.8461 46.5109 45.4978 46.5243 45.1309C46.5377 44.7639 46.4118 44.4055 46.1718 44.1276L34.6091 32.5648C34.3311 32.3248 33.9727 32.1989 33.6058 32.2123C33.2388 32.2257 32.8905 32.3775 32.6309 32.6371C32.3712 32.8968 32.2195 33.2451 32.206 33.612C32.1926 33.979 32.3186 34.3374 32.5586 34.6153L44.1213 46.1781Z"
              fill="white"
            />
            <path
              d="M30.888 46.5615L38.2411 50.8061C38.4599 50.9375 38.7097 51.0083 38.9649 51.0111C39.2852 51.0122 39.5969 50.9074 39.8515 50.7129C40.106 50.5184 40.289 50.2452 40.3721 49.9359C40.4552 49.6265 40.4336 49.2984 40.3107 49.0025C40.1879 48.7067 39.9707 48.4598 39.6929 48.3003L32.3418 44.0538C32.1764 43.9571 31.9936 43.894 31.8037 43.8682C31.6139 43.8425 31.4208 43.8545 31.2356 43.9035C31.0504 43.9526 30.8768 44.0378 30.7246 44.1542C30.5725 44.2707 30.4449 44.416 30.3491 44.5819C30.2533 44.7478 30.1912 44.931 30.1665 45.121C30.1417 45.311 30.1548 45.504 30.2049 45.6889C30.2549 45.8738 30.3411 46.047 30.4583 46.1985C30.5756 46.35 30.7216 46.4769 30.888 46.5718V46.5615Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_4278_2654">
              <rect width="86" height="86" fill="white" transform="translate(21 21)" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className={c.top}>
        <div className={c.title}>Investor Perks</div>
        <div className={c.descr}>{currentAsset.investorPerks?.highlights}</div>
      </div>
      {currentAsset.investorPerks?.fields.length > 0 &&
        currentAsset.investorPerks.fields.map((field, i) => (
          <div className={c.fieldItem} key={i}>
            <div className={c.bonus}>
              {field.title} {field.description}
            </div>
          </div>
        ))}
    </div>
  );
}

export default observer(AdvanceInvestorPerks);
