import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import '../../../app/styles/reusable/containers.scss';
import { useParams } from 'react-router-dom';
import ActivityLogTable from '../common/activity-log-table';
import c from '../style.module.scss';
import { IActivityLog } from '../../../shared/services/activity-log/interfaces';
import activityLogService from '../../../shared/services/activity-log/activityLogService';

const InvestorActivityLog = () => {
  const [logs, setLogs] = useState<IActivityLog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const allActivities = await activityLogService.getOneByIssuer(id!);
        setLogs(allActivities);
      } catch {
        // handle error
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return (
    <Stack className="nestedContainer">
      <Stack pt={24} px={16} pb={16}>
        <h3 className={c.title}>
          <FormattedMessage id="activityLog.title" />
        </h3>
      </Stack>
      <ActivityLogTable isLoading={isLoading} logs={logs} />
    </Stack>
  );
};

export default InvestorActivityLog;
