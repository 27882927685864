import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { CustomElement, FormattedText } from '../types';

const serializeDescendant = (node: any, align: string) => {
  if (Text.isText(node as FormattedText)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children: CustomElement = node.children.map((n: CustomElement) => serializeDescendant(n, node.align)).join('');
  const style = { textAlign: align };

  switch (node.type) {
    case 'paragraph':
      return `<p style=text-align:${style.textAlign}>${children}<br></p>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}" target='_blank' rel='noreferrer'>${children}</a>`;
    case 'heading-one':
      return `<h2 style=text-align:${style.textAlign}>
          ${children}
        </h2>`;
    case 'bulleted-list':
      return `<ul>
          ${children}
        </ul>`;
    case 'list-item':
      return `<li>
          ${children}
        </li>`;
    case 'numbered-list':
      return `<ol>
          ${children}
        </ol>`;
    default:
      return `<p style=text-align:${style.textAlign}>
          ${children}
        </p>`;
  }
};

const serialize = (nodes: any) => {
  return nodes
    .map((nodeParent: any) => {
      return serializeDescendant(nodeParent, nodeParent.align);
    })
    .join('');
};

export { serialize, serializeDescendant };
