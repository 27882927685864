import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useEffect } from 'react';
import { flowResult } from 'mobx';
import { useStores } from '../../../store';
import c from './styles.module.scss';
import NotificationItem from './notificationItem';

function NotificationsPage() {
  const { vereficationStore } = useStores();

  const { allNotifications } = vereficationStore;

  useEffect(() => {
    flowResult(vereficationStore.getUserNotifications());
  }, [vereficationStore]);

  return (
    <div>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <div className={c.title}>Notifications</div>
      <div className={c.list}>
        {vereficationStore.isLoading ? (
          <Spinner single />
        ) : (
          <>
            {allNotifications?.length === 0 && <div className={c.notification}>No notifications yet</div>}
            {allNotifications?.length > 0 &&
              allNotifications.map((item, index) => <NotificationItem notification={item} key={index} />)}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(NotificationsPage);
