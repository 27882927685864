import React from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';

import { observer } from 'mobx-react-lite';
import TablePagination from '@mui/material/TablePagination';
import c from './style.module.scss';
import { MONTH_NAMES } from '../../../shared/config/constants';
import { IActivity } from '../../../shared/interfaces/userInfo.interface';
import { useStores } from '../../../store';
import { useFrontPagination } from '../../../shared/hooks/useFrontPagination';

function ActivityLog() {
  const { userInfoStore } = useStores();

  const { paginationItems, onPageSizeChange, onCurrentPageChange, controlledParams } = useFrontPagination(
    userInfoStore.currentUserActivity,
  );

  const pad = (num: number) => `0${num}`.slice(-2);

  const getTimeFromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${pad(hours)}:${pad(minutes)}`;
  };

  const getDateFromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = MONTH_NAMES[date.getMonth()];
    const days = date.getDate();
    return `${days} ${month}, ${year}`;
  };

  return (
    <div className={c.wrapper}>
      <div className={c.topPanel}>
        <div className={c.text}>Activity log</div>
      </div>
      <div className={c.table}>
        <div className={c.titleRow}>
          <div className={c.columnLeftTitle}>Date</div>
          <div className={c.columnRightTitle}>Description</div>
        </div>
        <div className={c.profilesRows}>
          <div className={c.loadingCell}>
            {userInfoStore.isLoading && (
              <div className={c.loading}>
                <Spinner single />
              </div>
            )}
          </div>
          {userInfoStore.currentUserActivity.length > 0 ? (
            paginationItems.map((activity: IActivity) => {
              return (
                <div className={c.profileRow} key={activity.id}>
                  <div className={c.columnLeft}>
                    <div className={c.date}>{getDateFromTimestamp(+activity.timestamp)}</div>
                    <div className={c.time}>{getTimeFromTimestamp(+activity.timestamp)}</div>
                  </div>
                  <div className={c.columnRight}>{activity.description}</div>
                </div>
              );
            })
          ) : (
            <div className={c.emptyBlock}>
              <FormattedMessage id="usersPage.table.text.noUsersMessage" />
            </div>
          )}
        </div>
      </div>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={userInfoStore.currentUserActivity.length}
        rowsPerPage={+controlledParams.pageSize}
        page={userInfoStore.currentUserActivity.length <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: userInfoStore.isLoading,
        }}
        backIconButtonProps={{
          disabled: userInfoStore.isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            userInfoStore.isLoading ||
            Math.ceil(userInfoStore.currentUserActivity.length / +controlledParams.pageSize) - 1 ===
              +controlledParams.currentPage ||
            userInfoStore.currentUserActivity.length === 0,
        }}
      />
    </div>
  );
}

export default observer(ActivityLog);
