import { ethers } from 'ethers';
import { makeAutoObservable, runInAction } from 'mobx';
import walletService from '../shared/services/wallet/walletService';
import RootStore from './index';
import {
  ElementTypes,
  CryptoBalance,
  FiatBalances,
  CryptoBalances,
  CustodialAccount,
} from '../shared/interfaces/walletInterface';

const availableNetworks = process.env.REACT_APP_METAMASK_AVAILABLE_NETWORKS?.split(' ');

const networksData = new Map([
  [80001, 'mumbai'],
  [137, 'polygon'],
]);

class WalletStore {
  rootStore: RootStore;

  isLoading = false;

  errorMessage = '';

  userSavedWallet = '';

  metamaskSelectedWallet = '';

  fiatBalance = 0;

  custodialAccounts: CustodialAccount[] = [];

  cryptoBalances: CryptoBalance[] = [];

  walletAddress = '';

  walletNetwork = '';

  provider: ethers.providers.Web3Provider | null = null;

  chainId: number | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  reset() {
    this.cryptoBalances = [];
    this.custodialAccounts = [];
    this.fiatBalance = 0;
  }

  *getFiatBalances() {
    this.isLoading = true;
    try {
      const data: FiatBalances = yield walletService.getFiatBalance();
      const fiatBalance = data.data.find(item => item.assetType === 'fiatCurrency');
      this.fiatBalance = fiatBalance?.total || 0;
    } catch (e: any) {
      this.errorMessage = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getCustodialAccounts() {
    this.isLoading = true;
    try {
      const data: CustodialAccount[] = yield walletService.getCustodialAccounts();
      this.custodialAccounts = data;
    } catch (e: any) {
      this.errorMessage = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getCryptoBalances() {
    this.isLoading = true;
    try {
      const data: CryptoBalances = yield walletService.getCryptoBalances();
      this.cryptoBalances = data.data;
      this.walletAddress = data.address;
      this.walletNetwork = data.network;
    } catch (e: any) {
      this.errorMessage = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *getUserSavedWallet() {
    this.isLoading = true;
    try {
      const data: { wallet: string } = yield walletService.getUserSavedWallet();
      this.userSavedWallet = data.wallet;
    } catch (e: any) {
      this.errorMessage = e?.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  *createCastodialAccount(onClose: () => void) {
    this.isLoading = true;
    this.errorMessage = '';
    try {
      yield walletService.createCastodialAccount().then(() => {
        this.rootStore.headerStore.setWallet(true);
        this.rootStore.headerStore.setCryptoWallet(true);
        onClose();
      });
    } catch (e: any) {
      this.errorMessage = e.response?.data?.message || 'Server error';
    } finally {
      this.isLoading = false;
    }
  }

  async getJWT(element: ElementTypes, id?: string) {
    this.isLoading = true;
    try {
      let jwt = '';
      if (id) {
        jwt = await walletService.getJWTforAsset(element, id);
      } else {
        jwt = await walletService.getJWT(element);
      }
      return jwt ?? '';
    } catch (e: any) {
      this.errorMessage = e.response?.data?.message || 'Server error';
      return '';
    } finally {
      this.isLoading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getNetworkCode(code: number): string {
    let availableNetwork = 'unknown';
    if (networksData.has(code!)) {
      availableNetwork = networksData.get(code!)!;
    }
    return availableNetwork;
  }

  private async switchNetworkPolygon() {
    if (!this.provider) return false;
    try {
      const MaticNetworkId = '0x89';
      if (this.chainId !== +MaticNetworkId) {
        try {
          await this.provider.send('wallet_switchEthereumChain', [{ chainId: MaticNetworkId }]);
          this.setCurrentChainId();
        } catch (error: any) {
          if (error.code !== 4902) {
            return false;
          }
          await this.provider.send('wallet_addEthereumChain', [
            {
              chainId: MaticNetworkId,
              chainName: 'Polygon Mainnet',
              rpcUrls: ['https://polygon-rpc.com'],
              blockExplorerUrls: ['https://polygonscan.com'],
              nativeCurrency: {
                symbol: 'MATIC',
                decimals: 18,
              },
            },
          ]);
          this.setCurrentChainId();
        }
      }
      return true;
    } catch (error: any) {
      return false;
    }
  }

  private async switchNetworkMumbai() {
    if (!this.provider) return false;
    try {
      const MumbaiNetworkId = '0x13881';

      if (this.chainId !== +MumbaiNetworkId) {
        try {
          await this.provider.send('wallet_switchEthereumChain', [{ chainId: MumbaiNetworkId }]);
          this.setCurrentChainId();
        } catch (error: any) {
          if (error.code !== 4902) {
            return false;
          }
          await this.provider.send('wallet_addEthereumChain', [
            {
              chainId: MumbaiNetworkId,
              chainName: 'Mumbai',
              rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
              blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
              nativeCurrency: {
                symbol: 'MATIC',
                decimals: 18,
              },
            },
          ]);
          this.setCurrentChainId();
        }
      }
      return true;
    } catch (error: any) {
      return false;
    }
  }

  async switchToAvailableNetwork() {
    if (!this.provider) return false;
    if (availableNetworks && availableNetworks[0] === 'mumbai') {
      return this.switchNetworkMumbai();
    }
    if (availableNetworks && availableNetworks[0] === 'polygon') {
      return this.switchNetworkPolygon();
    }
    return false;
  }

  private async setCurrentChainId() {
    if (!this.provider) return;
    try {
      const network = await this.provider.getNetwork();
      runInAction(() => {
        this.chainId = network.chainId;
      });
    } catch (_e) {
      console.log('Can not change');
    }
  }

  async connectWallet() {
    let metamaskProvider = null;
    if (window.ethereum?.providers) {
      metamaskProvider = window.ethereum.providers.find((p: any) => p.isMetaMask);
    }
    if (!metamaskProvider && window?.ethereum?.isMetaMask) {
      metamaskProvider = window.ethereum;
    }
    if (!window.ethereum) {
      // eslint-disable-next-line no-alert
      alert('Metamask extension is not installed.');
      return;
    }
    this.provider = new ethers.providers.Web3Provider(metamaskProvider);
    this.setCurrentChainId();
    try {
      const accounts: string[] = await this.provider.send('eth_requestAccounts', []);
      let isSwitch = true;
      if (this.getNetworkCode(this.chainId!) === 'unknown') {
        isSwitch = await this.switchToAvailableNetwork();
      }
      if (isSwitch) {
        runInAction(() => {
          this.metamaskSelectedWallet = accounts[0];
        });
      } else {
        // remove listeners
        this.provider = null;
        this.chainId = null;
      }
    } catch (e: any) {
      console.log(e);
    }
  }
}

export default WalletStore;
