import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00BC68',
      dark: '#0DA75D',
    },
    error: {
      main: '#F05555',
    },
    text: {
      primary: '#27303E',
      secondary: '#8D8D8D',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 730,
      lg: 1024,
      xl: 1376,
    },
  },
  spacing: (factory: any) => `${factory * 0.0625}rem`, // if factory = 1 => 0.0625rem ~ 1px (covert px units to rem)
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            backgroundColor: '#D2D2D2',
          },
          '&.MuiButton-containedPrimary': {
            color: '#FFF',
          },
        },
      },
    },
  },
});

export { theme };
