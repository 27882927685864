import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';

function AssetFullAccess() {
  const [email, setEmail] = useState('');
  const {
    assetStore: { currentAsset },
  } = useStores();
  const navigate = useNavigate();

  return (
    <div className={c.assetFullAccess}>
      <div className={c.title}>Log In for Full Access to Financial Data</div>
      <div className={c.descr}>
        Once you log in, you’ll have access to data about this project such as total returns, cumulative net cash flow,
        sales proceeds and more.
      </div>
      <div className={c.form}>
        <input
          className={c.email}
          placeholder="Enter Your Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
        <button
          className={c.enter}
          onClick={() =>
            navigate(`/login?email=${encodeURIComponent(`${email}`)}&redirectTo=/assets/${currentAsset.id}`)
          }
        >
          Log In
        </button>
      </div>
    </div>
  );
}

export default observer(AssetFullAccess);
