import React from 'react';
import { FormattedMessage } from 'react-intl';
import c from './styles.module.scss';
import PortalDetails from '../../../components/admin-portals/portal-details/portal-details';

export default function DashboardPage() {
  return (
    <>
      <div className={c.title}>
        <FormattedMessage id="dashboardPage.header" />
      </div>
      <PortalDetails />
    </>
  );
}
