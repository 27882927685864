import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AssetCheckout from '../asset-checkout/assetCheckout';
import c from './styles.module.scss';
import { useStores } from '../../../store/index';
import AssetTopRight from '../asset-top-right/assetTopRight';

function AdvanceItemInfo() {
  const {
    assetStore: { currentAsset },
  } = useStores();
  const navigate = useNavigate();
  const [checkout, setCheckout] = useState(false);
  const [load, setLoaded] = useState(true);

  const toggleCheckout = () => {
    if (localStorage.getItem('accessToken')) {
      setCheckout(!checkout);
    } else {
      navigate('/login');
    }
  };

  return (
    <div className={c.assetInfo}>
      <div className={c.assetTitle}>
        <div>
          <div className={c.title}>
            {currentAsset.name}{' '}
            <span>{currentAsset.tickerSymbol && `($${currentAsset.tickerSymbol.toUpperCase()})`}</span>
          </div>
          <div className={c.location}>{currentAsset.location}</div>
          <div className={c.descr}>{currentAsset.description}</div>
        </div>
      </div>
      <div className={c.assetInfoMain}>
        <div className={c.left}>
          <div className={c.assetImage}>
            {currentAsset.mainImage && (
              <>
                <div className={load ? `${c.loading}` : `${c.loading} ${c.hide}`} />
                <img
                  src={currentAsset.mainImage.url}
                  alt=""
                  onLoad={() => {
                    setLoaded(false);
                  }}
                />
              </>
            )}
            {currentAsset.mainVideo && (
              <>
                <div className={load ? `${c.loading}` : `${c.loading} ${c.hide}`} />
                <iframe
                  src={currentAsset.mainVideo}
                  title="Main Video"
                  frameBorder="0"
                  onLoad={() => {
                    setLoaded(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={c.right}>
          {checkout ? (
            <AssetCheckout toggleCheckout={toggleCheckout} checkout={checkout} />
          ) : (
            <AssetTopRight toggleCheckout={toggleCheckout} checkout={checkout} />
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(AdvanceItemInfo);
