import { TextIndexInput, TextInput } from '@token-suite/common-ui/src/components/Inputs/inputs';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import first from '../../../shared/assets/createAsset/first.png';
import second from '../../../shared/assets/createAsset/second.png';
import third from '../../../shared/assets/createAsset/third.png';
import { IField } from '../../../shared/interfaces/assets/asset.interface';
import { useStores } from '../../../store';

import c from '../styles.module.scss';

function HowItWorkEditBlock(props: { id: string }) {
  const { id } = props;
  const intl = useIntl();
  const { assetStore } = useStores();

  const [workHighlight, setWorkHighlight] = useState('');
  const [works, setWorks] = useState<IField[]>([]);

  const showClick = () => {
    setWorks([
      { title: '', description: '' },
      { title: '', description: '' },
      { title: '', description: '' },
    ]);
  };

  const workTitleHandler = (e: string, index: number) => {
    const tempArr = works;
    tempArr[index].title = e;
    setWorks([...tempArr]);
  };
  const workDescriptionHandler = (e: string, index: number) => {
    const tempArr = works;
    tempArr[index].description = e;
    setWorks([...tempArr]);
  };

  useEffect(() => {
    if (assetStore.currentAsset.howItWorks) {
      setWorks(assetStore.currentAsset.howItWorks.fields);
      setWorkHighlight(assetStore.currentAsset.howItWorks.highlights);
    }
  }, [assetStore.currentAsset.howItWorks]);

  const submit = () => {
    flowResult(assetStore.addHowItWorks(id, workHighlight, works));
  };

  const deleteBlock = () => {
    flowResult(assetStore.deleteHowItWorks(id));
    setWorks([]);
    setWorkHighlight('');
  };

  return (
    <div className={c.formPart}>
      <div className={c.title}>
        <FormattedMessage id="createAssetPage.text.howItWorksBlock" />
      </div>
      {works.length > 0 ? (
        <div className={c.wrapper}>
          <TextInput
            value={workHighlight}
            changeValue={setWorkHighlight}
            label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.highlights.label' })}
            placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.highlights.placeholder' })}
            type="text"
            fullWidth
          />
          <div className={c.block}>
            <img src={first} alt="first" />
            <div className={c.right}>
              <TextIndexInput
                value={works[0].title}
                changeValue={workTitleHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.placeholder' })}
                type="text"
                fullWidth
                index={0}
              />
              <TextIndexInput
                value={works[0].description}
                changeValue={workDescriptionHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.placeholder' })}
                type="text"
                fullWidth
                multiline
                index={0}
              />
            </div>
          </div>
          <div className={c.block}>
            <img src={second} alt="second" />
            <div className={c.right}>
              <TextIndexInput
                value={works[1].title}
                changeValue={workTitleHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.placeholder' })}
                type="text"
                fullWidth
                index={1}
              />
              <TextIndexInput
                value={works[1].description}
                changeValue={workDescriptionHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.placeholder' })}
                type="text"
                fullWidth
                multiline
                index={1}
              />
            </div>
          </div>
          <div className={c.block}>
            <img src={third} alt="third" />
            <div className={c.right}>
              <TextIndexInput
                value={works[2].title}
                changeValue={workTitleHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.title.placeholder' })}
                type="text"
                fullWidth
                index={2}
              />
              <TextIndexInput
                value={works[2].description}
                changeValue={workDescriptionHandler}
                label={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.label' })}
                placeholder={intl.formatMessage({ id: 'createAssetPage.howItWorks.field.description.placeholder' })}
                type="text"
                fullWidth
                multiline
                index={2}
              />
            </div>
          </div>
        </div>
      ) : (
        <button className={c.addButton} onClick={showClick}>
          <FormattedMessage id="uploader.button.addBlock" />
        </button>
      )}
      <div className={c.buttons}>
        <button className={c.submitButton} onClick={submit}>
          Save
        </button>
        {assetStore.currentAsset.howItWorks && (
          <button className={`${c.submitButton} ${c.deleteBtn}`} onClick={deleteBlock}>
            Delete Block
          </button>
        )}
      </div>
    </div>
  );
}
export default observer(HowItWorkEditBlock);
