import React, { useEffect, useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { flowResult } from 'mobx';
import copyMail from '../../shared/assets/icons/copyMail.svg';
import verticalMenuDots from '../../shared/assets/icons/verticalMenuDots.svg';
import c from './style.module.scss';
import { IProfileFortressRow } from '../../shared/interfaces/headerInterface';
import tokenInstance from '../../shared/services/API/tokenApi';
import { useStores } from '../../store';
import { EAccountStatuses } from '../../shared/types/account.types';
import AcceptActionDialog from '../UI/dialogs/accept-action/accept-action-dialog';
import adminService from '../../shared/services/admin/adminService';
import AcceptActionWithInput from '../UI/dialogs/accept-action-with-input/accept-action-with-input';
import ProfileRowStateIcon from './ProfileRowStateIcon';
import { copyDataInBuffer } from '../../shared/lib';

function RenderStatus({ status }: { status: string }) {
  if (!status) {
    return (
      <div className={`${c.status} ${c.notStarted}`}>
        <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.notStarted" />
      </div>
    );
  }
  switch (status) {
    case 'Verification Not Started Yet':
      return (
        <div className={`${c.status} ${c.notStarted}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.notStarted" />
        </div>
      );
    case 'Verified':
      return (
        <div className={`${c.status} ${c.verified}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verified" />
        </div>
      );
    case 'Verification Pending':
      return (
        <div className={`${c.status} ${c.pending}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedPending" />
        </div>
      );
    case 'Verification Denied':
      return (
        <div className={`${c.status} ${c.denied}`}>
          <FormattedMessage id="investorsPage.onbordingInvestors.table.statusFilter.values.verifiedDenied" />
        </div>
      );
    case 'Blocked':
      return <div className={`${c.status} ${c.denied}`}>Blocked</div>;
    default:
      return <div className={`${c.status} ${c.notStarted}`}>{status}</div>;
  }
}

function ProfileRowFortress({
  profileData,
  setItems,
  onboardingInvestors = false,
  allIssuers = false,
}: IProfileFortressRow) {
  const { headerStore, userInfoStore } = useStores();
  const navigate = useNavigate();
  const userRole = headerStore.user.role || 'investor';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { id, firstName, lastName, email, verificationStatus } = profileData;
  const emailRef = useRef<null | HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  const [isEmailTooltipOpen, setIsEmailTooltipOpen] = useState(false);
  const [waitingTime, setTime] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBlockListOpen, setIsBlockListOpen] = useState(false);

  const mouseOnEmailHandler = () => {
    if (!emailRef.current) return;
    if (emailRef.current.offsetWidth >= emailRef.current.scrollWidth) return;
    setIsEmailTooltipOpen(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const showUserProfile = () => {
    navigate(`/user/${profileData.id}`);
  };

  const onRedirectToViewDetailsHandler = (investorId: string) => {
    navigate(`/investors/investor-details/${investorId}`);
  };

  const resendInvitation = () => {
    setLoading(true);
    const regex = /\d+/;
    if (onboardingInvestors) {
      tokenInstance
        .get(`users/investors/resend/${id}`)
        .then(() => {
          setAnchorEl(null);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          setTime(+e.response.data.message.match(regex));
        });
      return;
    }
    if (allIssuers) {
      tokenInstance
        .get(`users/issuers/resend/${id}`)
        .then(() => {
          setAnchorEl(null);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          setTime(+e.response.data.message.match(regex));
        });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(waitingTime - 1);
    }, 1000);
    if (waitingTime === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [waitingTime]);

  const onDeleteUser = async (isIssuerTriggered = false) => {
    if (isIssuerTriggered) {
      await flowResult(userInfoStore.deleteInvestor(profileData.id));
    } else {
      await flowResult(userInfoStore.deleteUser(profileData.id));
    }
    setAnchorEl(null);
    if (!setItems) return;
    setItems(prev => prev.filter(({ id: i }) => i !== profileData.id));
  };

  const onUserAddToBlockList = async (message: string) => {
    try {
      if (!message) {
        throw new Error('Input a reason');
      }
      await adminService.addInvestorToBlockList(profileData.id, message);
      setAnchorEl(null);
      setIsBlockListOpen(false);
    } catch (e) {
      setAnchorEl(null);
    }
  };

  const isIssuer = userRole === 'issuer';

  const isAdmin = userRole === 'admin';

  return (
    <div className={`${c.profileRow}`}>
      <div className={c.columnLeft}>
        <div className={c.name}>
          {firstName} {lastName}
        </div>
        <div className={c.email}>
          <Tooltip
            open={isEmailTooltipOpen}
            onClose={() => setIsEmailTooltipOpen(false)}
            title={email}
            onOpen={mouseOnEmailHandler}
            arrow
          >
            <div className={c.emailText} ref={emailRef}>
              {email}
            </div>
          </Tooltip>
          <button
            onClick={() => {
              copyDataInBuffer(email);
            }}
          >
            <img src={copyMail} alt="copyMail" />
          </button>
        </div>
      </div>
      <div className={c.columnRight}>
        <div className={c.itemColStatus}>
          <RenderStatus status={verificationStatus?.kycStatus || ''} />
        </div>
        {onboardingInvestors && (
          <Typography fontWeight={600} fontSize="0.8rem" className={c.itemColType}>
            {verificationStatus?.isBusiness ? 'Entity' : 'Individual'}
          </Typography>
        )}
        {onboardingInvestors && (
          <div className={c.itemColType}>
            <span className={c.mobileDescription}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.isActive" /> -{' '}
            </span>
            <ProfileRowStateIcon isActive={verificationStatus?.activated} />
          </div>
        )}
        {(onboardingInvestors || allIssuers) && isAdmin && (
          <div className={c.itemColType}>
            <span className={c.mobileDescription}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.portalName" /> -{' '}
            </span>
            <Typography fontWeight={600} fontSize="0.8rem">
              {profileData.portals?.map(item => item.portalName)?.join(', ')}
            </Typography>
          </div>
        )}
        {onboardingInvestors && isIssuer && (
          <div className={c.itemColType}>
            <span className={c.mobileDescription}>
              <FormattedMessage id="investorsPage.onbordingInvestors.table.columns.wallet" /> -{' '}
            </span>
            <ProfileRowStateIcon isActive={!!profileData.fortressWallet?.id} />
          </div>
        )}
        <div className={c.menu}>
          <IconButton
            id="positioned-button"
            aria-controls={open ? 'positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={c.menuBtn}
          >
            <img src={verticalMenuDots} alt="verticalMenuDots" />
          </IconButton>
          <Menu
            id="positioned-menu"
            aria-labelledby="positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {!verificationStatus?.activated && (
              <MenuItem
                onClick={() => {
                  resendInvitation();
                }}
                disabled={waitingTime > 0 || loading}
              >
                {waitingTime > 0 ? (
                  <p>Invitation already sent, please wait {waitingTime} seconds</p>
                ) : (
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.menuButtons.resend" />
                )}
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem
                onClick={() => {
                  showUserProfile();
                }}
              >
                <FormattedMessage id="investorsPage.onbordingInvestors.table.menuButtons.profile" />
              </MenuItem>
            )}
            {isAdmin && onboardingInvestors && (
              <MenuItem onClick={() => setIsBlockListOpen(true)} disabled={verificationStatus.blocked}>
                <FormattedMessage id="investorsPage.onbordingInvestors.table.menuButtons.addToBlockList" />
              </MenuItem>
            )}
            {onboardingInvestors && isIssuer && (
              <MenuItem onClick={() => onRedirectToViewDetailsHandler(profileData.id)}>
                <FormattedMessage id="investorsPage.menu.viewDetails" />
              </MenuItem>
            )}
            {(isIssuer || isAdmin) &&
              onboardingInvestors &&
              verificationStatus?.kycStatus === EAccountStatuses.VerificationNotStartedYet && (
                <MenuItem onClick={() => setIsDialogOpen(true)}>
                  <FormattedMessage id="investorsPage.onbordingInvestors.table.menuButtons.deleteUser" />
                </MenuItem>
              )}
          </Menu>
        </div>
      </div>
      <AcceptActionDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        onAgreeHandler={() => onDeleteUser(isIssuer)}
        title={`Delete ${profileData.firstName} ${profileData.lastName}?`}
        content={`Are you sure you want to delete user ${profileData.firstName} ${profileData.lastName}?`}
      />
      <AcceptActionWithInput
        open={isBlockListOpen}
        setOpen={setIsBlockListOpen}
        onAgreeHandler={onUserAddToBlockList}
        placeholder="Input a reason"
        title={`Add ${profileData.firstName} ${profileData.lastName} to block list?`}
        content={`Are you sure you want to add user ${profileData.firstName} ${profileData.lastName} to block list?`}
        actionButtonText="Add"
      />
    </div>
  );
}
export default observer(ProfileRowFortress);
