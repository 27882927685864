import { FormikControlProps } from '../formik/formik-control/formik-control.types';

const fields: Array<FormikControlProps & { id: number }> = [
  {
    id: 1,
    control: 'input',
    inputProps: {
      name: 'firstName',
      id: 'firstName',
      label: 'First name',
      placeholder: 'Enter issuer first name',
    },
  },
  {
    id: 2,
    control: 'input',
    inputProps: {
      name: 'lastName',
      id: 'lastName',
      label: 'Last name',
      placeholder: 'Enter issuer last name',
    },
  },
  {
    id: 3,
    control: 'input',
    inputProps: {
      name: 'email',
      id: 'email',
      label: 'Email',
      placeholder: 'name@example.com',
    },
  },
];

export { fields };
