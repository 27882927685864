import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { useStores } from '../../../store';
import c from './styles.module.scss';
import CopyIcon from '../../../shared/assets/icons/copyMail.svg';
import copyDataInBuffer from '../../../shared/lib/copy-data-in-buffer';
import { useMetaKeep } from '../../../context/MetaKeepContext/MetaKeep.context';
import { WalletBalance } from '../../../components/wallet-left-menu/WalletBalances';
import TransactionHistory from '../../../components/transactions-history/common/transaction-history';

function WalletPage() {
  const { portalStore } = useStores();
  const { wallet, balance, getBalance } = useMetaKeep();

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  if (!wallet) return <Spinner />;

  return (
    <div>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title
            ? `Wallet | ${portalStore.portalUI.portalUi.title}`
            : 'Wallet | TokenSuite'}
        </title>
      </Helmet>
      <div className={c.title}>Tokens</div>
      <WalletBalance balance={balance} />
      <div className={c.title}>Transaction history</div>
      <TransactionHistory />
      <div className={c.title}>Wallet</div>
      <div className={c.blocks}>
        <div className={`${c.whiteBlock} ${c.crypto}`}>
          <div className={c.blockTitle}>Crypto Wallet</div>
          <div className={c.block}>
            <div className={c.text}>
              {wallet}
              <IconButton onClick={() => copyDataInBuffer(wallet)}>
                <img src={CopyIcon} alt="" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {/* {qr && <DepositCryptoModal open={depositCrypto} onClose={onClose} wallet={wallet} network={wallet} qrcode={qr} />} */}
    </div>
  );
}

export default observer(WalletPage);
