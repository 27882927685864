import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import tokenInstance from '../../../shared/services/API/tokenApi';

export default function ReferalsPage() {
  const query = useQueryParams();
  const navigate = useNavigate();
  const assetId = query.get('assetId');
  const referralId = query.get('referralId');

  useEffect(() => {
    if (referralId && assetId) {
      if (localStorage.getItem('accessToken')) {
        tokenInstance.post('referrals/link', {
          referralId,
        });
      } else {
        localStorage.setItem('referralId', referralId);
      }
      navigate(`/assets/${assetId}`);
    }
  }, [assetId, referralId, navigate]);

  return <Spinner />;
}
