import React, { useState } from 'react';
import { IconButton, Modal, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';

interface ModalImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  modalHeight: number;
  modalWidth: number;
}

const ModalImage = ({ src, alt, modalWidth, modalHeight, ...rest }: ModalImageProps) => {
  const [open, setOpen] = useState(false);
  const openModalHandler = () => {
    setOpen(true);
  };

  const closeModalHandler = () => {
    setOpen(false);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
      <img src={src} alt={alt} {...rest} onClick={openModalHandler} style={{ cursor: 'pointer' }} />
      <Modal
        open={open}
        onClose={closeModalHandler}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            maxWidth: modalWidth,
            maxHeight: modalHeight,
            width: '100%',
            position: 'relative',
            pb: '38%',
            overflow: 'hidden',
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={closeModalHandler}
          >
            <Close fontSize="large" color="inherit" />
          </IconButton>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalImage;
