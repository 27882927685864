import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, MenuItem, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import {
  AutocompleteInput,
  DateInput,
  ICountryType,
  SelectInput,
  StatesAutocompleteInput,
  TextInput,
} from '@token-suite/common-ui/src/components/Inputs/inputs';
import { MuiTelInput } from 'mui-tel-input';
import scrollToTop from '@token-suite/common-ui/src/sharedFunctions/scrollToTop';
import moment from 'moment';
import c from './styles.module.scss';
import IdentityFilesUploader from '../../../components/identityFilesUploader/identityFilesUploader';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import tokenInstance from '../../../shared/services/API/tokenApi';
import Back from '../../../shared/assets/icons/arrow-back.svg';
import { useStores } from '../../../store';
import { countriesWithPhonecode, states } from '../../../shared/config/constants';
import { KybDocumentType, LegalStructureType } from '../../../shared/interfaces/userInfo.interface';

const KybPage = () => {
  const query = useQueryParams();
  const id = query.get('id');
  const navigate = useNavigate();
  const intl = useIntl();

  const [companyName, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [streetAddress, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState(null);
  const [naics, setNaics] = useState('');
  const [naicsDescr, setNaicsDescr] = useState('');
  const [country, setCountry] = useState<ICountryType | null>(null);
  const [regionOfFormation, setRegion] = useState('');
  const [description, setDescription] = useState('');
  const [establishedOn, setEstablished] = useState(new Date());

  const [documentType, setDocumentType] = useState<'Other' | 'Proof of Address' | 'Proof of Company Formation'>(
    'Proof of Address',
  );
  const [legalStructure, setLegalStructure] = useState<
    | 'Other'
    | 'C Corporation'
    | 'Limited Liability Company'
    | 'Partnership'
    | 'Nonprofit'
    | 'S Corporation'
    | 'Sole Proprietorship'
  >('Partnership');

  const [documentFront, setFront] = useState([]);

  const [errorMessages, setErrors] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState(false);

  const { portalStore } = useStores();

  const [errorFields, setErrorFields] = useState({
    companyName: false,
    phone: false,
    email: false,
    streetAddress: false,
    zipCode: false,
    city: false,
    state: false,
    naics: false,
    naicsDescr: false,
    country: false,
    regionOfFormation: false,
    description: false,
    dateError: false,
    documentFront: false,
  });

  const handlePhoneChange = (newPhone: string) => {
    setPhone(newPhone.replaceAll(' ', ''));
  };

  const getCommonFields = useCallback(() => {
    const commonFields = [
      { field: 'companyName', value: companyName },
      { field: 'phone', value: phone },
      { field: 'email', value: email },
      { field: 'streetAddress', value: streetAddress },
      { field: 'zipCode', value: zipCode },
      { field: 'city', value: city },
      { field: 'country', value: country },
      { field: 'regionOfFormation', value: regionOfFormation },
      { field: 'description', value: description },
      { field: 'dateError', value: !dateError },
      { field: 'documentFront', value: documentFront.length > 0 },
    ];

    if (country?.label === 'United States of America') {
      commonFields.splice(
        commonFields.length - 1,
        0,
        { field: 'state', value: state },
        { field: 'naics', value: naics },
        { field: 'naicsDescr', value: naicsDescr },
      );
    }
    return commonFields;
  }, [
    companyName,
    phone,
    email,
    streetAddress,
    zipCode,
    city,
    country,
    regionOfFormation,
    naics,
    naicsDescr,
    description,
    dateError,
    documentFront.length,
    state,
  ]);

  const isValidDataHandler = () => {
    const commonFields = getCommonFields();
    const newErrorFields = {
      companyName: false,
      phone: false,
      email: false,
      streetAddress: false,
      zipCode: false,
      city: false,
      state: false,
      naics: false,
      naicsDescr: false,
      country: false,
      regionOfFormation: false,
      description: false,
      dateError: false,
      documentFront: false,
    };
    commonFields.forEach(({ field, value }) => {
      if (field === 'documentFront') {
        newErrorFields[field] = !value as boolean;
      } else {
        newErrorFields[field as 'companyName'] = typeof value === 'string' ? !value?.trim() : !value;
      }
    });
    setErrorFields(newErrorFields);
    return (
      !commonFields.some(({ value }) => (typeof value === 'string' ? !value?.trim() : !value)) &&
      documentFront.length > 0
    );
  };

  const handleClose = () => {
    navigate('/portfolio', { replace: true });
  };

  useEffect(() => {
    if (country?.label !== 'United States of America') {
      setState(null);
    }
  }, [country]);

  useEffect(() => {
    setFront([]);
  }, [documentType]);

  useEffect(() => {
    const fieldsForChange: string[] = [];
    const commonFields = getCommonFields();
    commonFields.forEach(({ field, value }) => {
      if (errorFields[field as 'companyName'] && value) {
        fieldsForChange.push(field);
      }
    });
    if (!fieldsForChange.length) return;
    const newErrorFields = { ...errorFields };
    fieldsForChange.forEach(field => {
      newErrorFields[field as 'companyName'] = false;
    });
    setErrorFields(newErrorFields);
  }, [
    companyName,
    phone,
    email,
    streetAddress,
    zipCode,
    city,
    state,
    naics,
    naicsDescr,
    country,
    regionOfFormation,
    description,
    dateError,
    errorFields,
    getCommonFields,
  ]);

  const submit = () => {
    setErrors('');
    if (!isValidDataHandler()) {
      scrollToTop();
      return;
    }
    setLoading(true);
    const kycFormData = new FormData();
    kycFormData.append('companyName', companyName.trim());
    kycFormData.append('phone', `${phone}`);
    kycFormData.append('email', email.trim());
    kycFormData.append('street1', streetAddress.trim());
    kycFormData.append('postalCode', zipCode);
    kycFormData.append('city', city.trim());
    kycFormData.append('country', `${country?.code}`);
    kycFormData.append('regionOfFormation', regionOfFormation.trim());
    kycFormData.append('description', description.trim());
    kycFormData.append('establishedOn', moment(establishedOn).format('YYYY-MM-DD'));
    kycFormData.append('legalStructure', LegalStructureType[legalStructure]);
    if (country?.label === 'United States of America') {
      kycFormData.append('state', `${state}`);
      kycFormData.append('naics', `${naics}`);
      kycFormData.append('naicsDescription', naicsDescr.trim());
    }
    kycFormData.append('documentType', KybDocumentType[documentType]);
    kycFormData.append('DocumentFront', documentFront[0]);
    if (id) {
      kycFormData.append('userId', id);
      tokenInstance
        .post('users/kyb/v2/user', kycFormData)
        .then(() => {
          navigate(`/user/${id}`, { replace: true });
          setLoading(false);
        })
        .catch(e => {
          scrollToTop();
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setErrors(e.response.data.message);
          }
          setLoading(false);
        });
    } else {
      tokenInstance
        .post('users/kyb/v2', kycFormData)
        .then(() => {
          setOpen(true);
          setLoading(false);
        })
        .catch(e => {
          scrollToTop();
          if (e.response.data.errors) {
            setErrors(e.response.data.errors);
          } else {
            setErrors(e.response.data.message);
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {portalStore.portalUI?.portalUi?.title ? `KYB | ${portalStore.portalUI.portalUi.title}` : 'KYB | TokenSuite'}
        </title>
      </Helmet>
      <button className={c.back} onClick={() => navigate(-1)}>
        <img src={Back} alt="" />
        Back
      </button>
      <div className={c.title}>
        <FormattedMessage id="kybPage.title" />
      </div>
      <div className={c.block}>
        <div className={c.errors}>
          <div className={c.errorText}>{errorMessages}</div>
        </div>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            <TextInput
              value={companyName}
              changeValue={setName}
              label={intl.formatMessage({ id: 'kycPage.fields.companyName.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.companyName.placeholder' })}
              type="text"
              error={errorFields.companyName}
            />
            <TextInput
              value={email}
              changeValue={setEmail}
              label={intl.formatMessage({ id: 'kycPage.fields.companyEmail.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.companyEmail.placeholder' })}
              type="text"
              error={errorFields.email}
            />
            <TextInput
              value={regionOfFormation}
              changeValue={setRegion}
              label={intl.formatMessage({ id: 'kycPage.fields.region.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.region.placeholder' })}
              type="text"
              error={errorFields.regionOfFormation}
            />
            <TextInput
              value={description}
              changeValue={setDescription}
              label={intl.formatMessage({ id: 'kycPage.fields.description.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.description.placeholder' })}
              type="text"
              error={errorFields.description}
            />
            {country?.label === 'United States of America' && (
              <>
                <TextInput
                  value={naics}
                  changeValue={setNaics}
                  label={intl.formatMessage({ id: 'kycPage.fields.naics.label' })}
                  placeholder={intl.formatMessage({ id: 'kycPage.fields.naics.placeholder' })}
                  type="number"
                  error={errorFields.naics}
                />
                <TextInput
                  value={naicsDescr}
                  changeValue={setNaicsDescr}
                  label={intl.formatMessage({ id: 'kycPage.fields.naicsDescr.label' })}
                  placeholder={intl.formatMessage({ id: 'kycPage.fields.naicsDescr.placeholder' })}
                  type="text"
                  error={errorFields.naicsDescr}
                />
              </>
            )}
            <SelectInput
              value={legalStructure}
              changeValue={setLegalStructure}
              label={intl.formatMessage({ id: 'kycPage.fields.structure.label' })}
              defaultValue={intl.formatMessage({ id: 'kycPage.fields.structure.label' })}
            >
              <MenuItem value="C Corporation">C Corporation</MenuItem>
              <MenuItem value="Limited Liability Company">Limited Liability Company</MenuItem>
              <MenuItem value="Partnership">Partnership</MenuItem>
              <MenuItem value="Nonprofit">Nonprofit</MenuItem>
              <MenuItem value="S Corporation">S Corporation</MenuItem>
              <MenuItem value="Sole Proprietorship">Sole Proprietorship</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </SelectInput>
            <DateInput
              value={establishedOn}
              changeValue={setEstablished}
              label={intl.formatMessage({ id: 'kycPage.fields.established.label' })}
              setError={setDateError}
              maxDate={moment(new Date())}
            />
            <div className={c.inputBlock}>
              <div className={c.inputLabel}>Company phone number</div>
              <MuiTelInput
                disableDropdown
                value={phone}
                onChange={handlePhoneChange}
                className={c.input}
                error={errorFields.phone}
                helperText={errorFields.phone ? `field is required` : null}
              />
            </div>
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            <AutocompleteInput
              value={country}
              changeValue={setCountry}
              label={intl.formatMessage({ id: 'kycPage.fields.address.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.country.placeholder' })}
              options={countriesWithPhonecode}
              error={errorFields.country}
            />
            <TextInput
              value={zipCode}
              changeValue={setZipCode}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.zipCode.placeholder' })}
              label={intl.formatMessage({ id: 'kycPage.fields.zipCode.label' })}
              type="number"
              error={errorFields.zipCode}
            />
            {country?.label === 'United States of America' && (
              <StatesAutocompleteInput
                value={state}
                changeValue={setState}
                label={intl.formatMessage({ id: 'kycPage.fields.state.label' })}
                placeholder={intl.formatMessage({ id: 'kycPage.fields.state.placeholder' })}
                options={states}
                error={errorFields.state}
              />
            )}
            <TextInput
              value={city}
              changeValue={setCity}
              label={intl.formatMessage({ id: 'kycPage.fields.city.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.city.placeholder' })}
              type="text"
              error={errorFields.city}
            />
            <TextInput
              value={streetAddress}
              changeValue={setStreet}
              label={intl.formatMessage({ id: 'kycPage.fields.street.label' })}
              placeholder={intl.formatMessage({ id: 'kycPage.fields.street.placeholder' })}
              type="text"
              error={errorFields.streetAddress}
            />
          </div>
        </div>
        <div className={c.formPart}>
          <div className={c.inputsBlock}>
            <SelectInput
              value={documentType}
              changeValue={setDocumentType}
              label={intl.formatMessage({ id: 'kycPage.fields.documentType.label' })}
              defaultValue={intl.formatMessage({ id: 'kycPage.fields.documentType.label' })}
            >
              <MenuItem value="Proof of Company Formation">Proof of Company Formation</MenuItem>
              <MenuItem value="Proof of Address">Proof of Address</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </SelectInput>
          </div>
          <div className={c.inputsBlock}>
            <div className={`${c.inputBlock} ${c.fullWidth}`}>
              <div className={c.inputLabel}>Upload Documents</div>
              <IdentityFilesUploader setDocuments={setFront} documents={documentFront} cap={1} isKycPage />
              {errorFields.documentFront && (
                <Stack justifyContent="center" alignItems="center">
                  <Typography fontSize="12px" lineHeight="14px" sx={{ color: '#F05555FF', fontFamily: 'Montserrat' }}>
                    Upload document
                  </Typography>
                </Stack>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={c.btns}>
        <button className={c.btn} onClick={submit} disabled={loading}>
          {loading ? <>loading...</> : <FormattedMessage id="kycPage.submit" />}
        </button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div className={c.dialog}>
          <div className={c.title}>
            <FormattedMessage id="kycPage.dialog.title" />
          </div>
          <p className={c.text}>
            <FormattedMessage id="kycPage.dialog.text" />
          </p>
          <div className={c.actions}>
            <Link to="/portfolio" className={c.submitButton}>
              <FormattedMessage id="kycPage.dialog.continue" />
            </Link>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default KybPage;
