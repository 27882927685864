import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { IActivityLog } from './interfaces';

class ActivityLogService {
  authInstance: AxiosInstance;

  constructor(auth: AxiosInstance) {
    this.authInstance = auth;
  }

  /**
   *
   * Return all activity for issuer - dont using
   */
  async getAllByIssuer(): Promise<IActivityLog[]> {
    const { data } = await this.authInstance.get<IActivityLog[]>('users/activity/issuer');
    return data;
  }

  /**
   *
   * Return all activity of investor for issuer
   */
  async getOneByIssuer(investorId: string): Promise<IActivityLog[]> {
    const { data } = await this.authInstance.get<IActivityLog[]>(`users/activity/issuer/investor/${investorId}`);
    return data;
  }

  async getAllByInvestor(): Promise<IActivityLog[]> {
    const { data } = await this.authInstance.get<IActivityLog[]>('users/activity/investor');
    return data;
  }
}

export default new ActivityLogService(tokenInstance);
