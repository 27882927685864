import React, { FC } from 'react';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import '../../../app/styles/reusable/tables.scss';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import { ITransactionHistory } from '../../../shared/services/transactions/transactions.types';
import TransactionHistoryRow from './transaction-history-row';
import c from '../styles.module.scss';
import { headCells } from './transactions-history.meta';
import { RequestParams } from '../../../shared/types/investorTableType';
import HeaderIcon from './header-icon';

interface ITransactionsTable {
  transactions: ITransactionHistory[];
  totalTransactions: number;
  isLoading: boolean;
  controlledParams: RequestParams;
  onCurrentPageChange: (value: number | string) => void;
  onPageSizeChange: (value: number | string) => void;
  onOrderChange: (value: 'name' | 'status') => void;
}

const TransactionsTable: FC<ITransactionsTable> = ({
  totalTransactions,
  transactions,
  isLoading,
  controlledParams,
  onCurrentPageChange,
  onPageSizeChange,
  onOrderChange,
}) => {
  return (
    <TableContainer
      component={Paper}
      className={`wrapperDT ${c.tableWrapper}`}
      sx={{
        borderRadius: 0,
        boxShadow: 'none',
      }}
    >
      <Table>
        <TableHead>
          <TableRow className="tableHeadDT">
            {headCells.map(cell => (
              <TableCell key={cell.id} className="headCellDT">
                <Stack direction="row" alignItems="center">
                  {cell.text}
                  {cell.sortable && (
                    <IconButton disabled={isLoading} onClick={() => onOrderChange(cell.id as 'name')}>
                      <HeaderIcon controlledParams={controlledParams} id={cell.id} />
                    </IconButton>
                  )}
                </Stack>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Spinner single />
              </TableCell>
            </TableRow>
          )}
          {transactions.map(transaction => (
            <TransactionHistoryRow key={transaction.id} transaction={transaction} />
          ))}
          {!transactions.length && !isLoading && (
            <TableRow>
              <TableCell className="emptyBlockDT" colSpan={8}>
                <FormattedMessage id="transactionsHistory.table.emptyBlock" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalTransactions}
        rowsPerPage={+controlledParams.pageSize}
        page={totalTransactions <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            isLoading ||
            Math.ceil(totalTransactions / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
            totalTransactions === 0,
        }}
      />
    </TableContainer>
  );
};

export default TransactionsTable;
