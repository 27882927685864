import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import c from './style.module.scss';
import Back from '../../../shared/assets/icons/arrow-back.svg';
import PortalIssuers from '../../../components/admin-portals/portal-issuers/portal-issuers';

const AdminPortalIssuersPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Portal Issuers | TokenSuite</title>
      </Helmet>
      <button className={c.backBtn} onClick={() => navigate(-1)}>
        <img src={Back} alt="" />
        Back
      </button>
      <h2>Issuers on portal</h2>
      <PortalIssuers />
    </div>
  );
};

export default AdminPortalIssuersPage;
