import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import exportXlsx from '@token-suite/common-ui/src/sharedFunctions/exportXlsx';
import RootStore from './index';
import { IDividendAssetItem, IInvestorDividend } from '../shared/interfaces/userInfo.interface';
import dividendService from '../shared/services/dividends/dividendService';
import assetService from '../shared/services/asset/assetService';
import { IMarketPlaceAsset } from '../shared/services/asset/asset.types';
import { MONTH_NAMES } from '../shared/config/constants';

class DividendStore {
  rootStore: RootStore;

  message = '';

  isLoading = false;

  isDateModalOpen = false;

  isDistributeModalOpen = false;

  currentAssetId = '';

  currentDeposit = 0;

  currentStartDate = '';

  currentEndDate = '';

  allDividendAssets: IDividendAssetItem[] = [];

  allDividendIssuerAssets: IMarketPlaceAsset[] = [];

  calculateDividends: IInvestorDividend[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  resetAssets() {
    this.currentAssetId = '';
    this.allDividendAssets = [];
    this.allDividendIssuerAssets = [];
  }

  setAllAssets(payload: IDividendAssetItem[]) {
    this.allDividendAssets = payload;
  }

  setAllIssuerAssets(payload: IMarketPlaceAsset[]) {
    this.allDividendIssuerAssets = payload;
  }

  setCalculateDividends(payload: IInvestorDividend[]) {
    this.calculateDividends = payload;
  }

  getCurrentDate(startDateProps: string, endDateProps: string) {
    const startDate = new Date(startDateProps);
    const endDate = new Date(endDateProps);
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = MONTH_NAMES[startDate.getMonth()];
    const endMonth = MONTH_NAMES[endDate.getMonth()];
    const startDays = startDate.getDate();
    const endDays = endDate.getDate();
    this.currentStartDate = `${startDays} ${startMonth}, ${startYear}`;
    this.currentEndDate = `${endDays} ${endMonth}, ${endYear}`;
  }

  openDateModal(assetId: string) {
    this.currentAssetId = assetId;
    this.isDateModalOpen = true;
  }

  openDistributeModal(assetId: string) {
    this.currentAssetId = assetId;
    this.isDistributeModalOpen = true;
  }

  closeDateModal() {
    this.isDateModalOpen = false;
  }

  closeDistributeModal() {
    this.isDistributeModalOpen = false;
  }

  *getAllAssets() {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const assets: IDividendAssetItem[] = yield dividendService.getAllAssets();
      if (Array.isArray(assets)) {
        this.setAllAssets(assets);
      }
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server error';
    }
  }

  *getAllIssuerAssets() {
    this.isLoading = true;
    this.rootStore.loaderStore.toggleLoader();
    try {
      const assets: IMarketPlaceAsset[] = yield assetService.getAssetsForPortal();
      if (Array.isArray(assets)) {
        this.setAllIssuerAssets(assets);
      }
      this.rootStore.loaderStore.toggleLoader();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'Server error';
    }
  }

  *sendNewDate(startDateProps: Date) {
    const startDate = moment(startDateProps).toISOString();
    try {
      this.message = '';
      yield dividendService.sendNewDate(this.currentAssetId, startDate);
      this.getAllAssets();
    } catch (e) {
      this.message = 'Server error';
    }
  }

  // *calculateTestDividends(startDateProps: Date, endDateProps: Date, dividends: number) {
  //   this.isLoading = true;
  //   const startDate = moment(startDateProps).toISOString();
  //   const endDate = moment(endDateProps).toISOString();
  //   this.currentDeposit = dividends;
  //   this.getCurrentDate(startDate, endDate);
  //   try {
  //     this.message = '';
  //     const calculateDividends: IInvestorDividend[] = yield dividendService.calculateTestDividends(
  //       this.currentAssetId,
  //       startDate,
  //       endDate,
  //       dividends,
  //     );
  //     if (Array.isArray(calculateDividends)) {
  //       this.setCalculateDividends(calculateDividends);
  //     }
  //     this.isLoading = false;
  //   } catch (e) {
  //     this.isLoading = false;
  //     this.message = 'End Date should be greater than start date';
  //   }
  // }

  *calculateAdminDividends(assetId: string, startDate: string, endDate: string, dividends: number) {
    this.isLoading = true;
    this.currentDeposit = dividends;
    this.getCurrentDate(startDate, endDate);
    try {
      this.message = '';
      const calculateDividends: IInvestorDividend[] = yield dividendService.calculateAdminDividends(
        assetId,
        startDate,
        endDate,
        dividends,
      );
      if (Array.isArray(calculateDividends)) {
        this.setCalculateDividends(calculateDividends);
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'End Date should be greater than start date';
    }
  }

  *calculateIssuerDividends(assetId: string, startDate: string, endDate: string, dividends: number) {
    this.isLoading = true;
    this.currentDeposit = dividends;
    this.getCurrentDate(startDate, endDate);
    try {
      this.message = '';
      const calculateDividends: IInvestorDividend[] = yield dividendService.calculateIssuerDividends(
        assetId,
        startDate,
        endDate,
        dividends,
      );
      if (Array.isArray(calculateDividends)) {
        this.setCalculateDividends(calculateDividends);
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.message = 'End Date should be greater than start date';
    }
  }

  *exportAdminReport(assetId: string, startDate: string, endDate: string, dividends: number) {
    try {
      this.message = '';
      const file: Blob = yield dividendService.exportAdminDividends(assetId, startDate, endDate, dividends);
      exportXlsx(`Dividend calculations, ${this.currentStartDate} - ${this.currentEndDate}`, file);
    } catch (e) {
      this.message = 'End Date should be greater than start date';
    }
  }

  *exportIssuerReport(assetId: string, startDate: string, endDate: string, dividends: number) {
    try {
      this.message = '';
      const file: Blob = yield dividendService.exportIssuerDividends(assetId, startDate, endDate, dividends);
      exportXlsx(`Dividend calculations, ${this.currentStartDate} - ${this.currentEndDate}`, file);
    } catch (e) {
      this.message = 'End Date should be greater than start date';
    }
  }
}

export default DividendStore;
