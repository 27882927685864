import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import PptIcon from '../../../shared/assets/asset-view/ppt.png';
import c from './styles.module.scss';
import { useStores } from '../../../store';

function AdvancePresentaion() {
  const { assetStore } = useStores();
  const [download, setDownload] = useState(false);

  const presentationHandler = () => {
    const docLink = assetStore.currentAsset.presentationDocument?.url;
    if (docLink) {
      const a = document.createElement('a');
      a.href = docLink;
      a.download = 'presentation.pdf';
      a.target = '_blank';
      a.click();
      setDownload(true);
      setTimeout(() => {
        setDownload(false);
      }, 500);
    }
  };

  return (
    <div className={c.assetPresentation}>
      <img src={PptIcon} alt="" className={c.ppt} />
      <div className={c.inner}>
        <div className={c.title}>{assetStore.currentAsset.name}</div>
        <div className={c.subtitle}>Full Investor Presentation</div>
        <button className={c.enter} onClick={presentationHandler}>
          Download Now
        </button>
        {download ? <span className={c.downloaded}>Thanks for downloading!</span> : null}
      </div>
    </div>
  );
}

export default observer(AdvancePresentaion);
