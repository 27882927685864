import React from 'react';
import { NonMintedAsset } from '../../../../shared/types/investorTableType';
import NonMintedRow from './nonMintedRow/NonMintedRow';

interface NonMintedAssetsListProps {
  assets: NonMintedAsset[];
}

const NonMintedAssetsList = ({ assets }: NonMintedAssetsListProps) => {
  if (!assets.length) return null;

  return (
    <>
      {assets.map(asset => (
        <NonMintedRow key={asset.id} asset={asset} />
      ))}
    </>
  );
};

export default NonMintedAssetsList;
