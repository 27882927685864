import React, { useEffect, useRef } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ErrorMessage, useField } from 'formik';
import moment, { Moment } from 'moment';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import c from '../formik-input/style.module.scss';
import { FormikDateProps } from './formik-date.types';

const ErrorMessageWrapper = styled('div')`
  color: #f05555;
  font-size: 12px;
  margin-top: 8px;
`;

const Label = styled('div')`
  font-size: 16px;
  margin-bottom: 8px;
`;

const FormikDate = (props: FormikDateProps) => {
  const { name, label } = props;
  const [field, meta, helpers] = useField(name);
  const errorRef = useRef<null | HTMLDivElement>(null);
  const onChangeHandler = (newValue: Moment | null) => {
    helpers.setError('');
    helpers.setValue(newValue?.format('MM-DD-YYYY') || '');
  };
  useEffect(() => {
    if (!errorRef.current) return;
    if (!meta.error) return;
    errorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [meta.error]);
  return (
    <div className={c.wrapper}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Label>{label}</Label>
        <DesktopDatePicker
          ref={errorRef}
          onChange={onChangeHandler}
          value={moment(field.value)}
          renderInput={params => <TextField error={!!meta.error} {...params} fullWidth className={c.input} />}
          inputFormat="DD/MM/YYYY"
          onError={error => helpers.setError(error || '')}
        />
        {meta.error && (
          <ErrorMessageWrapper>
            <ErrorMessage name={name || ''} />
          </ErrorMessageWrapper>
        )}
      </LocalizationProvider>
    </div>
  );
};

export default FormikDate;
