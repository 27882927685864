import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app/App';
import English from './app/localization/en.json';
// import Russian from './localization/ru.json';

const locale = navigator.language;
const lang = English;

// if (locale === 'ru-RU') {
//   lang = Russian;
// }

Sentry.init({
  dsn: 'https://b72bf4a7df604f89982bf00565a2c191@o4504316434907136.ingest.sentry.io/4504316439494656',
  integrations: [new BrowserTracing()],
  ignoreErrors: [
    'Request failed with status code 401',
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  environment: `${process.env.REACT_APP_SENTRY}`,
  tracesSampleRate: 0.9,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={lang}>
      <App />
    </IntlProvider>
  </React.StrictMode>,
);
