import { Stack, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { getFormmatedDateAndTime } from '../../../shared/lib';
import { IActivityLogRowProps } from '../interfaces';
import c from '../style.module.scss';

const ActivityLogRow: FC<IActivityLogRowProps> = ({ log }) => {
  const { dateFormatted, timeFormatted } = getFormmatedDateAndTime(log.timestamp);
  return (
    <TableRow key={log.id} className="tableRowDT">
      <TableCell className="cellDT">
        <Stack>
          <div>{dateFormatted}</div>
          <div className={c.time}>{timeFormatted}</div>
        </Stack>
      </TableCell>
      <TableCell className="cellDT">{log.description}</TableCell>
    </TableRow>
  );
};

export default ActivityLogRow;
