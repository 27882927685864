import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../../store/index';

export default function RedirectPage() {
  const navigate = useNavigate();
  const { headerStore } = useStores();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      navigate('/assets');
      return;
    }
    const sessionIdInfo: any = jwtDecode(accessToken);
    const tokenRole = sessionIdInfo['cognito:groups'].find((elem: string) => elem.includes('role')).slice(5);
    headerStore.setUserRoleAndName({ role: tokenRole, name: sessionIdInfo.username });
    if (tokenRole === 'admin') {
      navigate('/issuers');
      return;
    }
    if (tokenRole === 'issuer' || tokenRole === 'demoIssuer') {
      navigate('/investors');
      return;
    }
    if (tokenRole === 'investor') {
      navigate('/assets');
    }
  }, [headerStore, navigate]);

  return <Spinner />;
}
