import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import notification from '../../shared/assets/profileMenu/notification.svg';
import c from './style.module.scss';
import { IParsedNotification } from '../../shared/services/verefication/verefication.types';
import NotificationItem from './notificationItem';

function AccountNotificationsCollapse({ notifications }: { notifications: IParsedNotification[] }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <div className={c.dropDownMenu}>
      <div className={c.mobileNotifications}>
        <button
          className={c.notifications}
          onClick={() => {
            navigate('/notifications');
          }}
        >
          <img alt="logout-icon" src={notification} />
          <div className={`${c.notificationBlock} ${notifications.length > 0 ? `${c.show}` : ''}`}>
            {notifications.length}
          </div>
        </button>
      </div>
      <div className={c.topMenu}>
        <button
          className={c.notifications}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img alt="logout-icon" src={notification} />
          <div className={`${c.notificationBlock} ${notifications.length > 0 ? `${c.show}` : ''}`}>
            {notifications.length}
          </div>
        </button>
        {open && <div className={c.backdrop} onClick={closeMenu} aria-hidden="true" />}
        <div className={`${c.subMenu} ${open ? c.open : ''}`}>
          <p className={c.title}>New Notifications</p>
          <div className={c.list}>
            {notifications?.length === 0 && <div className={c.notification}>No notifications yet</div>}
            {notifications?.length > 0 &&
              notifications.map((item, index) => <NotificationItem notification={item} key={index} />)}
          </div>
          <Link className={c.link} to="/notifications" onClick={closeMenu}>
            All Notifications
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AccountNotificationsCollapse;
