import React, { FC } from 'react';
import { Box, IconButton, Stack, TableCell, TableRow } from '@mui/material';
import { IBrokerRow } from '../../../shared/interfaces/issuer/issuer.interfaces';
import copyIcon from '../../../shared/assets/icons/copyMail.svg';
import c from './style.module.scss';
import copyDataInBuffer from '../../../shared/lib/copy-data-in-buffer';

interface BrokerRowProps {
  brokerRow: IBrokerRow;
}

const HolderRow: FC<BrokerRowProps> = ({ brokerRow }) => {
  const { firstName, lastName, email, assets } = brokerRow;

  return (
    <TableRow className={c.tableRow}>
      <TableCell className={c.nameCell}>
        <Stack>
          <Stack direction="row" alignItems="center">
            {firstName} {lastName}
          </Stack>
          <Stack direction="row" alignItems="center" gap={8}>
            <div className={c.email}>{email}</div>
            <IconButton onClick={() => copyDataInBuffer(email)} sx={{ p: 0 }}>
              <img src={copyIcon} alt="" />
            </IconButton>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell className={c.cell}>
        <Stack gap={32}>
          {assets.length > 0 && assets.map(({ assetId, name }) => <Box key={assetId}>{name}</Box>)}
        </Stack>
      </TableCell>
      <TableCell className={c.cell} align="center">
        <Stack gap={32}>
          {assets.length > 0 && assets.map(({ assetId, clickCounter }) => <Box key={assetId}>{clickCounter}</Box>)}
        </Stack>
      </TableCell>
      <TableCell className={c.cell} align="center">
        <Stack gap={32}>
          {assets.length > 0 && assets.map(({ assetId, registrations }) => <Box key={assetId}>{registrations}</Box>)}
        </Stack>
      </TableCell>
      <TableCell className={c.cell} align="center">
        <Stack gap={32}>
          {assets.length > 0 && assets.map(({ assetId, kycPassed }) => <Box key={assetId}>{kycPassed}</Box>)}
        </Stack>
      </TableCell>
      <TableCell className={c.cell} align="center">
        <Stack gap={32}>
          {assets.length > 0 && assets.map(({ assetId, purchases }) => <Box key={assetId}>{purchases}</Box>)}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default HolderRow;
