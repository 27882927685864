import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import c from './style.module.scss';

function Spinner({ single, medium, style, size }: { single?: true, medium?: true, style?: React.CSSProperties, size?: number }) {
  if (single) {
    return (
      <CircularProgress
        size={size}
        className={c.loading}
        style={{ color: '#00BC68', ...style }}
      />
    );
  }

  if (medium) {
    return (
      <div className={c.spinnerMeduimWrapper}>
        <CircularProgress
          className={c.loading}
          style={{ color: '#00BC68', ...style }}
        />
      </div>
    );
  }

  return (
    <div className={c.spinnerWrapper}>
      <CircularProgress
        className={c.loading}
        style={{ color: '#00BC68', ...style }}
      />
    </div>
  );
}

export default Spinner;
