import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IconButton, Button } from '@mui/material';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import c from './styles.module.scss';
import sliderArrowPrev from '../../../../shared/assets/icons/sliderArrowPrev.svg';
import sliderArrowNext from '../../../../shared/assets/icons/sliderArrowNext.svg';
import { useStores } from '../../../../store';
import { CustodialAccount, ElementTypes } from '../../../../shared/interfaces/walletInterface';
import { theme } from '../../../../mui-theme';

function NavArrow(props: any) {
  const { onClick, className } = props;
  return (
    <IconButton
      disabled={className.includes('slick-disabled')}
      onClick={onClick}
      className={`${c.nav} ${className.includes('slick-next') && c.next} `}
    >
      <img src={className.includes('slick-next') ? sliderArrowNext : sliderArrowPrev} alt="" />
    </IconButton>
  );
}

function SliderItem({ account, openWidget }: { account: CustodialAccount; openWidget: (id: string) => void }) {
  return (
    <div className={c.slider_item}>
      <header>
        {/* <img src={asset.iconSrc} alt={asset.name} /> */}
        <div className={c.img}>{account.name.slice(0, 1)}</div>
        <span className={c.name}>
          {account.name} <span className={c.symbol}>{account.tickerSymbol ? account.tickerSymbol : ''}</span>
        </span>
      </header>
      <main>
        {/* {account.fundedPercentage === 100 ? (
          <p className={c.funded}>Funded 100%</p>
        ) : (
          <p className={c.funding}>{`Funding in progress ${asset.fundedPercentage}%`}</p>
        )} */}
        <div className={c.field}>
          <div className={c.name}>Raised till now</div>
          <div className={c.value}>
            {account.balances[0].total} {account.balances[0].assetFiatType.toUpperCase()}
          </div>
        </div>
        {/* <div className={c.field}>
          <div className={c.name}>My tokens</div>
          <div className={c.value}>{`${asset.myTokens} ${asset.tickerSymbol ? asset.tickerSymbol : 'tokens'}`}</div>
        </div> */}
      </main>
      <footer>
        <Button
          onClick={() => {
            openWidget(account.id);
          }}
          disabled={account.balances[0].total === 0}
        >
          Withdraw
        </Button>
      </footer>
    </div>
  );
}

// @ts-ignore
const EJS = window.FortressElementsJS;

function IssuerAssetsSlider() {
  const { walletStore } = useStores();
  useEffect(() => {
    walletStore.getCustodialAccounts();
  }, [walletStore]);

  if (walletStore.custodialAccounts.length === 0) return null;

  const ElementTransferWithDrawal = EJS.createElementClient({
    elementName: EJS.ElementNames.TRANSFER,
    theme: { primaryColor: theme.palette.primary.main },
    paymentMethodTypes: 'Ach+Wire',
    transferDirection: 'Withdrawal',
  });

  const withdrawalWidgetOpen = async (id: string) => {
    await getJwt(ElementTypes.TRANSFER, id);
  };

  const getJwt = async (element: ElementTypes, id: string) => {
    try {
      const currentJWT = await walletStore.getJWT(element, id);
      if (!currentJWT) {
        return;
      }
      ElementTransferWithDrawal.run(currentJWT);
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Slider
      className={c.slider}
      prevArrow={<NavArrow />}
      nextArrow={<NavArrow />}
      speed={500}
      slidesToShow={3}
      slidesToScroll={1}
      infinite={false}
      centerMode={false}
      responsive={[
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1090,
          settings: {
            slidesToShow: 1,
          },
        },
      ]}
    >
      {walletStore.custodialAccounts.map(account => (
        <SliderItem account={account} key={account.id} openWidget={withdrawalWidgetOpen} />
      ))}
    </Slider>
  );
}

export default observer(IssuerAssetsSlider);
