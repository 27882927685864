import { useState } from 'react';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CopyIcon from '../../../shared/assets/icons/copyMail.svg';
import c from './styles.module.scss';

const GreenTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#00BC68',
    minWidth: '116px',
    border: 'none',
    color: 'white',
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    borderRadius: 2,
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 24,
    width: 24,
    color: '#00BC68',
  },
}));

const ClipboardCopy = (props: { text: string }) => {
  const { text } = props;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setOpen(false);
    }, 1500);
    setTimeout(() => {
      setIsCopied(false);
    }, 1750);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (!isCopied) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <GreenTooltip
      style={{ marginLeft: '10px' }}
      title={isCopied ? 'Copied!' : 'Copy Text'}
      placement="top"
      arrow
      disableInteractive
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      TransitionProps={{ timeout: 500 }}
      TransitionComponent={Fade}
    >
      <button className={c.copyIcon} onClick={handleCopyClick}>
        <img src={CopyIcon} alt="copy icon" />
      </button>
    </GreenTooltip>
  );
};

export default ClipboardCopy;
