import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import LoadingFavicon from '../../shared/assets/icons/loading-fav.png';
import LogoIcon from '../../shared/assets/icon32.png';

const PortalMetaInfo = observer(() => {
  const { portalStore } = useStores();

  if (!portalStore.isCustomPortal) return null;

  useEffect(() => {
    if (!portalStore.isCustomPortalLoading && portalStore.portalUI?.portalUi?.colorStyles) {
      const {
        primary,
        primaryDark,
        hoverGreen,
        lightGreen,
        bg,
        text,
        textDark,
        menuText,
        textSecondary,
        textGrey,
        buttonDisabled,
        inputBg,
        tableHeadBg,
        tableHeadText,
      } = portalStore.portalUI.portalUi.colorStyles;
      const colorScheme = {
        primary,
        'primary-dark': primaryDark,
        'hover-green': hoverGreen,
        'light-green': lightGreen,
        bg,
        text,
        'text-dark': textDark,
        'menu-text': menuText,
        'text-secondary': textSecondary,
        'text-grey': textGrey,
        'button-disabled': buttonDisabled,
        'input-bg': inputBg,
        'table-head-bg': tableHeadBg,
        'table-head-text': tableHeadText,
      };
      for (const [key, value] of Object.entries(colorScheme)) {
        document.documentElement.style.setProperty(`--${key}`, value);
      }
    }
    if (portalStore.portalUI?.portalUi?.fontStyle) {
      document.body.style.fontFamily = `${portalStore.portalUI.portalUi.fontStyle}, Montserrat`;
    }
  }, [
    portalStore.portalUI?.portalUi?.colorStyles,
    portalStore.isCustomPortalLoading,
    portalStore.portalUI?.portalUi?.fontStyle,
  ]);

  return (
    <Helmet>
      {portalStore.portalUI?.portalUi?.metaDescription && (
        <meta name="description" content={portalStore.portalUI.portalUi.metaDescription} />
      )}
      {portalStore.portalUI?.portalUi?.metaImage && (
        <meta name="og:image" content={portalStore.portalUI.portalUi.metaImage.url} />
      )}
      {portalStore.isCustomPortalLoading ? (
        <>
          <title>Loading...</title>
          <link rel="icon" href={LoadingFavicon} type="image/png" />
        </>
      ) : (
        <>
          {/* <title>{portalStore.portalUI?.portalUi?.title || 'TokenSuite'}</title> */}
          <link rel="icon" href={portalStore.portalUI?.portalUi?.favicon?.url || LogoIcon} type="image/png" />)
        </>
      )}
    </Helmet>
  );
});

export default PortalMetaInfo;
