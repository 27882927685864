import { AxiosInstance } from 'axios';
import tokenInstance from '../API/tokenApi';
import { CreatePortalDto, TokenOnPortal } from './types';

class TokenService {
  authInstance: AxiosInstance;

  constructor(authInstance: AxiosInstance) {
    this.authInstance = authInstance;
  }

  async createToken(dto: CreatePortalDto): Promise<void> {
    await this.authInstance.post('token', dto);
  }

  async getTokens(): Promise<TokenOnPortal[]> {
    const { data } = await this.authInstance.get<TokenOnPortal[]>('token');
    return data;
  }

  async getTokensByPortalId(portalId: string): Promise<TokenOnPortal[]> {
    const { data } = await this.authInstance.get<TokenOnPortal[]>(`initial-sale/token/${portalId}`);
    return data;
  }

  async getTokensByPortalIssuer(): Promise<TokenOnPortal[]> {
    const { data } = await this.authInstance.get<TokenOnPortal[]>('token/issuer');
    return data;
  }

  async mintTokenByAdmin(tokenId: string, amount: number): Promise<void> {
    await this.authInstance.post(`token/mint/${tokenId}`, { amount });
  }

  async distributeTokenByAdmin(contractAddress: string): Promise<void> {
    await this.authInstance.get(`initial-sale/distribute/${contractAddress}`);
  }
}

export default new TokenService(tokenInstance);
