import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IconButton, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect, ReactElement } from 'react';
import Spinner from '@token-suite/common-ui/src/components/Spinner/spinner';
import { FormattedMessage } from 'react-intl';
import addComma from '@token-suite/common-ui/src/sharedFunctions/addCommaFunction';
import exportCsv from '@token-suite/common-ui/src/sharedFunctions/exportCsv';
import c from './styles.module.scss';
import tokenInstance from '../../../shared/services/API/tokenApi';
import { CURRENCY_SYMBOLS } from '../../../shared/config/constants';
import filterDisabled from '../../../shared/assets/icons/filterArrowsDisabled.svg';
import filterASC from '../../../shared/assets/icons/filterArrowsASC.svg';
import filterDESC from '../../../shared/assets/icons/filterArrowsDESC.svg';
import exportIcon from '../../../shared/assets/icons/export.svg';
import { usePagination } from '../../../shared/hooks/usePagination';
import { RequestParams } from '../../../shared/types/investorTableType';
import AllowListRow from './allowListRow';

type Column = {
  id: any;
  label: ReactElement | null;
  minWidth?: number;
  sortable?: true;
  left?: true;
  format?: (asset: any) => string;
  render?: (asset: any) => JSX.Element;
};

export interface ProfileData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  vereficationStatus: {
    isBusiness: boolean;
  };
}

const columns: readonly Column[] = [
  {
    id: 'name',
    left: true,
    label: <FormattedMessage id="investorsPage.allowList.table.columns.name" />,
    sortable: true,
  },
  {
    id: 'assetName',
    left: true,
    label: <FormattedMessage id="investorsPage.allowList.table.columns.assetName" />,
  },
  {
    id: 'token',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.token" />,
  },
  {
    id: 'tokenAllocation',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.tokenAllocation" />,
    format: user => `${addComma(String(user.tokenAllocation))}`,
  },
  {
    id: 'value',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.value" />,
    format: user => `${addComma(String(user.value))}`,
  },
  {
    id: 'currency',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.currency" />,
  },
  {
    id: 'tokenPrice',
    label: <FormattedMessage id="investorsPage.allowList.table.columns.tokenPrice" />,
    format: user => `${CURRENCY_SYMBOLS[user.currency]}${addComma(String(user.tokenPrice))}`,
  },
];

export function AllowList() {
  const [items, setItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const loadItems = async (requestParams: RequestParams) => {
    setIsLoading(true);
    const { data } = await tokenInstance.get<{ users: ProfileData[]; total: number }>('users/investors/allow-list', {
      params: requestParams,
    });
    setItems(data.users);
    setTotalItems(data.total);
    setIsLoading(false);
  };

  const {
    loadItems: load,
    onPageSizeChange,
    onCurrentPageChange,
    controlledParams,
    onOrderChange,
  } = usePagination({
    callback: loadItems,
  });

  const renderOrderIcon = (id: 'name' | 'status') => {
    if (controlledParams.value === id && controlledParams.sortOrder === 'DESC') return <img src={filterDESC} alt="" />;
    if (controlledParams.value === id && controlledParams.sortOrder === 'ASC') return <img src={filterASC} alt="" />;
    return <img src={filterDisabled} alt="" />;
  };

  useEffect(() => {
    load();
  }, [load]);

  const exportCsvHandler = () => {
    tokenInstance.get('users/investors/allow-list/csv').then((data: any) => {
      exportCsv('Allow-list', data.data);
    });
  };

  return (
    <div className={c.wrapper}>
      <div className={c.topPanel}>
        <div className={c.filterPanel}>
          <div className={c.left}>
            <div className={c.text}>
              <FormattedMessage id="investorsPage.allowList.table.assetFilter.name" />
            </div>
            <Select disabled className={c.select} value="All">
              <MenuItem value="All">All</MenuItem>
            </Select>
          </div>
          <div className={c.right}>
            <button className={c.button} onClick={exportCsvHandler}>
              <img src={exportIcon} alt="exportIcon" />
              Export CSV
            </button>
          </div>
        </div>
      </div>
      <TableContainer className={c.table_container}>
        <Table className={c.table} stickyHeader aria-label="sticky table">
          <TableHead className={c.head}>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                  <div className={`${column.left ? `${c.flexHead} ${c.left}` : `${c.flexHead}`}`}>
                    {column.label}
                    {column.sortable && (
                      <IconButton disabled={isLoading} onClick={() => onOrderChange(column.id)}>
                        {renderOrderIcon(column.id)}
                      </IconButton>
                    )}
                  </div>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={20} align="center" className={c.loadingCell}>
                {isLoading && (
                  <div className={c.loading}>
                    <Spinner single />
                  </div>
                )}
              </TableCell>
            </TableRow>

            {items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={20}>
                  <div className={c.emptyBlock}>
                    <FormattedMessage id="investorsPage.onbordingInvestors.table.text.noInvestorsMessage" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              items.map((row: ProfileData) => {
                return <AllowListRow key={row.id} row={row} isLoading={isLoading} />;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={c.pagination}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={+controlledParams.pageSize}
        page={totalItems <= 0 ? 0 : +controlledParams.currentPage}
        onPageChange={(_, newPage: number) => onCurrentPageChange(newPage)}
        onRowsPerPageChange={e => onPageSizeChange(e.target.value)}
        SelectProps={{
          disabled: isLoading,
        }}
        backIconButtonProps={{
          disabled: isLoading || +controlledParams.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            isLoading ||
            Math.ceil(totalItems / +controlledParams.pageSize) - 1 === +controlledParams.currentPage ||
            totalItems === 0,
        }}
      />
    </div>
  );
}
